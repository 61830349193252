import React from "react";
import PropTypes from "prop-types";
import Anchor from "../Anchor/Anchor";
import {withStyles} from "@material-ui/core";
import classnames from "classnames";

const styles = theme => ({
    text: {
        whiteSpace: "pre-wrap"
    },
    html: {},
    open: {},
    closed: {
        maxHeight: "17px",
        overflow: "hidden",
    },
    fadedContent: {
        maxHeight: "60px",
        overflow: "hidden",

    },
    anchor: {
        display: "block",
        marginBottom: "5px",
    }
});

const openClass = classes => classes.open;
const closedClass = (classes, showFadedContent) => showFadedContent ? classes.fadedContent : classes.closed;

class PartialComponent extends React.Component {

    static propTypes = {
        showFadedContent: PropTypes.bool,
        Component: PropTypes.any.isRequired,
        classes: PropTypes.object.isRequired,
    };

    state = {
        open: false,
    }

    render() {

        const {Component, showFadedContent = true, variant = "body1", classes, ...props} = this.props
        const {open} = this.state;

        return <div className={classnames(open ? openClass(classes)
                                               : closedClass(classes, showFadedContent))}
                    component="div"
                    variant={variant}>
            <Anchor onClick={_ => this.setState({open: !open})}>
                <span className={classes.anchor}>{open ? "Verberg details" : "Toon details"}</span>
            </Anchor>
            <Component {...props}/>
        </div>

    }
}

export default withStyles(styles)(PartialComponent);
