/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 19-4-18.
 */
import React from "react";
import {postBusiness} from "../postCustomer";
import {Forms} from "../../../../../../../api/form/FormClient";
import ModalForm from "../../../../../../component/form/ModalForm/ModalForm";
import Header from "../markup/Header";
import Item from "../markup/Item";
import {stringify} from "../../../../../../../util/util";
import {Grid} from "@material-ui/core";
import {businessPersonUpdater, businessType} from "../updater/businessPersonUpdater";

const hasEmployeeType = type => person => person.employeeType === type;

const flatten = x => stringify(x);
const description = person => `${person.initials} ${person.insertPart} ${person.lastName}`;

const BusinessPeople =
    (type, title) =>
        ({customer}) =>
            <div>
                <Grid container>
                    <Header title={title}
                            modalForm={<ModalForm formEndpoint={Forms.editBusinessPerson}
                                                  data={{}}
                                                  icon="add_circle"
                                                  submitAction={postBusiness(businessPersonUpdater(type))(customer)}/>}/>
                </Grid>
                <Grid container>
                    {
                        customer.employees === null
                        ? null
                        : customer.employees
                                  .filter(hasEmployeeType(type))
                                  .map((employee, index) => <Item key={index}
                                                                  object={employee}
                                                                  icon="person"
                                                                  getDescription={description}
                                                                  modalForm={<ModalForm formEndpoint={Forms.editBusinessPerson}
                                                                                        data={flatten(employee)}
                                                                                        submitAction={
                                                                                            postBusiness(businessPersonUpdater(type, index))(customer)
                                                                                        }/>}/>)
                    }
                </Grid>
            </div>;

const Directors = BusinessPeople(businessType.director, "Bestuurders");
const Employees = BusinessPeople(businessType.employee, "Werknemers");

export {
    Directors,
    Employees,
};
