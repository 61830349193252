/**
 * Applying this middleware to the store allows you to dispatch Actions of type PROMISE to the store:
 * {
 *     type: promiseActionType.PROMISE
 *     promise: Promise<Action>
 *
 *     errorExceptionAction:  error => Action
 *     start: Action
 * }
 * where promise is a Promise for an action that will be dispatched when once resolved.
 *
 * Optional fields:
 * errorExceptionsAction: if the promise rejects this ActionCreator will be used to create
 *                        an action to be dispatched
 *                        Defaults to an ActionCreator for an action of type promiseActionType.ERROR.
 *
 * start:                 Action that will be dispatched immediately (unless the promise is allready fullfilled)
 *                        The start-action can for example be used to transition to a state
 *                        that shows a waiting spinner.
 *
 * USAGE:
 *
 * import {createStore, applyMiddleware, compose} from 'redux'
 * import promiseDispatch from ....
 *
 * then:
 * replace      reduxStore = createStore(reducer)
 * with         reduxStore = createStore(reducer, applyMiddleware(promiseDispatch))
 *
 * or (in combination with more middleware/enhancers):
 * replace    reduxStore = createStore(reducer, reduxBrowserExtension)
 * const      reduxStore = createStore(reducer, compose(applyMiddleware(promiseDispatch),
 *                                                      reduxBrowserExtension));
 *
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 20-7-16.
 */
import Promise from "bluebird";

/**
 * @typedef {string} ActionType
 */

/**
 * An action is an object that will contain a number of fields; it will at the very
 * least  contain a field type (an ActionType, i.e. a string).
 *
 * Additional fields present depend on the choice of ActionType
 *
 * @typedef {Object} Action
 * @property {ActionType} type
 */

/**
 * Function that uses a full state object in order to create a new Action
 *
 * @callback StateActionCreator
 * @param {object} state - the full redux state
 * @return {Action}
 */

/**
 * Returns true iff the object is a thennable (ie. if the object is a Promise)
 * @param object
 * @return {boolean}
 */
const isThennable = object => typeof object.then === 'function';

/**
 * @type {Object}
 * @property {ActionType} COMPOUND
 * @property {ActionType} ERROR
 */
const promiseActionType = Object.freeze(
    {
        PROMISE: "PROMISE_DISPATCH__PROMISE",
        ERROR: "PROMISE_DISPATCH__ERROR",
    },
);

/**
 *
 * @param error
 * @return {Action}
 */
const defaultExceptionAction = error => {
    return {
        type: promiseActionType.ERROR,
        error: error,
    };
};

/**
 * @param {Promise<Action|StateActionCreator>}  promise
 * @param {Action|null}      [start=null]
 * @param {Action|function(object): Action}  [exceptionAction=defaultExceptionAction]
 * @returns {Action}
 */
const promiseAction = (promise, start = null, exceptionAction = defaultExceptionAction) => {
    return {
        type: promiseActionType.PROMISE,
        start: start,
        exceptionAction: exceptionAction,
        promise: promise,
    };
};

/**
 * Tries to create a string representation of the variable that's useful for debugging.
 * @param variable
 * @return {string}
 */
const toString = variable => {
    switch (typeof variable) {
        case  "function":
        case  "symbol":
            return variable.toString();
        default:
            return JSON.stringify(variable, null, 2);
    }
};

const promiseDispatch =
    store =>
        nextDispatch =>
            action => {

                if (action.type === promiseActionType.PROMISE) {
                    action.promise = Promise.resolve(action.promise); // 'cast' the regular promise to a Bluebird-Promise: provides the isFulfilled function

                    const exceptionAction = (typeof action.exceptionAction === "function")
                                            ? action.exceptionAction
                                            : defaultExceptionAction;

                    if (action.start !== null &&
                        !action.promise.isFulfilled()) {
                        store.dispatch(action.start);
                    }

                    let message = "Promise was rejected"; // prepare a message, just in case anything goes wrong
                    const updateMessage = action => {
                        message = `Could not dispatch aynchronously obtained action:\n${toString(action)}`;
                        return action;
                    };

                    action.promise
                          .then(updateMessage)
                          .then(promisedAction => {
                              if (typeof promisedAction === "function") {
                                  store.dispatch(promisedAction(store.getState()));
                              }
                              else {
                                  store.dispatch(promisedAction);
                              }

                              // We used to simply return store.dispatch(promisedAction), but the return type for dispatch
                              // is not properly defined by redux.  Apparently one of the middlewares (redux-router?) has
                              // chosen not to return a value at all (which is equivalent to  returning undefined).
                              //
                              // Now when an argument to then() is a function that returns undefined bluebird warns about
                              // forgotten return statements. In this case not returning anything is intended  behaviour:
                              // this is simply the end of the promise-pipeline
                              //
                              // So we tell bluebird about that by explicitly returning null
                              return null;

                          })
                          .catch(error => {
                              console.error(error);
                              console.error(`PromiseDispatch Error: ${message} -- ${error.message}`);
                              store.dispatch(exceptionAction(error));
                          });
                    return action;
                }
                else {
                    if (isThennable(action)) {
                        console.warn("Attempting to dispatch a promise [dispatch(promise)]. Did you mean [promise.then(dispatch)]?");
                    }
                    return nextDispatch(action);
                }
            };

export {
    promiseActionType,
    promiseAction,
    promiseDispatch,
};
export default promiseDispatch;
