import If from "../../Optional/If";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import NotListedLocation from "@material-ui/icons/NotListedLocation";
import React from "react";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 14-9-18.
 */
const Tip = ({tip}) =>
    <If show={typeof tip === "string" && tip !== ""}>
        <Tooltip title={tip}>
            <IconButton aria-label="Tooltip icon">
                <NotListedLocation color="primary"/>
            </IconButton>
        </Tooltip>
    </If>;



export default Tip;
