import {parseMulti} from "../role";

const userUpdater = (user, form) => {
    form.groups.forEach(function (group) {
        group.questions.forEach(function (question) {
            const field = question.field;

            if (field === "roles") {
                user.roles = parseMulti(JSON.parse(question.value))
            } else {
                user[field] = question.value;
            }
        });
    });
    return user;
};

export default userUpdater;
