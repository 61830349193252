import React from "react";
import {withStyles} from "@material-ui/core";

/**
 * Material Design does not define a anchor element (it is designed for android apps, not for html-pages)
 * and the material-ui does not provide a Anchor element. As a result it's not directly possible to use the
 * material-ui theming to uniformly style all anchors.
 *
 * In order to inject the theme into <a/>-elements we provide this component that just wraps an  <a/> element
 *
 *
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 17-12-18.
 */


const anchorStyles = theme => ({
    anchor: {
        color: theme.skin.palette.anchors.regular,
        cursor: "pointer",
        '&:hover': {
            color: theme.skin.palette.anchors.hover,
            textDecoration: "underline",
        },
        '&:visited': {
            color: theme.skin.palette.anchors.visited,
        }
    }
});

const Anchor = ({href, onClick, children, classes}) =>
    <a href={href}
       className={classes.anchor}
       onClick={onClick}>
        {children}
    </a>;

export default withStyles(anchorStyles)(Anchor);
