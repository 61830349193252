/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 13-6-18.
 */
import moment from "moment/moment";

const businessType = {
    director: "Directors and Officers",
    employee: "employee",
};

function emptyBusinessPerson(type) {
    return {
        employeeType: type,
        startDate: moment().format("DD-MM-YYYY"),
        active: true,
        type: type,
    }
}

/**
 * @param type
 * @param personIndex
 * @return {updater}
 */
const businessPersonUpdater =
    (type, personIndex = -1) =>
        (customer, form) => {

            let member = personIndex < 0 ? emptyBusinessPerson(type) : customer.employee[personIndex];

            form.groups.forEach(function (group) {
                group.questions.forEach(function (question) {
                    const field = question.field;
                    member[field] = question.parsedValue;
                });
            });

            if (personIndex < 0) {
                if (!(customer.employee instanceof Array)) {
                    customer.employees = [];
                }
                customer.employees.push(member);
            }
            else {
                customer.employees[personIndex] = member;
            }

            return customer;
        };

export {
    businessType,
    businessPersonUpdater,
}
