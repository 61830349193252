import {logResult, stringify, throwErrors} from "./util";

/**
 * Variant of 'postJson' for services that do not return values
 * (e.g. just a '204: No Content' header)
 *
 * @param {string} serviceUrl
 * @returns {function(object): Promise<null>}
 */
const postJsonNoContent =
    serviceUrl =>
        data => {

            if (global.LOG_API_CALLS) {
                let text = "\n";
                text += `POST ${serviceUrl}\n`;
                text += stringify(data, null, 2);
                text += `Content-Type: application/json\n`;
                text += "\n";
                console.log(text);
                text += "\n";
                text += "### [postJsonNoContent()]\n";
                console.debug(text);
            }

            return fetch(serviceUrl, {
                method:  "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body:    JSON.stringify(data),
            }).then(throwErrors)
              .then(logResult(`[postJsonNoContent()] Posted data to '${serviceUrl}'`, global.LOG_API_CALLS));
        };

/**
 *
 * Variant of 'postJson' for services that return json (eg a copy of the value that was just successfully posted to the
 * service)
 * (e.g. just a '204: No Content' header)
 *
 * @param {string} serviceUrl
 * @param {object} cache
 * @returns {function(object): Promise<object>}
 */
const postJson = (serviceUrl, cache = {}) => data => {
    const key = JSON.stringify(data);

    if (global.LOG_API_CALLS) {
        let text = "\n";
        text += `POST ${serviceUrl}\n`;
        console.log(text);
        text += "Accept: application/json\n"
        text += `Content-Type: application/json\n`;
        text += "\n";
        text += stringify(data, null, 2);
        text += "\n";
        text += "### [postJson()]\n";
        console.debug(text);
    }

    if (!cache.hasOwnProperty(key)) {
        cache[key] = fetch(serviceUrl, {
            method:  "post",
            headers: {
                "Accept":       "application/json",
                "Content-Type": "application/json",
            },
            body:    stringify(data),
        });
    }
    return cache[key]
        .then(throwErrors)
        .then(result => result.json())
        .then(logResult(`[postJson()] Posted data to '${serviceUrl}'`, global.LOG_API_CALLS));
};

/**
 *  Used when the response-payload is a pdf, rather than a json object
 **/
const postJson2 = (serviceUrl, cache = {}) => data => {
    const key = JSON.stringify(data);

    if (global.LOG_API_CALLS) {
        let text = "\n";
        text += `POST ${serviceUrl}\n`;
        console.log(text);
        text += "Accept: application/pdf\n"
        text += `Content-Type: application/json\n`;
        text += "\n";
        text += stringify(data, null, 2);
        text += "\n";
        text += "### [postJson2()]\n";
        console.debug(text);
    }

    if (!cache.hasOwnProperty(key)) {
        cache[key] = fetch(serviceUrl, {
            method:       "post",
            headers:      {
                "Accept":       "application/pdf",
                "Content-Type": "application/json",
            },
            responseType: 'arraybuffer',
            body:         stringify(data),
        });
    }
    return cache[key]
        .then(throwErrors)
        .then(logResult(`postJson2 Posted data to '${serviceUrl}'`, global.LOG_API_CALLS));
};

const postFile =
    (serviceUrl) =>
        file => {
            let formData = new FormData();
            formData.append('document', file);

            if (global.LOG_API_CALLS) {
                let text = "\n";
                text += `POST ${serviceUrl}\n`;
                console.log(text);
                text += "Accept: application/json\n";
                text += "\n";
                text += "\n[content skipped]";
                text += "### [postFile\n";
                console.debug(text);
            }

            return fetch(serviceUrl, {
                method:  "post",
                headers: {
                    "Accept": "application/json",
                    //"Content-Type": "multipart/form-data", NB: The browser will set this, UNLESS it's set here :(
                },
                body:    formData,
            }).then(throwErrors)
              .then(result => result.json())
              .then(logResult(`[postFile Posted file to '${serviceUrl}'`, global.LOG_API_CALLS));
        };

const postJsonKeycloak =
    (serviceUrl, headers) =>
        data => {

            if (global.LOG_API_CALLS) {
                let text = "\n";
                text += `POST ${serviceUrl}\n`;
                console.log(text);
                if (Array.isArray(headers)) {
                    for (const header of headers) {
                        text += `${header}\n`
                    }
                }
                text += "\n";
                text += stringify(data, null, 2);
                text += "\n";
                text += "### [postJsonKeycloak]\n";
                console.debug(text);
            }

            return fetch(serviceUrl, {
                method:  "post",
                headers: headers,
                body:    data,
            }).then(throwErrors)
              .then(result => result.json())
              .then(logResult(`[postJsonKeycloak Posted data to '${serviceUrl}'`, global.LOG_API_CALLS));
        };

export {
    postJsonKeycloak,
    postFile,
    postJson,
    postJson2,
    postJsonNoContent,
};
