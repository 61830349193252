/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 26-2-18.
 */
import PropTypes from 'prop-types';
import questionPropType from "./question";

const groupShape = {
    header: PropTypes.string.isRequired,
    step: PropTypes.number.isRequired,
    valid: PropTypes.bool.isRequired,
    questions: PropTypes.arrayOf(questionPropType),
};
const groupPropType = PropTypes.shape(groupShape);

export default groupPropType;
export {
    groupShape,
    groupPropType,
}
