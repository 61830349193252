/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 29-1-18.
 */
import React from "react";
import {FormControl, InputLabel, withStyles} from "@material-ui/core";
import Message from "../Message";
import questionPropType from "../../../propType/question";
import PropTypes from 'prop-types';
import {hasClasses} from "../../../../../../util/util";
import Tip from "../../Tip";
// Importing the picker from the real package.
// import {DatePicker} from "material-ui-pickers";
// Importing the picker from our mirrored (+adjusted) package.
import {DatePicker} from "material-ui-pickers/build";

const id = question => `question-${question["id"]}`;

const styles = theme => ({
    label: {
        transform: "translate(0, 8px) scale(1)"
    },
    datePicker: {
        marginTop: 20
    }
});

const DateQuestion = ({question, value, onChange, classes}) =>
    <div>
        <FormControl disabled={question.locked}
                     error={!question.valid}
                     fullWidth>
            <InputLabel required={question.required} className={classes.label}>{value ? null : question.description}</InputLabel>
            <DatePicker id={id(question)}
                        classes={{}}
                        value={value}
                        onChange={onChange}
                        date="ignorethisprop"
                        className={classes.datePicker}
                        keyboard={false}
                        format='D MMMM YYYY'
                        invalidLabel={""}
                        animateYearScrolling={false}/>
            <Message question={question}/>
        </FormControl>
        <Tip tip={question.tooltip}/>
    </div>;

DateQuestion.propTypes = {
    question: questionPropType.isRequired,
    value: PropTypes.object, // a moment
    onChange: PropTypes.func.isRequired,
    classes: hasClasses(["datePicker"]),
};

export default withStyles(styles)(DateQuestion);
