/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 16-5-18.
 */
import React from "react";
import {MenuItem} from "@material-ui/core";
import PropTypes from "prop-types";
import {suggestionToString} from "../../../api/relation/customer/autosuggest";

/**
 * @param {SuggestionType} suggestion
 * @param {bool} isHighlighted
 * @param {object} itemProps - the itemProps will be spread the the MenuItems root element (intended to pass through eventhandlers such as onClick)
 * @return {React}
 * @constructor
 */
const Suggestion = ({suggestion, isHighlighted, itemProps}) => {
    return <MenuItem component="div"
                     {...itemProps}
                     selected={isHighlighted}
                     style={{
                         fontWeight: isHighlighted ? 500 : 400,
                     }}>
        {suggestionToString(suggestion)}
    </MenuItem>;
};

Suggestion.propTypes = {
    suggestion: PropTypes.any.isRequired,
    isHighlighted: PropTypes.bool.isRequired,
    itemProps: PropTypes.object.isRequired,
};

export default Suggestion;
