/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 11-7-18.
 */
import React from "react";
import {Grid, Icon, Typography} from "@material-ui/core";
import classNames from "classnames";

const Warning = ({classes}) =>
    <Grid container
          className={classNames(classes.warning, classes.pageHeader)}>
        <Grid item>
            <Typography variant="body1"
                        type="body1"
                        gutterBottom>
                <Icon>
                    info_outline
                </Icon>
            </Typography>
        </Grid>
        <Grid item>
            <Typography gutterBottom>
                Het klantprofiel is niet volledig ingevuld. Daarom kunnen bepaalde verzekeringen ontbreken. Vul het klantprofiel volledig in om alle
                verzekeringsmogelijkheden te zien.
            </Typography>
        </Grid>
    </Grid>;

export default Warning;
