/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 26-9-18.
 */
import React from "react";
import {connect} from "react-redux";
import {TableCell, TableRow} from "@material-ui/core";
import {formattedDate} from "../../../../../util/camunda";
import classnames from "classnames";
import {currentUser} from "../../../../../state/selector/user";
import ArrowDownwardSharp from '@material-ui/icons/ArrowDownwardSharp';
import Promise from "bluebird";
import {instanceVariables, processInstance} from "../../../../../api/process/process/processes";
import {cleanupVariables} from "../../util";
import {setAssignee} from "../assignee";

const getAsignee = (name, email) => ({
    name: name,
    email: email
});

const getData =
    context =>
        processInstanceId =>
            Promise.all([processInstance(processInstanceId),
                         instanceVariables(processInstanceId)])
                   .spread((instance, variables) => {
                       variables = cleanupVariables(variables);
                       return context.setState({
                                                   instance,
                                                   assignee: variables.nameAssignee ? getAsignee(variables.nameAssignee, variables.emailAssignee) : null,
                                               })
                   });

class TaskRow extends React.Component {

    state = {
        hover: false,
        instance: null,
        variables: null,
    };

    componentDidMount() {
        const {task} = this.props;
        const {processInstanceId} = task;

        getData(this)(processInstanceId);
    }

    render() {
        const {task, onClick, classes, user, showAssignee} = this.props;
        const {processInstanceId} = task;
        const {assignee} = this.state;

        return (
            <TableRow className={classnames(classes.tableRow, this.state.hover ? classes.selection : "")}
                      onMouseOver={_ => this.setState({hover: true})}
                      onMouseOut={_ => this.setState({hover: false})}>
                <TableCell onClick={onClick}>{task.customerName}</TableCell>
                <TableCell onClick={onClick}>{task.name}</TableCell>
                <TableCell onClick={onClick}>{task.processName}</TableCell>
                <TableCell onClick={onClick}>{task.created && formattedDate(task.created)}</TableCell>

                {
                    showAssignee ? <TableCell onClick={onClick}>{assignee ? assignee.name : ""}</TableCell>
                                 : null
                }

                {
                    showAssignee ? <TableCell onClick={_ => setAssignee(task.processInstanceId)(user).then(_ => getData(this)(processInstanceId))}
                                              title={"Claim deze taak"}><ArrowDownwardSharp/></TableCell>
                                 : null
                }

            </TableRow>
        );
    }
}

const mapStateToProps = state => ({
    user: currentUser(state),
});

export default connect(mapStateToProps)(TaskRow);


