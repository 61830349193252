/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 12-12-17.
 */

import ActionType from "../../action/ActionType";

const login = (state = {}, {type, id, email, name, userName, image, roles, imageUrl, fullName}) => {
    if (type === ActionType.asynchronous.login.login) {
        return {
            id,
            email,
            name,
            userName,
            image,
            roles,
            imageUrl,
            fullName,
        }
    }
    return state;
};

export default login;
