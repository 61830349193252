const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

/**
 * Ensure response represents a valid service-worker script
 * @param response
 * @returns {boolean}
 */
function isValidServiceWorker(response) {
    return !(response.status === 404 ||
             response.headers.get('content-type').indexOf('javascript') === -1);
}

function registerServiceWorker(serviceWorkerUrl) {
    navigator.serviceWorker
             .register(serviceWorkerUrl)
             .then(registration => {
                 registration.onupdatefound = () => {

                     const installingWorker = registration.installing;
                     installingWorker.onstatechange = () => {
                         if (installingWorker.state === 'installed') {
                             if (navigator.serviceWorker.controller) {
                                 // At this point, the old content will have been purged and
                                 // the fresh content will have been added to the cache.
                                 // It's the perfect time to display a "New content is
                                 // available; please refresh." message in your web app.
                                 console.log('New content is available; please refresh.');
                             }
                             else {
                                 // At this point, everything has been precached.
                                 // It's the perfect time to display a
                                 // "Content is cached for offline use." message.
                                 console.log('Content is cached for offline use.');
                             }
                         }
                     };
                 };
             })
             .catch(error => {
                 console.error('Error during service worker registration:', error);
             });
}

/**
 * When there's a valid ServiceWorker at the URL it will be registered.
 * Otherwise, this is probably a different app, and we'll reload the page.
 *
 * @param serviceWorkerUrl
 */
function registerServiceWorkerIfPresent(serviceWorkerUrl) {
    fetch(serviceWorkerUrl)
        .then(response => {
            return isValidServiceWorker(response)
                   ? registerServiceWorker(serviceWorkerUrl)
                   : navigator.serviceWorker
                              .ready
                              .then(registration =>
                                        registration.unregister()
                                                    .then(() => window.location.reload()))
        })
        .catch(() => console.log('No internet connection found. App is running in offline mode.'));
}

export default function register() {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {

        const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
            return;
        }

        window.addEventListener('load', () => {
            const serviceWorkerUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            return isLocalhost
                   ? registerServiceWorkerIfPresent(serviceWorkerUrl)
                   : registerServiceWorker(serviceWorkerUrl);
        });
    }
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
                 .ready
                 .then(registration => registration.unregister());
    }
}
