import React from "react";
import {Card, CardContent, Grid} from "@material-ui/core";
import DocumentUpload from "../../../../../component/Documents/DocumentUpload";
import CardHeader from "@material-ui/core/CardHeader";

const Documents = () => (

    <Grid item
          lg={12}
          md={12}
          sm={12}>
        <Card>
            <CardContent>
                <CardHeader title="Uploads"/>
                <DocumentUpload/>
            </CardContent>
        </Card>
    </Grid>

);

export default Documents;

