import {postJsonKeycloak} from "../../HTTP/post";

const tokenHeader =  {
                        "Content-Type": "application/x-www-form-urlencoded"
                     };
	
class KeycloakClient {
	
    static getServiceUrl(endpoint) {	
        return `${process.env.REACT_APP_KEYCLOAKSERVICE}/${endpoint}`;
    }

    static postJson(endpoint, headers, form) {
        return postJsonKeycloak(KeycloakClient.getServiceUrl(endpoint), headers)(form);
    }
	
	static getTokenHeader() {
		return tokenHeader;
	}
	
	static getRealm() {
		return process.env.REACT_APP_KEYCLOAK_REALM;
	}
	
	static getClientSecret() {
		return process.env.REACT_APP_CLIENT_SECRET;
	}
	
	static getClientId() {
		return process.env.REACT_APP_CLIENT_ID;
	}
	
	static createTokenUrl() {
		const tokenEndPoint = "auth/realms/" + KeycloakClient.getRealm() +"/protocol/openid-connect/token";
        return tokenEndPoint;
    }
}

export default KeycloakClient;
export {
    KeycloakClient,
}
