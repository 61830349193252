/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 1-2-18.
 */
import React from "react";
import {Card, CardContent, Grid} from "@material-ui/core";
import ModalForm from "../../../../../../component/form/ModalForm/ModalForm";
import {Forms} from "../../../../../../../api/form/FormClient";
import Header from "../markup/Header";
import Item from "../markup/Item";
import {stringify} from "../../../../../../../util/util";
import automobileUpdater from "../updater/automobileUpdater";

const exists = variable => typeof variable !== "undefined" && variable !== null;


// the registration number below does not appear to be set correctly in all cases
const toUpperCase = string => typeof string === "string" ? string.toUpperCase() : "nummerbord onbekend";

const description = asset => `${asset.vehicle.automobile.brand.toUpperCase()} ${asset.vehicle.automobile.model}`;
const details = asset => `${toUpperCase(asset.vehicle.registrationNo)} uit ${asset.vehicle.automobile.constructionYear}`;

const isAutomobile = asset => exists(asset) &&
                              exists(asset.vehicle) &&
                              exists(asset.vehicle.automobile);


const flatten = asset => {
    let flattened = {...asset.vehicle.automobile};
    flattened.registrationNo = asset.vehicle.registrationNo;
    return stringify(flattened);
};

const Vehicles = ({customer, postCustomer}) =>
    <Card>
        <CardContent>

            <Grid container>
                <Header title="Auto"
                        modalForm={<ModalForm formEndpoint={Forms.editAutomobile}
                                              data={{}}
                                              icon="add_circle"
                                              submitAction={postCustomer(automobileUpdater())(customer)}/>}/>
            </Grid>

            <Grid container>
                {
                    customer.customerAssets === null
                    ? null
                    : customer.customerAssets
                              .filter(isAutomobile)
                              .map((asset, index) => <Item key={index}
                                                           object={asset}
                                                           icon="directions_car"
                                                           getDescription={description}
                                                           getDetails={details}
                                                           modalForm={<ModalForm formEndpoint={Forms.editAutomobile}
                                                                                 data={flatten(asset)}
                                                                                 submitAction={postCustomer(automobileUpdater(index))(customer)}/>}/>)
                }
            </Grid>
        </CardContent>
    </Card>;

export default Vehicles;

