/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 11-12-17.
 */

/**
 * @typedef {Object} FieldError
 * @property {string} field
 * @property {string} message
 */

/**
 * @type {FieldError}
 */
class FieldError extends Error {

    constructor(field, message) {
        super(message);
        this.field = field;
    }
}

export default FieldError;





