/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 14-12-17.
 */

import {createMuiTheme} from "@material-ui/core";
import skin from "./skin/skin";
import {info} from "../util/util";

info(skin);

const createSkinnedMuiTheme = skin => {

    return createMuiTheme(
        {
            palette: {
                primary: skin.palette.primary,
                secondary: skin.palette.secondary,
                error: skin.palette.error,
                primaryText: skin.palette.primaryText,
                secondaryText: skin.palette.secondaryText,
            },
            typography: {
                useNextVariants: true,
                body1: {
                    fontSize: "1.07rem"
                },
                h1: {
                    fontSize: "30px",
                    fontWeight: 400,
                },
                h2: {
                    fontSize: "1.5rem",
                    fontWeight: '300',
                    marginBottom: 15,
                }
                ,
                h3: {
                    fontSize: "1.5rem",
                    fontWeight: '300',
                    marginBottom: 15,
                },
                h4: {},
                h5: {
                    fontSize: '1.75em',
                    fontWeight: '400',
                },
                h6: {
                    fontSize: "1.07rem",
                    fontWeight: "bold",
                },
            },
            drawerPaper: {
                border: 0,
                width: 270
            },
            overrides: {
                MuiButton: {
                    root: {
                        fontSize: '1em',
                        fontWeight: 400,
                        padding: '0 30px'
                    }
                },
                MuiStepIcon: {
                    root: {
                        fontSize: '2em'
                    }
                },
                MuiDivider: {
                    root: {
                        margin: "10px 0px 10px 0px",
                    },
                },
                MuiCard: {
                    root: {
                        marginBottom: 15,
                        padding: 15,
                        width: "100%",
                        borderRadius: 2,
                    },
                },
                MuiCardContent: {
                    root: {
                        paddingTop: 0,
                    },
                },
            },
            props: {
                MuiCircularProgress: {
                    thickness: 6,
                    color: "primary",
                }
            },
            skin: {
                images: {...skin.images},
                palette: {
                    anchors: {
                        regular: skin.palette.anchors.regular ? skin.palette.anchors.regular : skin.palette.primary,
                        hover: skin.palette.anchors.hover ? skin.palette.anchors.hover : skin.palette.primary,
                        visited: skin.palette.anchors.visited ? skin.palette.anchors.visited : skin.palette.primary,
                    },
                    personaBackground: skin.palette.personaBackground,
                    copyrightForeground: skin.palette.copyrightForeground,
                    copyrightBackground: skin.palette.copyrightBackground,
                    footerBackground: skin.palette.footerBackground,
                },

                overrides: {
                    ...skin.overrides,
                }
            }
        });
};

const theme = createSkinnedMuiTheme(skin);
export default theme
