/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-9-17.
 */
import React from "react";
import PropTypes from "prop-types";
import Downshift from 'downshift';
import autosuggestTextQuestion from "../../presentation/AutosuggestText/autosuggestTextQuestion";
import suggestionClient from "./suggestionClient";
import questionPropType from "../../../propType/question";

const remoteSuggestions = question => question.suggestionUrl === null || question.suggestionUrl.length > 0;

const fold = string => string.trim().toLowerCase();
const match = value => suggestion => fold(suggestion).slice(0, value.length) === fold(value);

/**
 * If all possible suggestions are stored in the state, we will use the match function above to filter
 * out the matching suggestions.
 * If a remote service is used, all of the returned values will be actual matches, so
 *    1. There's no need to filter
 *    2. The remote service might decide to use some matching rule we can't predict here, so we
 *       shouldn't try
 * @param question
 * @return {function(string): boolean}
 */
const suggestionFilter =
    question =>
        value =>
            suggestion => remoteSuggestions(question)
                          ? true
                          : match(value)(suggestion);

const Autosuggest = presentation =>

    class AutosuggestContainer extends React.Component {

        static propTypes = {
            question: questionPropType.isRequired,
            onSubmitQuestion: PropTypes.func.isRequired,
        };

        constructor({question}) {
            super({question});
            this.onBlur = this.onBlur.bind(this);
            this.getSuggestions = this.getSuggestions.bind(this);
            this.onSelectSuggestion = this.onSelectSuggestion.bind(this);
            this.onValueChange = this.onValueChange.bind(this);

            // If the question has specified a url to retrieve suggestion, we'll retrieve new suggestions (tailored to
            // the new value) at each change and store it in the state.
            // But if the question instead specifies the full list of possible suggestions, we store it in the state once, here.
            // The getSuggestions method will take care of filtering valid suggestions. That won't be necessary for
            // remoteSuggestions, but won't hurt either.
            let {value, acceptableValues} = question;

            this.state = remoteSuggestions(question)
                         ? {value, suggestions: []}
                         : {value, suggestions: Object.values(acceptableValues)};
        }

        onValueChange(value) {
            if (remoteSuggestions(this.props.question)) {
                suggestionClient(this.props.question)(value)
                    .then(suggestions => this.setState({value, suggestions}));
            }
            else {
                this.setState({value});
            }
        }

        onSelectSuggestion(value) {
            this.setState({value});
        }

        onBlur(event) {
            this.setState({value: event.target.value});
            this.props.onSubmitQuestion(event.target.value);

        }

        getSuggestions(value) {
            return match(value)("") ? [] : [...this.state
                                                   .suggestions
                                                   .filter(suggestionFilter(this.props.question)(value))
                                                   .slice(0, 10),
                                            value];
        }

        componentWillReceiveProps(nextProps) {
            if (nextProps.question) {
                this.setState({value: nextProps.question.value})
            }
        }

        render() {
            return (
                <Downshift onStateChange={({inputValue}, _) => inputValue ? this.onValueChange(inputValue) : null}
                           onChange={this.onSelectSuggestion}
                           value={this.state.value}>
                    {
                        presentation(this.props.question, this.getSuggestions, this.onBlur)
                    }
                </Downshift>
            );
        }
    }
;
export default Autosuggest(autosuggestTextQuestion);
export {
    Autosuggest,
}
