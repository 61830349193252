const organizationUpdater = (organization, form) => {
    form.groups.forEach(function (group) {
        group.questions.forEach(function (question) {
            const field = question.field;

            if (field.includes(".")) {
                console.error("TODO TODO TODO . . . ");
            }

            organization[field] = question.parsedValue;
        });
    });
    return organization;
};

export default organizationUpdater;
