/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-7-19.
 */
import React from "react";
import Promise from "bluebird";
import {instanceVariables, processInstance, setInstanceVariable} from "../../../../../../api/process/process/processes";
import {Forms} from "../../../../../../api/form/FormClient";
import QuestionForm from "../../../../../component/QuestionForm/smart/QuestionForm";
import {retrieveForm} from "../../../../../../action/creator/asynchonous/form/retrieveForm";
import {CircularProgress, Typography, withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMediaHeader from "../../../../../component/CardMediaHeader/CardMediaHeader";
import {push} from "react-router-redux";
import {paths} from "../../../../../paths";
import insuranceProcessClient from "../../../../../../api/process/insuranceProcess/InsuranceProcessClient";
import {cleanupVariables} from "../../../util";
import {promiseAction} from "../../../../../../middleware/promiseDispatch";
import Contract from "../../../../../component/Contract/Contract";
import {orderClient} from "../../../../../../api/relation/order/OrderClient";
import * as ReactDOMServer from "react-dom/server";
import {compose} from "redux";
import {connect} from "react-redux";
import {noteClient} from "../../../../../../api/relation/note/NoteClient";
import ConfirmationNote from "./ConfirmationNote";
import {currentUser} from "../../../../../../state/selector/user";
import {closeTask} from "../../../../../../api/process/task/tasks";
import {getQuestionValue} from "../../../../../../util/form";

const styles = theme => ({
    card: {paddingLeft: "24px"},
});

const getNotes = customer => noteClient.getNotes(customer);

const pickCustomer = variables => variables.customer ? variables.customer : variables.privateCustomer;

class CustomerApprove extends React.Component {

    state = {
        instance: null,
        agent: null,
        customer: null,
        request: null,
        notes: [],
    };

    componentDidMount() {
        const {task} = this.props;
        const {processInstanceId} = task;

        Promise.all([processInstance(processInstanceId),
                     instanceVariables(processInstanceId)])
               .spread((instance, variables) => {
                   this.setState({
                                     instance,
                                     agent: cleanupVariables(variables).agent,
                                     customer: pickCustomer(cleanupVariables(variables)),
                                     request: cleanupVariables(variables).form,
                                     order: JSON.parse(cleanupVariables(variables).order),
                                 });
               })
               .then(() => getNotes(this.state.customer).then(notes => this.setState({notes})));
        this.props.loadForm();
    }

    render() {
        const {customer, order, notes} = this.state;
        const {task, classes} = this.props;

        if (this.state.instance && this.state.agent && this.state.order) {
            const approve = form => {

                const setVariablesPromise = getQuestionValue(form)("akkoord_av") === "Ja"
                                            ? Promise.all([
                                                              setInstanceVariable(task.processInstanceId)(
                                                                  "String",
                                                                  "handtekening_klant",
                                                                  getQuestionValue(form)("handtekening_klant")),
                                                              setInstanceVariable(task.processInstanceId)("Boolean", "AkkoordAlgemeneVoorwaarden", true),
                                                              orderClient.confirm(order.id, getQuestionValue(form)("handtekening_klant"))
                                                                         .then(updatedOrder =>
                                                                                   Promise.all([
                                                                                                   setInstanceVariable(task.processInstanceId)(
                                                                                                       "String",
                                                                                                       "order",
                                                                                                       JSON.stringify((updatedOrder))),
                                                                                                   noteClient.addSystemNote(customer,
                                                                                                                            "Opdracht bevestigd",
                                                                                                                            ReactDOMServer.renderToStaticMarkup(
                                                                                                                                <ConfirmationNote order={updatedOrder}
                                                                                                                                                  customer={customer}/>))
                                                                                               ])
                                                                         ),

                                                          ])
                                            : noteClient.addSystemNote(customer,
                                                                       "Opdracht geannuleerd",
                                                                       "De klant is niet akkoord met de algemene voorwaarden");

                return promiseAction(setVariablesPromise.then(_ => closeTask(task))
                                                        .then(_ => push(paths.thanksForSignature)));
            };

            return notes ? [
                <Card key={1}>
                    <CardMediaHeader title={`Actie ${task ? task.name : ""}`}
                                     image="/images/handshake.jpg"/>

                    <div className={classes.card}>
                        <Typography key="0"
                                    variant="h5">
                            Opdracht bevestiging {order.description}
                        </Typography>

                        <Contract order={order}
                                  customer={customer}
                                  useFactuur={false}
                                  useSignature={false}/>
                        <br/>

                        <QuestionForm endpoint={Forms.customerApproval}
                                      submitAction={approve}
                                      stepPromise={Promise.resolve}/>
                    </div>
                </Card>
                // ,
                // <Card key={2}>
                //     <Typography variant="h5">Aanvraag</Typography>
                //
                //     <MiniPages miniPages={[
                //         MiniPage.create("Verzoek", <Request customer={customer}
                //                                             request={request}/>),
                //         MiniPage.create("Tijdlijn", <Notes customer={customer}
                //                                            notes={notes}/>),
                //     ]}/>
                // </Card>
            ] : <CircularProgress/>;
        }
        return <div/>;
    }
}

const mapStateToProps = state => ({
    user: currentUser(state),
});

const mapDispatchToProps = dispatch => ({
    back: task => () => dispatch(push(paths.task)),
    completeTask: task => () => insuranceProcessClient.completeTask(task.id),
    loadForm: () => dispatch(retrieveForm(Forms.customerApproval)), dispatch,
});

const decorate = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
);

export default decorate(CustomerApprove);
