/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 16-1-18.
 */
import React from "react";
import Header from "../../Header/Header";
import MainBody from "../../MainBody/MainBody";
import Footer from "../../Footer/Footer";
import classNames from "classnames";
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import {APP_INSTANCE, appInstance} from "../../../../../../appInstance";

const styles = theme => ({
    root:           {
        height: "100%",
    },
    mainBackground: theme.skin.overrides.mainBackground,
});

/*
 * TODO: move this to the skin
 */
const defaultHeader = () => {
    switch (appInstance()) {
        case APP_INSTANCE.BANKGARANTIE:
            return "Vraag nu een bankgarantie aan";
        case APP_INSTANCE.BLISS:
            return "Plan nu uw kosteloze en vrijblijvende belafspraak";
        case APP_INSTANCE.BOUWKUNDIGE_KEURING:
            return "Plan nu uw kosteloze en vrijblijvende belafspraak";
        case APP_INSTANCE.ERASMUS:
            return "Plan nu uw kosteloze en vrijblijvende belafspraak";
        case APP_INSTANCE.MOGELIJK:
            return "Plan nu uw kosteloze en vrijblijvende belafspraak";
        case APP_INSTANCE.WONING_SCAN:
            return "Plan nu uw kosteloze en vrijblijvende belafspraak";
        case APP_INSTANCE.QUICK_SCAN:
            return "Financiering aanvragen: Start vrijblijvend de quickscan en we laten u weten welke leensom haalbaar is";
        default:
            throw Error(`Could not determine the default header for AppInstance ${appInstance()}`);
    }
}

const LoggedOff = ({children, header = null, classes}) => (
    <div className={classNames(classes.root, classes.mainBackground)}>
        <Header person={null}
                header={header === null? defaultHeader() : header}
                onMenuToggle={() => null}/>
        <MainBody>
            {children}
        </MainBody>
        <Footer/>
    </div>
);

let decorate = compose(
    withStyles(styles),
);

export default decorate(LoggedOff);
