import ActionType from "../../action/ActionType";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 24-8-18.
 */

const documents = (state = [], action) => {
    if (action.type === ActionType.asynchronous.documents.RETRIEVED_ALL) {
        return action.documents;
    }
    return state;
};
export default documents;
