import {getJson} from "../api/HTTP/get";
import {empty} from "../api/process/util";

/**
 * @param {number} byte
 * @returns {string} the hexadecimal representation of the byte
 */
const hex = byte => byte.toString(16)
                        .padStart(2, '0');

/**
 * @param {string} string
 * @returns {Promise<string>} (promise for) the sha256 hash of the string
 */
const sha256 = string => crypto.subtle
                               .digest("SHA-256",
                                       new TextEncoder().encode(string))
                               .then(buffer => Array.from(new Uint8Array(buffer)))
                               .then(bytes => bytes.map(hex)
                                                   .join(''))

const gravatarApi = {
    thumbnail: hash => new URL(`https://en.gravatar.com/avatar/${hash}`),
    profile:   hash => new URL(`https://en.gravatar.com/${hash}.json`),
}

/**
 * @param email
 * @returns {Promise<Object>}
 */
const gravatarProfile =
    email =>
        sha256(email.trim().toLowerCase())
            .then(hash => getJson()(gravatarApi.profile(hash).href));

/**
 * Just creates the url for the gravatar thumbnail without checking the email has a gravatar profile
 *
 * @param {string} email
 * @returns {Promise<string>}
 */
const gravatarThumbnail =
    (email) =>
        sha256(email.trim().toLowerCase())
            .then(hash => gravatarApi.thumbnail(hash).href)

/**
 * Looks up the gravatar thumbnail in the profile, and falls back if the email does not hava a gravatar profile
 *
 * @param {string} email
 * @param {string|number} size
 * @param {URL} fallback
 * @returns {Promise<string>}
 */
const checkedGravatarThumbnail =
    (email, size = 64, fallback = null) =>
        gravatarProfile(email)
            .then(pickThumbnailUrl)
            .then(url => appendSize(url, size))
            .catch(_ => fallback)

/**
 * The gravatar service responds with json that looks like
 * {
 *     "entry": [
 *         {
 *             "hash": "22bd03ace6f176bfe0c593650bcf45d8",
 *             "requestHash": "205e460b479e2e5b48aec07710c08d50",
 *             "profileUrl": "https:\/\/gravatar.com\/beau",
 *             "preferredUsername": "beau",
 *             "thumbnailUrl": "https:\/\/0.gravatar.com\/avatar\/22bd03ace6f176bfe0c593650bcf45d8",
 *             "photos": [
 *                 {
 *                     "value": "https:\/\/0.gravatar.com\/avatar\/22bd03ace6f176bfe0c593650bcf45d8",
 *                     "type": "thumbnail"
 *                 }
 *             ],
 *             "last_profile_edit": "2023-12-01 20:25:10",
 *             ...
 *         }
 *     ]
 * }
 * This picks out the thumbnail or throws an error if the format doesn't hold up
 *
 * @param {object}response
 */
const pickThumbnailUrl = response => {
    if (Array.isArray(response.entry) && response.entry.length > 0) {
        const entry = response.entry[0];
        if (Object.hasOwn(entry, "thumbnailUrl")) {
            const thumbnailUrl = entry.thumbnailUrl;
            if (typeof thumbnailUrl === 'string' && !empty(thumbnailUrl.trim())) {
                return new URL(thumbnailUrl);
            } else {
                throw new Error("Empty thumbnail")
            }
        } else {
            throw new Error("No thumbnail")
        }
    } else {
        throw new Error("No entry in gravatar response")
    }
}

const appendSize = (url, size) => {
    url.searchParams.append("s", size);
    return url;
}

export {
    gravatarThumbnail,
    checkedGravatarThumbnail,
    gravatarProfile,
}


