/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 8-5-18.
 */
import ActionType from "../action/ActionType";

const error = (errors = [], action) => {
    if (action.type === ActionType.ERROR) {

        return [
            ...errors,
            {
                message: action.message,
                error: action.error
            }
        ];
    }

    if (action.type === ActionType.DISMISS_ERROR) {

        let newState = [];
        errors.forEach((error, index) => {
            if (index !== action.index) {
                newState = [...newState,
                            error];
            }
        });
        return newState;
    }

    return errors;
};

export default error;
