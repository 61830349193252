/**
 * Recommmended  way to add scrolltotop behaviour to React Router v4, according to :
 *    https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-6-18.
 */
import React from "react";
import {withRouter} from "react-router";

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop)
