import {logResult, stringify, throwErrors} from "./util";

/**
 * Variant of 'putJson' for services that return json (eg a copy of the value that was just successfully put into the
 * service)
 * (e.g. just a '204: No Content' header)
 *
 *
 * @param serviceUrl
 * @returns {function(string): Promise<object>} */

const putJson =
    (serviceUrl) =>
        data => {

            if (global.LOG_API_CALLS) {
                let text = "\n";
                text += `PUT ${serviceUrl}\n`;
                console.log(text);
                text += "Accept: application/json\n"
                text += `Content-Type: application/json\n`;
                text += "\n";
                text += stringify(data, null, 2);
                text += "\n";
                text += "### [putJson\n";
                console.debug(text);
            }

            return fetch(serviceUrl, {
                method:  "put",
                headers: {
                    "Accept":       "application/json",
                    "Content-Type": "application/json",
                },
                body:    JSON.stringify(data),
            }).then(throwErrors)
              .then(result => result.json())
              .then(logResult(`[putJson Put data to '${serviceUrl}'`, global.LOG_API_CALLS));
        };

/**
 * Variant of 'putJson' for services that do not return values
 * (e.g. just a '204: No Content' header)
 *
 * @param serviceUrl
 * @return {Promise}
 */
const putJsonNoContent =
    (serviceUrl) =>
        data => {

            if (global.LOG_API_CALLS) {
                let text = "\n";
                text += `PUT ${serviceUrl}\n`;
                console.log(text);
                text += "Accept: application/json\n"
                text += `Content-Type: application/json\n`;
                text += "\n";
                text += stringify(data, null, 2);
                text += "\n";
                text += "### [putJsonNoContent\n";
                console.debug(text);
            }

            return fetch(serviceUrl, {
                method:  "put",
                headers: {
                    "Accept":       "application/json",
                    "Content-Type": "application/json",
                },
                body:    JSON.stringify(data),
            }).then(throwErrors)
              .then(logResult(`[putJsonNoContent()] Put data to '${serviceUrl}'`, global.LOG_API_CALLS));

        };

export {
    putJsonNoContent,
    putJson,
};
