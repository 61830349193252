/**
 * For FILE questions, the user is presented a file-upload box.
 * Using it will upload a file to the Relations fileupload service and associate
 * it with the currentCustomer.
 *
 * THIS ELEMENT WILL ONLY WORK CORRECTLY WHEN THE REDUX STATE CONTAINS THE CORRECT  CURRENT CUSTOMER
 *
 * After the file has been uploaded, the underlying questions value is filled in with the type upload type
 * (so that the  the formservice can use that value for validation: IsRequired, or even HasCorrectType)
 *
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 8-10-18.
 */
import React from "react";
import PropTypes from 'prop-types';
import {CircularProgress, FormControl, Input, InputLabel, withStyles} from "@material-ui/core";
import Message from "../Message";
import questionPropType from "../../../propType/question";
import Tip from "../../Tip";
import {customerClient} from "../../../../../../api/relation/customer/CustomerClient";
import Dropzone from "react-dropzone";
import {createUpload} from "../../../../Documents/uploads";
import {currentCustomer} from "../../../../../../state/selector/customer";
import {connect} from "react-redux";
import {compose} from "redux";
import {loadDocumentsAction} from "../../../../../../action/creator/asynchonous/customer/document";
import styles from "./styles"

const fakeChangeEvent = value => ({
    target: {
        value
    }
});

const stringOrNumber = PropTypes.oneOfType([PropTypes.string,
    PropTypes.number]);

const id = question => `question-${question["id"]}`;

class FileQuestion extends React.Component {

    state = {
        toBeUploaded: [],
    };

    componentDidUpdate(prevProps, prevState) {
        const {onChange, onBlur, customer, loadDocuments} = this.props;

        if (this.state.toBeUploaded.length > 0) {
            this.state.toBeUploaded.forEach(
                upload => customerClient.uploadDocument(customer, upload.file, upload.type)
                    .then(() => onChange(fakeChangeEvent(upload.type)))
                    .then(onBlur)
                    .then(_ => loadDocuments(customer))
                    .catch(error => console.error(JSON.stringify(error, null, 2)))
            );
            this.setState({toBeUploaded: []});
        }
    }

    render() {

        const {
            question,
            value,
            onChange,
            onBlur,
            children,
            customer,
            classes,
            inputmode,
            dispatch,
            loadDocuments,
            ...inputProps
        } = this.props;
        const {toBeUploaded} = this.state;

        let type = null;
        if (typeof question.acceptableValues === "object" && Object.values(question.acceptableValues).length > 0) {
            type = Object.values(question.acceptableValues)[0];
        }
        if (type === null) {
            console.error("FILE question has no acceptable values", question.field);
            return null;
        }

        if (customer === null) {
            console.error("FILE questions require a customer", question.field);
            return null;
        }

        return (
            <div style={{display: "flex"}}>
                <FormControl disabled={question.locked}
                             error={!question.valid}
                             fullWidth>

                    <InputLabel required={question.required}>{question.description}</InputLabel>

                    <Dropzone className={classes.defaultDropzone}
                              activeClassName={classes.activeDropzone}
                              rejectClassName={classes.rejectedDropzone}
                              onDrop={files => this.setState({
                                  toBeUploaded: [...toBeUploaded,
                                      ...files.map(createUpload(type))]
                              })}>
                        <div>Sleep de {type.toLowerCase()} naar dit kader, of klik
                            om {question.value ? " nog " : ""} een bestand te selecteren.
                        </div>
                    </Dropzone>

                    {
                        toBeUploaded.length > 0 ? <CircularProgress thickness={6}/>
                            : null
                    }

                    <Input id={id(question)}
                           classes={{root: classes.input}}
                           inputMode={inputmode}
                           multiline={false}
                           value={value}
                           onChange={onChange}
                           onBlur={onBlur}
                           inputProps={inputProps}/>
                    {children}
                    <Message question={question}/>
                </FormControl>
                <Tip tip={question.tooltip}/>
            </div>
        )
    }
}

FileQuestion.propTypes = {
    question: questionPropType.isRequired,
    value: stringOrNumber.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
};

const stateToProps = state => ({
    currentCustomer: currentCustomer(state)
});

const dispatchToProps = dispatch => ({
    loadDocuments: customer => dispatch(loadDocumentsAction(customer)),
});

const decorate = compose(
    connect(stateToProps, dispatchToProps),
    withStyles(styles)
);

export default decorate(FileQuestion);



