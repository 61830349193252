/**
 * This map provides a mapping between the formal representation of the roles in keycloak (e.g. role_admin) and
 * the informal representation in the EDIT_USER form (e.g. Admin)
 *
 */
const mapping = {
    role_admin:             "Admin",
    role_apotheek_adviseur: "Adviseur",
    role_makelaar:          "Makelaar",
    role_notaris:           "Notaris",
    role_eerste_lijn:       "Eerste lijn",
}

/**
 * @param string
 * @returns {boolean}
 */
const isInformal = string => Object.values(mapping).includes(string);

/**
 * @param string
 * @returns {boolean}
 */
const isFormal = string => Object.keys(mapping).includes(string);

/**
 * @param {string} string
 * @returns {string}
 */
const informal = string => {

    if (isInformal(string)) {
        // make sure the function is idempotent
        return string;
    }

    if (isFormal(string)) {
        return mapping[string];
    }

    throw new Error(`Unexpected role '${string}'`);
}

/**
 * @param {string} string
 * @returns {string}
 */
const formal = string => {

    if (isFormal(string)) {
        // make sure the function is idempotent
        return string;
    }

    if (isInformal(string)) {
        for (const [formal, informal] of Object.entries(mapping)) {
            if (string === informal) {
                return formal;
            }
        }
    }
    throw new Error(`Unexpected role '${string}'`);
}

/**
 * This the multiselect in the form requires a weird format '[\"Adviseur\", \"Makelaar\"]'
 * @param roles
 * @returns {string}
 */
const formatForMulti = roles => `[${roles.map(informal)
                                         .map(role => `\"${role}\"`)
                                         .join(", ")}]`

const parseMulti = array => {
    return array.map(formal);
}

const test = () => {

    global.debug.red(formal("Notaris"));
    global.debug.red(formal("role_notaris"));

    global.debug.blue(informal("Eerste lijn"));
    global.debug.blue(informal("role_eerste_lijn"));

    try {
        informal("onzin");
    } catch (e) {
        global.debug.yellow(e);
    }

    try {
        formal("onzin");
    } catch (e) {
        global.debug.yellow(e);
    }

    global.debug.green(formatForMulti(Object.keys(mapping)));

    global.debug.green(parseMulti("Adviseur,Admin"));
    global.debug.green(parseMulti("Admin"));

}

export {
    informal,
    formal,
    formatForMulti,
    parseMulti,
};
