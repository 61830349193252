import React from "react";
import Paginator from "./Paginator/Paginator";
import {Icon, Table, TableBody, TableCell, TableHead, TableRow, withStyles} from "@material-ui/core";
import styles from "./styles";

/**
 * A result such as returned by e.g. Organization.findAll(). We only assume it has an id.
 * @typedef {Object} Result
 * @property {number} id
 */

/**
 * @typedef {Object} OrganizationResults
 * @property {array.<Organizations>} organizations
 * @property {number} count
 */

class OrganizationResults extends React.Component {

    /*
     *
     * @param {OrganizationResults} OrganizationResults
     * @param mapper: Result -> OrganizationResults  Maps the result to the kind of OrganizationResults expected by the OrganizationResults Componenent
     * @param {function} onClick: object -> ()
     * @param {string} action
     *
     *
     *   () ->
     * @return {React}
     */
    render() {
        const {
            organizationResults, mapper, onClick, action, actionIcon, onAction, classes
        } = this.props;

        return (
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Naam</TableCell>
                            <TableCell>E-mailadres</TableCell>
                            <TableCell>Telefoonnummer</TableCell>
                            <TableCell>Bekijk</TableCell>
                            <TableCell>{action}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {organizationResults.organizations
                                            .map(mapper(onClick, onAction))
                                            .map(result =>
                                                     <TableRow key={result.id}>
                                                         <TableCell>{result.name}</TableCell>
                                                         <TableCell>{result.phone}</TableCell>
                                                         <TableCell>{result.email}</TableCell>
                                                         <TableCell className={classes.icon}
                                                                    onClick={result.onClick}>
                                                             <Icon color="primary">remove_red_eye</Icon>
                                                         </TableCell>
                                                         <TableCell className={classes.icon}
                                                                    onClick={result.onAction}>
                                                             <Icon color="primary">{actionIcon}</Icon>
                                                         </TableCell>
                                                     </TableRow>
                                            )}
                    </TableBody>
                </Table>

                <Paginator currentPage={1}
                           itemsPerPage={5}
                           totalResults={organizationResults.count}/>
            </div>

        );
    }
}

export default withStyles(styles)(OrganizationResults);
