import moment from "moment";
import {cleanupVariables} from "../../../page/process/util";
import {isPresent, now} from "../../../../util/util";
import {delta} from "../date";
import {dossierVariableNames} from "../../../page/customer/Customer/components/dossier/variables";
import DossierDate from "./DossierDate";
import OVDossierDate from "./OvDossierDate";

class Dossier {

    process;
    variables;
    customer;

    constructor(process) {
        this.process = process;
    }

    setCustomer(customer) {
        this.customer = customer;
    }

    setVariables(variables) {
        this.variables = variables;
    }

    getStatus() {
        if (this.variables) {
            const variables = cleanupVariables(this.variables);
            if (variables[dossierVariableNames.STATUS]) {
                return variables[dossierVariableNames.STATUS];
            }
        }
        return "";
    }

    getCustomerId() {

        if (this.variables) {
            const variables = cleanupVariables(this.variables);
            if (variables[dossierVariableNames.CUSTOMER_ID]) {
                return variables[dossierVariableNames.CUSTOMER_ID];
            }
        }
        return null;
    }

    getVariable(name) {

        if (this.variables) {
            const variables = cleanupVariables(this.variables);
            if (variables.hasOwnProperty(name)) {
                return variables[name];
            }
        }
        return null;
    }

    getDate(name) {

        if (this.variables) {
            const variables = cleanupVariables(this.variables);
            if (variables.hasOwnProperty(name)) {
                return moment(variables[name]);
            }
        }
        return null;
    }

    getAge() {
        const update = this.getDate(dossierVariableNames.LAST_STATUS_CHANGE);
        if (update) {
            return delta(now(), update)
        }
        return 0;
    }

    getAdviseur() {

        if (this.variables) {
            const variables = cleanupVariables(this.variables);
            if (variables.hasOwnProperty("nameAdvisor")) {
                return variables.nameAdvisor;
            }
        }
        return "";
    }

    getAanvraag() {

        if (this.variables) {
            const variables = cleanupVariables(this.variables);
            if (variables.hasOwnProperty(dossierVariableNames.AANVRAAG)) {
                return variables[dossierVariableNames.AANVRAAG];
            }
        }
        return "";
    }

    getDossierDate(name) {
        // if (name === "ONTBINDENDE_VOORWAARDEN") {
        //     console.log(new OVDate(this.getDate(dossierVariableNames.deadline(name)),
        //                            this.getVariable(dossierVariableNames.complete(name)),
        //                            this.getDate(dossierVariableNames.completion(name)),
        //                            this.getVariable(dossierVariableNames.STATUS_ONTBINDENDE_VOORWAARDEN))
        //     );
        // }

        return name === "ONTBINDENDE_VOORWAARDEN" ? new OVDossierDate(name,
                                                                      this.getDate(dossierVariableNames.deadline(name)),
                                                                      this.getVariable(dossierVariableNames.complete(name)),
                                                                      this.getDate(dossierVariableNames.completion(name)),
                                                                      this.getVariable(dossierVariableNames.STATUS_ONTBINDENDE_VOORWAARDEN))
                                                  : new DossierDate(name,
                                                                    this.getDate(dossierVariableNames.deadline(name)),
                                                                    this.getVariable(dossierVariableNames.complete(name)),
                                                                    this.getDate(dossierVariableNames.completion(name))
            );
    }

    /**
     * @return {object} An object containing the formvalues of the dossier in a format that can be understood by the formservice
     */
    data() {
        const format = moment => moment.format("DD-MM-YYYY")

        const data = {};
        data[dossierVariableNames.STATUS] = this.getStatus();

        data[dossierVariableNames.complete("KOOPOVEREENKOMST")]   = this.getVariable(dossierVariableNames.complete("KOOPOVEREENKOMST")) ? "Ja" : "Nee";
        data[dossierVariableNames.complete("TAXATIE")]            = this.getVariable(dossierVariableNames.complete("TAXATIE"))          ? "Ja" : "Nee";
        data[dossierVariableNames.complete("BANKGARANTIE")]       = this.getVariable(dossierVariableNames.complete("BANKGARANTIE"))     ? "Ja" : "Nee";
        data[dossierVariableNames.STATUS_ONTBINDENDE_VOORWAARDEN] = this.getVariable(dossierVariableNames.STATUS_ONTBINDENDE_VOORWAARDEN);
        data[dossierVariableNames.complete("PASSEERDATUM")]       = this.getVariable(dossierVariableNames.complete("PASSEERDATUM"))     ? "Ja" : "Nee";

        if (isPresent(this.getVariable([dossierVariableNames.AANVRAAG]))) {
            data[dossierVariableNames.AANVRAAG] = this.getVariable([dossierVariableNames.AANVRAAG]);
        }

        if (this.getDate(dossierVariableNames.DEADLINE_KOOPOVEREENKOMST)) {
            data[dossierVariableNames.DEADLINE_KOOPOVEREENKOMST] = format(this.getDate(dossierVariableNames.DEADLINE_KOOPOVEREENKOMST))
        }

        if (this.getDate(dossierVariableNames.DEADLINE_TAXATIE)) {
            data[dossierVariableNames.DEADLINE_TAXATIE] = format(this.getDate(dossierVariableNames.DEADLINE_TAXATIE))
        }

        if (this.getDate(dossierVariableNames.DEADLINE_BANKGARANTIE)) {
            data[dossierVariableNames.DEADLINE_BANKGARANTIE] = format(this.getDate(dossierVariableNames.DEADLINE_BANKGARANTIE))
        }

        if (this.getDate(dossierVariableNames.DEADLINE_ONTBINDENDE_VOORWAARDEN)) {
            data[dossierVariableNames.DEADLINE_ONTBINDENDE_VOORWAARDEN] = format(this.getDate(dossierVariableNames.DEADLINE_ONTBINDENDE_VOORWAARDEN))
        }

        if (this.getDate(dossierVariableNames.DEADLINE_PASSEERDATUM)) {
            data[dossierVariableNames.DEADLINE_PASSEERDATUM] = format(this.getDate(dossierVariableNames.DEADLINE_PASSEERDATUM))
        }

        return data;
    }
}

export default Dossier;
