/**
 * @author M.J. van der Werf <vanderwerf@bluehorizon.nl>
 * Created on 14-3-16.
 */

import Promise from "bluebird";
import moment from 'moment'
import 'moment/locale/nl';
// import this for the side-effect:
// replace fetch as part of the browser with a fetch-implementation with the same interface as the cmdln fetch in node
import "isomorphic-fetch";

const config = () => {
    moment.locale('nl');
    Promise.config(
        {
            warnings: true,
            longStackTraces: true,
            cancellation: true,
            monitoring: false,
        });
};
export default config;
