import {Table, TableBody, TableCell, TableHead, TableRow, withStyles} from "@material-ui/core";
import TaskRow from "./TaskRow";
import Paginator from "../../../../component/SearchResults/Paginator/Paginator";
import React from "react";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 26-9-18.
 */

const styles = theme => ({
    tableRow: {
        cursor: "pointer",
    },
    selection: {
        backgroundColor: "#fcfce7",
    }
});

const TaskList = ({tasks, totalResults, query, onClickTask, onPage, useHeader = true, showAssignee = true, classes}) => {

    return <div>
        <Table>
            {
                useHeader ? <TableHead>
                              <TableRow>
                                  <TableCell>Klant</TableCell>
                                  <TableCell>Taak</TableCell>
                                  <TableCell>Proces</TableCell>
                                  <TableCell>Datum</TableCell>

                                  {
                                      showAssignee? <TableCell>Verantwoordelijk</TableCell> :  null
                                  }
                                  {
                                      showAssignee? <TableCell>Actie</TableCell> :  null
                                  }

                              </TableRow>
                          </TableHead>
                          : null
            }

            <TableBody>
                {
                    tasks.map(task => <TaskRow classes={classes}
                                               key={task.id}
                                               task={task}
                                               onClick={onClickTask(task)}
                                               showAssignee={showAssignee}
                    />)
                }
            </TableBody>
        </Table>

        {
            totalResults > query.itemsPerPage ? <Paginator currentPage={query.page}
                                                           itemsPerPage={query.itemsPerPage}
                                                           totalResults={totalResults}
                                                           onPaging={onPage}/>
                                              : null
        }
    </div>;
};

export default withStyles(styles)(TaskList);
