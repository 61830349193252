/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 5-2-18.
 */
import {Paper} from "@material-ui/core";
import If from "../../../../Optional/If";
import Suggestion from "./Suggestion";
import React from "react";
import TextQuestion from "../Text/TextQuestion";
import questionPropType from "../../../propType/question";
import PropTypes from 'prop-types';

const autosuggestTextQuestion = (question, getSuggestions, onBlur) =>
    ({getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex}) => (
        <div>
            <TextQuestion question={question}
                          {...getInputProps({onBlur})}
                          value={inputValue}>
                <If show={isOpen}>
                    <Paper square>
                        {
                            getSuggestions(inputValue).map(
                                (suggestion, index) => <Suggestion suggestion={suggestion}
                                                                   key={index}
                                                                   itemProps={getItemProps({item: suggestion})}
                                                                   isSelectedSuggestion={highlightedIndex === index}/>)
                        }
                    </Paper>
                </If>
            </TextQuestion>
        </div>);

autosuggestTextQuestion.propTypes = {
    question: questionPropType.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
};

export default autosuggestTextQuestion;
