import {organizationClient} from "../../../../../api/relation/identity/organization/OrganizationClient";
import {currentOrganization} from "../../../../../state/selector/organization";
import ActionType from "../../../../../action/ActionType";
import {promiseAction} from "../../../../../middleware/promiseDispatch";
import retrieving from "../../../../../action/creator/asynchonous/retrieving";
import {info} from "../../../../../util/util";
import {errorAction} from "../../../../../api/relation/RelationClient";

/**
 * Assumes that response is a response to one of the Organization apis of the Relations service.
 * Some of these service calls wrap the resulting organization in an 'entity' element (and some not)
 *
 * @param {Response} response
 * @return {object} Organization
 */
const extractOrganization = response => response.hasOwnProperty("entity") ? response.entity : response;



/**
 * @param  {func} apiClient:  organization -> Promise<Response>
 * @param {updater} updater:
 * @param {object} organization
 * @param {object} submittedForm
 * @return {Promise.<Action>}
 */
const postOrg =
    apiClient =>
        updater =>
            organization =>
                submittedForm => {
                    return promiseAction(apiClient(updater(organization, submittedForm))
                                             .then(info("API response:"))
                                             .then(extractOrganization)
                                             .then(currentOrganization),
                                         retrieving(ActionType.asynchronous.form.post.POSTING),
                                         errorAction,
                    );
                };

const postOrganization = postOrg(organizationClient.update);
export {
    postOrganization,
};


