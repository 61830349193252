import React from "react";
import Tasks from "../../../../../component/Task/Tasks";
import {Button, Grid, Icon, Modal, withStyles} from "@material-ui/core";
import singleColumn from "../../util/singleColumn";
import MainPrivateCustomer from "./item/main/MainPrivateCustomer";
import GeneralInfo from "./item/GeneralInfo";
import RealEstate from "./item/RealEstate";
import {postClient} from "./postCustomer";
import Vehicles from "./item/Vehicles";
import NewNote from "../../components/note/NewNote";
import {promiseAction} from "../../../../../../middleware/promiseDispatch";
import {push} from "react-router-redux";
import paths from "../../../../../paths";
import retrieving from "../../../../../../action/creator/asynchonous/retrieving";
import ActionType from "../../../../../../action/ActionType";
import {insuranceProcessClient} from "../../../../../../api/process/insuranceProcess/InsuranceProcessClient";
import errorAction from "../../../../../../action/creator/error";
import columnStyles from "../../../../../../style/columnStyles";
import {customerClient} from "../../../../../../api/relation/customer/CustomerClient";
import {noteClient, noteTypes} from "../../../../../../api/relation/note/NoteClient";
import currentCustomerAction from "../../../../../../action/creator/asynchonous/customer/currentCustomer";
import {currentUser} from "../../../../../../state/selector/user";
import {compose} from "redux";
import {connect} from "react-redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import NewDossier from "../../components/dossier/NewDossier";
import Dossiers from "../../../../../component/Dossier/Dossiers";

/**
 * Temporary solution :-(
 * @type {{agentPhone: string, agentOffice: string, agentEmail: string, agentName: string}}
 */
const hardCodedAgent = {
    agentOffice: 'Bliss Hypotheek',
    agentName:   'Alwin Langerak',
    agentPhone:  '085-0470222',
    agentEmail:  'info@blisshypotheekadviseurs.nl',
};

const hardcodedForm = {
    formType: "RESET_PASSWORD",
    groups:   [],
};

const remoteError = error => {
    console.error(`${error.message}: ${error.response.message} -- ${error.response.error}`);
    return errorAction("Error adding BusinessCustomer")(error.response.message);
};

const processFormAction =
    (agent, customer) =>
        form => promiseAction(insuranceProcessClient.documentedRequest(agent, customer, form)
                                                    .then(response => push(paths.thankyou)),
                              retrieving(ActionType.asynchronous.form.post.POSTING),
                              remoteError);

const styles = theme => ({
    ...columnStyles(theme),
    modal: {
        position:        'absolute',
        width:           "50%",
        backgroundColor: theme.palette.background.paper,
        boxShadow:       theme.shadows[5],
        padding:         theme.spacing.unit * 4,
        borderRadius:    4,
        top:             "50%",
        left:            "50%",
        transform:       `translate(-50%, -50%)`,
    },
});

class PrivateCustomerMain extends React.Component {

    state = {
        portalModalOpen: false,
    };

    render() {
        const {user, customer, width, onAccept, classes} = this.props;

        return [

            <Tasks key={0}
                   customer={customer}/>,

            <Dossiers key={"1a"}
                      showCustomer={false}
                      query={[{
                          "name":     "customerId",
                          "operator": "eq",
                          "value":    customer.id,
                      }]}/>,

            <Grid key={1}
                  item
                  className={singleColumn(width)? classes.singleColumn : classes.leftColumn}
                  sm={12}
                  md={6}>

                <MainPrivateCustomer key={1}
                                     customer={customer}
                                     classes={classes}
                                     onAccept={onAccept(user)}/>
                <GeneralInfo key={2}
                             customer={customer}/>
            </Grid>,
            <Grid key={2}
                  item
                  className={singleColumn(width)? classes.singleColumn : classes.rightColumn}
                  sm={12}
                  md={6}>
                <RealEstate postCustomer={postClient}
                            customer={customer}/>
                <Vehicles postCustomer={postClient}
                          customer={customer}/>


                <Button variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            processFormAction(hardCodedAgent, customer)(hardcodedForm);
                            this.setState({portalModalOpen: true});
                        }}
                        type="submit">
                    Uitnodigen klantportaal <Icon>arrow_forward</Icon>
                </Button>

                <Modal open={this.state.portalModalOpen}
                       onClose={() => this.setState({portalModalOpen: false})}>
                    <div className={classes.modal}>
                        De klant is uitgenodigd voor het klantportaal
                    </div>
                </Modal>

                <NewNote description="Maak notitie"
                         user={user}
                         customer={customer}/>

                <NewDossier user={user}
                            customer={customer}/>


                <br/>

                {/*<Consultants customer={customer}></Consultants>*/}
            </Grid>,
        ]
    }
}

const mapStateToProps = state => ({
    user: currentUser(state),
});

const mapDispatchToProps = dispatch => ({
    onAccept:
        user =>
            customer =>
                () =>
                    customerClient.accept(customer)
                                  .then(_ => noteClient.addNote(user, customer, {
                                      description: noteTypes.ACCEPT_CUSTOMER,
                                      text:        "",
                                  }))
                                  .then(() => customerClient.fetchCustomer(customer.id))
                                  .then(currentCustomerAction)
                                  .then(dispatch),
});

const decorate = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withWidth(),
)

export default decorate(PrivateCustomerMain);
