import ActionType from "../../action/ActionType";
import {initialState} from "./form";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 22-1-18.
 */
const handleRetrievingForm = (form, action) => {
    if (action.type === ActionType.asynchronous.form.get.RETRIEVING) {
        return form
               ? {...initialState, formType: form.formType}
               : initialState;
    }
    return form;
};

export default handleRetrievingForm;
