import React from "react";
import Paginator from "./Paginator/Paginator";
import {Icon, Table, TableBody, TableCell, TableHead, TableRow, withStyles} from "@material-ui/core";
import styles from "./styles";

/**
 * A result such as returned by e.g. User.findAll(). We only assume it has an id.
 * @typedef {Object} Result
 * @property {number} id
 */

/**
 * @typedef {Object} UserResults
 * @property {array.<User>} users
 * @property {number} count
 */

class UserResults extends React.Component {

    /*
     *
     * @param {UserResults} UserResults
     * @param mapper: Result -> UserResults  Maps the result to the kind of UserResults expected by the UserResults Component
     * @param {function} onClick: object -> ()
     * @param {string} action
     *
     *
     *   () ->
     * @return {React}
     */
    render() {
        const {
            userResults, mapper, onClick, action, actionIcon, onAction, classes
        } = this.props;
        return (

            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Naam</TableCell>
                            <TableCell>Telefoonnummer</TableCell>
                            <TableCell>E-mailadres</TableCell>
                            <TableCell>Bekijk</TableCell>
                            <TableCell>{action}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userResults.users
                                    .map(mapper(onClick, onAction))
                                    .map(result =>
                                             <TableRow key={result.id}>
                                                 <TableCell>{result.name}</TableCell>
                                                 <TableCell>{result.phone}</TableCell>
                                                 <TableCell>{result.email}</TableCell>
                                                 <TableCell className={classes.icon}
                                                            onClick={result.onClick}>
                                                     <Icon color="primary">remove_red_eye</Icon>
                                                 </TableCell>
                                                 <TableCell className={classes.icon}
                                                            onClick={result.onAction}>
                                                     <Icon color="primary">{actionIcon}</Icon>
                                                 </TableCell>
                                             </TableRow>
                                    )}
                    </TableBody>
                </Table>

                <Paginator currentPage={1}
                           itemsPerPage={5}
                           totalResults={userResults.count}/>
            </div>

        );
    }
}

export default withStyles(styles)(UserResults);
