import {MenuItem, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import uploadTypes from "./UploadTypes";
import React from "react";
import {removeUpload} from "./uploads";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 14-9-18.
 */



const typeSelector =
    (parent, typeFilter = _ => true) =>
        ({file, type}) =>
            <div key={file.name}>
                <FormControl style={{width: "100%"}}>
                    <InputLabel>Bestandstype '{file.name}'</InputLabel>
                    <Select onChange={event => parent.setState({
                                                                   inProgress: removeUpload(file, parent.state.inProgress),
                                                                   toBeUploaded: [...parent.state.toBeUploaded,
                                                                       {file, type: uploadTypes[event.target.value]}]
                                                               })}
                            value={type}>
                        {
                            Object.entries(uploadTypes)
                                  .filter(([type]) => type !== "unknown")
                                  .filter(typeFilter)
                                  .map(([acceptableValue, description]) => (
                                      <MenuItem key={acceptableValue}
                                                value={acceptableValue}
                                                selected={acceptableValue === type}>
                                          {description}
                                      </MenuItem>
                                  ))
                        }
                    </Select>
                </FormControl>
            </div>;

export default typeSelector;
