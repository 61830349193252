/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 5-9-17.
 */
import ActionType from "../../action/ActionType";

const updatedQuestion = (question, action) => {
    return question["id"] === action.questionId ? {
        ...question,
        submit: true,
        updated: true,
        value: action.value
    } : question;
};

const updateQuestionValue = (form, action) => {

    if (action.type === ActionType.question.update) {
        return {
            ...form,
            groups: form.groups.map(group => {
                return {
                    ...group,
                    questions: group.questions.map(question => updatedQuestion(question, action)),
                };
            }),
        };
    }
    return form;
};
export default updateQuestionValue;
