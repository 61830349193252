import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, withStyles} from "@material-ui/core";
import React from "react";

const styles = theme => ({
    labelContainer: {
        marginLeft: 0,
        marginRight: 0,
    },
    label: {
        fontSize: "0.8125rem",
    }
});

const Filter = ({name, current, filters, onChange, classes}) => <FormControl>

    <FormLabel>
        <Typography align="right"
                    variant={"subtitle2"}>{name}</Typography>
    </FormLabel>
    <RadioGroup align="right"
                row
                name={name}
                onChange={onChange}
                value={current}>

        {Object.entries(filters)
               .map(([key, description], index) => <FormControlLabel id={index}
                                                                     className={classes.labelContainer}
                                                                     key={index}
                                                                     value={key}
                                                                     label={<Typography className={classes.label}
                                                                                        variant="body1">{description}</Typography>}
                                                                     checked={current === key}
                                                                     control={<Radio color="primary"/>}/>)}
    </RadioGroup>
</FormControl>;

export default withStyles(styles)(Filter);

