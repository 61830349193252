import defaultSkin from "./defaultSkin";

const bankgarantieSkin = {
    ...defaultSkin,
    palette: {
        ...defaultSkin.palette,
        primary: {main: "#1e72bd"},
        personaBackground: {main: "#1e72bd"},
        copyrightForeground: {main: "#000000"},
        copyrightBackground: {main: "#ffffff"},
        footerForeground: {main: "#ffffff"},
        footerBackground: {main: "#1e72bd"},
    },
    id: "BANKGARANTIE",
    texts: {
        ...defaultSkin.texts,
        copyright: "Bankgarantie.com",
        company: "Bankgarantie.com"
    },
};

export default bankgarantieSkin;
