/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 21-12-17.
 */
import {compoundAction} from "../../../../middleware/compoundDispatch";
import nop from "../../nop";
import stepAction from "./stepAction";
import FormClient from "../../../../api/form/FormClient";
import ActionType from "../../../ActionType";
import form from "../../../../state/selector/form/form";
import {promiseAction} from "../../../../middleware/promiseDispatch";
import retrieved from "../retrieved";
import retrieving from "../retrieving";
import error from "../../error";

/**
 * @param step
 * @param form
 * @return {boolean}
 */
const isSubmitStep = (step, form) => step === form.groups.length;

/**
 * Returns an action that progresses the submitAction (or submits the contents if we're allready in the final step)
 * @param {number} requestedStep
 * @param {object} form
 * @param {function} submitAction: form -> Action
 * @return {Action}
 */
const performStepAction = (requestedStep, form, submitAction) => isSubmitStep(requestedStep, form) ? submitAction(form) : stepAction(requestedStep);

/**
 * Returns an action that progresses to the next step (or a nop if the step hasn't changed)
 * This will return either a nop, if the step hasn't changed,
 *               or a stepAction,
 *             or a submitAction, if the currentstep is the last step
 *
 * @param currentStep
 * @param {number} requestedStep
 * @param {object} form
 * @param {function} submitAction: form -> Action
 * @return {Action}
 */
const laterStepAction = (currentStep, requestedStep, form, submitAction) =>
    requestedStep > currentStep ? performStepAction(requestedStep, form, submitAction) : nop;

/**
 * Posts the form and creates a promise for a compound action that contains
 *   1. A retrieved-form action that instructs the store to update the form
 *   2. A later-step action that instructs the store to advance to the next possible step.
 *
 * This promise is then wrapped in a PromiseAction before it is returned.
 *
 * NB. if, according to the retrieved form, the current step or an intermediate step is not valid,
 *     the later-step action will direct to the first invalid step.
 *
 * @param endpoint
 * @param {number} currentStep
 * @param {number} requestedStep
 * @param {function} submitAction: object -> Action
 * @return {Action}
 */
const postFormForLaterStep = (endpoint, currentStep, requestedStep, submitAction) => state => {

    let nextAllowedStep = currentStep;
    const checkNextAllowedStep = form => {
        for (let step = currentStep; step <= form.groups.length; step++) {
            nextAllowedStep = step;
            if (step === form.groups.length || !form.groups[step].valid) {
                break;
            }
        }
        return form;
    };

    return promiseAction(
        FormClient.postForm(endpoint, form(state))
                  .then(checkNextAllowedStep)
                  .then(retrieved(ActionType.asynchronous.form.post.RESULT))
                  .then(retrievedAction => compoundAction(retrievedAction,
                                                          laterStepAction(currentStep,
                                                                          Math.min(nextAllowedStep, requestedStep),
                                                                          retrievedAction.payload,
                                                                          submitAction))),
        retrieving(ActionType.asynchronous.form.post.POSTING),
        error("Problem posting QuestionForm"),
    );
};

export default postFormForLaterStep;
