import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import Row from "./Row";
import React from "react";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 26-9-18.
 */


const List = ({processes, onClickTask, onPage}) =>
    <div>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Naam</TableCell>
                    <TableCell>Gemaakt op</TableCell>
                    <TableCell>Einddatum</TableCell>
                    <TableCell/>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    processes.map(process => <Row key={process.id}
                                                  process={process}
                                                  onClick={onClickTask(process)}/>)
                }
            </TableBody>
        </Table>

    </div>;

export default List;
