/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 04-09-20.
 */


const businessForm2Customer = (form, customer = null) => {
    const addressFields = ["postalCode",
                           "houseNo",
                           "addition",
                           "streetName",
                           "city"];
    const isAddressField = field => addressFields.includes(field);

    let jsonData = {};
    let data = {};
    let address = {};

    form.groups.forEach(function (group) {
        if (group.header !== "Gegevens adviseur") {
            group.questions.forEach(function (question) {
                let field = question.field;

                if (isAddressField(field)) {
                    address[field] = question.parsedValue;
                }
                else {
                    data[field] = question.parsedValue;
                }
            });
        }
    });

    data["addresses"] = address;
    jsonData["data"] = data;

    return jsonData;
}

export default businessForm2Customer;
