/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 3-8-18.
 */
import {compoundAction} from "../../../middleware/compoundDispatch";
import paths from "../../paths";
import {Button, Icon, withStyles} from "@material-ui/core";
import retrieveForm from "../../../action/creator/asynchonous/form/retrieveForm";
import {connect} from "react-redux";
import React from "react";
import {compose} from "redux";
import {push} from 'react-router-redux';
import fulfillsAllRequirements, {missingRequirements} from "./fulfillsAllRequirements";

const styles = theme => ({
    button: {
        float: "left",
        clear: "both",
        marginBottom: 15,
    }
});

const ProposalButton = ({formType, description, onProposalRequest, requirements = [], documents = [], employees = {}, classes}) =>
    fulfillsAllRequirements(requirements, documents, employees)
    ? <Button variant="contained"
              color='primary'
              className={classes.button}
              onClick={onProposalRequest(formType, documents)}
              type="submit">
        {description} <Icon>arrow_forward</Icon>
    </Button> :
    <Button variant="contained"
            color='inherit'
            className={classes.button}
            onClick={() => console.log("PROBLEM!", missingRequirements(requirements, documents, employees))}
            title={"Voeg nog de volgend document(en) toe voordat u begint: \n    " + missingRequirements(requirements, documents, employees).join(",\n    ")
                   + "."}
            type="submit">
        {description} <Icon>arrow_forward</Icon>
    </Button>;

const dispatchToProps = dispatch => ({
    onProposalRequest: (formType, documents) => () => dispatch(compoundAction(push(paths.request),
                                                                              retrieveForm(formType, null, documents)))
});

const decorate = compose(
    connect(null, dispatchToProps),
    withStyles(styles),
);

export default decorate(ProposalButton);
