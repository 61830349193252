import {restrict} from "../../../util/util";
import moment from "moment";
import Badge from "@material-ui/core/Badge";
import {withStyles} from "@material-ui/core";
import React from "react";

/**
 * @param {Object} object
 * @return {Object}
 */
const labelClasses =
    object =>
        restrict(object,
            ["label"]);

const merge = (classes, overwrites) => ({...classes, ...labelClasses(overwrites)})

/**
 * @param {Array<Note>} notes
 * @param {number} days
 * @return {boolean}
 */
const hasActivity = (notes, days) => {
    const cutoff = moment().subtract(days, 'd');
    return notes.filter(note => moment(note.creationTime).isAfter(cutoff))
        .length > 0;
}

/**
 * @param {Array<Note>} notes
 * @return {String}
 */
const badgeColor = (notes, active) => hasActivity(notes, 3) || active ? 'primary' : 'secondary';

/**
 * A React component to be used as label for the tabs containing the notesoverview
 * It shows the word Tijdlijn, joined with a badge describing the number/recency of notes
 *
 * @param notes
 * @param active
 * @param overwrites
 * @param classes
 * @return {JSX.Element}
 */
const NotesLabel =
    ({notes, active = false, overwrites = {}, classes = {}}) => {

        classes = merge(classes, overwrites);

        return hasActivity(notes, 30) ?
            <Badge color={badgeColor(notes, active)}
                   badgeContent={notes.length}>
                <span className={classes.label}>Tijdlijn</span>
            </Badge> :
            <span className={classes.label}>Tijdlijn</span>;
    }

const labelStyles = {
    label: {},
}
export default withStyles(labelStyles)(NotesLabel);
