/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 8-5-18.
 */
import React from "react";
import {Button, Snackbar} from "@material-ui/core";
import {connect} from "react-redux";
import {dismiss} from "../../../action/creator/error";

const Message = ({error, index, onClose}) => {
    console.log(JSON.stringify(error));
    return <Snackbar anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                     open={true}
                     autoHideDuration={6000}
                     onClose={onClose(index)}
                     ContentProps={{
                         'aria-describedby': 'message-id',
                     }}
                     message={<span id="message-id">{`${error.message}: ${error.error}`}</span>}
                     action={<Button color="inherit"
                                     size="small">X</Button>}/>;
};

const mapDispatcherToProps = dispatch => ({
    onClose: index => () => dispatch(dismiss(index)),
});

export default connect(null, mapDispatcherToProps)(Message);
