import errorAction from "../../../action/creator/error";
import {promiseAction} from "../../../middleware/promiseDispatch";
import {customerClient} from "../../../api/relation/customer/CustomerClient";
import paths from "../../paths";
import retrieving from "../../../action/creator/asynchonous/retrieving";
import ActionType from "../../../action/ActionType";
import {Card, CardContent, Grid} from "@material-ui/core";
import QuestionForm from "../../component/QuestionForm/smart/QuestionForm";
import {Forms} from "../../../api/form/FormClient";
import React from "react";
import LoggedOff from "../../component/Main/Base/LoggedOff/LoggedOff";
import {compose} from "redux";
import {connect} from "react-redux";
import {retrieveForm} from "../../../action/creator/asynchonous/form/retrieveForm";
import businessFormData from "./businessFormData";
import {compoundAction} from "../../../middleware/compoundDispatch";
import {push} from 'react-router-redux';
import getAgent from "../../../state/selector/agent";
import stepAction from "../../../action/creator/asynchonous/form/stepAction";
import Hidden from "@material-ui/core/Hidden/Hidden";
import insuranceProcessClient from "../../../api/process/insuranceProcess/InsuranceProcessClient";
import skin from "../../../style/skin/skin";
import callToAction from "../../component/callToAction/callToAction";
import {noteClient, noteTypes} from "../../../api/relation/note/NoteClient";
import Camunda from "../../../api/process/Camunda";
import {setAssignee} from "../process/task/assignee";
import {APP_INSTANCE, appInstance} from "../../../../appInstance";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 27-7-18.
 */
const remoteError = error => {
    console.error(`${error.message}: ${error.response.message} -- ${error.response.error}`);
    return errorAction("Error adding BusinessCustomer")(error.response.message);
};


const getFormType = () => {
    switch (appInstance()) {
        case APP_INSTANCE.BANKGARANTIE:
            return Forms.garantiestelling;
        case APP_INSTANCE.BLISS:
            return Forms.blissHypotheek;
        case APP_INSTANCE.BOUWKUNDIGE_KEURING:
            return Forms.bouwkundigekeuring;
        case APP_INSTANCE.ERASMUS:
            return Forms.garantiestelling;
        case APP_INSTANCE.MOGELIJK:
            return Forms.mogelijk;
        case APP_INSTANCE.WONING_SCAN:
            return Forms.woningScan;
        case APP_INSTANCE.QUICK_SCAN:
            return Forms.quickScan;
        default:
            throw Error(`Could not determine FormType for AppInstance ${appInstance()}`);
    }
};


const getThankYouPath = form => {
    switch (form.formType) {
        case Forms.garantiestelling:
            return paths.thankyouBankgarantie;
        default:
            return paths.thankyou;
    }
}

/**
 * @param form
 * @return object -- Same form, but with INFO fields removed. INFO fields are do not contain info
 *         entered by the user and are therefore unnecessary. (On top of that the CarInsurance form
 *         uses an infoField to display a jpeg; better to not send _big_ stuff like that along)
 */
const infoFilter = form => ({
    ...form,
    groups: form.groups.map(group => ({
        ...group,
        questions: group.questions.filter(question => question.type !== "INFO"),
    })),
});

/**
 * @param {object} agent
 * @param {object} customer
 * @return {function} form -> Action
 */
const processFormAction =
    (agent, customer) =>
        form => promiseAction(insuranceProcessClient.documentedRequest(agent, customer, infoFilter(form))
                                                    .then(processBusinessKey => processBusinessKey)
                                                    .then(Camunda.getInstanceByBusinessKey)
                                                    .then(instance => setAssignee(instance.id)({
                                                                                                   name:  "Michelle van der Horst",
                                                                                                   email: "michelle@preface.nl",
                                                                                               }))
                                                    .then(_ => push(getThankYouPath(form))),
                              retrieving(ActionType.asynchronous.form.post.POSTING),
                              remoteError);
/**
 * Temporary solution :-(
 * @type {{agentPhone: string, agentOffice: string, agentEmail: string, agentName: string}}
 */
const hardCodedAgent = {
    agentOffice: 'Bliss Hypotheek',
    agentName:   'Alwin Langerak',
    agentPhone:  '085-0470222',
    agentEmail:  'info@blisshypotheekadviseurs.nl',
};

const isEmpty = value => {
    if (typeof value === "undefined") {
        return true;
    }
    if (value === null) {
        return true;
    }
    if (typeof value === "string" && value.trim() === "") {
        return true;
    }
    return false;
}

const glue = (accumulator, current) => `${accumulator}\n${current}`;

/**
 * Make sure the text is save to use in html (especially in title-attributes) by
 * replacing the non-alphanumeric characters by t̶h̶e̶i̶r̶ ̶u̶n̶i̶c̶o̶d̶e̶ space
 * -- unicode also didn't work -> just falling back on plain spaces, this is mostly about the occasional question marks
 * anyways.
 *
 *
 * (this was prompted by 'invalid attribute name' errormessages for questions that contain questionmarks, when
 * displaying notes)
 *
 * @param {string} text
 * @return {string} {*}
 */
const htmlEntities = text => {
    return text.replace(/./gm, character => character.match(/[a-z0-9\s]+/i)? character : ` `);
};

/**
 * Formats the form as html for usages in systemnotes
 * @param {Object} form
 * @return text
 */
const format = form =>
    form.groups
        .map(group => {
            return group.questions
                        .filter(question => !isEmpty(question.value))
                        .map(
                            ({value, description}) =>
                                `<div style="margin-bottom: 10px"
                                                     title=${htmlEntities(description)}>
                                                    <div style="font-weight: bold">
                                                        ${description + " "}
                                                    </div>
                                                    <div>${value}</div>
                                                 </div>`)
                        .reduce(glue);
        })
        .reduce(glue)

const submitFormAction =
    customer =>
        form =>
            promiseAction(customerClient.updateClient2(customer, form, getFormType())
                                        .then(response => response.entity)
                                        .then(updatedCustomer => {
                                            noteClient.addSystemNote(updatedCustomer,
                                                                     noteTypes.NEW_CUSTOMER,
                                                                     format(form))
                                            return updatedCustomer;
                                        })
                                        .then(updatedCustomer => processFormAction(hardCodedAgent,
                                                                                   updatedCustomer)(
                                            form)),
                          retrieving(ActionType.asynchronous.form.post.POSTING),
                          remoteError);

const CallToAction = callToAction(skin);

class CreateBusinessCustomer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {customer: "nader te bepalen"};
    }

    componentDidMount() {
        this.props.didMount()
    }

    render() {
        const {customer} = this.state;

        const creationStep = getFormType() === "AANVRAAG_GARANTIESTELLING"? 3 : 0;
        const verkoperCreationStep = 5;

        return (
            <LoggedOff>

                <Grid item
                      sm={12}>
                    <Hidden mdUp>
                        <CallToAction fullWidth={true}/>
                    </Hidden>
                </Grid>

                <Grid item
                      xs={12}
                      md={8}>
                    <Card>
                        <CardContent>
                            <QuestionForm endpoint={getFormType()}
                                          customer={customer}
                                          submitAction={submitFormAction(customer)}
                                          stepPromise={(step, form) => {
                                              if (step === creationStep) {
                                                  return customerClient.addClient(form, getFormType())
                                                                       .then(response => response.entity)
                                                                       .then(addedCustomer => this.setState({customer: addedCustomer}));
                                              } else if (getFormType() ===
                                                         "AANVRAAG_GARANTIESTELLING" &&
                                                         step ===
                                                         verkoperCreationStep) {
                                                  return customerClient.addClient(form, "VerkoperGarantiestelling")
                                                                       .then(response => response.entity);
                                              } else {
                                                  return Promise.resolve();
                                              }
                                          }}/>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item
                      md={4}>
                    <Hidden smDown>
                        <CallToAction fullWidth={false}/>
                    </Hidden>
                </Grid>

            </LoggedOff>
        )
    }
}

const mapStateToProps = state => ({
    agent: getAgent(state),
});
const mapDispatchToProps = dispatch => ({
    didMountWithData: (data, skipFirst = false) => () => dispatch(compoundAction(retrieveForm(getFormType(), data),
                                                                                 stepAction(skipFirst? 1 : 0))),
});
const mergeProps = ({agent}, {didMountWithData}, ownProps) => {
    return {
        ...ownProps,
        didMount: didMountWithData(businessFormData(agent)),
    }
};

const decorate = compose(
    connect(mapStateToProps,
            mapDispatchToProps,
            mergeProps),
);
export default decorate(CreateBusinessCustomer);
export {
    CreateBusinessCustomer,
}
