import Badge from "@material-ui/core/Badge";
import React from "react";

const badgeColor = dossier => dossier.getAge() > 7 ? 'primary' : 'secondary';

const Status = ({dossier}) => dossier.getAge() > 4 ?
                              <Badge color={badgeColor(dossier)}
                                     badgeContent={dossier.getAge()}>
                                  <span>{dossier.getStatus()}</span>
                              </Badge> :
                              <span>{dossier.getStatus()}</span>;

export default Status;
