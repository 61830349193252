/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 17-1-18.
 */
import {formattedBirthdate, fullAddress, fullName} from "../../../util/customer";

const isClient = ({customerType}) => customerType === "CLIENT";
const isBusiness = ({customerType}) => customerType === "BUSINESS";

const customerToSearchResult =
    (onClick, onAction) =>
        customer => {
            return ({
                id: customer.id,
                type: customer.customerType,
                name: fullName(customer),
                address: fullAddress(customer.addresses),
                date: formattedBirthdate(customer),
                customerStatus: customer.customerStatus,
                onClick: () => onClick(customer),
                onAction: () => onAction(customer),
            });
        };

export {
    customerToSearchResult,
    isClient,
    isBusiness,
}


