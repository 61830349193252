/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 17-7-18.
 */

import logos from "./logos";

const itemType = {
    text: "TEXT",
    boolean: "BOOLEAN",
    amount: "AMOUNT",
};

const item = (type, value, description) => ({
    type, value, description,
});

const product = (company, logo, items) => ({
    company, logo, items,
});

const products = {
    asr: product("ASR",
                 logos.asr,
                 [
                     item(itemType.text, "", "Geen standaardpremie beschikbaar"),
                 ]),
    dll: product("Delta Lloyd",
                 logos.dll,
                 [
                     item(itemType.amount, 121.80, "Premie per jaar incl. as.bel."),
                     item(itemType.amount, 25000.00, "Verzekerd bedrag inventaris/goederen"),
                     item(itemType.amount, 1000, "Verzekerd bedrag huurdersbelang"),
                     item(itemType.amount, 250, "Eigen risico"),
                     item(itemType.boolean, true, "Verplichte taxatie"),
                     item(itemType.boolean, true, "Stormschade"),
                     item(itemType.boolean, true, "Schade aan hurdersbelang"),
                     item(itemType.boolean, true, "Bereddingskosten"),
                 ]),
    nn: product("Nationale Nederlanden",
                logos.nn,
                [
                    item(itemType.amount, 121.80, "Premie per jaar incl. as.bel."),
                    item(itemType.amount, 25000.00, "Verzekerd bedrag inventaris/goederen"),
                    item(itemType.amount, 1000, "Verzekerd bedrag huurdersbelang"),
                    item(itemType.amount, 250, "Eigen risico"),
                    item(itemType.boolean, false, "Verplichte taxatie"),
                    item(itemType.boolean, true, "Stormschade"),
                    item(itemType.boolean, true, "Schade aan hurdersbelang"),
                ]),
};

export {
    itemType,
    products,
}
