/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 29-8-18.
 */

import React from "react";
import PropTypes from "prop-types";
import {FormControl, FormLabel} from "@material-ui/core";
import Message from "../Message";
import {questionShape} from "../../../propType/question";
import Tip from "../../Tip";

const Info = ({question}) =>
    <div style={{display: "flex"}}>
        <FormControl component="fieldset">
            <FormLabel component="legend"
                       style={{color: "#000", fontWeight: "bold"}}
                       error={!question.valid}>{question.description}</FormLabel>

            <div dangerouslySetInnerHTML={{__html: question.value}}/>
            <Message question={question}/>
        </FormControl>
        <Tip tip={question.tooltip}/>
    </div>;

Info.propTypes = {
    question: PropTypes.shape(
        {
            ...questionShape,
        })
};

export default Info;
