import React from "react";
import Promise from "bluebird";
import errorAction from "../../action/creator/error";
import error from "../../action/creator/error";
import {promiseAction} from "../../middleware/promiseDispatch";
import {customerClient} from "../../api/relation/customer/CustomerClient";
import retrieving from "../../action/creator/asynchonous/retrieving";
import ActionType from "../../action/ActionType";
import {Card, CardContent, Grid} from "@material-ui/core";
import QuestionForm from "../component/QuestionForm/smart/QuestionForm";
import {Forms} from "../../api/form/FormClient";
import LoggedOff from "../component/Main/Base/LoggedOff/LoggedOff";
import {compose} from "redux";
import {connect} from "react-redux";
import {retrieveForm} from "../../action/creator/asynchonous/form/retrieveForm";
import getAgent from "../../state/selector/agent";
import Hidden from "@material-ui/core/Hidden/Hidden";
import LoginClient from "../../api/login/LoginClient";
import {compoundAction} from "../../middleware/compoundDispatch";
import {loginAction} from "../../action/creator/asynchonous/login";
import callToAction from "../component/callToAction/callToAction";
import skin from "../../style/skin/skin";
import {noteClient, noteTypes} from "../../api/relation/note/NoteClient";
import CamundaProcessClient from "../../api/process/process/CamundaProcessClient";
import CamundaTaskClient from "../../api/process/task/CamundaTaskClient";
import {push} from "react-router-redux";
import paths from "../paths";

const remoteError = error => {
    console.error(`${error.message}: ${error.response.message} -- ${error.response.error}`);
    return errorAction("Error adding BusinessCustomer")(error.response.message);
};

const getEmail = form => {

    let result;
    form.groups.forEach(group => {
        group.questions.forEach(question => {
            if (question.field === "email") {
                result = question.value;
            }

        })
    })
    return result;
};

const getPassword = form => {
    let result;
    form.groups.forEach(group => {
        group.questions.forEach(question => {
            if (question.field === "password") {
                result = question.value;
            }

        })
    })
    return result;
};

const CallToAction = callToAction(skin);

const closeTasks =
    (processDefinitionKey, taskName) =>
        customer => {

            CamundaProcessClient.findBycustomerId(customer.id, processDefinitionKey)
                                .then(processIds =>
                                          Promise.each(processIds,
                                                       processId => CamundaTaskClient.findByProcessInstanceId(processId, taskName)
                                                                                     .then(tasks => Promise.each(tasks,
                                                                                                                 task => CamundaTaskClient.closeTask(task)))))
            return customer;
        };

const closingNote = {
    description: noteTypes.PORTAL_ACTIVATION,
    text: "Wachtwoord ingesteld door klant"
};

/**
 * @param {object} token
 * @return {Action}
 */
const submitFormAction =
    token =>
        form => promiseAction(customerClient.setPassword(getEmail(form), getPassword(form), token)
                                            .then(closeTasks("RESET_PASSWORD", "SetPassword"))
                                            .then(customer => noteClient.addCustomerNote(customer, closingNote))
                                            .then(_ => LoginClient.logOn(getEmail(form), getPassword(form))
                                                                  .then(loginResult => compoundAction(loginAction(loginResult),
                                                                                                      push(paths.user)))),
                              retrieving(ActionType.asynchronous.form.post.POSTING),
                              remoteError);

class SetPassword extends React.Component {

    state = {
        password: "",
    };

    componentDidMount() {
        const token = this.props.match.params.token;
        return this.props.didMount(token)
    }

    render() {

        const token = this.props.match.params.token;

        return (
            <LoggedOff>

                <Grid item
                      sm={12}>
                    <Hidden mdUp>
                        <CallToAction fullWidth={true}/>
                    </Hidden>
                </Grid>

                <Grid item
                      xs={12}
                      md={8}>
                    <Card>
                        <CardContent>
                            <QuestionForm endpoint={Forms.setPassword}
                                          submitAction={submitFormAction(token)}/>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item
                      md={4}>
                    <Hidden smDown>
                        <CallToAction fullWidth={false}/>
                    </Hidden>
                </Grid>

            </LoggedOff>
        )
    }
}

const mapStateToProps = state => ({
    agent: getAgent(state),
});
const mapDispatchToProps = dispatch => ({
    didMount: token => {
        dispatch(promiseAction(
            customerClient.getEmail(token).then(email => retrieveForm(Forms.setPassword, {email})),
            retrieving(ActionType.asynchronous.form.get.RETRIEVING),
            error("Problem fetching email"),
        ));
    },
});

const decorate = compose(
    connect(mapStateToProps,
            mapDispatchToProps)
);
export default decorate(SetPassword);
export {
    SetPassword,
}
