/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 15-1-18.
 */

import ActionType from "../../action/ActionType";

const logoff = (state = {}, {type, email, name, image}) => {
    if (type === ActionType.asynchronous.login.logoff) {
        return {}
    }
    return state;
};

export default logoff;
