/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 20-12-17.
 */

import React from "react";
import {connect} from "react-redux";
import {currentCustomer, documents} from "../../../../../state/selector/customer";
import securePage from "../../../SecurePage/SecurePage";
import {Card, CardContent, Divider, Grid, withStyles, withWidth} from "@material-ui/core";
import LoggedOn from "../../../../component/Main/Base/LoggedOn/LoggedOn";
import CustomerTopMenu from "../components/menu/CustomerTopMenu";
import {compose} from "redux";
import singleColumn from "../util/singleColumn";
import ProposalButton from "../../../anonymous/ProposalButton";
import {Forms} from "../../../../../api/form/FormClient";
import {customerClient} from "../../../../../api/relation/customer/CustomerClient";
import currentCustomerAction from "../../../../../action/creator/asynchonous/customer/currentCustomer";
import {roles} from "../../../../../api/login/LoginClient";
import MainBusinessCustomer from "../components/main/item/main/MainBusinessCustomer";
import {Directors, Employees} from "../components/main/item/BusinesPeople";
import RealEstate from "../components/main/item/RealEstate";
import Vehicles from "../components/main/item/Vehicles";
import GeneralInfo from "../components/main/item/GeneralInfo";
import {postBusiness} from "../components/main/postCustomer";
import columnStyles from "../../../../../style/columnStyles";

const BusinessCustomer = ({customer, documents, width, onAccept, classes}) =>
    <LoggedOn pageHeader={<CustomerTopMenu/>}>
        <Grid item
              className={singleColumn(width)? classes.singleColumn : classes.leftColumn}
              sm={12}
              md={6}>
            <MainBusinessCustomer customer={customer} onAccept={onAccept}/>
        </Grid>
        <Grid item
              className={singleColumn(width)? classes.singleColumn : classes.rightColumn}
              sm={12}
              md={6}>
            <Card>
                <CardContent>
                    <Directors customer={customer}/>
                    <Divider/>
                    <Employees customer={customer}/>
                </CardContent>
            </Card>

            <RealEstate postCustomer={postBusiness}
                        customer={customer}/>

            <Vehicles customer={customer}
                      postCustomer={postBusiness}/>

            <GeneralInfo customer={customer}/>

            <ProposalButton formType={Forms.carInsurance}
                            description="Aanvraag autoverzekering"
                            documents={documents}/>
        </Grid>
    </LoggedOn>;

const mapStateToProps = state => ({
    customer:  currentCustomer(state),
    documents: documents(state),
});

const mapDispatchToProps = dispatch => ({
    onAccept:
        customer =>
            () =>
                customerClient.accept(customer)
                              .then(() => customerClient.fetchCustomer(customer.id))
                              .then(currentCustomerAction)
                              .then(dispatch),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    securePage([roles.admin]),
    withWidth(),
    withStyles(columnStyles),
)(BusinessCustomer);
