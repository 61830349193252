/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 1-9-17.
 * Created by Darryl Amatsetam <darryl@bluehorizon.nl> on 1-9-17.
 */
import React from "react";
import {Provider} from "react-redux";
import {MuiThemeProvider} from "@material-ui/core";
import PropTypes from 'prop-types';
import Router from "./Router";
// Importing the picker utils from our mirrored (+adjusted) package.
import MomentUtils from 'material-ui-pickers/build/moment-utils';
import {MuiPickersUtilsProvider} from 'material-ui-pickers/build';
import {APP_INSTANCE, appInstance} from "../../appInstance";

// Importing the picker utils from the real package.
//import MomentUtils from 'material-ui-pickers/utils/moment-utils';
//import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';

const getTitle = () => {
    switch (appInstance()) {
        case APP_INSTANCE.BANKGARANTIE:
            return "bankgarantie.com";
        case APP_INSTANCE.BLISS:
            return "Bliss Hypotheekadviseurs";
        case APP_INSTANCE.BOUWKUNDIGE_KEURING:
            return "Bouwkundige Keuring";
        case APP_INSTANCE.ERASMUS:
            return "Erasmus Verzekeringen";
        case APP_INSTANCE.MOGELIJK:
            return "Blue Horizon";
        case APP_INSTANCE.WONING_SCAN:
            return "Greenfactor";
        case APP_INSTANCE.QUICK_SCAN:
            return "QuickScan";
        default:
            throw Error(`Could not determine FormType for AppInstance ${appInstance()}`);
    }
};

const getFavicon = () => {
    switch (appInstance()) {
        case APP_INSTANCE.BANKGARANTIE:
            return "favicon-bankgarantie-16x16.png";
        case APP_INSTANCE.BLISS:
            return null;
        case APP_INSTANCE.BOUWKUNDIGE_KEURING:
            return null;
        case APP_INSTANCE.ERASMUS:
            return null;
        case APP_INSTANCE.MOGELIJK:
            return null;
        case APP_INSTANCE.WONING_SCAN:
            return null;
        case APP_INSTANCE.QUICK_SCAN:
            return null;
        default:
            throw Error(`Could not determine FormType for AppInstance ${appInstance()}`);
    }
};

const setFavicon = (url) => {
    if (url !== null) {
        const link = document.querySelector('link[rel="icon"]');
        link.setAttribute('href', url);
    }
}

const WebApp = ({store, history, theme}) => {
    document.title = getTitle();
    setFavicon(getFavicon());

    return <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}
                                     locale="nl">
                <div className="App">
                    <Router history={history}/>
                </div>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    </Provider>;
};

WebApp.propTypes = {
    store:   PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    theme:   PropTypes.object.isRequired,
};

export default WebApp;
