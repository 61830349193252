/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 30-1-18.
 */

import {getJson} from "../../../../../../api/HTTP/get";

// Each time the user reloads the page, a fresh cache will be used.
// (the offered suggestions will change over time, but not that often)
let suggestionCache = {};

/**
 * Initializes (when necessary) and returns a cache that will be used for requests for the give suggestionUrl
 * @param suggestionUrl
 * @return {object} the cached values for suggestionUrl
 */
const subCache = suggestionUrl => {
    if (!suggestionCache.hasOwnProperty(suggestionUrl)) {
        suggestionCache[suggestionUrl] = {};
    }
    return suggestionCache[suggestionUrl];
};

/**
 * Expects a remote url of the form http://domain.com/a/b/c/value that will return a json representation a
 * list a suggestions
 *
 * @param suggestionUrl
 * @return {function(string): Promise<Object>}
 */
const suggestionClient = ({suggestionUrl}) =>
    value => getJson(subCache(suggestionUrl))(`${suggestionUrl}${value}`);

export default suggestionClient;
