import React from "react";
import PropTypes from "prop-types";

/**
 * Simple HOC that makes it possible to show components conditionally, without mixing and matching of JSX-tags and javascript, i.e.:
 *      {
 *          show && <Component prop1={...} prop2={...}>
 *      }
 * Can be written as:
 *      <Optional show={show} Component={Component} prop1={...} prop2={...}/>
 *
 * If a React component is given, Optional will instantiate it with the given props and else it will simply return null.
 *
 * @param {boolean} show
 * @param {JSX.Element|null} Component
 * @param props
 * @return {JSX.Element|null}
 * @constructor
 */

const Optional =
    ({show, Component, ...props}) => {
        return show
               ? <Component {...props}/>
               : null;
    }

Optional.propTypes = {
    show: PropTypes.bool.isRequired,
    Component: PropTypes.any.isRequired,
}

export default Optional;
