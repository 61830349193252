/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 27-7-18.
 */

const paths = {
    root: "/",
    user: "/ingelogd",
    viewUser: "/gebruiker",
    setPassword: "/wachtwoordinstellen",
    dossier: "/dossier",
    klant: "/klant",
    request: "/aanvraag",
    thankyou: "/danku",
    thankyouBankgarantie: "/bankgarantieaangemeld",
    login: "/login",
    dashboard: "/dashboard",
    newClient: '/nieuwe-klant',
    newPrivateCustomer: '/nieuwe-particulier-klant',
    newBusinessCustomer: '/nieuwe-zakelijke-klant',
    customers: '/klanten',
    privateCustomer: '/klant',
    businessCustomer: '/zakelijkeklant',
    prospects: '/prospects',
    passwordReminder: '/wachtwoord-vergeten',
    partnerAccount: '/partner-account',
    archive: '/archief',
    offers: '/offers',
    timeline: '/timeline',
    contracts: '/contracts',
    documents: '/documents',
    comparison: '/comparison',
    task: '/taken',
    mytasks: '/mijn-taken',
    processTypes: "/processen",
    processes: '/proces',
    organisation: "/organizations",
    formTesting: '/formtesting/form',
    groupTesting: '/formtesting/group',
    questionTesting: '/formtesting/question',
    signature: '/ondertekening',
    signature1: '/ondertekening1',
    signature2: '/ondertekening2',
    thanksForSignature: '/ondertekend',
    thanksForSignatureBankgarantie: '/bankgarantieondertekend',

};

const proposalDisplayPath = id => `${paths.request}/${id}`;
// const proposalFormPath = id =>`${paths.request}/${id}`; // Todo: Corresponding view; then adjust path
const taskPath = id => `${paths.task}/${id}`;
const casePath = id => `${paths.cases}/${id}`;
const processTypePath = id => `${paths.processTypes}/${id}`;
const instancePath = id => `${paths.processes}/${id}`;
const privateCustomerPath = id  =>  `${paths.privateCustomer}/${id}`
const businessCustomerPath = id  =>  `${paths.businessCustomer}/${id}`
const signaturePath = id => `${paths.signature}/${id}`
const userPath = id  =>  `${paths.viewUser}/${id}`

const tokenPath = token => `${paths.processes}/${token}`;

export default paths;
export {
    paths,
    proposalDisplayPath,
    taskPath,
    casePath,
    processTypePath,
    instancePath,
    tokenPath,
    privateCustomerPath,
    businessCustomerPath,
    userPath,
    signaturePath,
}
