import React from "react";
import {Grid} from "@material-ui/core";
import {informal} from "../role";
import WcIcon from "@material-ui/icons/Wc";

class RoleItem extends React.Component {
    render() {
        const {roles} = this.props;

        return (<Grid container>
            <Grid item
                  key={1}
                  sm={1}>
                <span title={"Rollen"}>
                <WcIcon/>
                    </span>
            </Grid>
            <Grid item
                  key={2}
                  sm={11}>
                {
                    roles.map(informal).join(", ")
                }

            </Grid>
        </Grid>);
    }
}

export default RoleItem;

