/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 20-12-17.
 */
import ActionType from "../../action/ActionType";

const updatedQuestion = (groupIndex, question, action) =>
    groupIndex === action.groupIndex && question["visible"] === true
    ? {...question, submit: true}
    : question;

const submitGroup = (form, action) => {
    if (action.type === ActionType.group.submitGroup) {

        return {
            ...form,
            groups: form.groups.map((group, groupIndex) => ({
                ...group,
                questions: group.questions.map(question => updatedQuestion(groupIndex, question, action)),
            })),
        };
    }
    return form;
};

export default submitGroup;
