/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 22-11-17.
 */
import React from "react";
import {Card, CardContent, CardHeader, Grid, withStyles} from "@material-ui/core";
import LoggedOff from "../../../component/Main/Base/LoggedOff/LoggedOff";
import {connect} from "react-redux";
import form from "../../../../state/selector/form/form";
import QuestionForm from "../../../component/QuestionForm/smart/QuestionForm";
import {Forms} from "../../../../api/form/FormClient";
import {push} from "react-router-redux"
import paths from "../../../paths";

const styles = theme => ({
    root: {
        padding: 10,
    },
    header: {
        paddingBottom: 0,
    },
    content: {
        paddingTop: 0
    }
});

/**
 * @param {object} form
 * @return {Promise.<Action>}
 */
const partnerRequestAction = form => push(paths.login);

const PartnerAccount = ({form, classes}) => (
    <LoggedOff>
        <Grid item
              lg={8}
              md={8}>
            <Card className={classes.root}>
                <CardHeader title="Partner account aanvragen"
                            className={classes.header}/>
                <CardContent className={classes.content}>
                    <QuestionForm endpoint={Forms.partnerAccount}
                                  submitAction={partnerRequestAction}/>
                </CardContent>
            </Card>
        </Grid>
    </LoggedOff>
);

const mapStateToProps = state => {
    return {
        form: form(state),
        //requestId: requestId(state),
        //status: status(state),
    };
};

export default withStyles(styles)(connect(mapStateToProps, null)(PartnerAccount));
