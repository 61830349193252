/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 1-2-18.
 */
import moment from "moment/moment";
import {join as joinWith} from "./util";

const join = joinWith(' ');

const fullName = ({firstName, initials, insertPart, lastName}) =>
    join([firstName ? firstName : initials,
          insertPart,
          lastName]).trim();

const fullAddress = address => {
    const street = `${address.streetName} ${address.houseNo}`.trim();
    const location = `${address.postalCode} ${address.city}`.trim();
    return (street === '' || location === '')? `${street}${location}` : `${street}, ${location}`
}

const formattedBirthdate = ({birthDate}) => birthDate? moment(birthDate, "DD-MM-YYYY").format("D MMMM YYYY") : "";

const formattedDate = birthDate => moment(birthDate, "DD-MM-YYYY").format("D MMMM YYYY");

export {
    fullName,
    fullAddress,
    formattedBirthdate,
    formattedDate,
}
