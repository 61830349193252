import Stroke from "./Stroke";

class ContinuousStroke extends Stroke {

    /**
     * @type {BézierCurve}
     */
    curve;

    /**
     * @param {Curves} curves
     * @param {Array<Sample>} samples
     */
    constructor(curves, samples) {
        super(curves, samples);
        this.curve = curves.newCurve(samples)
    }

    /**
     * @param {CanvasRenderingContext2D} renderingContext
     */
    draw(renderingContext) {
        const delta = 1 / (100 * Math.floor(this.curve.length()));

        renderingContext.beginPath();
        for (let t = 0; t <= 1; t += delta) {
            this.drawDot(renderingContext, this.curve.at(t));
        }
        renderingContext.closePath();
        renderingContext.fill();
    }
}

export default ContinuousStroke;
