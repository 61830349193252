class Stroke {

    /**
     * @type {Curves}
     */
    curves;

    /**
     * @type {Array<Sample>}
     */
    samples;

    /**
     * @param {Curves} curves
     * @param {Array<Sample>} samples
     * @private
     */
    constructor(curves, samples) {
        this.curves = curves
        this.samples = samples;
    }

    /**
     * @param {CanvasRenderingContext2D} renderingContext
     * @abstract
     */
    draw(renderingContext) {
    }

    /**
     * Utility method to draw a single Dot
     *
     * @param {CanvasRenderingContext2D} renderingContext
     * @param {Dot} dot
     * @protected
     */
    drawDot(renderingContext, dot) {
        const savedFillStyle = renderingContext.fillStyle;
        renderingContext.fillStyle = dot.color ? dot.color : savedFillStyle;

        renderingContext.moveTo(dot.x, dot.y);
        renderingContext.arc(dot.x, dot.y, dot.weight, 0, 2 * Math.PI, false);

        renderingContext.fillStyle = savedFillStyle;
    }
}

export default Stroke;
