/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 25-9-18.
 */

import React from "react";
import {Card, CardContent, CircularProgress, withStyles} from "@material-ui/core";
import {taskPath} from "../../paths";
import {connect} from "react-redux";
import {push} from 'react-router-redux';
import {compose} from "redux";
import TaskList from "../../page/process/task/taskList/TaskList";
import CardHeader from "@material-ui/core/CardHeader";
import loadTasks from "../../../api/process/task/loadTasks";
import {currentCustomerQuery} from "../../page/process/task/TaskRestriction";

const styles = theme => ({
    root: {
        width: "100%",
        borderRadius: 2
    }
});

class Tasks extends React.Component {

    state = {
        searchResults: null,
    };

    componentDidMount() {
        loadTasks(this, currentCustomerQuery(this.props.customer));
        return null;
    }

    render() {

        const {onClick, classes} = this.props;
        const searchResults = this.state.searchResults;

        return searchResults !== null && searchResults.totalResults > 0
               ? <Card className={classes.root}>
                   <CardHeader title="Openstaande taken"/>
                   <CardContent>
                       {
                           searchResults == null
                           ? <CircularProgress/>
                           : <TaskList tasks={searchResults.results}
                                       useHeader={false}
                                       totalResults={searchResults.totalResults}
                                       query={searchResults.query}
                                       onClickTask={onClick}
                                       onPage={page => {
                                           this.setState({searchResults: null});
                                           loadTasks(this, {...searchResults.query});
                                       }}/>
                       }
                   </CardContent>
               </Card>
               : null;
    }
}

const dispatchToHandlers = dispatch => ({
    onClick: task => () => dispatch(push(taskPath(task.id))),
});

const decorate = compose(
    connect(null, dispatchToHandlers),
    withStyles(styles)
);

export default decorate(Tasks);
