import ActionType from "../../action/ActionType";

const loginLoading = (state = false,{type}) => {
    if (type === ActionType.LOGIN_LOADING) {
        return true;
    }

    if (type === ActionType.asynchronous.login.login) {
        return false;
    }

    if (type === ActionType.LOGIN_ERROR_LOADING) {
        return false;
    }

    return state;
};

export default loginLoading;