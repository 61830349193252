/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-4-18.
 */

import {Button} from "@material-ui/core";
import React from "react";
import PropTypes from 'prop-types';
import QuestionComponent from "../Question/QuestionComponent";
import {formPropType} from "../propType/form";
import {hasClasses} from "../../../../util/util";
import BigCardHeader from "../../Card/BigCardHeader";

const PlainForm = ({form, onStep, onSubmitQuestion, header = null, buttonText = null, classes}) => {

    if (form.groups.length !== 1) {
        throw new Error("Failed assert: PlainForm expect a form consisting of just one group")
    }

    const group = form.groups[0];
    return (
        <div>
            <BigCardHeader title={header ? header : group.header}/>
            {
                group.questions
                    .filter(question => question.visible === true)
                    .map(question => <QuestionComponent key={question.field}
                                                        onSubmitQuestion={onSubmitQuestion(question)}
                                                        question={question}/>)
            }


            {/*<OneClickButton variant="contained"*/}
            {/*                className={classes.plainButton}*/}
            {/*                color='primary'*/}
            {/*                onClick={onStep(1)}*/}
            {/*                buttonText={buttonText}/>*/}

            <Button variant="contained"
                    className={classes.plainButton}
                    color='primary'
                    onClick={onStep(1)}
                    disabled={false}
            >
                {buttonText ? buttonText : "Opslaan"}
            </Button>
        </div>);
};

PlainForm.propTypes = {
    form: formPropType,
    onStep: PropTypes.func.isRequired,
    onSubmitQuestion: PropTypes.func.isRequired,
    classes: hasClasses(["plainButton"])
};

export default PlainForm;
