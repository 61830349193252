/**
 * @type Device
 */
class TouchPad {

    /**
     * type {StrokeEventHandler}
     */
    strokeEventHandler;

    /**
     *
     * @param {StrokeEventHandler} strokeEventHandler
     */
    register(strokeEventHandler) {
        strokeEventHandler.currentCanvas.style.msTouchAction = 'none';
        strokeEventHandler.currentCanvas.addEventListener("touchstart", this.handleTouchStart.bind(this));
        strokeEventHandler.currentCanvas.addEventListener("touchmove", this.handleTouchMove.bind(this));
        this.strokeEventHandler = strokeEventHandler;
        document.addEventListener("touchend", this.handleTouchEnd.bind(this));
    }

    /**
     */
    unRegister() {
        if (this.strokeEventHandler) {
            this.strokeEventHandler.currentCanvas.removeEventListener("touchstart", this.handleTouchStart);
            this.strokeEventHandler.currentCanvas.removeEventListener("touchmove", this.handleTouchMove);
        }
        document.removeEventListener("touchend", this.handleTouchEnd);
    }

    handleTouchStart(event) {
        this.strokeEventHandler.strokeBegin(event.changedTouches[0]);
    }

    handleTouchMove(event) {
        // Prevent scrolling.
        event.preventDefault();

        let touch = event.changedTouches[0];
        this.strokeEventHandler.strokeUpdate(touch);
    }

    handleTouchEnd(event) {
        if (event.target === this.signaturePad.canvasRef.current) {    // TODO: FIX THIS REFERENCE TO SIGNATUREPAD
            this.strokeEventHandler.strokeEnd(event);
        }
    }
}

export default TouchPad;
