/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 22-11-17.
 */
import React from "react";
import {AppBar, Hidden, Icon, IconButton, Toolbar, Typography, withStyles} from "@material-ui/core";
import classnames from "classnames";
import {compose} from "redux";
import {connect} from "react-redux";
import styles from "./styles";
import {person} from "../../../../state/selector/person";
import PersonalHeader from "./PersonalHeader";
import paths from "../../../paths";
import {push} from "react-router-redux"
import skin from "../../../../style/skin/skin";
import {roles} from "../../../../api/login/LoginClient";

const hasPerson = person => person && person.name;
const hasRole = (person, role) => hasPerson(person) && person.roles.includes(role);

const Header = ({person, classes, onMenuToggle, dispatch, portal = false, onHome, header = skin.texts.company}) => (
    <AppBar className={classes.headerBar}>
        <Toolbar className={classes.toolbar}>
            <Hidden mdUp>
                {
                    hasPerson(person)
                    ? <IconButton color="inherit"
                                  className={classes.menuIcon}
                                  onClick={onMenuToggle}><Icon>menu</Icon></IconButton>
                    : <div/>
                }
            </Hidden>

            <Typography variant="h1"
                        component="h1"
                        className={classnames(classes.brandLogo, classes.flex)}>
                {header}
            </Typography>

            <Hidden smDown>
                <div>
                    {
                        hasPerson(person)
                        ? <PersonalHeader person={person}
                                          onHome={onHome}
                                          classes={classes}
                                          portal={portal}/>
                        : <IconButton color="inherit"
                                      className={classes.menuIcon}
                                      title="inloggen"
                                      onClick={() => dispatch(push(paths.login))}><Icon>exit_to_app</Icon></IconButton>
                    }
                </div>
            </Hidden>
        </Toolbar>
    </AppBar>
);

const mapStateToProps = state => ({
    person: person(state),
});

const dispatchToProps = dispatch => ({
    dispatch,
});

const mergeProps = (stateProps, {dispatch}, ownProps) => ({
    ...ownProps,
    ...stateProps,
    dispatch,
    onHome: () => {
        if (ownProps.onHome) {
            return ownProps.onHome();
        }
        if (hasRole(stateProps.person, roles.admin)) {
            dispatch(push(paths.dashboard))
        }
        if (hasRole(stateProps.person, roles.portalUser)) {
            dispatch(push(paths.user))
        }
        return null;
    },
});

const decorate = compose(
    connect(mapStateToProps, dispatchToProps, mergeProps),
    withStyles(styles),
);
export default decorate(Header);
