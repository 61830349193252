import RelationClient from "../../RelationClient";
import {info} from "../../../../util/util";

class OrganizationUserClient {

    createJsonForNewUserOrgMapping(userName, orgId) {
        let data = {};
        let user = {};
        let organization = {};

        user['userName'] = userName;
        organization['id'] = orgId;

        data['userDto'] = user;
        data['organizationDto'] = organization;

        return data;
    }


    findAll(organizationId) {
        return RelationClient.getData(RelationClient.orgUser + organizationId);
    }

    createMapping(userName, orgId) {
        let formJson = organizationUserClient.createJsonForNewUserOrgMapping(userName, orgId);
        return RelationClient.putData(RelationClient.orgUser, formJson).then(info("CREATED USER"));
    }
}

const organizationUserClient = new OrganizationUserClient();

export default OrganizationUserClient;
export {
    organizationUserClient,
}
