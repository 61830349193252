import Promise from "bluebird";
import CamundaCaseClient from "./CamundaCaseClient";

/**
 * @typedef {object} Query
 * @property {function} page
 * @property {function} itemsPerPage
 * @property {function} sorts
 */

/** @type Query */
const defaultQuery = {
    page: 1,
    itemsPerPage: 5,
    sorts: [],
};

const cases =
    (query = defaultQuery) =>
        Promise.all([CamundaCaseClient.getCaseCount(query),
                     CamundaCaseClient.getCases(query)])
               .spread(({count}, cases) => {
                   return ({
                       results: cases,
                       totalResults: count,
                       query: query,
                   });
               });

const caseRef =
    id =>
        CamundaCaseClient.getCase(id);

const caseDiagram =
    id =>
        CamundaCaseClient.getCaseDiagram(id);

const caseInstance =
    id =>
        CamundaCaseClient.getCaseInstance(id);

const historicCaseInstance =
    query =>
        CamundaCaseClient.getHistoricCaseActivityInstances(query);

export {
    caseRef,
    cases,
    caseDiagram,
    caseInstance,
    historicCaseInstance,
}
