import {querystring, restApi} from "./util";
import {getJson, getXml} from "../HTTP/get";
import {postJsonNoContent} from "../HTTP/post";
import {putJsonNoContent} from "../HTTP/put";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 25-9-18.
 */



const endpoints = {
    task: `${restApi}/task`,
    history: `${restApi}/history`,
    caseDefinition: `${restApi}/case-definition`,
    processType: `${restApi}/process-definition`,
    processes: `${restApi}/process-instance`,
    instance: `${restApi}/process-instance`,
};

/**
 *  @type {TaskClient}
 */
class Camunda {

    static caseDiagramCache = {};
    static instanceDiagramCache = {};

    static getTaskCount(query) {
        return getJson()(`${endpoints.task}/count${querystring(query)}`);
    }

    static getTasks(query) {
        return getJson()(`${endpoints.task}${querystring(query)}`);
    }

    static getTask(id) {
        return getJson()(`${endpoints.task}/${id}`);
    }

    static getTaskAttachments(id) {
        return getJson()(`${endpoints.task}/${id}/attachment`);
    }

    static getTaskAttachmentById(taskId, attachmentId) {
        return getJson()(`${endpoints.task}/${taskId}/attachment/${attachmentId}`);
    }

    static closeTask(task, variables = null) {
        if (variables !== null) {
            throw  new Error("closeTask: variable not supported yet...");
        }
        return postJsonNoContent(`${endpoints.task}/${task.id}/complete`)({variables: {}});
    }

    static getCaseCount(query) {
        return getJson()(`${endpoints.history}/case-instance/count${querystring(query)}`);
    }

    static getCases(query) {
        return getJson()(`${endpoints.history}/case-instance${querystring(query)}`);
    }

    static getCase(id) {
        return getJson()(`${endpoints.history}/case-instance/${id}`);
    }

    static getCaseInstance(id) {
        return getJson()(`${endpoints.history}/case-instance/${id}`);
    }

    static getCaseDiagram(id) {
        return getXml(this.caseDiagramCache)(`${endpoints.caseDef}/${id}/diagram`);
    }

    static getHistoricCaseActivityInstances(query) {
        return getJson()(`${endpoints.history}/case-activity-instance${querystring(query)}`);
    }

    static getActivities(query) {
        return getJson()(`${endpoints.task}${querystring(query)}`);
    }

    static getProcessTypes() {
        return getJson()(`${endpoints.processType}`);
    }

    static getProcesses(typeId) {
        return getJson()(`${endpoints.processes}?processDefinitionId=${typeId}`);
    }

    static getInstance(instanceId) {
        return getJson()(`${endpoints.processes}/${instanceId}`);
    }

    /**
     * NB. We currently don't reduce businesskeys, so this should always return at most one result
     *     Camunda itself does support businesskey reuse, so if we ever decide to use businesskeys in
     *     that manner this method will be faulty
     * @param businessKey
     * @returns {Promise}
     */
    static getInstanceByBusinessKey(businessKey) {
        return getJson()(`${endpoints.processes}?businessKey=${businessKey}`)
            .then(array => array.length === 1 ? array[0] : null);
    }

    static getProcessDiagram(processTypeId) {
        console.log(`${endpoints.processType}/${processTypeId}/xml`);
        return getJson(this.instanceDiagramCache)(`${endpoints.processType}/${processTypeId}/xml`)
            .then(({bpmn20Xml}) => bpmn20Xml);
    }

    static getProcessActivities(processId) {
        return getJson(this.instanceDiagramCache)(`${endpoints.instance}/${processId}/activity-instances`)
    }

    static getInstanceVariables(processId) {
        return getJson()(`${endpoints.instance}/${processId}/variables`)
            .then(variables => {
                let unpacked = {};
                Object.entries(variables)
                      .forEach(([key, {value}]) => unpacked[key] = value);
                return unpacked;
            })
    }

    static setInstanceVariables(processId, type, name, value) {
        return putJsonNoContent(`${endpoints.instance}/${processId}/variables/${name}`)({value, type});
    }

}

export default Camunda;
