/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 16-1-18.
 */
const styles = theme => {
    return ({

        root: {
            height: '100%',
            display: 'flex',

        },
        mainBackground: theme.skin.overrides.mainBackground,
        noBackGround: {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right top',
        },
        mainContent: {
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
        }
    });
};

export default styles;
