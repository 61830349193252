import * as React from "react";
import {connect} from "react-redux";
import {retrieveForm} from "../../../action/creator/asynchonous/form/retrieveForm";
import QuestionForm from "../../component/QuestionForm/smart/QuestionForm";
import nop from "../../../action/creator/nop";

class GroupTest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {id: 0};
    }

    componentDidMount() {
        this.setState({id: this.props.match.params.id});
        return this.props.didMount()
    }

    render() {
        return <QuestionForm endpoint={'test/group/' + this.state.id}
                             submitAction={form => nop}
                             buttonText="Test"
        />;
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    didMount: () => dispatch(retrieveForm('test/group/' + ownProps.match.params.id))
});

export default connect(null, mapDispatchToProps)(GroupTest);
export {
    GroupTest
}
