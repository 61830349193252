import React from "react";
import {Card, CardContent, Grid, Typography, withStyles} from "@material-ui/core";
import {person} from "../../../state/selector/person";
import {connect} from "react-redux";
import securePage from "../SecurePage/SecurePage";
import LoggedOn from "../../component/Main/Base/LoggedOn/LoggedOn";
import search from "../../../action/creator/asynchonous/search/search";
import {currentSearch, searchResults, searchTerm} from "../../../state/selector/customer";
import SearchBar from "../../component/SearchBar/SearchBar";
import {compose} from "redux";
import {errorAction} from "../../../api/relation/RelationClient";
import columnStyles from "../../../style/columnStyles";
import withWidth from "@material-ui/core/withWidth/withWidth";
import singleColumn from "../customer/Customer/util/singleColumn";
import BigCardHeader from "../../component/Card/BigCardHeader";
import SmallCardHeader from "../../component/Card/SmallCardHeader";
import SearchResults from "../../component/SearchResults/SearchResults";
import {customerToSearchResult} from "../../../api/relation/customer/util";
import {routeCustomer} from "../../../action/creator/router";
import CardMediaHeader from "../../component/CardMediaHeader/CardMediaHeader";
import {accept, archive, unArchive} from "../../../action/creator/asynchonous/customer/status";
import {roles} from "../../../api/login/LoginClient";
import paths from "../../paths";
import {push} from "react-router-redux"
import {currentUser} from "../../../state/selector/user";

const actionIcon = customer => {
    switch (customer.customerStatus) {
        case "CUSTOMER":
            return "archive";
        case "PROSPECT":
            return "check";
        case "ARCHIVED":
            return "unarchive";
        default:
            console.error("Unexpected customer status", customer.customerStatus);
    }
}

const actionDescription = customer => {
    switch (customer.customerStatus) {
        case "CUSTOMER":
            return "Archiveer";
        case "PROSPECT":
            return "Accepteer";
        case "ARCHIVED":
            return "Restaureer";
        default:
            console.error("Unexpected customer status", customer.customerStatus);
    }
}

const DashboardHome = ({
                           user,
                           person,
                           currentSearchTerm,
                           searchResults,
                           onSearch,
                           onView,
                           onAction,
                           onHome,
                           width,
                           classes
                       }) =>
    <LoggedOn onHome={onHome}>

        <Grid item
              sm={12}>
            <SearchBar currentSearchTerm={currentSearchTerm}
                       onSearch={onSearch(user)(1)}
                       customerStatus="ALL"/>
        </Grid>

        {
            searchResults.results.length > 0
                ? <Card>
                    <CardMediaHeader title="Contacten"
                                     image="/images/handshake.jpg"/>
                    <CardContent>
                        <SearchResults searchResults={searchResults}
                                       customerStatus="ALL"
                                       mapper={customerToSearchResult}
                                       onClick={onView}
                                       actionIcon={actionIcon}
                                       action={actionDescription}
                                       onAction={customer => null}
                                       onPaging={page => onSearch(user)(page)(currentSearchTerm)}/>
                    </CardContent>
                </Card>
                : null
        }

        {
            searchResults.results.length === 0
                ? <Grid item
                        className={singleColumn(width) ? classes.singleColumn : classes.leftColumn}
                        sm={12}
                        md={6}>
                    <Card>
                        <CardContent>
                            <BigCardHeader title={person.name}/>
                            <SmallCardHeader title="Welkom bij Atlas"/>
                            <Typography variant="body1"
                                        gutterBottom>
                                Ga naar klanten of polissen.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <SmallCardHeader title="Bruto premie"/>
                            <Typography variant="body1"
                                        gutterBottom>
                                Bruto premie
                            </Typography>
                        </CardContent>
                    </Card>

                </Grid>
                : null
        }

        {
            searchResults.results.length === 0
                ? <Grid item
                        className={singleColumn(width) ? classes.singleColumn : classes.rightColumn}
                        sm={12}
                        md={6}>
                    <Card>
                        <CardContent>
                            <SmallCardHeader title="Product portfolio verdeling"/>
                            <Typography variant="body1"
                                        gutterBottom>
                                Product portfolio verdeling
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <SmallCardHeader title="Nieuwe leads"/>
                            <Typography variant="body1"
                                        gutterBottom>
                                Nieuwe leads
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                : null
        }
    </LoggedOn>;

const mapStateToProps = state => ({
    person: person(state),
    user: currentUser(state),
    searchResults: searchResults(state),
    search: currentSearch(state),
    currentSearchTerm: searchTerm(state),
});

const mapDispatchToProps = dispatch => ({
    onView: customer => {
        return routeCustomer(customer.id).then(dispatch);
    },
    onHome: () => dispatch(push(paths.root)),
    dispatch
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onAction: customer => {
        let action = {};
        switch (customer.customerStatus) {
            case "CUSTOMER":
                action = archive(customer, stateProps.search);
                break;
            case "PROSPECT":
                action = accept(customer, stateProps.search);
                break;
            case "ARCHIVED":
                action = unArchive(customer, stateProps.search);
                break;
            default:
                console.error("Unexpected customer status", customer.customerStatus);
        }
        return action.then(dispatchProps.search);
    },
    onSearch:
        user =>
            page =>
                searchTerm => {
                    return search(user)(stateProps.user)({
                        searchTerm: searchTerm,
                        page: page,
                        customerStatus: null,
                    }).then(dispatchProps.dispatch)
                        .catch(errorAction);
                },
});

let decorate = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    securePage([roles.admin]),
    withStyles(columnStyles),
    withWidth()
);
export default decorate(DashboardHome);
