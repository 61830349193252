import {withStyles} from "@material-ui/core";
import React from "react";
import MenuDrawer from "./MenuDrawer/MenuDrawer";
import Header from "../../Header/Header";
import MainBody from "../../MainBody/MainBody";
import Footer from "../../Footer/Footer";
import {compose} from "redux";
import {connect} from "react-redux";
import SideMenu from "./SideMenu/SideMenu";
import styles from "./styles";
import {person} from "../../../../../state/selector/person";
import classNames from "classnames";
import {currentUser} from "../../../../../state/selector/user";

class LoggedOn extends React.Component {

    state = {
        openedMenu: false,
    };

    handleMenuToggle = () => {
        this.setState({...this.state, openedMenu: !this.state.openedMenu});
    };

    render() {
        const {classes, children, person, onHome, background = "mainBackground", user, portal = false, currentUser, ...other} = this.props;
        const openedMenu = this.state.openedMenu;

        return (
            <div className={classNames(classes.root, classes[background])}>
                <MenuDrawer open={openedMenu}
                            onToggle={this.handleMenuToggle}>
                    <SideMenu person={person}
                              currentUser={currentUser}
                              user={user}/>
                </MenuDrawer>
                <div className={classes.mainContent}>
                    <Header onMenuToggle={this.handleMenuToggle}
                            portal={portal}
                            onHome={onHome}/>
                    <MainBody {...other}>
                        {children}
                    </MainBody>
                    <Footer/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    person: person(state),
    currentUser: currentUser(state),
});

const decorate = compose(
    withStyles(styles),
    connect(mapStateToProps)
);
export default decorate(LoggedOn);
