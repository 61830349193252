import * as React from "react";
import {Button, Card, CardContent, CardHeader, Grid, Icon, Typography, withStyles} from "@material-ui/core";

import {withRouter} from "react-router";
import {connect} from "react-redux";
import {compose} from "redux";
import {Field, reduxForm} from "redux-form";
import {forgotPassword} from "../../../action/creator/asynchonous/login";
import LoginField from "../../component/LoginForm/LoginField";
import throwSubmissionError from "../../../util/throwSubmissionError";
import LoggedOff from "../../component/Main/Base/LoggedOff/LoggedOff";

const styles = theme => ({
    loginButton: {
        marginTop: 20,
        float:     "right",
        color:     "white",
    },
    link:        {
        color: theme.palette.primary[200],
    },
});

const ForgotPassword = ({onLogin, handleSubmit, onForgotPassword, classes}) => {
    return (
        <LoggedOff>
            <Grid item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}>
                <div className="ForgotPassword">
                    <Card className="login-card">
                        <CardHeader className="login-card-title"
                                    title="Wachtwoord vergeten"/>

                        <CardContent className="login-card-content">
                            <form id="login-form"
                                  onSubmit={handleSubmit(onForgotPassword)}>
                                <Field name="email"
                                       id="email"
                                       component={LoginField(classes)}
                                       placeholder="Gebruikersnaam"
                                       icon="email"/>
                                <Button variant="contained"
                                        color="primary"
                                        className={classes.loginButton}
                                        type="submit"><Icon>arrow_forward</Icon>
                                </Button>
                            </form>
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant="subtitle1">
                                Geef uw e-mailadres op om uw wachtwoord opnieuw in te stellen.
                            </Typography>
                        </CardContent>

                    </Card>
                </div>
            </Grid>
        </LoggedOff>);
};

const mapDispatcherToProps = dispatch => ({
    onForgotPassword: ({email}) => forgotPassword(email).then(dispatch)
                                                        .catch(throwSubmissionError("Inloggen mislukt")),
});

export default compose(
    reduxForm(
        {
            form: 'ForgotPassword',
        }),
    connect(null, mapDispatcherToProps),
    withRouter,
    withStyles(styles),
)(ForgotPassword);

