import * as React from "react";
import {Button, Card, CardContent, CircularProgress, Icon, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {compose} from "redux";
import securePage from "../../../SecurePage/SecurePage";
import LoggedOn from "../../../../component/Main/Base/LoggedOn/LoggedOn";
import {Forms} from "../../../../../api/form/FormClient";
import {routeFormPage, routeOrganization} from "../../../../../action/creator/router";
import {organizationClient} from "../../../../../api/relation/identity/organization/OrganizationClient";
import {organizationToResult} from "../../../../../api/relation/identity/organization/util";
import OrganizationResults from "../../../../component/SearchResults/OrganizationResults";
import {push} from "react-router-redux";
import {roles} from "../../../../../api/login/LoginClient";
import BigCardHeader from "../../../../component/Card/BigCardHeader";

class Organizations extends React.Component {

    constructor() {
        super();
        this.state = {
            loaded:             false,
            organizationResult: {
                organizations: [],
                count:         0,
            },
        };
    }

    componentDidMount() {
        this.reloadOrganizations();
    }

    reloadOrganizations =
        () =>
            organizationClient.findAll()
                              .then(data => this.setState({
                                                              organizationResult: {
                                                                  organizations: data.entity.organizations,
                                                                  count:         data.entity.count,

                                                              },
                                                              loaded:             true,
                                                          }));

    render() {
        const {classes, onNewOrganization, onDelete, onView} = this.props;
        const {loaded} = this.state
        return (
            <LoggedOn>
                <div style={{width: "100%"}}>
                    <Card>
                        <BigCardHeader title="Organisaties"/>
                        <CardContent>


                            {loaded
                                ? <OrganizationResults organizationResults={this.state.organizationResult}
                                                       mapper={organizationToResult}
                                                       onClick={onView}
                                                       actionIcon="delete"
                                                       action="Verwijder"
                                                       onAction={organization => onDelete(organization)
                                                           .then(() => this.reloadOrganizations())
                                                           .catch(error => console.error(error))}/>
                                : <CircularProgress/>
                            }

                            <Button className={classes.button}
                                    id="neworg"
                                    variant="contained"
                                    color="primary"
                                    onClick={onNewOrganization}>

                                <Icon className={classes.icon}>add</Icon> Organisatie toevoegen
                            </Button>

                        </CardContent>
                    </Card>
                </div>
            </LoggedOn>
        );
    }
}

const styles = theme => ({
    button: {
        float:  "right",
        margin: "20px 0 20px 0",
    },
    icon:   {
        marginRight: 10,
    },
});

const mapDispatchToProps = dispatch => ({
    onNewOrganization: () => routeFormPage("/new-organization", Forms.organization).then(dispatch),
    onView:            organization => routeOrganization(organization).then(dispatch),
    onDelete:          organization => organizationClient.delete(organization.id)
                                                         .then(() => dispatch(push('/organizations'))),
});

export default compose(
    withStyles(styles),
    connect(null, mapDispatchToProps),
    securePage([roles.admin]),
)(Organizations);
