import {Card, CardContent, Typography} from "@material-ui/core";
import React from "react";
import classnames from "classnames";
import {withStyles} from "@material-ui/core/index";
import Contract from "../Contract/Contract";
import PartialComponent from "../Partial/PartialComponent";

const styles = theme => ({
    fullWidthCard: {
        paddingTop: "30px",
    }
});

const Order =
    ({order, customer, index, classes}) =>
        <Card key={index}
              className={classnames(classes.fullWidthCard)}>
            < CardContent>

                <Typography className={classes.header}
                            variant="h2">
                    {order.description}
                </Typography>

                <PartialComponent Component={Contract}
                                  order={order}
                                  customer={customer}
                                  useFacturen={true}
                                  showFadedContent={false}/>

            </CardContent>
        </Card>

export default withStyles(styles)(Order);
