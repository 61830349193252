import paths from "../../../../../paths";

const portalMenuItems = (taskcount = 3) => [
    {
        group: "Klant",
        items: [
            {
                name: "Overzicht",
                icon: "people",
                url: paths.user,
            },
        ]
    },
];

export default portalMenuItems;
