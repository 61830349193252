/**
 * @param {number[]} values
 */

const sum = values => values.reduce((a, b) => a + b, 0);

/**
 * @param {number[]} values
 */
const sumsToOne = values => Math.abs(sum(values) - 1) < Number.EPSILON;

/**
 * @param {number[]} values
 */
const normalize = values => {
    const total = sum(values);
    const normalized = values.map(value => value / total);
    console.assert(sumsToOne(normalized), normalized, "Normalized values should sum to 1");
    return normalized;
}

class MovingAverage {

    /**
     * @type {number[]}
     */
    weights;

    /**
     * An array to keep the (weight.length) latest values
     * @type {number[]}
     */
    values = [];

    /**
     * @param {number} weights
     */
    constructor(...weights) {
        if (!sumsToOne(weights)) {
            throw new Error("Weights should sum to 1: [" + weights + "] sums to " + sum(weights))
        }

        this.weights = weights;
    }

    /**
     * @param {number} value
     */
    add(value) {
        const values = this.values;
        const weights = this.weights;

        values.push(value);
        while (values.length > weights.length) {
            values.shift();
        }
    }

    /**
     * @return {number}
     */
    average() {

        console.assert(this.values.length <= this.weights.length, this.values, this.weights, "Too many values");

        if (this.values.length === 0) {
            return 0;
        }

        const values = this.values;
        const weights = this.weights.length === values.length ? this.weights
                                                              : normalize(this.weights.slice(0 - this.values.length));

        console.assert(values.length === weights.length, values, weights, "Uneven lengths");

        let total = 0;
        for (let index = 0; index < values.length; index++) {

            const value = values[index]
            const weight = weights[index]

            total += value * weight;
        }

        return total;
    }

    reset() {
        this.values = [];
    }
}

export default MovingAverage;
