/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-9-17.
 */

import React from "react";
import PropTypes from "prop-types";
import ChoiceQuestion from "../../presentation/Choice/ChoiceQuestion"
import {questionShape} from "../../../propType/question";

const getValue = question => {
    const defaultValue = question.defaultValue ? question.defaultValue : "";
    return question.value ? question.value : defaultValue;
};

const Choice = Presentation =>
    ({question, onSubmitQuestion}) =>
        <Presentation question={question}
                      value={getValue(question)}
                      onChange={event => onSubmitQuestion(event.target.value)}/>;

Choice.propTypes = {
    question: PropTypes.shape(
        {
            ...questionShape,
            acceptableValues: PropTypes.object.isRequired
        }),
    onSubmitQuestion: PropTypes.func.isRequired,
};

export default Choice(ChoiceQuestion);
export {
    Choice,
}
