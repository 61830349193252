/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 30-8-18.
 */
import {promiseAction} from "../../../../middleware/promiseDispatch";
import {customerClient} from "../../../../api/relation/customer/CustomerClient";
import retrieving from "../retrieving";
import ActionType from "../../../ActionType";
import error from "../../error";
import {compoundAction} from "../../../../middleware/compoundDispatch";
import {postFormFromState} from "../form/postForm";

const deletingAction = document => ({
    type: ActionType.asynchronous.documents.DELETING,
    document,
});

const deletedAction = document => ({
    type: ActionType.asynchronous.documents.DELETED,
    document,
});

const retrievedAllDocuments = ({entity}) => ({
    type: ActionType.asynchronous.documents.RETRIEVED_ALL,
    documents: entity ? entity : []
});

const loadDocumentsAction = customer => promiseAction(
    customerClient.documents(customer)
                  .then(response => compoundAction(retrievedAllDocuments(response),
                                                   postFormFromState)),
    retrieving(ActionType.asynchronous.documents.RETRIEVING_ALL),
    error("Problem posting QuestionForm")
);

const deleteDocumentAction = document => promiseAction(
    customerClient.deleteDocument(document)
                  .then(_ => compoundAction(deletedAction(document),
                                            loadDocumentsAction(document.customer))),
    deletingAction(document),
    error("Problem posting QuestionForm")
);

export {
    loadDocumentsAction,
    deleteDocumentAction
}
