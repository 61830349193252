import Badge from "@material-ui/core/Badge";
import * as React from "react";
import {Icon} from "@material-ui/core";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 31-5-19.
 */

const MenuIcon =
    ({
         icon,
         badge = null,
         badgeColor = "primary"
     }) =>
        badge && badge > 0
        ? <Badge color={badgeColor}
                 badgeContent={badge}>{<Icon>{icon}</Icon>}
        </Badge>
        : <Icon>{icon}</Icon>;

export default MenuIcon;
