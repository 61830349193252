import {Button, Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles} from "@material-ui/core";
import React from "react";
import classnames from "classnames";
import If from "../Optional/If";
import {date, formatPrice, hasDescription, hasTotal, total} from "./common";
import Optional from "../Optional/Optional";
import Signature from "./Signature";
import {isPresent} from "../../../util/util";
import {currentUser} from "../../../state/selector/user";
import {noteClient, noteTypes} from "../../../api/relation/note/NoteClient";
import {compose} from "redux";
import {connect} from "react-redux";
import {orderClient} from "../../../api/relation/order/OrderClient";
import Facturen from "../Factuur/Facturen";

const styles = theme => ({
    total: {
        fontWeight: "500",
        fontSize: "14px",
    },
    button: {
        float: "left",
        marginTop: "30px",
        marginBottom: "30px",

    }
});

const factuur = order => ({
    amount: 500,
});

const onCreateFactuur =
    user =>
        customer =>
            order =>
                orderClient.createFactuur(order.id, factuur(order))
                           .then(_ => noteClient.addNote(user, customer, {description: noteTypes.FACTUUR, text: "Factuur opgesteld"}))

class Contract extends React.Component {

    state = {
        facturen: [],
    }

    componentDidMount() {
        orderClient.getFacturen(this.props.order.id)
                   .then(facturen => this.setState({facturen}));
    }

    render() {

        const {order, customer, user, classes, useHeader = true, useSignature = true, useFacturen = false} = this.props;
        const items = order.items;

        const itemClass = item => item.total ? classnames(classes.total) : classnames();
        if (!hasTotal(items)) {
            items.push({name: "Totaal", price: total(items), description: "", total: true})
        }

        return (
            <div>
                <br/>
                <Typography paragraph={true}
                            variant="body2">
                    Aangemaakt op {date(order)}
                </Typography>

                <Table>
                    {
                        useHeader ? <TableHead>
                                      <TableRow key={0}>
                                          <TableCell key={1}>Dienst</TableCell>
                                          <TableCell key={2}>Prijs</TableCell>
                                          <If show={hasDescription(items)}>
                                              <TableCell key={3}>Uitleg</TableCell>
                                          </If>
                                      </TableRow>
                                  </TableHead>
                                  : null
                    }

                    <TableBody>
                        {
                            items.map((item, index) =>
                                          <TableRow key={index}
                                                    className={classes.total}>
                                              <TableCell key={1}
                                                         className={itemClass(item)}>
                                                  {item.name}
                                              </TableCell>
                                              <TableCell key={2}
                                                         className={itemClass(item)}>
                                                  {formatPrice(item.price)}
                                              </TableCell>
                                              <If show={hasDescription(items)}>
                                                  <TableCell key={3}>{item.description}</TableCell>
                                              </If>
                                          </TableRow>)
                        }
                    </TableBody>
                </Table>
                <br/>

                <Optional show={isPresent(order.confirmedAt) && useSignature}
                          Component={Signature}
                          order={order}
                          customer={customer}/>


                <If show={useFacturen}>
                    <br/>
                    <Button variant="contained"
                            className={classes.button}
                            color='primary'
                            onClick={() => onCreateFactuur(user)(customer)(order).then(_ => orderClient.getFacturen(order.id)
                                                                                                       .then(facturen => this.setState({facturen})))}>
                        Maak factuur op
                    </Button>

                    <br/>

                    <Facturen facturen={this.state.facturen}/>
                </If>

            </div>);

    }
}

const mapStateToProps = state => ({
    user: currentUser(state),
});

const mapDispatchToProps = dispatch => ({});

const decorate = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
)

export default decorate(Contract);


