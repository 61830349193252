/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 6-9-17.
 */

import ActionType from "../../action/ActionType";

const value = (value = null, action) => {
    if (action.type === ActionType.asynchronous.quote.result.RETRIEVED) {
        return action.payload;
    }
    return value;
};
export default value;
