/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 30-8-18.
 */

/**
 * @typedef {String} UploadType
 **/

/**
 * @enum{UploadType}
 */
const uploadTypes = {
    unknown: "Kies een waarde",
    koopovereenkomst: "Koopovereenkomst",
    renteaanbod: "Offerte / renteaanbod",
    overige: "Overige",
    medewerkerslijst: "Medewerkerlijst",
    cv: "Curriculum Vitea",
    leveringsvoorwaarden: "Leveringsvoorwaarden",
    organogram: "Organogram",
    kvk: "Uittreksel Kvk",
    montageverzekering: "CAR/Montageverzekering afschrift",
    werkmaterieelverzekering: "Werkmaterieelverzekering afschrift",
    ongevallenregister: "Ongevallenregister",
    riepva: "RI&E en PvA",
    overzichtgevolgdeopleidingen: "Overzicht gevolgde opleidingen",
    financieeljaarverslag: "(Geconsolideerde) financiële jaarverslag",
    ondernemingsplan: "Ondernemingsplan / businessplan",
    koopakte: "koopakte",
};

export  default uploadTypes;
