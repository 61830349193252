/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 20-12-17.
 */

import ActionType from "../../action/ActionType";

const initialStep = 0;

const currentStep = (state = initialStep, {type, step}) => {
    if (type === ActionType.currentStep) {
        return step;
    }

    if (type === ActionType.asynchronous.form.get.RETRIEVING) {
        return initialStep;
    }

    return state;
};
export default currentStep;
export {
    currentStep,
    initialStep,
}
