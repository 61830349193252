import Dossier from "../../../../component/Dossier/model/Dossier";
import DossierList from "../../../../component/Dossier/list/DossierList";
import React from "react";
import {routeCustomer} from "../../../../../action/creator/router";
import {compose} from "redux";
import {connect} from "react-redux";
import {Forms} from "../../../../../api/form/FormClient";
import retrieveForm from "../../../../../action/creator/asynchonous/form/retrieveForm";
import {Card, CardContent, withStyles} from "@material-ui/core";
import QuestionForm from "../../../../component/QuestionForm/smart/QuestionForm";
import {setAdvisor, setAssignee} from "../../task/assignee";
import {dossierVariables} from "../../../customer/Customer/components/dossier/variables";
import {setVariables} from "../../../../../api/process/process/processes";
import {promiseAction} from "../../../../../middleware/promiseDispatch";
import nop from "../../../../../action/creator/nop";
import {getQuestionValue} from "../../../../../util/form";

const dossier = ({instance, instanceVariables, customer}) => {
    const dossier = new Dossier(instance);
    dossier.setCustomer(customer);
    dossier.setVariables(instanceVariables);
    return dossier;
}

const style = theme => (
    {
        root: {
            // Prevents the submitbutton from being placed outside the view/adds scrollbar to find it back.
            // Not the ideal solution, but at least the button is clickable now
            maxWidth:  "calc(100% - 100px)",
            overflowX: "auto",
        },
    }
);

const assignee = (form, user, customer) => getQuestionValue(form)("status") === "Klant"? customer : user;

const updateDossier =
    user =>
        customer =>
            oldDossier =>
                form => {
                    const processId = oldDossier.process.id;
                    const variables = dossierVariables(form, customer, oldDossier);

                    return setVariables(processId, variables).then(_ => Promise.all([
                                                                                        setAdvisor(processId)(user),
                                                                                        setAssignee(processId)(
                                                                                            assignee(form,
                                                                                                     user,
                                                                                                     customer)),
                                                                                    ]))
                }

class DossierInstance extends React.Component {

    constructor(props) {
        super(props)
        this.dossier = React.createRef()
    }

    componentDidMount() {
        this.props.loadForm(dossier(this.props).data());
    }

    render() {
        const {user, customer, onClickCustomer, reload = () => null, classes} = this.props;

        return <Card className={classes.root}>
            <CardContent>

                <br/>
                <div ref={ref => this.dossier = ref}/>
                <DossierList dossiers={[dossier(this.props)]}
                             onClickDossier={() => null}
                             onClickCustomer={() => onClickCustomer(customer)}/>

                <br/>
                <br/>
                <QuestionForm endpoint={Forms.hypotheekDossier}
                              submitAction={form => promiseAction(updateDossier(user)(
                                  customer)(
                                  dossier(this.props))(
                                  form)
                                                                      .then(_ => reload())
                                                                      .then(_ => this.componentDidMount())
                                                                      .then(_ => this.dossier.scrollIntoView())
                                                                      .then(_ => nop))}/>

            </CardContent>
        </Card>;

    }
}

const dispatchToHandlers = dispatch => ({
    onClickCustomer: customer => () => routeCustomer(customer.id).then(dispatch),
    loadForm:        data => dispatch(retrieveForm(Forms.hypotheekDossier, data)),
});

const decorate = compose(
    connect(null, dispatchToHandlers),
    withStyles(style),
);

export default decorate(DossierInstance);
