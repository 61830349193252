/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 19-12-17.
 */
import React from "react";
import {connect} from "react-redux";
import LoginPage from "../Login/LoginPage";

let hasAccess = (roles, allowedRoles) => {
    return true;
    //return allowedRoles.reduce((acc, allowedRole) => acc || roles.includes(allowedRole), false);
};

const isEmptyObject = expr => typeof expr === "object" && Object.keys(expr).length === 0;
const isEmptyArray = expr => Array.isArray(expr) && expr.length === 0;

const isEmpty = expr => expr === null || typeof expr === "undefined" || isEmptyObject(expr) || isEmptyArray(expr);

const getRoles = login => {
    if (!isEmpty(login)) {
        if (!isEmpty(login.roles)) {
            return login.roles;
        }
    }
    return [];
};

const securePage =
    allowedRoles =>
        Page => {
            const SecuredPage = ({children, ...props}) =>
                hasAccess(getRoles(props.login), allowedRoles) ? <Page {...props}>{children}</Page>
                    : <LoginPage message="Geen toegang"/>;

            const mapStateToProps = state => ({
                login: state.login,
            });

            return connect(mapStateToProps)(SecuredPage);
        };

export default securePage;
export {
    securePage,
    hasAccess
};
