import React from "react";
import {friendlyNameFromDefinitionId} from "../../../../../api/process/util/process";
import MiniPage from "../../../../component/MiniPage/MiniPage";
import DossierInstance from "./DossierInstance";

const mainPage = (user, customer, instance, instanceVariables, activities, reload) => {
    switch (friendlyNameFromDefinitionId(instance.definitionId)) {
        case "HypotheekAanvraag":
            return MiniPage.create("Hypotheek aanvraag", <DossierInstance instance={instance}
                                                                          instanceVariables={instanceVariables}
                                                                          user={user}
                                                                          customer={customer}
                                                                          reload={reload}/>);
        default:
            return null;
    }
}

export default mainPage;
