import {Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import React from "react";

const DefaultProposal = ({proposal, formDescription}) =>
    <Grid item
          lg={12}
          md={12}
          sm={12}>
        <Card>
            {proposal === null
             ? null
             : <CardContent>
                 <Typography variant="subtitle1">
                     {formDescription}
                 </Typography>

                 <Table>
                     <TableHead>
                         <TableRow>
                             <TableCell>Vraag</TableCell>
                             <TableCell>Antwoord</TableCell>

                         </TableRow>
                     </TableHead>
                     <TableBody>
                         {
                             proposal !== null && proposal.questionResponse.length > 0
                             ? proposal.questionResponse
                                       .map((item, index) =>
                                                <TableRow key={index}>
                                                    <TableCell>{item.field}</TableCell>
                                                    <TableCell>{item.value}</TableCell>
                                                </TableRow>
                                       )
                             : <TableRow/>
                         }
                     </TableBody>
                 </Table>
             </CardContent>
            }
        </Card>
    </Grid>

export default DefaultProposal;
