/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-9-17.
 */
const retrieved = type => payload => {
    return {
        type: type,
        payload: payload
    }
};
export default retrieved;
