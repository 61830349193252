/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 29-1-18.
 */
import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import Message from "../Message";
import PropTypes from 'prop-types';
import questionPropType from "../../../propType/question";
import Tip from "../../Tip";

const id = question => `question-${question.id}`;

const ChoiceQuestion = ({question, value, onChange}) =>
    <div style={{display: "flex"}}>
        <FormControl disabled={question.locked}
                     error={!question.valid}
                     fullWidth>
            <InputLabel required={question.required}>{question.description}</InputLabel>
            <Select name={id(question)}
                    error={!question.valid}
                    onChange={onChange}
                    value={value}>
                {
                    Object.entries(question.acceptableValues).map(([acceptableValue, description]) => (
                        <MenuItem key={acceptableValue}
                                  value={acceptableValue}
                                  selected={acceptableValue === value}>
                            {description}
                        </MenuItem>
                    ))
                }
            </Select>
            <Message question={question}/>
        </FormControl>
        <Tip tip={question.tooltip}/>
    </div>;

ChoiceQuestion.propTypes = {
    question: questionPropType.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ChoiceQuestion;
