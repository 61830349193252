/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 27-9-18.
 */
import ActionType from "../../action/ActionType";

const formState = (state = "EMPTY", action) => {

    if (action.type === ActionType.asynchronous.form.get.RETRIEVING ||
        action.type === ActionType.asynchronous.form.post.POSTING) {
        return "RETRIEVING"

    }

    if (action.type === ActionType.asynchronous.form.get.RETRIEVED ||
        action.type === ActionType.asynchronous.form.post.RESULT) {
        return "RETRIEVED";
    }
    return state;
};

export default formState;
