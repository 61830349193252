/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 5-9-17.
 */

export default bundleReducers;

/**
 * Alternative to combineReducers.
 * Combines a bunch of reducers that work on a commonly shared state
 * (by contrast, combineReducers combines reducers that each work only their own part of the state)
 *
 * @param initialState
 * @param {Reducer[]} reducers -- Each reducer is expected to work on the same substate an
 *                                should expect the full substate and return a new updated version of the full substatestate.
 *                                Each reducer should either handle the action (if so indicated by action.type)
 *                                and return the new state, or simply return the unchanged state.
 * @returns {Reducer}          -- a Reducer that returns the new state after each of the reducers have been
 *                                called in turn
 */
function bundleReducers(initialState, reducers) {
    return (state = initialState, action) => {
        return reducers.reduce((acc, reducer) => reducer(acc, action), state);
    };
}
