/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 13-4-18.
 */
import PropTypes from 'prop-types';

/**
 * TODO: this is still a work in progress..
 * @type {shim}
 */
const customerPropType = PropTypes.shape({
                                             initials: PropTypes.string,
                                             insertPart: PropTypes.string,
                                             lastName: PropTypes.string,
                                             birthDate: PropTypes.string,
                                             mobile: PropTypes.string,
                                             email: PropTypes.string,
                                             ibanBankAcc: PropTypes.string
                                         });

export default  customerPropType;
