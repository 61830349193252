/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 15-1-18.
 * Pretty much taken from the redux tutorial at https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
 */
const storageKey = "redux-store";

/**
 *
 * @return {undefined|state}
 */
const getStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem(storageKey);
        if (serializedState === null) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error("No previous state");
        }
        return JSON.parse(serializedState);
    } catch (error) {
        console.error("Could not read from LocalStorage", error.message);
        console.info("Starting with fresh state");
        return undefined;
    }
};

const saveStateToLocalStorage = state => {
    try {
        localStorage.setItem(storageKey, JSON.stringify(state));
    } catch (error) {
        console.error("Could not write to LocalStorage", error.message);
    }
};

export {
    getStateFromLocalStorage,
    saveStateToLocalStorage
};
