/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 16-7-18.
 */
import React from "react";
import {Icon, Paper, Table, TableBody, TableCell, TableRow, withStyles} from "@material-ui/core";
import {itemType} from "./products";

const styles = theme => ({
    root: {
        flex: "1 0 100%",
        flexBasis: "40%",
        color: "black",
        backgroundColor: "#E8F5E9",
        padding: 10,
    },
    cell: {
        padding: 0,
        borderWidth: 0,
        margin: 0,
        paddingRight: 0,
    },
    row: {
        height: 0,
    },
    text: {
        fontSize: 12
    },
    image: {
        height: "30px",
    },
    imageWrapper: {
        textAlign: "center"
    }
});

/**
 *
 * @param {object} product
 * @param {number} index
 * @param {number} order
 * @param {object} style -- This component is meant to be used in a carousel that needs to know about width and margin as well.
 *                          So they are passed as argument to the Carousel component, which uses them and passes them through to
 *                          this component as a style object.
 * @param {object} classes
 * @return {*}
 * @constructor
 */
const Product = ({product, index, order, style, classes}) => {
    return <Paper className={classes.root}
                  style={{
                      ...style,
                      order: order,
                  }}>

        <div className={classes.imageWrapper}>
            <img className={classes.image}
                 src={product.logo}
                 alt={`Logo ${product.company}`}/>
        </div>

        <Table>
            <TableBody>
                {product.items.map(itemToRow(classes))}
            </TableBody>
        </Table>
    </Paper>;
};

const itemToRow = classes => (item, index) =>
    <TableRow className={classes.row}
              key={index}>
        <TableCell padding="none"
                   className={classes.cell}>{item.description}</TableCell>
        <TableCell padding="none"
                   className={classes.cell}>{itemToValue(item, classes)}</TableCell>
    </TableRow>;

const itemToValue = (item, classes) => {
    switch (item.type) {
        case itemType.text:
            return item.value;
        case itemType.amount:
            return `€ ${item.value}`;
        case itemType.boolean:
            return <Icon className={classes.text}>{item.value ? "check" : "clear"}</Icon>;
        default:
            console.error("Unsupported item", item);
            return null;
    }
};

export default withStyles(styles)(Product);
