import CamundaProcessClient from "./CamundaProcessClient";

const deduplicateVersions = processTypes => {
    let deduplicated = {};
    for (const processType of processTypes) {
        const key = processType.key;
        if (!deduplicated.hasOwnProperty(key) ||
            processType.version > deduplicated[key].version) {
            deduplicated[processType.key] = processType;
        }
    }
    return Object.values(deduplicated);
};

const startProcess =
    (processDefinitionKey, variables) =>
        CamundaProcessClient.startProcess(processDefinitionKey, variables);

const processTypes =
    query =>
        CamundaProcessClient.getProcessTypes(query)
                            .then(deduplicateVersions);
const processes =
    key =>
        CamundaProcessClient.getProcesses(key);

const processesList =
    (key, query) =>
        CamundaProcessClient.getProcesList(key,query);

const processInstance =
    instanceId =>
        CamundaProcessClient.getInstance(instanceId);

const diagram =
    processTypeId =>
        CamundaProcessClient.getProcessDiagram(processTypeId);
const activities =
    processId =>
        CamundaProcessClient.getProcessActivities(processId);

const instanceVariables =
    processId =>
        CamundaProcessClient.getInstanceVariables(processId);

const setInstanceVariable =
    processId =>
        (type, name, value) =>
            CamundaProcessClient.setInstanceVariables(processId, type, name, value);

const setVariables =
    (processId, variables) => CamundaProcessClient.setVariables(processId, variables);

export {
    processTypes,
    processes,
    processesList,
    processInstance,
    diagram,
    activities,
    instanceVariables,
    setInstanceVariable,
    startProcess,
    setVariables,
};
