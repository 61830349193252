/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 13-5-19.
 */

import mogelijkSkin from "./mogelijkSkin";
import blissSkin from "./blissSkin";
import woningscanSkin from "./woningscanSkin";
import bankgarantieSkin from "./bankgarantieSkin";
import quickScanSkin from "./quickScanSkin";
import {APP_INSTANCE, appInstance} from "../../../appInstance";
import erasmusSkin from "./erasmusSkin";

/**
 * Like colors  in material-ui
 * a color can either be a material-ui color (grey[50], or teal)
 * or an object with (at least) a main key that contains the hexcode
 *
 * This can be used to pass the correct value to a  tyles object
 *
 * @param color
 * @return {*}
 */
const getColor = color => color != null && typeof color === "object" && color.hasOwnProperty("main")
    ? color.main
    : color;

const getSkin = () => {
    switch (appInstance()) {
        case APP_INSTANCE.BANKGARANTIE:
            return bankgarantieSkin;
        case APP_INSTANCE.BLISS:
            return blissSkin;
        case APP_INSTANCE.BOUWKUNDIGE_KEURING:
            return woningscanSkin;
        case APP_INSTANCE.ERASMUS:
            return erasmusSkin;
        case APP_INSTANCE.MOGELIJK:
            return mogelijkSkin;
        case APP_INSTANCE.WONING_SCAN:
            return woningscanSkin;
        case APP_INSTANCE.QUICK_SCAN:
            return quickScanSkin;
        default:
            throw Error(`Could not determine FormType for AppInstance ${appInstance()}`);
    }
};

const skin = getSkin();

export default skin;
export {
    getColor,
}
