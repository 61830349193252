import React from "react";
import {FormControlLabel, FormLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import {formatDate} from "./common";
import {fullName} from "../../../util/customer";

const Signature = ({order, customer}) => {
    return (

        [
            <br key={1}/>,
            <Typography paragraph={true}
                        variant="body2"
                        key={2}>
                Ondertekend op {formatDate(order.confirmedAt)}
            </Typography>,
            <FormLabel required={false}
                       key={3}
                       component="legend">Akkoord met de algemene voorwaarden</FormLabel>,
            <RadioGroup row
                        key={4}
                        name="Akkoord"
                        value="Ja">

                <FormControlLabel id={1}
                                  key="Ja"
                                  value="Ja"
                                  label="Ja"
                                  defaultChecked={true}
                                  control={<Radio color="primary"/>}/>

                <FormControlLabel id={2}
                                  key="Nee"
                                  value="Nee"
                                  label="Nee"
                                  defaultChecked={false}
                                  control={<Radio color="primary"/>}/>

            </RadioGroup>,

            <FormLabel key={5}
                       required={false}
                       component="legend">Handtekening {fullName(customer)}</FormLabel>,
            <br key={6}/>,
            <img key={7}
                 src={order.signature}
                 alt="ondertekening"/>,

        ]);
};

export default Signature
