/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-7-19.
 */
import React from "react";
import {Forms} from "../../../../../../api/form/FormClient";
import QuestionForm from "../../../../../component/QuestionForm/smart/QuestionForm";
import {retrieveForm} from "../../../../../../action/creator/asynchonous/form/retrieveForm";
import {compose} from "redux";
import {connect} from "react-redux";
import {CircularProgress, Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMediaHeader from "../../../../../component/CardMediaHeader/CardMediaHeader";
import Anchor from "../../../../../component/Anchor/Anchor";
import Promise from "bluebird";
import Summary from "./Summary";
import Request from "../component/Request";
import {currentUser} from "../../../../../../state/selector/user";
import Notes from "../../../../../component/Note/Notes";
import MiniPages from "../../../../../component/MiniPage/MiniPages";
import MiniPage from "../../../../../component/MiniPage/MiniPage";
import NotesLabel from "../../../../../component/Note/NotesLabel";
import {approveAppointment} from "./businessLogic";
import {loadConsultants} from "./consultant";
import {loadProcess} from "./process";
import {loadNotes} from "./note";

/**
 * Task corresponding to the step 'Beoordeel aanvraag' in the  DOCUMENTED_REQUEST process
 *
 * It should present the user with all relevant information in the request, and allow the user to accept or decline the
 * request
 *
 */
class MakeAppointment
    extends React.Component {

    state = {
        instance:    null,
        agent:       null,
        customer:    null,
        request:     null,
        variables:   null,
        values:      null,
        consultants: [],
        loaded:      false,
        notes:       [],
    };

    setAsyncState = (newState) =>
        new Promise((resolve) => this.setState(newState, resolve));

    componentDidMount() {
        const {task} = this.props;

        // N.B. loadProcess also retrieves the customer (as stored in the process variable) and updates the state
        //      with it, so that it is available in the chained getNotes() call

        loadProcess(task.processInstanceId, this.setAsyncState)
            .then(_ => loadNotes(this.state.customer, this.setAsyncState))
            .then(_ => loadConsultants(this.setAsyncState))
            .then(_ => this.props.loadForm(this.state.values))
            .then(_ => this.setState({loaded: this}));
    }

    render() {

        const {user, task} = this.props;
        const {request, customer, notes, consultants, loaded} = this.state;

        return [
            <Card key={1}>
                <CardMediaHeader image="/images/handshake.jpg" title={`Taak ${task? task.name : ""}`}/>
                {
                    customer && request
                        ? <Summary customer={customer}
                                   request={request}/>
                        : <CircularProgress/>
                }
            </Card>,

            <Card key={2}>
                {
                    loaded
                        ? <MiniPages miniPages={[
                            MiniPage.create("Afspraak", <QuestionForm endpoint={Forms.documentedRequestAppointment}
                                                                      submitAction={form => approveAppointment(form,
                                                                                                               task,
                                                                                                               user,
                                                                                                               customer,
                                                                                                               consultants)}/>),
                            MiniPage.create("Details", <Request customer={customer}
                                                                request={request}/>),
                            MiniPage.create(<NotesLabel notes={notes}/>, <Notes customer={customer}
                                                                                notes={notes}/>),
                        ]}/>
                        : <CircularProgress/>
                }
            </Card>,

            <Card key={3}>
                <Typography variant="h5">Process</Typography>
                Deze taak maakt deel uit van een <Anchor href={`/proces/${task.processInstanceId}`}>process</Anchor>
            </Card>,
        ];
    }
}

const mapStateToProps = state => ({
    user: currentUser(state),
})

const mapDispatchToProps = dispatch => ({
    loadForm: values => dispatch(retrieveForm(Forms.blissAppointment, values)),
});

const decorate = compose(
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(MakeAppointment);
export {
    MakeAppointment,
}
