import Promise from "bluebird";
import {setInstanceVariable} from "../../../../../../api/process/process/processes";
import {setAdvisor, setAssignee} from "../../assignee";
import {getAssignee} from "./assignee";
import {addConsultant, getConsultant} from "./consultant";
import {promiseAction} from "../../../../../../middleware/promiseDispatch";
import {noteClient} from "../../../../../../api/relation/note/NoteClient";
import {note} from "./note";
import nop from "../../../../../../action/creator/nop";
import {empty} from "../../../../../../api/process/util";
import {getAppointment} from "./formLogic";
import {push} from "react-router-redux";
import paths from "../../../../../paths";
import {closeTask} from "../../../../../../api/process/task/tasks";

let approveAppointment = (form, task, user, customer, consultants) => {

        const appointment = getAppointment(form);
        const adviseur = appointment.adviseur;

        const setVariable = setInstanceVariable(task.processInstanceId);

        const saveAppointment = Promise.all(
            [
                setVariable("String", "gewenste_datum", appointment.gewenste_datum),
                setVariable("String", "gewenst_tijdvak", appointment.gewenst_tijdvak),
                setVariable("String", "opmerkingen", appointment.opmerkingen),
                setVariable("String", "reden", appointment.reden),
                setVariable("String", "telefoonnummer", appointment.telefoonnummer),
                setVariable("String", "adviseur", appointment.adviseur),
                setVariable("Boolean", "afspraak_gaat_door", appointment.afspraak_gaat_door),
            ]).then(_ =>
                        empty(adviseur)
                            ? Promise.resolve()
                            : Promise.all([setAssignee(task.processInstanceId)(getAssignee(adviseur)),
                                           setAdvisor(task.processInstanceId)(getAssignee(adviseur))]));

        const eersteLijn = user;
        const consultant = getConsultant(consultants, adviseur);

        return promiseAction(saveAppointment
                                 .then(_ => noteClient.addNote(user, customer, note(appointment)))
                                 .then(_ => closeTask(task))
                                 .then(_ => addConsultant(user, customer, eersteLijn, "role_eerste_lijn"))
                                 .then(_ => addConsultant(user, customer, consultant, "role_apotheek_adviseur"))
                                 .then(_ => push(paths.task))                              
                                 );

    }
;

export {
    approveAppointment,
}
