import Tab from "@material-ui/core/Tab";
import React from "react";
import {Icon} from "@material-ui/core";
import {isPresent, restrict} from "../../../util/util";

/**
 * @param {Object} object
 * @return {Object}
 */
const tabClasses =
    object =>
        restrict(object,
                 ["root",
                  "labelIcon",
                  "textColorInherit",
                  "textColorPrimary",
                  "textColorSecondary",
                  "selected",
                  "disabled",
                  "fullWidth",
                  "wrapper",
                  "labelContainer",
                  "label",
                  "labelWrapped"]);

/**
 * Checks whether the argument is to be used as the label in a Tag-element (e.g. <Tag label={argument}/>)
 *
 * @param argument
 * @return {boolean}
 */
const isLabel = argument => {

    if (isPresent(argument)) {

        if (typeof argument === "string") {
            return true;
        }
        else if (React.isValidElement(argument)) {
            // Where this is called we want to determine whether to use this as a label in a Tab element,
            // or whether we've been given a Tab element directly.
            // That's an awkward question for a React element -- we would like to simply check if the tagname is 'Tab', but
            // we might be dealing with an higher order component.
            // Instead we just check whether a prop named 'label' has been provided. That will be false when the element has been
            // created with <Tab label="whatever">, and is unlikely to be true if the argument is a component that is intended to be
            // used as the label
            return !Object.keys(argument.props).includes('label');
        }
    }
    return false;
}

/**
 * Passes an active prop to the the label (if applicable)
 *
 * @param label
 * @param active
 */
const getLabel = (label, active) => React.isValidElement(label) ? React.cloneElement(label, {active}) : label

class MiniPage {

    /**
     * The tab will be rendered as a <Tab/> component.
     * This can happen in multiple ways:
     *   1) A <Tab/> is provided directy. It will  be returned in the getTab() method
     *   2) A String attribute is provided. The getTab() method will wrap it in a <Tab label={tab}/>, before returning the wrapper
     *   3) A non-Tag react component is provided. Again, the getTab() method will wrap it as <Tab label={tab}/>
     *
     * @type {String|ReactElement|JSX.Element}
     */
    tab;

    /**
     * @type {void|String|ReactElement|JSX.Element}
     */
    icon;

    /**
     * @type {ReactElement|JSX.Element}
     */
    content;

    /**
     *
     * @param {String|ReactElement} tab
     * @param {ReactElement} content
     */
    constructor(tab, content) {
        this.tab = tab;
        this.content = content;
    }

    /**
     * @param {String|ReactElement} tab
     * @param {ReactElement|JSX.Element} content
     */
    static create(tab, content) {
        return new MiniPage(tab, content);
    }

    /**
     * @param {String|ReactElement} tab
     * @param {String|ReactElement} icon
     * @param {ReactElement} content
     */
    static createWithIcon(tab, icon, content) {
        const miniPage = new MiniPage(tab, content);
        miniPage.setIcon(icon);
        return miniPage;
    }

    /**
     * {string|ReactElement}
     */
    setIcon(icon) {
        this.icon = icon
    }

    /**
     * Returns a <Tab key={index}, classes={classes}/> element
     *
     * @return {React.ReactElement}
     */
    getTab(index, active, overwrites = {}) {

        let tab = isLabel(this.tab) ? <Tab key={index}
                                           label={getLabel(this.tab, active)}/>
                                    : React.cloneElement(this.tab, {key: index})

        let icon = null;
        if (this.icon) {
            icon = typeof this.icon === "string" ? <Icon>{this.icon}</Icon> : icon;
            tab = React.cloneElement(tab, {icon})
        }

        return React.cloneElement(tab, {classes: tabClasses({...overwrites})});
    }

    /**
     * @return {React.ReactElement}
     */
    getContent() {
        return this.content;
    }
}

export default MiniPage;
