import React from "react";
import ApproveTask from "./documentedRequest/approveTask/ApproveTask";
import AssessDocuments from "./documentedRequest/assessDocument/AssessDocuments";
import AmendDocuments from "./documentedRequest/amendDocuments/AmendDocuments";
import CustomerApprove from "./documentedRequest/customerApprove/CustomerApprove";
import GenericTask from "./GenericTask";
import MakeAppointment from "./documentedRequest/appointment/MakeAppointment";
import AssesCustomer from "./bankgarantie/assessCustomer/AssesCustomer";
import CustomerApproveGarantie from "./bankgarantie/customerApprove/CustomerApproveGarantie";
import ApproveCustomer from "./bankgarantie/approveCustomer/AssesCustomer";

const Task = ({task, showProcess = true}) => {
    let {processDefinitionId, name} = task;

    if ((processDefinitionId && processDefinitionId.startsWith("DOCUMENTED_REQUEST"))) {
        switch (name) {
            case "Beoordeel aanvraag":
                return <ApproveTask task={task}/>;
            case "Beoordeel Documenten":
                return <AssessDocuments task={task}/>;
            case "Vul documenten aan":
                return <AmendDocuments task={task}/>;
            case "Maak afspraak":
                return <MakeAppointment task={task}/>;
            case "Ondertekening":
                return <CustomerApprove task={task} showProcess={showProcess}/>;
            default:
                throw new Error(`Unexpected task ${name}`);
        }
    } else if ((processDefinitionId && processDefinitionId.startsWith("BANKGARANTIE"))) {
        name = name.trim()
        switch (name) {
            case "Beoordeel aanvraag":
                return <AssesCustomer task={task}/>;
            case "Bevestig aanvraag":
                return <ApproveCustomer task={task}/>;
            case "Ondertekening":
                return <CustomerApproveGarantie task={task} showProcess={showProcess}/>;
            default:
                throw new Error(`Unexpected task ${name}`);
        }
    }
    return <GenericTask task={task}/>;
};

export default Task;
