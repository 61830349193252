import {isPresent, now} from "../../../../../../util/util";
import moment from "moment";
import {getQuestionValue} from "../../../../../../util/form";

/**
 * Camunda expects dates to be formatted as '2019-01-23T20:10:00.000+0200' (offset part is +0200)
 * the moment documentation is rather unclear on how to format the offset as anything but +02:00.
 *
 * For now just add the same constant offset to any date...
 *
 * @type {string}
 */
const CAMUNDA_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS+0200";

/**
 * @param {string} string
 * @return {string}
 */
const preparse = string => {
    const parts = string.split("-");
    return `${parts[2]}-${parts[1]}-${parts[0]}`
}

const getMoment = date => isPresent(date) && date !== "" ? moment(preparse(date),)
    : null

const dossierVariableNames = {
    AANVRAAG: "aanvraag",
    STATUS: "status",
    CUSTOMER_ID: 'customerId',
    DEADLINE_KOOPOVEREENKOMST: "deadline_koopovereenkomst",
    COMPLETE_KOOPOVEREENKOMST: "voltooid_koopovereenkomst",
    COMPLETION_KOOPOVEREENKOMST: "voltooiing_koopovereenkomst",
    DEADLINE_BANKGARANTIE: "deadline_bankgarantie",
    COMPLETE_BANKGARANTIE: "voltooid_bankgarantie",
    COMPLETION_BANKGARANTIE: "voltooiing_bankgarantie",
    DEADLINE_TAXATIE: "deadline_taxatie",
    COMPLETE_TAXATIE: "voltooid_taxatie",
    COMPLETION_TAXATIE: "voltooiing_taxatie",
    DEADLINE_ONTBINDENDE_VOORWAARDEN: 'deadline_ontbindende_voorwaarden',
    STATUS_ONTBINDENDE_VOORWAARDEN: 'status_ontbindende_voorwaarden',
    COMPLETE_ONTBINDENDE_VOORWAARDEN: 'voltooid_ontbindende_voorwaarden',
    COMPLETION_ONTBINDENDE_VOORWAARDEN: 'voltooiing_ontbindende_voorwaarden',
    DEADLINE_PASSEERDATUM: 'deadline_passeerdatum',
    COMPLETE_PASSEERDATUM: 'voltooid_passeerdatum',
    COMPLETION_PASSEERDATUM: 'voltooiing_passeerdatum',
    DEADLINE_FIRST: 'deadline_first',
    LAST_STATUS_CHANGE: 'last_status_change',

    deadline: function (name) {
        return this[`DEADLINE_${name.toUpperCase()}`];
    },

    complete: function (name) {
        return this[`COMPLETE_${name.toUpperCase()}`];
    },

    completion: function (name) {
        return this[`COMPLETION_${name.toUpperCase()}`];
    },
}

const statusChanged =
    (form, oldDossier) => !oldDossier ||
        getQuestionValue(form)("status") !== oldDossier.getStatus();

const completeChanged =
    name =>
        (form, oldDossier) => {
            let complete = dossierVariableNames.complete(name);
            return !oldDossier || getQuestionValue(form)(complete) !== oldDossier.getVariable(complete);
        };

/**
 * Extracts variables from the Forms.hypotheekDossier form and returns them in the format used by camunda
 *
 * @param {object} form
 * @param customer
 * @param {Dossier} oldDossier
 * @return {object}
 */
const dossierVariables = (form, customer, oldDossier) => {

    let variables = {};

    variables[dossierVariableNames.STATUS] = {
        value: getQuestionValue(form)("status"),
        type: "String",
    }

    variables[dossierVariableNames.CUSTOMER_ID] = {
        value: customer.id,
        type: "Long",
    }

    let deadlines = [];

    const lastStatusChange = statusChanged(form, oldDossier) ? now()
        : oldDossier.getDate(dossierVariableNames.LAST_STATUS_CHANGE);

    variables[dossierVariableNames.LAST_STATUS_CHANGE] = {
        value: lastStatusChange.format(CAMUNDA_DATE_FORMAT),
        type: "Date",
    }

    const aanvraag = getQuestionValue(form)(dossierVariableNames.AANVRAAG);
    if (aanvraag) {
        variables[dossierVariableNames.AANVRAAG] = {
            value: aanvraag,
            type: "String",
        }
    }

    const handleDate = name => {

        const deadline = getMoment(getQuestionValue(form)(dossierVariableNames.deadline(name)));
        const isComplete = getQuestionValue(form)(dossierVariableNames.complete(name)) === "Ja";

        if (deadline) {
            variables[dossierVariableNames.deadline(name)] = {
                value: deadline.format(CAMUNDA_DATE_FORMAT),
                type: "Date",
            }
            if (!isComplete) {
                deadlines.push(deadline);
            }
        }

        variables[dossierVariableNames.complete(name)] = {
            value: isComplete,
            type: "Boolean",
        }

        if (completeChanged(name)(form, oldDossier)) {
            variables[dossierVariableNames.completion(name)] = {
                value: isComplete ? now().format(CAMUNDA_DATE_FORMAT) : null,
                type: "Date",
            }
        }
    }

    const handleOVDate = () => {

        const deadline = getMoment(getQuestionValue(form)(dossierVariableNames.deadline("ONTBINDENDE_VOORWAARDEN")));
        let status = getQuestionValue(form)(dossierVariableNames.STATUS_ONTBINDENDE_VOORWAARDEN);
        const isComplete = status === "Finaal akkoord";

        if (deadline) {
            variables[dossierVariableNames.deadline("ONTBINDENDE_VOORWAARDEN")] = {
                value: deadline.format(CAMUNDA_DATE_FORMAT),
                type: "Date",
            }
            if (!isComplete) {
                deadlines.push(deadline);
            }
        }

        variables[dossierVariableNames.complete("ONTBINDENDE_VOORWAARDEN")] = {
            value: isComplete,
            type: "Boolean",
        }

        variables[dossierVariableNames.STATUS_ONTBINDENDE_VOORWAARDEN] = {
            value: status,
            type: "String",
        }

        if (completeChanged("ONTBINDENDE_VOORWAARDEN")(form, oldDossier)) {
            variables[dossierVariableNames.completion("ONTBINDENDE_VOORWAARDEN")] = {
                value: isComplete ? now().format(CAMUNDA_DATE_FORMAT) : null,
                type: "Date",
            }
        }
    }

    handleDate("KOOPOVEREENKOMST");
    handleDate("TAXATIE");
    handleDate("BANKGARANTIE");
    handleOVDate();
    handleDate("PASSEERDATUM");

    if (deadlines.length > 0) {
        variables[dossierVariableNames.DEADLINE_FIRST] = {
            value: moment.min(deadlines).format(CAMUNDA_DATE_FORMAT),
            type: "Date",
        }
    }

    return variables;
};

export {
    dossierVariableNames,
    dossierVariables,
};


