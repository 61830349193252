/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 1-10-18.
 */

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import Typography from "@material-ui/core/Typography/Typography";
import Paper from "@material-ui/core/Paper/Paper";
import Anchor from "../Anchor/Anchor";
import BigCardHeader from "../Card/BigCardHeader";
import {Avatar} from "@material-ui/core";
import * as PropTypes from "prop-types";

const CallToActionBliss = ({fullWidth = false, classes}) =>
    <Paper className={fullWidth ? classes.fullWidth : classes.floatRight}
           elevation={1}>

        <BigCardHeader title="Bliss helpt u verder"/>
        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Vul het formulier in om zelf een afspraak in te plannen voor een vrijblijvend hypotheekgesprek.
        </Typography>
        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Hulp nodig of komt u er niet uit? Bel ons dan gerust op <Anchor href="tel:+3185-0470222">085-0470222</Anchor> of stuur een
            email naar onze <Anchor href="mailto:info@blisshypotheekadviseurs.nl">intakebalie</Anchor>.

        </Typography>

        <Avatar alt="Alwin_Langerak"
                src="/images/Alwin_Langerak.jpg"
                className={classes.personaAvatar}/>

        <Typography component="p"
                    variant="body1">
            Alwin Langerak,
        </Typography>

        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Manager Intakebalie
        </Typography>

    </Paper>;
CallToActionBliss.propTypes = {fullWidth: PropTypes.any};

const CallToActionErasmus = ({fullWidth = false, classes}) =>
    <Paper className={fullWidth ? classes.fullWidth : classes.floatRight}
           elevation={1}>

        <BigCardHeader title="bankgarantie.com helpt u verder"/>
        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Vul het formulier in om zelf een garantiestelling aan te vragen.
        </Typography>
        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Hulp nodig of komt u er niet uit? Bel ons dan gerust op <Anchor href="tel:+3185-0470222">085-0470222</Anchor> of stuur een
            email naar onze <Anchor href="mailto:info@blisshypotheekadviseurs.nl">intakebalie</Anchor>.

        </Typography>

        <Avatar alt="Alwin_Langerak"
                src="/images/Alwin_Langerak.jpg"
                className={classes.personaAvatar}/>

        <Typography component="p"
                    variant="body1">
            Alwin Langerak,
        </Typography>

        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Manager Intakebalie
        </Typography>

    </Paper>;

CallToActionErasmus.propTypes = {fullWidth: PropTypes.any};

const CallToActionWoningScan = ({fullWidth = false, classes}) =>
    <Paper className={fullWidth ? classes.fullWidth : classes.floatRight}
           elevation={1}>

        <BigCardHeader title="Verdienen met verduurzamen."/>
        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Vul het formulier in om zelf een afspraak in te plannen voor een vrijblijvend gesprek.
        </Typography>
        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Hulp nodig of komt u er niet uit? Stuur gerust een email naar onze <Anchor href="mailto:info@bgreenfactor.nl">intakebalie</Anchor>.
        </Typography>

        <Avatar alt="Mandy de Boer"
                src="/images/mandy-de-boer-greenfactor.jpeg"
                className={classes.personaAvatar}/>

        <Typography component="p"
                    variant="body1">
            Mandy de Boer,
        </Typography>

        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Manager Intakebalie
        </Typography>

    </Paper>;

CallToActionWoningScan.propTypes = {fullWidth: PropTypes.any};



const CallToActionBankgarantie = ({fullWidth = false, classes}) =>
    <Paper className={fullWidth ? classes.fullWidth : classes.floatRight}
           elevation={1}>

        <BigCardHeader title="bankgarantie.com helpt u verder"/>
        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Vul het formulier in om zelf een garantiestelling aan te vragen.
        </Typography>
        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Hulp nodig of komt u er niet uit? Bel ons dan gerust op <Anchor href="tel:+3185-0041334"> 085- 0041334</Anchor> of stuur een
            email naar onze <Anchor href="mailto:aanvragen@bankgarantie.com">intakebalie</Anchor>.

        </Typography>

        <Avatar alt="Alwin_Langerak"
                src="/images/Alwin_Langerak.jpg"
                className={classes.personaAvatar}/>

        <Typography component="p"
                    variant="body1">
            Alwin Langerak,
        </Typography>

        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Manager Intakebalie
        </Typography>

    </Paper>;

CallToActionErasmus.propTypes = {fullWidth: PropTypes.any};


const CallToActionQuickScan = ({fullWidth = false, classes}) =>
    <Paper className={fullWidth ? classes.fullWidth : classes.floatRight}
           elevation={1}>

        <BigCardHeader title="Bliss Hypotheekadviseurs QuickScan"/>
        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Vul het formulier in om snel een quickscan te doen.
        </Typography>
        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Hulp nodig of komt u er niet uit? Bel ons dan gerust op <Anchor href="tel:+3185-0041334">085-0041334</Anchor>
        </Typography>

        <Avatar alt="Alwin_Langerak"
                src="/images/Alwin_Langerak.jpg"
                className={classes.personaAvatar}/>

        <Typography component="p"
                    variant="body1">
            Alwin Langerak,
        </Typography>

        <Typography className={classes.paragraph}
                    component="p"
                    variant="body1">
            Manager Intakebalie
        </Typography>

    </Paper>;

CallToActionQuickScan.propTypes = {fullWidth: PropTypes.any};


const callToAction = (skin) => {

    switch (skin.id) {
        case "BLISS":
            return CallToActionBliss;
        case "WONINGSCAN":
            return CallToActionWoningScan;
        case "ERASMUS":
            return CallToActionErasmus;
        case "BANKGARANTIE":
            return CallToActionBankgarantie;
        case "QUICK_SCAN":
            return CallToActionQuickScan;
        default:
            return CallToActionErasmus;
    }
};

export default skin => withStyles(styles)(callToAction(skin));




