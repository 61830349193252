import Point, {distance} from "./Point";

class Sample extends Point {
    time = new Date().getTime();
}

/**
 * @param {Point} p
 * @param {Point} q
 * @returns {number}
 */
const velocity = (p, q) => {
    console.assert(p.time !== q.time, p.time, q.time, "Velocity: Division by zero");
    return distance(p, q) / (q.time - p.time);
}

export default Sample;
export {
    Sample,
    velocity
}
