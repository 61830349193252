/**
 * @author M.J. van der Werf <vanderwerf@bluehorizon.nl>
 * Created on 21-7-16.
 */

/**
 * @typedef {string} ActionType
 */

/**
 * An action is an object that will contain a number of fields; it will at the very
 * least  contain a field type (an ActionType, i.e. a string).
 *
 * Additional fields present depend on the choice of ActionType
 *
 * @typedef {Object} Action
 * @property {ActionType} type
 */

/**
 * An hierarchical organized set of possible ActionTypes.
 */
const ActionType = Object.freeze(
    {
        asynchronous: Object.freeze(
            {
                form: Object.freeze(
                    {
                        get: Object.freeze(
                            {
                                RETRIEVING: "RETRIEVING_FORM",
                                RETRIEVED: "RETRIEVED_FORM",
                            }),
                        post: Object.freeze(
                            {
                                POSTING: "POSTING_FORM",
                                RESULT: "FORM_RESULT",
                            }),

                    }),

                quote: Object.freeze(
                    {
                        submit: Object.freeze(
                            {
                                POSTING: "POSTING_QUOTE",
                                RESULT: "QUOTE_RESULT",
                            }),
                        status: Object.freeze(
                            {
                                RETRIEVING: "RETRIEVING_STATUS",
                                RETRIEVED: "RETRIEVED_STATUS",
                            }),
                        result: Object.freeze(
                            {
                                RETRIEVING: "RETRIEVING_RESULT",
                                RETRIEVED: "RETRIEVED_RESULT",
                            }),
                    },
                ),
                login: Object.freeze(
                    {
                        login: "LOGIN",
                        forgotPassword: "FORGOT_PASSWORD",
                        logoff: "LOGOFF"
                    }
                ),
                documents: Object.freeze({
                                             RETRIEVING_ALL: "RETRIEVING_ALL_DOCUMENTS",
                                             RETRIEVED_ALL: "RETRIEVED_ALL_DOCUMENTS",
                                             DELETING: "DELETING_DOCUMENT",
                                             DELETED: "DELETED_DOCUMENT",
                                         })
            }),

        search: Object.freeze(
            {
                searchResult: "SEARCH_RESULT",
            }
        ),
        question: Object.freeze(
            {
                update: "QUESTION_UPDATE",
            }),
        group: Object.freeze(
            {
                submitGroup: "SUBMIT_GROUP",
            }),

        currentStep: "CURRENT_STEP",

        CURRENT_CUSTOMER: "CURRENT_CUSTOMER",
        ERROR: "ERROR",
        DISMISS_ERROR: "DISMISS_ERROR",
        MODULE: "MODULE",
        NEUTRAL: "NEUTRAL",
        CURRENT_ORGANIZATION: "CURRENT_ORGANIZATION",
        AGENT: "AGENT",
        CURRENT_USER: "CURRENT_USER",
        LOGIN_LOADING:"LOGIN_LOADING",
        LOGIN_ERROR_LOADING:"LOGIN_ERROR_LOADING",
    },
);
export default ActionType;
