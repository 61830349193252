import {Card, CardContent, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import React from "react";
import MiniPage from "../MiniPage/MiniPage";
import MiniPages from "../MiniPage/MiniPages";
import FormData from "./FormData";

const restrict = (response, acceptedFields) => {
    if (response === null || typeof response === "undefined") {
        return [];
    }
    return response.filter(({field, value}) => {
        return acceptedFields.includes(field);
    });
}

const restrictByString = (response, acceptedSubstring) => {
    if (response === null || typeof response === "undefined") {
        return [];
    }

    let restriction = [];
    for (let {field, value} of response) {
        if (field.startsWith(acceptedSubstring) && !field.includes("extra")) {
            restriction.push({"field": field.replace(acceptedSubstring, ""), value});
        }

    }
    return restriction;
}


const BankgarantieProposal = ({proposal, formDescription}) => {

    const adviseursData = restrict(proposal.questionResponse, ["adviseur_naam", "email_adviseur"]);
    const woningData = restrict(proposal.questionResponse, ["woning_postcode", "woning_huisnummer", "woning_toevoeging", "woning_straatnaam", "woning_plaatsnaam", "woning_datum_woonovereenkomst", "makelaar", "xxemail_makelaarx"]);
    const garantieData = restrict(proposal.questionResponse, ["garantiebedrag", "garantiebedrag_zegge", "garantiestelling_ingangsdatum", "garantiestelling_einddatum", "garantiebedrag_eenmailig_premie", "notaris", "email_notaris"]);
    const koperData = restrictByString(proposal.questionResponse, "koper1_");
    const koper2Data = restrictByString(proposal.questionResponse, "koper2_");
    const verkoperData = restrictByString(proposal.questionResponse, "verkoper1_");
    const verkoper2Data = restrictByString(proposal.questionResponse, "verkoper2_");

    let miniPages = [];
    miniPages.push(MiniPage.create("Adviseur", <FormData data={adviseursData}/>));
    miniPages.push(MiniPage.create("Woning", <FormData data={woningData}/>));
    miniPages.push(MiniPage.create("Bankgarantie", <FormData data={garantieData}/>));

    miniPages.push(MiniPage.create("Koper", <FormData data={koperData}/>));
    if (koper2Data.length > 0)
        miniPages.push(MiniPage.create("Tweede Koper", <FormData data={koper2Data}/>));
    miniPages.push(MiniPage.create("Verkoper", <FormData data={verkoperData}/>));
    if (verkoper2Data.length > 0)
        miniPages.push(MiniPage.create("Tweede Verkoper", <FormData data={verkoper2Data}/>));


    return <Grid item
                 lg={12}
                 md={12}
                 sm={12}>
        <Card>
            {proposal === null
                ? null
                : <CardContent>
                    <br/>
                    <Typography variant="h3">
                        {formDescription}
                    </Typography>

                    <MiniPages miniPages={miniPages}/>
                </CardContent>
            }
        </Card>
    </Grid>;
}

export default BankgarantieProposal;
