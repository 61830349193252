/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-7-19.
 */
import React from "react";
import Promise from "bluebird";
import {instanceVariables, processInstance, setInstanceVariable} from "../../../../../../api/process/process/processes";
import Request from "../component/Request";
import Documents from "./Documents";
import Customer from "./customer/Customer";
import {Forms} from "../../../../../../api/form/FormClient";
import QuestionForm from "../../../../../component/QuestionForm/smart/QuestionForm";
import {retrieveForm} from "../../../../../../action/creator/asynchonous/form/retrieveForm";
import {compose} from "redux";
import {connect} from "react-redux";
import {Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMediaHeader from "../../../../../component/CardMediaHeader/CardMediaHeader";
import {push} from "react-router-redux";
import {paths} from "../../../../../paths";
import {promiseAction} from "../../../../../../middleware/promiseDispatch";
import insuranceProcessClient from "../../../../../../api/process/insuranceProcess/InsuranceProcessClient";
import {closeTask} from "../../../../../../api/process/task/tasks";
import Anchor from "../../../../../component/Anchor/Anchor";
import {cleanupVariables} from "../../../util";
import Notes from "../../../../../component/Note/Notes";
import {routeCustomer} from "../../../../../../action/creator/router";
import {fullName} from "../../../../../../util/customer";
import order from "./order";
import {orderClient} from "../../../../../../api/relation/order/OrderClient";
import {noteClient} from "../../../../../../api/relation/note/NoteClient";
import MiniPage from "../../../../../component/MiniPage/MiniPage";
import MiniPages from "../../../../../component/MiniPage/MiniPages";
import NotesLabel from "../../../../../component/Note/NotesLabel";
import {getAdvisor, getAssignee, setAdvisor, setAssignee} from "../../assignee";
import {getQuestionValue} from "../../../../../../util/form";

const getNotes = customer => noteClient.getNotes(customer);

const pickCustomer = variables => variables.customer ? variables.customer : variables.privateCustomer;

/**
 * Task corresponding to the step 'Beoordeel aanvraag' in the  DOCUMENTED_REQUEST process
 *
 * It should present the user with all relevant information in the request, and allow the user to accept or decline the request
 *
 */
class ApproveTask extends React.Component {

    state = {
        instance: null,
        advisor: null,
        assignee: null,
        agent: null,
        customer: null,
        request: null,
        notes: [],
    };

    componentDidMount() {
        const {task} = this.props;
        const {processInstanceId} = task;

        Promise.all([processInstance(processInstanceId),
                     instanceVariables(processInstanceId)])
               .spread((instance, variables) => this.setState({
                                                                  instance,
                                                                  advisor: getAdvisor(cleanupVariables(variables)),
                                                                  assignee: getAssignee(cleanupVariables(variables)),
                                                                  agent: cleanupVariables(variables).agent,
                                                                  customer: pickCustomer(cleanupVariables(variables)),
                                                                  request: cleanupVariables(variables).form,
                                                              }))
               .then(() => getNotes(this.state.customer).then(notes => this.setState({notes})));
        this.props.loadForm();
    }

    render() {
        const {customer, request, notes, advisor} = this.state;

        const {task, dispatch} = this.props;

        const approve = form => {

            const approveActions = getQuestionValue(form)("aanvraag_geaccepteerd") === "Ja"
                                   ? orderClient.addOrder(customer, order(form))
                                                .then(order => Promise.all([
                                                                               setInstanceVariable(task.processInstanceId)(
                                                                                   "String",
                                                                                   "handtekening_adviseur",
                                                                                   getQuestionValue(form)("handtekening_adviseur")),
                                                                               setInstanceVariable(task.processInstanceId)("Boolean", "accepted", true),
                                                                               setInstanceVariable(task.processInstanceId)(
                                                                                   "String",
                                                                                   "specificatie",
                                                                                   getQuestionValue(form)("aanvraag_specificatie")),
                                                                               setInstanceVariable(task.processInstanceId)(
                                                                                   "String",
                                                                                   "totaal",
                                                                                   getQuestionValue(form)("aanvraag_totaal")),
                                                                               setInstanceVariable(task.processInstanceId)(
                                                                                   "String",
                                                                                   "order",
                                                                                   JSON.stringify(order)),
                                                                               setAdvisor(task.processInstanceId)(advisor), // if this is a legacy task the advisor is not yet saved into the task, so do it now (value possibly from the assignee)
                                                                               setAssignee(task.processInstanceId)(
                                                                                   {name: fullName(customer), email: customer.email}),
                                                                               setInstanceVariable(task.processInstanceId)(
                                                                                   "String",
                                                                                   "signature_link",
                                                                                   `${process.env.REACT_APP_SELF}/ondertekening/${task.processInstanceId}`)
                                                                           ]))
                                                .then(_ => noteClient.addSystemNote(customer,
                                                                                    "Opdracht geaccepteerd",
                                                                                    "De opdracht is door de adviseur geaccepteerd"))
                                   : Promise.all([
                                                     setInstanceVariable(task.processInstanceId)("Boolean", "accepted", false),
                                                     setInstanceVariable(task.processInstanceId)(
                                                         "String",
                                                         "reason",
                                                         getQuestionValue(form)("aanvraag_motivatie")),
                                                     noteClient.addSystemNote(customer,
                                                                              "Opdracht geweigerd",
                                                                              `De opdracht is niet door de adviseur geaccepteerd. De reden hiervoor was 
                                                                                    ${getQuestionValue(form)("aanvraag_motivatie")}`)
                                                 ]);

            //  return promiseAction(approveActions.then(_ => nop));
            return promiseAction(approveActions.then(_ => closeTask(task))
                                               .then(_ => push(paths.task)));
        };

        if (this.state.instance && this.state.agent) {

            return [
                <Card key={1}>
                    <CardMediaHeader title={`Taak ${task ? task.name : ""}`}
                                     image="/images/handshake.jpg"/>

                    <Typography key="0"
                                variant="h2">
                        Aanvraag voor <Anchor onClick={() => routeCustomer(customer.id).then(dispatch)}>{fullName(customer)}</Anchor>.
                    </Typography>

                    <QuestionForm endpoint={Forms.documentedRequestRequestApproval}
                                  submitAction={approve}
                                  stepPromise={Promise.resolve}/>
                </Card>,
                <Card key={2}>
                    <Typography variant="h5">Aanvraag</Typography>

                    <MiniPages miniPages={[
                        MiniPage.create("Afspraak", <Customer customer={customer}/>),
                        MiniPage.create("Verzoek", <Request customer={customer}
                                                            request={request}/>),
                        MiniPage.create("Documenten", <Documents customer={customer}
                                                                 request={request}/>),
                        MiniPage.create(<NotesLabel notes={notes}/>, <Notes customer={customer}
                                                                            notes={notes}/>),
                    ]}/>
                </Card>,
                <Card key={3}>
                    <Typography variant="h5">Process</Typography>
                    Deze taak maakt deel uit van een <Anchor href={`/proces/${task.processInstanceId}`}>process</Anchor>
                </Card>
            ];
        }
        return <div/>;
    }
}

const mapDispatchToProps = dispatch => ({
    back: task => () => dispatch(push(paths.task)),
    completeTask: task => () => insuranceProcessClient.completeTask(task.id),
    loadForm: () => dispatch(retrieveForm(Forms.documentedRequestRequestApproval)),
    dispatch,
});

const decorate = compose(
    connect(null, mapDispatchToProps)
);

export default decorate(ApproveTask);
