/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 11-9-18.
 */
import uploadTypes from "../../component/Documents/UploadTypes";

/**
 * @typedef {Object} Document
 * @property {string} documentType
 */

/**
 * @typedef {Object} Employee
 * @property {string} id
 */

/**
 * @param {Array<Document>} documents
 * @param {UploadType} requirement
 * @return {Array<Document>} the list of documents that are of the required type
 */
const fulfillments = (documents, requirement) => documents ? documents.filter(document => document.documentType === requirement) : [];

/**
 * @param {Array<Document>} documents
 * @param {Array<Employee>} employees
 * @return {function} UploadType -> boolean
 */
const hasMissingRequirement =
    (documents, employees) =>
        requirement => requirement === uploadTypes.medewerkerslijst
                       ? fulfillments(documents, requirement).length === 0 && employees.length === 0
                       : fulfillments(documents, requirement).length === 0;

/**
 * @param {Array<UploadType>} requirements
 * @param {Array<Document>} documents
 * @param {Array<Employee>} employees
 * @return {Array<string>}
 */
const missingRequirements =
    (requirements, documents, employees) => requirements.filter(hasMissingRequirement(documents, employees));

/**
 * @param {Array<UploadType>} requirements
 * @param {Array<Document>} documents
 * @param {Array<Employee>} employees
 * @return {boolean}
 */
const fulfillsAllRequirements =
    (requirements, documents, employees) =>
        missingRequirements(requirements, documents, employees).length === 0;

export default fulfillsAllRequirements;
export {
    fulfillsAllRequirements,
    missingRequirements,
}
