/**
 * TODO: Add Documentation
 */
import React from "react";
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import LoggedOn from "../../../../../component/Main/Base/LoggedOn/LoggedOn";
import CustomerTopMenu from "../../components/menu/CustomerTopMenu";
import {currentCustomer} from "../../../../../../state/selector/customer";
import {connect} from "react-redux";
import {compose} from "redux";
import securePage from "../../../../SecurePage/SecurePage";
import {roles} from "../../../../../../api/login/LoginClient";

// TODO: Remove; this class will be obsolete as soon as the PortalPrivateCustomer page is updated to the new setup
const Offers = ({customer}) => (
    <LoggedOn pageHeader={<CustomerTopMenu/>}>
        <Grid item
              lg={12}
              md={12}
              sm={12}>
            <Card>
                <CardContent>
                    <Typography variant="subtitle1">
                        Adviezen
                    </Typography>
                </CardContent>
            </Card>
        </Grid>

    </LoggedOn>
);

const mapStateToProps = state => ({
    customer: currentCustomer(state),
});

let decorate = compose(
    connect(mapStateToProps),
    securePage([roles.admin])
);

export default decorate(Offers);

