import React from "react";
import {tasks} from "../../../../api/process/task/tasks";
import LoggedOff from "../../../component/Main/Base/LoggedOff/LoggedOff";
import Task from "./Task";
import {processInstance} from "../../../../api/process/process/processes";
import {activeProcessInstanceQuery} from "./TaskRestriction";
import {CircularProgress} from "@material-ui/core";

/**
 * This page should be used when the user is directed to this task from
 * an email.
 *
 * Since the task-ids are UIDS the page will only be accessible to those who
 * have access to the mail with the link (plus UID) in it
 * (or to those who guess the UID, which is not that far-fetched since camunda
 *  generates v1 UIDs, but we'll ignore that possibility for now
 *  see https://versprite.com/blog/universally-unique-identifiers/
 *  )
 *
 *  At some point this page will also have to support users that are already logged in.
 */
class ExternalTask extends React.Component {

    state = {
        task: null,
    };

    componentDidMount() {
        // In general, there can be multiple tasks of the same type, within the same process instance.
        // For now: consider that an edge-case and just use the first (and usually only) one in the results
        processInstance(this.props.match.params.id)
            .then(processInstance => tasks(activeProcessInstanceQuery(processInstance, this.props.type)))
            .then(tasks => this.setState({task: tasks.results[0]}));
    }

    render() {
        const {task} = this.state;
        if (task === null) {
            return <CircularProgress/>;
        }
        else {
            // TODO: use {task.name} as header in LoggedOff, but double check the names of the actual tasks first
            return (<LoggedOff header="Opdrachtbevestiging">
                <Task task={task}
                      showProcess={false}/>
            </LoggedOff>);
        }
    }
}

export default ExternalTask;
