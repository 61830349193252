/**
 * Basic 2d Cartesian coordinates
 */
class Point {

    /**
     * @type {number}
     */
    x;

    /**
     * @type {number}
     */
    y;

    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    size() {
        return distance(this, new Point(0, 0));
    }

    scale(factor) {
        return new Point(factor * this.x, factor * this.y);
    }

    toString(){
        const x = this.x < Number.EPSILON ? 0 : this.x;
        const y = this.y < Number.EPSILON ? 0 : this.y;
        return `(${x}, ${y})`;
    }
}

/**
 * @param {Point} p
 * @param {Point} q
 * @returns {number}
 */
const distance = (p, q) => Math.sqrt((p.x - q.x) ** 2 + (p.y - q.y) ** 2);

/**
 * @param {...Point} points
 * @return {Point}
 */
function sum(...points) {

    /**
     *  @type function(Point, Point):  Point
     */
    const sum2 = (p, q) => new Point(p.x + q.x, p.y + q.y);

    return points.reduce(sum2, new Point(0, 0));
}

/**
 * @param {Point} p
 * @param {Point} q
 * @return {Point}
 */
const midPoint = (p, q) => sum(p, q).scale(0.5);

/**
 * @param {Point} p
 * @param {Point} q
 * @returns {Point}
 */
const diff = (p, q) => sum(p, q.scale(-1));


export default Point;
export {
    Point,
    distance,
    midPoint,
    sum,
    diff,
}
