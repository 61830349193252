/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-9-17.
 */
import ActionType from "./../ActionType";

const error =
    (message, type = ActionType.ERROR) =>
        error => ({
            type: type,
            message: message,
            error: error,
        });

const dismiss = errorIndex => ({
    type: ActionType.DISMISS_ERROR,
    index: errorIndex,
});

export default error;
export {
    error,
    dismiss,
}
