import React from "react";
import * as PropTypes from "prop-types";
import {fullName} from "../../../../../../util/customer";
import BigCardHeader from "../../../../../component/Card/BigCardHeader";
import Anchor from "../../../../../component/Anchor/Anchor";
import {routeCustomer} from "../../../../../../action/creator/router";
import {connect} from "react-redux";

const getQuestionValue =
    request =>
        field => {
            let value = "";
            request.groups.forEach(group => {
                if (group.fields.hasOwnProperty(field)) {
                    value = group.fields[field].value;
                }
            })

            return value;
        }

const Summary = ({customer, request, dispatch}) =>
    <div>
        <BigCardHeader title={`${fullName(customer)} heeft een terugbelafspraak aangevraagd`}/>

        <Anchor onClick={() => routeCustomer(customer.id).then(dispatch)}>{fullName(customer)} </Anchor>

        wil graag een afspraak op {getQuestionValue(request)("gewenste_datum")} van {getQuestionValue(request)("gewenst_tijdvak")}.
        <br/>
        De klant is bereikbaar op telefoonnummer {getQuestionValue(request)("telefoonnummer")}
    </div>;

Summary.propTypes = {
    customer: PropTypes.object,
    request: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
});

export default connect(null, mapDispatchToProps)(Summary)


