/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 13-4-18.
 */
import {customerClient} from "../../../../../../api/relation/customer/CustomerClient";
import currentCustomer from "../../../../../../action/creator/asynchonous/customer/currentCustomer";
import ActionType from "../../../../../../action/ActionType";
import {promiseAction} from "../../../../../../middleware/promiseDispatch";
import retrieving from "../../../../../../action/creator/asynchonous/retrieving";
import {info} from "../../../../../../util/util";
import {errorAction} from "../../../../../../api/relation/RelationClient";

/**
 * Assumes that response is a response to one of the customer apis of the Relations service.
 * Some of these service calls wrap the resulting customer in an 'entity' element (and some not)
 *
 * @param {Response} response
 * @return {object} customer
 */
const extractCustomer = response => response.hasOwnProperty("entity")? response.entity : response;


/**
 * @param  {func} apiClient:  customer -> Promise<Response>
 * @param {updater} updater:
 * @param {object} customer
 * @param {object} submittedForm
 * @return {Promise.<Action>}
 */
const postCustomer =
    apiClient =>
        updater =>
            customer =>
                submittedForm => {
                    //  console.log(JSON.stringify(updater(customer, submittedForm)));
                    return promiseAction(apiClient(updater(customer, submittedForm))
                                             .then(info("API response:"))
                                             .then(extractCustomer)
                                             .then(currentCustomer),
                                         // .then(info("set current customer to:"))

                                         retrieving(ActionType.asynchronous.form.post.POSTING),
                                         errorAction,
                    );
                };

const postClient = postCustomer(customerClient.updateClient);
const postBusiness = postCustomer(customerClient.updateBusiness);

export {
    postClient,
    postBusiness,
};


