import {diff, sum} from "./point/Point";

class LineSegment {
    /**
     * @type {Point}
     */
    start;

    /**
     * @type {Point}
     */
    end;

    /**
     * @param {Point} start
     * @param {Point} end
     */
    constructor(start, end) {
        this.start = start;
        this.end = end;
    }

    /**
     * @param {Point} displacement
     * @return {LineSegment}
     */
    translate(displacement) {
        this.start = sum(this.start, displacement);
        this.end = sum(this.end, displacement);
        return this;
    }

    toString() {
        return `[${this.start}, ${this.end}]`;
    }
}

/**
 * Just a simple debugging utility
 *
 * @param {LineSegment} l1
 * @param {LineSegment} l2
 * @return {LineSegment}
 */
const difference = (l1, l2) => new LineSegment(diff(l1.start, l2.start), diff(l1.end, l2.end));


export default LineSegment;
export {
    LineSegment,
    difference
}
