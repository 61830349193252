import React from "react";
import {TableCell, TableRow, withStyles} from "@material-ui/core";
import {formatSince} from "../date";
import {fullName} from "../../../../util/customer";
import {dossierVariableNames} from "../../../page/customer/Customer/components/dossier/variables";
import DateCell from "./date/DateCell";
import Status from "../Status";
import If from "../../Optional/If";

const styles = theme => ({
    tableRow: {
        cursor: 'pointer',
    },
    expired: {
        color: "red",
    },
    completed: {
        color: "green",
    },
    plain: {}
});

const DossierRow =
    ({dossier, showCustomer, onClickDossier, onClickCustomer, classes}) =>
        <TableRow className={classes.tableRow}
                  hover={true}>
            <TableCell onClick={onClickDossier}>{dossier.getAanvraag()}</TableCell>
            <If show={showCustomer}>
                <TableCell onClick={onClickCustomer}>
                    {dossier.customer ? fullName(dossier.customer) : ""}
                </TableCell>
            </If>
            <TableCell onClick={onClickDossier}>{dossier.getAdviseur()}</TableCell>
            <TableCell onClick={onClickDossier}
                       title={formatSince(dossier.getDate(dossierVariableNames.LAST_STATUS_CHANGE))}>
                <Status dossier={dossier}/>
            </TableCell>

            <DateCell dossierDate={dossier.getDossierDate("KOOPOVEREENKOMST")}
                      onClick={onClickDossier}/>
            <DateCell dossierDate={dossier.getDossierDate("TAXATIE")}
                      onClick={onClickDossier}/>
            <DateCell dossierDate={dossier.getDossierDate("BANKGARANTIE")}
                      onClick={onClickDossier}/>
            <DateCell dossierDate={dossier.getDossierDate("ONTBINDENDE_VOORWAARDEN")}
                      onClick={onClickDossier}/>
            <DateCell dossierDate={dossier.getDossierDate("PASSEERDATUM")}
                      onClick={onClickDossier}/>

        </TableRow>

export default withStyles(styles)(DossierRow);
