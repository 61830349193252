/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 12-1-18.
 */
import {getColor} from "../../../../style/skin/skin";

const styles = theme => ({

    headerBar: {
        height: 64,
        lineHeight: 64,
        alignItems: 'left',
        justifyContent: 'center',
        position: 'relative',
        padding: '0 0 0 15px'
    },
    toolbar: {
        width: "100%",
        paddingRight: 0,
    },
    brandLogo: {
        color: getColor(theme.palette.primaryText),
    },
    person: {
        display: "flex",
    },
    flex: {
        flex: "1"
    },
    name: {
        lineHeight: "20px",
        marginTop: 13,
    },
    paper: {
        top: 0,
        right: 0,
    },
    menuIcon: {
        margin: "5px  0 0 -20px",
    }
});

export default styles;
