/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 25-9-18.
 */

import {querystring, restApi} from "../util";
import {getJson} from "../../HTTP/get";
import {postJson, postJsonNoContent} from "../../HTTP/post";

const endpoints = {
    task: `${restApi}/task`,
};

/**
 *  @type {TaskClient}
 */
class CamundaTaskClient {

    static getTaskCount(query) {
        return getJson()(`${endpoints.task}/count${querystring(query)}`);
    }

    static getTasks(query) {
        const sortOrder = process.env.NODE_ENV === "production" ? "desc" : "asc";
        query = {
            sortBy: "created",
            sortOrder: sortOrder,
            ...query
        };
        return getJson()(`${endpoints.task}${querystring(query)}`);
    }

    /**
     * Camunda's task endpoint allows for slightly more general queries when using POST
     * @param query
     * @returns {Promise<Object>}
     */
    static searchTask(query) {
        const sortOrder = process.env.NODE_ENV === "production" ? "desc" : "asc";
        query = {
            sortBy: "created",
            sortOrder: sortOrder,
            ...query
        };

        return postJson(`${endpoints.task}`)(query);
    }

    static getTask(id) {
        return getJson()(`${endpoints.task}/${id}`);
    }

    static findByProcessInstanceId(processId, taskName = null) {
        let query = {
            processInstanceId: processId,
        }
        if (taskName) {
            query.name = taskName;
        }
        return postJson(`${endpoints.task}`)(query);
    }

    static closeTask(task, variables = null) {

        if (variables !== null) {
            throw  new Error("closeTask: variable not supported yet...");
        }
        return postJsonNoContent(`${endpoints.task}/${task.id}/complete`)({variables: {}});
    }

    static getActivities(query) {
        return getJson()(`${endpoints.task}${querystring(query)}`);
    }

    static getTaskAttachments(id) {
        return getJson()(`${endpoints.task}/${id}/attachment`);
    }

    static getTaskAttachmentById(taskId, attachmentId) {
        return getJson()(`${endpoints.task}/${taskId}/attachment/${attachmentId}`);
    }
}

export default CamundaTaskClient;
