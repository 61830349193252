import {Card, CardContent, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import React from "react";
import MiniPage from "../MiniPage/MiniPage";
import MiniPages from "../MiniPage/MiniPages";
import FormData from "./FormData";

const restrict = (response, acceptedFields) => {
    if (response === null || typeof response === "undefined") {
        return [];
    }
    return response.filter(({field, value}) => {
        return acceptedFields.includes(field);
    });
}

const BankgarantieProposal = ({proposal, formDescription}) => {

    const introductieData = restrict(proposal.questionResponse, ["hulpvraag", "uitleg_hulpvraag", "gewenste_datum", "gewenst_dagdeel", "gewenst_tijdvak", "voornaam", "achternaam", "email", "telefoonnummer"]);
    const gegevensData = restrict(proposal.questionResponse, ["woning_type", "eigen_woning", "met_partner", "huidige_situatie_single", "huidige_situatie_met_partner", "nationaliteit", "burgelijke_staat",
        "soort_inkomen", "kvk", "schulden", "partner_voornaam", "partner_achternaam", "partner_achternaam", "partner_achternaam",
        "partner_nationaliteit", "partner_burgelijke_staat", "partner_soort_inkomen", "partner_kvk", "partner_schulden", "woning_verhuurd", "zelf_eigenaar"
    ]);
    const wensenData = restrict(proposal.questionResponse, ["huidig_adres_postcode", "huidig_adres_huisnummer", "huidig_adres_toevoeging", "huidig_adres_straatnaam", "huidig_adres_plaatsnaam", "lead_bron",]);


    let miniPages = [];
    miniPages.push(MiniPage.create("Introductie", <FormData data={introductieData}/>));
    miniPages.push(MiniPage.create("Gegevens", <FormData data={gegevensData}/>));
    miniPages.push(MiniPage.create("Wensen", <FormData data={wensenData}/>));


    return <Grid item
                 lg={12}
                 md={12}
                 sm={12}>
        <Card>
            {proposal === null
                ? null
                : <CardContent>
                    <br/>
                    <Typography variant="h3">
                        {formDescription}
                    </Typography>

                    <MiniPages miniPages={miniPages}/>
                </CardContent>
            }
        </Card>
    </Grid>;
}

export default BankgarantieProposal;
