import React from "react";

/**
 * Simple HOC that makes it possible to use nullable components without mixing and matching of JSX-tags and javascript, i.e.:
 *      {
 *          Component && <Component prop1={...} prop2={...}>
 *      }
 * Can be written as:
 *      <Nullable Component={Component} prop1={...} prop2={...}/>
 *
 * If a React component is given, Nullable will instantiate it with the given props and else it will simply return null.
 *
 * @param {JSX.Element|null} Component
 * @param props
 * @return {JSX.Element|null}
 * @constructor
 */
const Nullable =
    ({Component, ...props}) =>
        Component
        ? <Component {...props}/>
        : null

export default Nullable;
