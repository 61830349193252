/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 5-4-18.
 */
const styles = theme => ({
    root: {
        float: "right"
    },
    modal: {
        position: 'absolute',
        width: "50%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        borderRadius: 4,
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`,
        maxHeight: "calc(100% - 96px)",
        overflowY: "auto",
    },
    clickable: {
        cursor: "pointer",
    },
    adjustedClickable: {
        position: "relative",
        top: "-30px",
        cursor: "pointer",
    },
    clickableHeader: {
        cursor: "pointer",
        marginTop: 16,
    }
});

export default styles;
