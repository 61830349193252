import React from "react";
import ActionType from "../../../../../../action/ActionType";
import retrieving from "../../../../../../action/creator/asynchonous/retrieving";
import errorAction from "../../../../../../action/creator/error";
import {promiseAction} from "../../../../../../middleware/promiseDispatch";
import {Forms} from "../../../../../../api/form/FormClient";
import {retrieveForm} from "../../../../../../action/creator/asynchonous/form/retrieveForm";
import {compose} from "redux";
import {connect} from "react-redux";
import {noteClient} from "../../../../../../api/relation/note/NoteClient";
import nop from "../../../../../../action/creator/nop";
import {ButtonForm} from "../../../../../component/form/ModalForm/ModalForm";
import {getQuestionValue} from "../../../../../../util/form";

const remoteError = error => {
    console.error(`${error.message}: ${error.response.message} -- ${error.response.error}`);
    return errorAction("Error adding NOTE")(error.response.message);
};

const newNoteAction =
    user =>
        customer =>
            form =>
                promiseAction(noteClient.addFormNote(user,
                                                     customer,
                                                     "Notitie toegevoegd",
                                                     getQuestionValue(form)("text"))
                                        .then(x => nop),
                              retrieving(ActionType.asynchronous.form.post.POSTING),
                              remoteError);

const getNotes = customer => noteClient.getNotes(customer)

class NewNote extends React.Component {

    state = {
        notes: [],
    }

    componentDidMount() {
        this.props.loadForm();

        getNotes(this.props.customer).then(notes => this.setState({notes: notes}));
    }

    render() {
        let {customer, user, description = "Maak notitie"} = this.props;

        return (
            <ButtonForm description={description}
                        formEndpoint={Forms.newNote}
                        submitAction={newNoteAction(user)(customer)}/>
        );
    }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    loadForm: () => dispatch(retrieveForm(Forms.newNote)),

});

const decorate = compose(
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(NewNote);

