/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-7-19.
 */

import React from "react";
import {task} from "../../../../api/process/task/tasks";
import LoggedOn from "../../../component/Main/Base/LoggedOn/LoggedOn";

import securePage from "../../SecurePage/SecurePage";
import {roles} from "../../../../api/login/LoginClient";
import Task from "./Task";

class LoggedOnTask extends React.Component {

    state = {
        task: null,
    };

    componentDidMount() {
        task(this.props.match.params.id)
            .then(task => this.setState({task}));
    }

    render() {
        const {task} = this.state;
        if (task === null) {
            return <div/>;
        }
        else {
            return (<LoggedOn>
                <Task task={task}/>
            </LoggedOn>);
        }
    }
}

export default securePage([roles.admin])(LoggedOnTask);
