/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-7-19.
 */
import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {Button, Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMediaHeader from "../../../../../component/CardMediaHeader/CardMediaHeader";
import {push} from "react-router-redux";
import {paths} from "../../../../../paths";
import Anchor from "../../../../../component/Anchor/Anchor";
import CardContent from "@material-ui/core/CardContent";
import SmallCardHeader from "../../../../../component/Card/SmallCardHeader";
import {DocumentUpload} from "../../../../../component/Documents/DocumentUpload";
import getData from "../getData";
import {getRejectedFileQuestions} from "../fileQuestions";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {getMotivationIdentifier} from "../identifiers";
import {promiseAction} from "../../../../../../middleware/promiseDispatch";
import {closeTask} from "../../../../../../api/process/task/tasks";
import {customerClient} from "../../../../../../api/relation/customer/CustomerClient";
import {setInstanceVariable} from "../../../../../../api/process/process/processes";

/**
 * Task corresponding to the step 'Beoordeel aanvraag' in the  DOCUMENTED_REQUEST process
 *
 * It should present the user with all relevant information in the request, and allow the user to accept or decline the request
 *
 */
class AmendDocuments extends React.Component {

    state = {
        documents: [],
        instance: null,
        agent: null,
        customer: null,
        request: null,
        tabIndex: 0,
    };

    componentDidMount() {
        const {task} = this.props;
        const {processInstanceId} = task;

        getData(this)(processInstanceId);
    }

    render() {

        const {customer, request, documents, variables} = this.state;
        const {task, completeTask} = this.props;
        const {processInstanceId} = task;

        if (this.state.instance && this.state.agent) {

            return [
                <Card key={1}>
                    <CardMediaHeader title={`Taak ${task ? task.name : ""}`}
                                     image="/images/handshake.jpg"/>
                    <Typography variant="h5">Problemen:</Typography>

                    <List>
                        {
                            request == null ? null : Object.entries(getRejectedFileQuestions(request, variables))
                                                           .map(([key, field]) => <ListItem button
                                                                                            key={key}>
                                                               <ListItemText key={1}
                                                                             primary={field.value}/>
                                                               <ListItemText key={2}
                                                                             primary={variables[getMotivationIdentifier(key)]}/>
                                                           </ListItem>)
                        }
                    </List>

                    <Button variant="contained"
                            color='primary'
                            onClick={_ => setInstanceVariable(task.processInstanceId)("Boolean", "allDocumentsAssessed", false)
                                .then(completeTask(task))}>Aangevuld</Button>

                </Card>,
                <Card key={2}>

                    <CardContent>
                        <SmallCardHeader title="Documenten"/>
                        <DocumentUpload customer={customer}
                                        documents={documents ? documents : []}
                                        loadDocuments={_ => getData(this)(processInstanceId)}
                                        onDeleteDocument={document => customerClient.deleteDocument(document)
                                                                                    .then(_ => getData(this)(processInstanceId))}
                                        typeFilter={([type, description]) => true}/>
                    </CardContent>

                </Card>,
                <Card key={3}>
                    <Typography variant="h5">Process</Typography>
                    Deze taak maakt deel uit van een <Anchor href={`/proces/${task.processInstanceId}`}>process</Anchor>
                </Card>
            ];
        }
        return <div/>;
    }
}

const mapDispatchToProps = dispatch => ({
    back: task => () => dispatch(push(paths.task)),
    completeTask: task => () => dispatch(promiseAction(closeTask(task).then(_ => push(paths.task)))),
});

const decorate = compose(
    connect(null, mapDispatchToProps)
);

export default decorate(AmendDocuments);
