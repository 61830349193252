/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 15-1-18.
 */
import paths from "../../../../../paths";
import upgrade from "../../../../../../util/upgrade";

const menuItems = (myTasksCount = 1, allTasksCount = 4) => [
    {
        items: [
            {
                name: "Dashboard",
                icon: "home",
                url:  paths.dashboard,
            },
        ],
    },
    {
        group: "Klanten",
        items: [
            {
                name: "Nieuwe klant",
                icon: "add_circle_outline",
                url:  paths.newClient,
            },
            {
                name: "Prospects",
                icon: "people",
                url:  paths.prospects,
            },
            {
                name:     "Klantenoverzicht",
                icon:     "people",
                preClick: upgrade,
                url:      paths.customers,
            },
            {
                name: "Klantenarchief",
                icon: "folder",
                url:  paths.archive,
            },
        ],
    },
    {
        group: "In behandeling",
        items: [
            {
                name:       "Mijn Taken",
                icon:       "business",
                badge:      myTasksCount,
                badgeColor: "primary",
                url:        paths.mytasks,
            },
            {
                name:       "Alle Taken",
                badgeColor: "secondary",
                icon:       "business",
                badge:      allTasksCount,
                url:        paths.task,
            },
            {
                name: "Processen",
                icon: "business",
                url:  paths.processTypes,
            },
            {
                name: "Dossiers",
                icon: "business",

                url: paths.dossier
                //url: paths.thanksForSignature,
            },
        ],
    },
    // {
    //     group: "Aanvragen",
    //     items: [
    //         {
    //             name: "Nieuwe aanvraag",
    //             icon: "add_circle_outline",
    //             url: paths.root
    //         },
    //     ]
    // },
    {
        items: [
            {
                name: "Organisaties",
                icon: "business",
                url:  paths.organisation,
            },
        ],
    },
];

export default menuItems;
