import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import DossierRow from "./DossierRow";
import If from "../../Optional/If";

/**
 *
 * @param {Array<Dossier>} dossiers -- It's the responsibility of the caller to make sure both the customer and the variables are loaded into the dossiers
 * @param {boolean}showCustomer
 * @param onClickDossier
 * @param onClickCustomer
 * @return {JSX.Element}
 */
const DossierList = ({dossiers, showCustomer = true, onClickDossier, onClickCustomer}) => {

    return <div>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Aanvraag</TableCell>
                    <If show={showCustomer}>
                        <TableCell>Klant</TableCell>
                    </If>
                    <TableCell>Adviseur</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Koopovereenkomst</TableCell>
                    <TableCell>Taxatie</TableCell>
                    <TableCell>Bankgarantie</TableCell>
                    <TableCell>Ontbindende voorwaarden</TableCell>
                    <TableCell>PasseerDatum</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {dossiers.map((dossier, index) => <DossierRow key={index}
                                                              showCustomer={showCustomer}
                                                              dossier={dossier}
                                                              onClickDossier={onClickDossier(dossier)}
                                                              onClickCustomer={onClickCustomer(dossier.customer)}/>)}
            </TableBody>
        </Table>
    </div>;
};

export default DossierList;
