/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 24-7-18.
 */

/**
 * Flattens an array of arrays into a long array
 * [[1,2],[3,4]] => [1,2,3,4]
 * @param {array }array
 * @return {array}
 */
const flatten = array => array.reduce((acc, values) => [...acc,
                                                        ...values],
                                      []);

/**
 * Creates an array that consists of n copies  of the array.
 * @param n
 * @return {Function}
 */
const repeat = n =>
    /**
     *
     * @param {array} array
     * @return {array}
     */
        array => {
        let arrays = [];
        for (let i = 0; i < n; i++) {
            arrays = [...arrays,
                      array];
        }
        return flatten(arrays);
    };


/**
 * @param {int} start
 * @param {int} end
 * @return {Array<int>} An array ranging from start (inclusive) to end (inclusive) advancing in steps of one
 */
const range = (start, end) => Array(end - start + 1).fill(null)
                                                    .map((_, index) => index + start);



/**
 * The inverse of Object.entries():
 *    result = Object.entries(object) // results in an array of [key, value] arrays
 *    objectFromEntries(result)       // results in  the original object
 *
 * (In between, the result will probably be filtered of mapped)
 *
 * @param {array} entries An array in the format that results from a Object.entries():
 *                [
 *                   [key1, value1],
 *                   [key2, value2],
 *                   ...
 *                ]
 * @return {object} The corresponding object
 *               {
 *                   key1: value1,
 *                   key2: value2,
 *                   ...
 *               }
 */
const objectFromEntries = entries => entries.reduce((acc, [key, value]) => ({...acc, [key]: value}), {});


export {
    flatten,
    repeat,
    range,
    objectFromEntries,
}
