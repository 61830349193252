/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 16-7-19.
 */


const serviceDomain = `${process.env.REACT_APP_INSURANCE_PROCESSOR}`;
const restApi = `${serviceDomain}/rest/engine/default`;

/**
 * @param variable
 * @return {boolean}
 */
const empty = variable => typeof (variable) === "undefined" || variable === null || variable === "";

/**
 * Maps the parameters as generally used in Webfrontend queries, to those used by Camunda
 *
 * @param page
 * @param itemsPerPage
 * @param sorts
 * @return {{maxResults: *, firstResult: number}}
 */
function mapQueryParameters({page = 1, itemsPerPage = 5, sorts, ...rest}) {
    return ({
        firstResult: (page - 1) * itemsPerPage,
        maxResults: itemsPerPage,
        ...rest,
    });
}

function querystring(query = {}) {
    const qs = Object.entries(mapQueryParameters(query))
                     .filter(pair => !empty(pair[1]))
                     .map(pair => pair.map(encodeURIComponent)
                                      .join('='))
                     .join('&');
    return qs && '?' + qs;
}

export {
    serviceDomain,
    restApi,
    empty,
    mapQueryParameters,
    querystring,
}
