/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 26-1-18.
 */
import React from "react";
import {MenuItem} from "@material-ui/core";

const Suggestion = ({suggestion, isSelectedSuggestion, itemProps}) => (
    <MenuItem component="div"
              {...itemProps}
              key={suggestion}
              selected={isSelectedSuggestion}
              style={{fontWeight: 500}}>
        {suggestion}
    </MenuItem>
);

export default Suggestion;
