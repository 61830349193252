/**
 * Bunch of routines that take a form and return a (filtered) array of questions
 *
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 03-08-19.
 */

import {objectFromEntries} from "../../../../../util/array";
import {getAcceptedIdentifier} from "./identifiers";

/**
 * Returns the FILE fields in the forms
 * @param form
 * @return {*}
 */
const getFileQuestions =
    form =>
        objectFromEntries(Object.values(form.groups)
                                .flatMap(group => Object.entries(group.fields))
                                .filter(([_, field]) => field.type === "FILE")
        );

/**
 * Returns the FILE-fields that have been assessed (ie both those approved and those rejected)
 * @param form
 * @param variables
 * @return {object}
 */
const getAssessedFileQuestions =
    (form, variables) =>
        objectFromEntries(
            Object.entries(getFileQuestions(form))
                  .filter(([identifier]) => variables.hasOwnProperty(getAcceptedIdentifier(identifier)))
        );

/**
 * Returns the FILE-fields that have been approved (N.B. those assessed and then approved but the questions not assessed yet are included as well)
 * @param form
 * @param variables
 * @return {object}
 */
const getApprovedFileQuestions =
    (form, variables) =>
        objectFromEntries(
            Object.entries(getFileQuestions(form))
                  .filter(([identifier]) => variables[getAcceptedIdentifier(identifier)])
        );

/**
 * Returns the FILE-fields that haven't been approved (ie both those assessed and rejected and those not assessed yet)
 * @param form
 * @param variables
 * @return {object}
 */
const getUnApprovedFileQuestions =
    (form, variables) =>
        objectFromEntries(
            Object.entries(getFileQuestions(form))
                  .filter(([identifier]) => !variables[getAcceptedIdentifier(identifier)])
        );

/**
 * Returns the FILE-fields that have been rejected (ie only those that have been assessed and the rejected)
 * @param form
 * @param variables
 * @return {object}
 */
const getRejectedFileQuestions =
    (form, variables) =>
        objectFromEntries(
            Object.entries(getFileQuestions(form))
                  .filter(([identifier]) => variables[getAcceptedIdentifier(identifier)] === false)
        );

export {
    getFileQuestions,
    getAssessedFileQuestions,
    getApprovedFileQuestions,
    getUnApprovedFileQuestions,
    getRejectedFileQuestions,
}
