/**
 * Applying this middleware to the store allows you to dispatch Actions to the store that are
 * compounded of multiple actions:
 * {
 *     type: compoundActionType.COMPOUND
 *     actions: Array<Action>
 * }
 * where actions is a array of actions that is to be dispatched.
 *
 * If a single userAction has multiple effects this is usually handled by having multiple
 * reducers that all react to the same basic action. But this doesn't work when some of the desired effects
 * are asynchronous, because actions must be serializable (or they must be, by the time they're handled
 * by the reducers).
 *
 * In such a case one would splitup the desired effects into a list of actions and have the
 * actionCreator wrap the list in a CompoundAction that will be handled by this middleware
 * (presumably another type of middleware will be in place to target the non-serializable actions)
 *
 * USAGE:
 *
 * import {createStore,applyMiddleware,compose } from 'redux'
 * import compoundDispatch from ....
 *
 * then:
 * replace      reduxStore = createStore(reducer)
 * with         reduxStore = createStore(reducer, applyMiddleware(compoundDispatch))
 *
 * or (in combination with more middleware/enhancers):
 * replace    reduxStore = createStore(reducer, reduxBrowserExtension)
 * with       reduxStore = createStore(reducer, compose(applyMiddleware(compoundDispatch),
 *                                                      reduxBrowserExtension));
 *
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 20-7-16.
 */

/**
 * @typedef {string} ActionType
 */

/**
 * An action is an object that will contain a number of fields; it will at the very
 * least  contain a field type (an ActionType, i.e. a string).
 *
 * Additional fields present depend on the choice of ActionType
 *
 * @typedef {Object} Action
 * @property {ActionType} type
 */

/**
 * A StateAction is a function that uses the the full state (as held by the store) to create an action.
 *
 * These kind of ActionCreators are common when an action needs to be created from within a dispatcher, for example when the action needs to
 * incorporate updates to the state that were caused by the dispatcher independently from the action.
 *
 * A StateAction can function as a thunk, in that the creation of the actual action is deferred until the state has been updated.
 * (like redux-thunk, but those thunks take an extra dispatch argument)
 *
 * @typedef {function} StateAction
 * @param {*} state
 * @returns {Action}
 */

/**
 * @type {Object}
 * @property {ActionType} COMPOUND
 */
const compoundActionType = Object.freeze(
    {
        COMPOUND: "COMPOUND_DISPATCH__COMPOUND",
    },
);

/**
 * @param {Array.<Action|StateAction>} actions. The actions to be dispatched. If the 'action' is a StateAction it will be applied to the stores state in order
 *                                        to obtain an actual action. This comes in handy if the changes made to the state by dispatching the first action
 *                                        should be incorporated into the second action.
 * @return {Action}
 */
const compoundAction = (...actions) => {

    if (!Array.isArray(actions)) {
        throw Error("Argument to compoundAction should be an array")
    }
    actions.forEach(action => {
        if (Array.isArray(action)) {
            console.error("compoundAction expects Expected {Action|StateAction}, got array");
            throw Error("Arguments to compoundActions should be either Actions (objects), or StateActions (functions that map the state to an Action)")
        }
    });

    return ({
        type: compoundActionType.COMPOUND,
        actions: actions,
    });
};

const compoundDispatch =
    store => nextDispatch => action => {

        if (action.type === compoundActionType.COMPOUND) {
            action.actions.forEach(action => typeof action === "function"
                                             ? store.dispatch(action(store.getState()))
                                             : store.dispatch(action));
            return action;
        }
        else {
            return nextDispatch(action);
        }
    };

export {
    compoundActionType,
    compoundAction,
    compoundDispatch,
};
export default compoundDispatch;
