import * as React from "react";
import {Avatar, Typography, withStyles} from "@material-ui/core";
import {getColor} from "../../../../../../../style/skin/skin";
import {isValidHttpUrl} from "../../../../../../../api/HTTP/util";

const persona = skin =>
    skin.images.personaBackground
        ? {
            backgroundImage:  'url(\'/images/office.jpg\')',
            backgroundSize:   'auto',
            backgroundRepeat: 'no-repeat',
        }
        : {
            backgroundColor: getColor(skin.palette.personaBackground),

        };

const styles = theme => ({
    persona:        {
        ...persona(theme.skin),
        height:  175,
        width:   '100%',
        padding: 32,
    },
    personaContent: {
        margin: '15px 0',
    },
    personaAvatar:  {
        width:  64,
        height: 64,
    },
    personaName:    {
        fontWeight: 'bold',
        color:      'white',
    },
    personaEmail:   {
        color: 'white',
    },
});

// Not sure where it slipped in, apperently some of our images end in a final quote turns the dataUrl invalid
const cleanImageData = string => {
    if (typeof string === 'string' && string.length > 0) {
        const lastChar = string.charAt(string.length - 1);
        if (lastChar === '"') {
            return string.slice(0, -1);
        }
    }
    return string;
}

/**
 * Before the image upload was supported we uploaded the profile pictures by hand,
 * which led invalid values and a lack of uniformity in the invalidity.
 *
 * If the string we receive is a valid URL the image is probably uploaded via the userinterface and all is well.
 * If not, we attempt a little bit of cleanup
 *
 * @param string
 * @returns {*|string}
 */
const makeDataUrl =
    string =>
        isValidHttpUrl(string, ["http:", "https:", "data:"])
            ? string
            : `data:image/jpeg;base64,${cleanImageData(string)}`;

const Persona = ({person, classes}) => {

    return <div className={classes.persona}>
        <Avatar alt={person.name}
                src={makeDataUrl(person.imageUrl)}
                className={classes.personaAvatar}/>
        <div className={classes.personaContent}>
            <Typography variant="subtitle1"
                        className={classes.personaName}>{person.fullName? person.fullName : person.name}</Typography>
            <Typography className={classes.personaEmail}>{person.email}</Typography>
        </div>
    </div>;
};

export default withStyles(styles)(Persona);
