import {Icon, TableCell, withStyles} from "@material-ui/core";
import React from "react";
import If from "../../../Optional/If";
import {delta} from "../../date";
import {isPresent, now} from "../../../../../util/util";
import Badge from "@material-ui/core/Badge";
import Optional from "../../../Optional/Optional";

const styles = theme => (
    {
        inProgress: {},
        done: {
            color: "green",

        },
        problem: {
            color: "red",
        },
        date: {
            marginBottom: "15px",
        }
    }
);

/**
 * @param {DossierDate} dossierDate
 * @param classes
 * @returns {JSX.Element}
 */
const DateContent = ({dossierDate, classes}) =>
    <span className={classes[dossierDate.getClass()]}>
        <If show={dossierDate.getIcon() !== null}>
            <Icon>
                {dossierDate.getIcon()}
            </Icon>
        </If>
        <span className={classes.date}>{dossierDate.getDescription()}</span>
    </span>;

/**
 *
 * @param {DossierDate} dossierDate
 * @param {function} onClick
 * @param classes
 * @returns {JSX.Element}
 * @constructor
 */
const Date = ({dossierDate, classes}) => {

    return dossierDate.getBadge() === null
           ? <DateContent dossierDate={dossierDate}
                          classes={classes}/>
           : <Badge color="primary"
                    anchorOrigin={{vertical: "top", horizontal: "left"}}
                    badgeContent={delta(dossierDate.expiry, now())}>
               <DateContent dossierDate={dossierDate}
                            classes={classes}/>
           </Badge>;
}

/**
 *
 * @param {DossierDate} dossierDate
 * @param {function} onClick
 * @param classes
 * @returns {JSX.Element}
 * @constructor
 */
const DateCell = ({dossierDate, onClick, classes}) =>

    <TableCell onClick={onClick}>
        <Optional show={isPresent(dossierDate)}
                  Component={Date}
                  dossierDate={dossierDate}
                  classes={classes}/>
    </TableCell>;

export default withStyles(styles)(DateCell);
