/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 1-9-17.
 */
import PropTypes from 'prop-types';
import moment from "moment";
import {dblack, dcyan, dred, dyellow} from "./debug";

/*
 * Log-functionality intended to be used in promises.
 *
 * E.g. Promise.resolve(5)
 *             .then(info('Just resolved:'))
 *             .then(callback)
 *
 * logs "'Just resolved:', 5" to the console, before handing 5 over to the next callback
 *
  *
 * @param message
 * @param {string} color
 * @param {boolean} logValue
 * @returns {function(*): void}
 */
const info =
    (message, color = "cyan", logValue = true) =>
        value => {
            const args = logValue? [message, value] : [message];
            switch (color) {
                case "red":
                    dred(...args);
                    break;
                case "green":
                    dred(...args);
                    break;
                case "blue":
                    dred(...args);
                    break;
                case "yellow":
                    dyellow(...args);
                    break;
                case "cyan":
                    dcyan(...args);
                    break;
                case "black":
                default:
                    dblack(...args)
            }
            return value;
        };

/**
 * Creates a list of objects by applying the mapper to each (key,value) pair in the object
 * @param {function} map (key,value) => object
 */
const objectMap = map => object => {
    const result = [];
    Object.keys(object).forEach(key => result.push(map(key, object[key])));
    return result;
};

/**
 * Returns an array containing the range of numbers between start and end (including both start and end themselves)
 * @param {number} start
 * @param {number} end
 * @return {Array.<number>}
 */
const range = (start, end) => {
    let result = [];
    for (let index = start; index <= end; index++) {
        result.push(index);
    }
    return result;
};

const join = glue => array => array.filter(value => typeof value === "string")
                                   .map(string => string.trim())
                                   .filter(string => string.length > 0)
                                   .reduce((acc, cur) => acc + glue + cur, "");

const hasClasses = classes => {
    const result = {};
    classes.forEach(key => result[key] = PropTypes.string.isRequired);
    return PropTypes.shape(result);
};

const stringify = object => {
    let stringified = {};
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            stringified[key] = String(object[key]);
        }
    }
    return stringified
};

const notEmpty = object => Object.keys(object).length > 0;

const isPresent = (argument) => typeof argument !== 'undefined' && argument !== null;

/**
 * Returns a new object that contains only the attributes in the object that are listed in the array of allowed
 * attributes
 *
 * @param {Object} object
 * @param {Array<string>} allowedAttributes
 * @return {Object}
 */
const restrict = (object, allowedAttributes) => {
    let result = {};
    for (const [attribute, value] of Object.entries(object)) {
        if (allowedAttributes.includes(attribute)) {
            result[attribute] = value;
        }
    }
    return result;
}

const now = () => new moment();

/**
 * Simple reverse lookup
 *
 * @param {object} query
 * @param {object} object
 * @return {string|void} the queries key if the query is one of the objects values, or else null
 */
const lookup = (query, object) => {
    for (const [key, value] of Object.entries(object)) {
        if (query === value) {
            return key;
        }
    }
}

export {
    objectMap,
    info,
    range,
    join,
    hasClasses,
    stringify,
    notEmpty,
    isPresent,
    restrict,
    now,
    lookup,
};
