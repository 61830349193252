import React from "react";
import Note from "./Note";
import {withStyles} from "@material-ui/core/index";

const styles = theme => ({
    notes: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: "30px"
    }
});

class Notes extends React.Component {

    render() {
        const {classes, notes = []} = this.props;

        return (
            <div className={classes.notes}>
                {
                    notes.map((note, index) =>
                                  <Note note={note}
                                        index={index}
                                        key={index}/>
                    )
                }
            </div>
        );
    }
}

export default withStyles(styles)(Notes);

