/**
 * Component that contains the material-ui specific parts of the Carousel
 *
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 25-7-18.
 */
import React from "react";
import {Grid, Icon, withStyles} from "@material-ui/core";

const styles = theme => ({
        button: {
            cursor: "pointer",
        },
    }
);

const Presentation = ({onSlideLeft, onSlideRight, children, classes}) =>
    <Grid container
          alignItems="center">
        <Grid item>
            <Icon color="primary"
                  className={classes.button}
                  onClick={onSlideLeft}>keyboard_arrow_left</Icon>
        </Grid>
        <Grid item>
            {children}
        </Grid>
        <Grid item>
            <Icon color="primary"
                  className={classes.button}
                  onClick={onSlideRight}>keyboard_arrow_right</Icon>
        </Grid>
    </Grid>;

export default withStyles(styles)(Presentation)
