/**
 * @type Device
 */
class Mouse {

    /**
     * type {StrokeEventHandler}
     */
    strokeEventHandler;

    /**
     * @type {boolean}
     */
    buttonDown = false;

    /**
     *
     * @param {StrokeEventHandler} strokeEventHandler
     */
    register(strokeEventHandler) {
        strokeEventHandler.currentCanvas.addEventListener("mousedown", this.handleMouseDown.bind(this));
        strokeEventHandler.currentCanvas.addEventListener("mousemove", this.handleMouseMove.bind(this));
        this.strokeEventHandler = strokeEventHandler;
        document.addEventListener("mouseup", this.handleMouseUp.bind(this));
    }

    unRegister() {
        if (this.strokeEventHandler) {
            this.strokeEventHandler.currentCanvas.removeEventListener("mousemove", this.handleMouseMove);
            this.strokeEventHandler.currentCanvas.removeEventListener("mousedown", this.handleMouseDown);
        }
        document.removeEventListener("mouseup", this.handleMouseUp);
    }

    handleMouseDown(event) {
        if (event.which === 1) {
            this.buttonDown = true;
            this.strokeEventHandler.strokeBegin(event);
        }
    }

    handleMouseMove(event) {
        if (this.buttonDown) {
            this.strokeEventHandler.strokeUpdate(event);
        }
    }

    handleMouseUp(event) {
        if (event.which === 1 && this.buttonDown) {
            this.buttonDown = false;
            this.strokeEventHandler.strokeEnd(event);
        }
    }
}

export default Mouse;
