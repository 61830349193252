/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 26-2-18.
 */
import PropTypes from 'prop-types';

const questionShape = {
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    tooltip: PropTypes.string,
    xpath: PropTypes.string,
    acceptableValues: PropTypes.object,
    required: PropTypes.bool.isRequired,
    submit: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    changed: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    message:  PropTypes.string,
    locked: PropTypes.bool.isRequired,
    visible: PropTypes.bool.isRequired,
    busy: PropTypes.bool.isRequired
};
const questionPropType = PropTypes.shape(questionShape);

export default questionPropType;
export {
    questionShape,
    questionPropType,
}
