import {empty} from "../process/util";


/**
 * USAGE:
 * Set the variable REACT_APP_LOG_API_CALLS in the .env file (for production), or in an untracked file called
 * .env.local (for development) The global will be initialized with the value from the environment.
 *
 * Once the app is loaded, the logging can be switched on/off from the devtools console by typing
 * >
 * > global.LOG_API_CALLS=true  // or false :-)
 * >
 *
 * @type {false}
 */
global.LOG_API_CALLS = !empty(process.env.REACT_APP_LOG_API_CALLS) && process.env.REACT_APP_LOG_API_CALLS;

/**
 * Tests whether the argument (interpreted has an HTTP-status code) signifies a successful (2xx)
 * response or not
 * @param code
 * @return {boolean}
 // */
const successCode = code => {
    return /2\d\d/.test(String(code));
};

/**
 * Prevent strings from getting extra quotes
 * @param data
 * @param replacer
 * @param space
 * @return {string}
 */
const stringify = (data, replacer = null, space = null) => typeof data === "string"? data : JSON.stringify(data,
                                                                                                           replacer,
                                                                                                           space);

/**
 * Log-functionality intended to be used in promises.
 *
 * E.g. Promise.resolve(5)
 *             .then(info('Just resolved:'))
 *             .then(callback)
 *
 * logs "'Just resolved:', 5" to the console, before handing 5 over to the next callback
 *
 * string => a => a
 */
const logResult =
    (message, log = true) =>
        value => {
            if (log) {
                console.info(message);
                console.debug(`%c ${JSON.stringify(value, null, 4)}`, 'color:  grey; font-weight: italic;')
            }
            return value;
        };


/**
 * If the request is not successful (ie the status-code is not in the 200 range)
 * the result should be processed as a thrown error
 *
 * @var {Response} response
 * @return object
 * @throws Error
 */
const throwErrors = response => {

    if (!successCode(response.status)) {
        return response.json()
                       .then(object => {
                           console.error("Communication Problem", JSON.stringify(object, null, 2));
                           const error = new Error("Communication Problem");
                           error.response = object;
                           throw error;
                       })
    }
    return response;
};


const isValidHttpUrl = (string, acceptedProtocols = ["http:", "https:"]) => {
    try {
        const url = new URL(string);
        return acceptedProtocols.includes(url.protocol);
    } catch (_) {
        return false;
    }
};


export {
    successCode,
    stringify,
    logResult,
    throwErrors,
    isValidHttpUrl,
}
