/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 18-12-17.
 */

const searchResults = state => state.searchResults;

const currentSearch = state => searchResults(state).search;

const searchTerm = state => currentSearch(state) ? currentSearch(state).searchTerm : "";

const currentCustomer = state => state.currentCustomer;

const documents = state => state.documents;

export {
    searchResults,
    searchTerm,
    currentSearch,
    currentCustomer,
    documents,
}
