/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-9-17.
 */
import React from "react";
import PropTypes from "prop-types";
import TextQuestion from "../../presentation/Text/TextQuestion"
import questionPropType from "../../../propType/question";

const Text = (Presentation, inputmode = "text") =>

    class TextQuestionContainer extends React.Component {

        static propTypes = {
            question: questionPropType.isRequired,
            onSubmitQuestion: PropTypes.func.isRequired,
        };

        constructor() {
            super();
            this.state = {value: ""};

            this.onChange = this.onChange.bind(this);
            this.onBlur = this.onBlur.bind(this);
        }

        onChange(event) {
            this.setState({...this.state, value: event.target.value});
        }

        onBlur() {
            this.props.onSubmitQuestion(this.state.value);
        }

        componentDidMount() {
            const {question} = this.props;
            this.setState({value: question.value ? question.value : ""});
        }

        componentWillReceiveProps(nextProps) {
            if (nextProps.question) {
                this.setState({value: nextProps.question.value})
            }
        }

        render() {
            return <Presentation key={this.props.question.id}
                                 customer={this.props.customer}
                                 question={this.props.question}
                                 value={this.state.value}
                                 onChange={this.onChange}
                                 inputmode={inputmode}
                                 onBlur={this.onBlur}/>;
        }
    };

/**
 * should open up a numeric keyboard on mobile devices (currently supported just by chrome)
 */
const NumericText = Text(TextQuestion, "numeric");

export default Text(TextQuestion);
export {
    Text,
    NumericText
}
