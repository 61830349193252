/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 04-09-20.
 */


const garantieStellingForm2Customer = (form, customer = null) => {
    const addressFields = ["koper1_postcode",
                           "koper1_huisnummer",
                           "koper1_toevoeging",
                           "koper1_straatnaam",
                           "koper1_woonplaats"];
    const isAddressField = field => addressFields.includes(field);

    let jsonData = {};

    // On the first insert (customer == null) we need to get an  customer into the db as soon as possible (in order to upload files
    // for the customer later on in the form). In order to prevent validation errors we will fill the fields with empty data
    // On update (the saved empty  customer from the previous step is provided), we need to update that empty customer with new info from the form

    let data = customer ? customer : {
        firstName: " ",
        lastName: " ",
        email: " ",
        phone: " ",
        initials: " ",
        idType: "xxx",
    };

    let address = customer ? customer.addresses : {
        postalCode: "x",
        houseNo: "x",
        addition: "x",
        streetName: "x",
        city: "x",
    };

    form.groups.forEach(function (group) {
        if (group.header !== "Gegevens adviseur") {
            group.questions.forEach(function (question) {
                let field = question.field;

                const parsedValue = question.parsedValue;
                if (parsedValue && typeof parsedValue === "string" && parsedValue !== "") {

                    if (isAddressField(field)) {

                        switch (field) {
                            case "koper1_postcode":
                                address["postalCode"] = parsedValue;
                                break;
                            case "koper1_huisnummer":
                                address["houseNo"] = parsedValue;
                                break;
                            case "koper1_toevoeging":
                                address["addition"] = parsedValue;
                                break;
                            case "koper1_straatnaam":
                                address["streetName"] = parsedValue;
                                break;
                            case "koper1_woonplaats":
                                address["city"] = parsedValue;
                                break;
                            default:
                                break;
                        }
                    }
                    else {
                        switch (field) {
                            case "koper1_voornaam":
                                data["firstName"] = parsedValue;
                                break;
                            case "koper1_achternaam":
                                data["lastName"] = parsedValue;
                                break;
                            case "email":
                                data["email"] = parsedValue;
                                break;
                            case "koper1_telefoonnummer":
                                data["phone"] = parsedValue;
                                break;
                            // TODO: fill up customer with koper1_ values :-)
                            default:
                                break;
                        }
                    }
                }
            });
        }
    });

    data["addresses"] = address;
    jsonData["data"] = data;
    return jsonData;

}

export default garantieStellingForm2Customer;
