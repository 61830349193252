/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 1-2-18.
 */
import React from "react";
import {Grid} from "@material-ui/core";
import SmallCardHeader from "../../../../../../component/Card/SmallCardHeader";

class Header extends React.Component {

    state = {
        mouseIsHovering: false
    };

    onMouseEnter = () => this.setState({
                                           mouseIsHovering: true
                                       });

    onMouseLeave = () => this.setState({
                                           mouseIsHovering: false
                                       });

    render() {
        const {title, modalForm} = this.props;

        return [
            <Grid item
                  key={1}
                  sm={10}
                  onMouseEnter={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}>
                <SmallCardHeader title={title}/>
            </Grid>,
            <Grid item
                  key={2}
                  sm={2}
                  onMouseEnter={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}>
                {modalForm}
            </Grid>
        ];
    }
}

export default Header;

