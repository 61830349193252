/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 21-12-17.
 */
import ActionType from "../../../ActionType";

/**
 *
 * @param {number} step
 * @return {Action}
 */
const stepAction = step => ({
    type: ActionType.currentStep,
    step
});

export default stepAction;
