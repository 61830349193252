/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 6-9-17.
 */

import {combineReducers} from "redux";
import requestId from "./requestId";
import status from "./status";
import value from "./value";

/**
 * @type {Reducer}
 */
const quote = combineReducers(
    {
        requestId,
        status,
        value,
    },
);
export default quote;
