/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 1-2-18.
 */
import React from "react";
import {Grid, Icon, Typography} from "@material-ui/core";
import If from "../../../../../../component/Optional/If";

/**
 * If a ReactComponent is passed as icon it is used directly
 * If a string is passed as icon, an Icon - component is created and used.
 * If all else fails the description is used
 *
 * @param icon
 * @param description
 * @return {JSX.Element|*}
 */
const ItemIcon = ({icon, title}) => {
    if (icon !== null) {
        if (typeof icon === "string") {
            return <Icon color="primary"
                         title={title}>{icon}</Icon>;
        }
        return React.cloneElement(icon, {color: "primary", title: title});
    }
    return title;
}

class Item extends React.Component {

    state = {
        mouseIsHovering: false
    };

    onMouseEnter = () => this.setState({
                                           mouseIsHovering: true
                                       });

    onMouseLeave = () => this.setState({
                                           mouseIsHovering: false
                                       });

    render() {
        const {object, icon, title, getDescription, getDetails, modalForm} = this.props;

        return [
            <Grid item
                  key={1}
                  xs={2}
                  onMouseEnter={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}>
                <span title={title}>
                <ItemIcon icon={icon}
                          title={title}/></span>

            </Grid>,
            <Grid item
                  key={2}
                  xs={8}
                  onMouseEnter={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}>
                {getDescription(object)}
                {
                    getDetails ? <Typography>{getDetails(object)}</Typography> : null
                }
            </Grid>,
            <Grid item
                  key={3}
                  xs={2}
                  onMouseEnter={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}>
                <If show={this.state.mouseIsHovering}>
                    {modalForm}
                </If>
            </Grid>
        ];
    }
}

export default Item;

