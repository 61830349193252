/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-9-17.
 */
import React from "react";
import PropTypes from "prop-types";
import BooleanQuestion from "../../presentation/Boolean/BooleanQuestion";
import {questionShape} from "../../../propType/question";

const getValue = question => {
    const defaultValue = question.defaultValue ? question.defaultValue : "";
    return question.value ? question.value : defaultValue;
};

const Boolean = Presentation =>
    ({question, onSubmitQuestion}) =>
        <Presentation question={question}
                      value={getValue(question)}
                      onChange={event => onSubmitQuestion(event.target.value)}/>;

Boolean.propTypes = {
    question: PropTypes.shape(
        {
            ...questionShape,
            acceptableValues: PropTypes.object.isRequired
        }),
    onSubmitQuestion: PropTypes.func.isRequired,
};

export default Boolean(BooleanQuestion);
export {
    Boolean
}
