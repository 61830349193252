/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 15-12-17.
 */
import React from "react";
import {Button, Icon, Paper, withStyles} from "@material-ui/core";
import styles from "./styles";
import PropTypes from 'prop-types';
import SearchField from "./SearchField";
import {compose} from "redux";

const string = x => x ? String(x) : "";

const searchBarInterface = {
    currentSearchTerm: PropTypes.string.isRequired,
    onSearch: PropTypes.func.isRequired, // Provide an actionDispatcher string -> void
    customerStatus: PropTypes.string,
};

class UnstyledSearchBar extends React.Component {

    static propTypes = {
        ...searchBarInterface,
        classes: PropTypes.object.isRequired,
    };

    state = {
        currentSearchTerm: "",
    }

    onSearch = () => this.props.onSearch(string(this.state.currentSearchTerm));

    onNewSearchTerm = currentSearchTerm => {
        this.setState({currentSearchTerm});
        this.props.onSearch(string(currentSearchTerm));
    };

    render() {
        const {classes, customerStatus} = this.props;
        return (
            <Paper className={classes.root}>
                <Icon className={classes.icon}>search</Icon>

                <SearchField onNewSearchTerm={this.onNewSearchTerm}
                             customerStatus={customerStatus}
                             classes={classes}/>

                <Button variant="contained"
                        color='primary'
                        type="submit"
                        onClick={this.onSearch}
                        className={classes.button}>
                    Zoeken
                </Button>
            </Paper>
        );
    }
}

const decorate = compose(
    withStyles(styles),
);

const SearchBar = decorate(UnstyledSearchBar);
SearchBar.propTypes = searchBarInterface;

export default SearchBar;
export {
    UnstyledSearchBar,
    SearchBar,
}
