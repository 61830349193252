/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 19-12-18.
 */
import {Typography, withStyles} from "@material-ui/core";
import React from "react";
import CardHeader from "@material-ui/core/CardHeader";

const styles = theme =>
    ({
        root: {
            paddingLeft: 0
        },
    });

const SmallCardHeader = ({title, classes}) =>
    <CardHeader className={classes.root}
                disableTypography={true}
                title={
                    <Typography variant="h3"
                                component="h3">
                        {title}
                    </Typography>
                }/>;

export default withStyles(styles)(SmallCardHeader);


