import {ListItem, ListItemIcon, ListItemText, withStyles} from "@material-ui/core";
import * as React from "react";
import {compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {route} from "../../../../../../../action/creator/router";
import MenuIcon from "./MenuIcon";
import {currentUser} from "../../../../../../../state/selector/user";

const styles = theme => ({
    menuListItem: {
        padding: '14px 32px',
        width: '100%'
    },
    menuListItemText: {
        fontWeight: '500'
    },
});

const SideMenuItem = ({user, item, classes, history, onClick}) => {

    return <ListItem button
                     onClick={onClick(user)}
                     className={classes.menuListItem}>
        <ListItemIcon>
            <MenuIcon icon={item.icon}
                      badge={item.badge}
                      badgeColor={item.badgeColor}/>
        </ListItemIcon>
        <ListItemText primary={item.name}
                      disableTypography={true}
                      className={classes.menuListItemText}/>
    </ListItem>;
};


const mapStateToProps = state => ({
    user: currentUser(state),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onClick:
        user =>
            () => {
                const item = ownProps.item;

                /** preClick: void => Promise<void> */
                const preClick = item.preClick ? item.preClick : () => Promise.resolve();


                return route(item.url, user).then(dispatchProps.dispatch).then(_ => preClick());
            },
});

export default compose(
    withStyles(styles),
    withRouter,
    connect(mapStateToProps, null, mergeProps)
)(SideMenuItem);
