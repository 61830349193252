/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 29-1-18.
 */
import React from "react";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import Message from "../Message";
import PropTypes from 'prop-types';
import questionPropType from "../../../propType/question";
import Tip from "../../Tip";
import {description} from "../util";

const id = question => `question-${question["id"]}`;
const optionId = question => index => `${id(question)}-option-${index}`;

const BooleanQuestion = ({question, value, onChange}) =>
    <div style={{display: "flex"}}>
        <Tip tip={question.tooltip}/>
        <FormControl component="fieldset">
            <FormLabel required={question.required}
                       component="legend"
                       error={!question.valid}>{description(question)}</FormLabel>
            <RadioGroup row
                        name={id(question)}
                        onChange={onChange}
                        value={value}>
                {Object.entries(question.acceptableValues)
                       .map(([choice, description], index) => (
                                <FormControlLabel id={optionId(question)(index)}
                                                  key={choice}
                                                  value={choice}
                                                  label={description}
                                                  checked={choice === value}
                                                  control={<Radio color="primary"/>}/>
                            )
                       )}
            </RadioGroup>
            <Message question={question}/>
        </FormControl>
    </div>;

BooleanQuestion.propTypes = {
    question: questionPropType.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default BooleanQuestion;
export {
    BooleanQuestion,
    id,
    optionId,
}
