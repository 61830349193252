import {Button, Grid, Icon, withStyles} from "@material-ui/core";
import * as React from "react";
import {compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {route} from "../../../../../../../action/creator/router";
import withWidth from "@material-ui/core/withWidth/withWidth";
import singleColumn from "../../../util/singleColumn";

const styles = theme => ({
    singleColumnRoot: {
        paddingBottom: 0,
        marginBottom: 0,
        marginLeft: 30,
        marginRight: 30,
    },
    doubleColumnRoot: {
        paddingBottom: 0, marginBottom: 0
    },
    singleColumnButton: {
        margin: theme.spacing.unit,
        marginBottom: 3,
        marginLeft: 0,
    },
    doubleColumnButton: {
        margin: theme.spacing.unit,
        marginBottom: 3,
    },
    iconMargin: {
        margin: "0px 5px 0px -20px"
    }
});

const CustomerTopMenuItem = ({item, history, onClick, width, classes}) =>
    <Grid item
          xs={item.colSize}
          className={singleColumn(width) ? classes.singleColumnRoot : classes.doubleColumnRoot}>
        <Button variant="contained"
                color="primary"
                className={singleColumn(width) ? classes.singleColumnButton : classes.doubleColumnButton}
                onClick={onClick}>
            <Icon className={classes.iconMargin}>{item.icon}</Icon>{item.name}
        </Button>
    </Grid>;

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onClick: () => route(ownProps.item.url).then(dispatchProps.dispatch),
});

export default compose(
    withWidth(),
    withStyles(styles),
    withRouter,
    connect(null, null, mergeProps)
)(CustomerTopMenuItem);
