/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 19-12-17.
 */
import ActionType from "../../../ActionType";
import {compoundAction} from "../../../../middleware/compoundDispatch";
import {loadDocumentsAction} from "./document";

const currentCustomer = (customer, loadDocuments = true) => {

    const currentCustomerAction = {
        type: ActionType.CURRENT_CUSTOMER,
        customer,
    };

    return loadDocuments ? compoundAction(currentCustomerAction,
                                          loadDocumentsAction(customer)) : currentCustomerAction;
};

export default currentCustomer;
