import React from "react";
import {Button, Modal, withStyles} from "@material-ui/core";
import QuestionForm from "../../QuestionForm/smart/QuestionForm";
import styles from "./styles";
import PropTypes from 'prop-types';
import {hasClasses} from "../../../../util/util";

const presentationInterface = {
    formEndpoint: PropTypes.string.isRequired,
    data: PropTypes.object,
    open: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

const Presentation = ({formEndpoint, data, open, submitAction, onOpen, onClose, icon = "create", description, classes}) =>
    <div className={classes.root}>

        <Button variant="contained"
                color='primary'
                onClick={onOpen}
                type="submit">{description}
        </Button>

        <Modal aria-labelledby="simple-modal-title"
               aria-describedby="simple-modal-description"
               open={open}
               onClose={onClose}>
            <div className={classes.modal}>
                <QuestionForm data={data}
                              endpoint={formEndpoint}
                              submitAction={form => {
                                  onClose();
                                  return submitAction(form);
                              }}/>
            </div>
        </Modal>
    </div>;

Presentation.propTypes = {
    ...presentationInterface,
    classes: hasClasses(["root",
                         "clickable",
                         "modal"]),
};

let decorated = withStyles(styles)(Presentation);
decorated.propTypes = presentationInterface;
export default decorated;

export {
    Presentation,
    presentationInterface
}



