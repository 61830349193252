/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 16-7-18.
 */
import React from "react";
import {withStyles} from "@material-ui/core";
import Warning from "./Warning";
import Premium from "./Premium";

const styles = {
    root: {
        padding: "5px 5px 5px 5px",
    },

    warning: {
        backgroundColor: '#e0f2f1',

    },
    premium: {
        color: "white",
        backgroundColor: '#4db6ac',
        marginTop: 0,
        marginBottom: 0,
    },
};

const TopMenu = withStyles(styles)(
    ({classes}) => <div className={classes.root}>
        <Warning classes={classes}/>
        <Premium classes={classes}/>
    </div>
);

export default withStyles(styles)(TopMenu);
