import {AccountBox, AddBox, CalendarToday, Subject} from "@material-ui/icons";
import {Icon, withStyles} from "@material-ui/core";
import React from "react";
import classnames from "classnames";

const styles = theme => ({
    icon: {
        fontSize: "40px",
        margin: "0 10px -8px 0px",
    },
    calenderToday: {
        marginBottom: "-5px",

    },
    grey: {
        color: "#aaa"
    }
});

const NoteIcon = ({description, classes}) => {
    switch (description) {
        case"Nieuwe afspraak":
            return <CalendarToday className={classnames(classes.icon, classes.calenderToday)}
                                  color="primary"/>;
        case"Notitie toegevoegd":
            return <Subject className={classnames(classes.icon)}
                         color="primary">check</Subject>;
        case"Nieuwe klant aangemaakt":
            return <AddBox className={classnames(classes.icon)}
                           color="primary">check</AddBox>;
        case"Klant geaccepteerd":
            return <Icon className={classnames(classes.icon, classes.grey)}
                         color="primary">check</Icon>;
        case"Test note":
            return <Icon className={classnames(classes.icon)}
                         color="primary">check</Icon>;
        case"E-mail verstuurd":
            return <Icon className={classnames(classes.icon, classes.grey)}>email</Icon>;
        case"Activatie klantportaal":
            return <AccountBox className={classnames(classes.icon)}
                               color="primary">check</AccountBox>;
        case"Klant gearchiveerd":
            return <Icon className={classnames(classes.icon)}
                         color="primary">archive</Icon>;
        case "Klant gerestaureerd":
            return <Icon className={classnames(classes.icon)}
                         color="primary">unarchive</Icon>;
        default:
            return <Icon className={classnames(classes.icon)}
                         color="primary">check</Icon>;
    }
}

export default withStyles(styles)(NoteIcon);
