import React from "react";
import {date, formatPrice, hasDescription} from "../../../../../component/Contract/common";
import If from "../../../../../component/Optional/If";
import {fullName} from "../../../../../../util/customer";

const css = hasDescription => ({

    container: {
        margin: "0 10px 0 10px",
    },

    table: {
        width: "100%",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: "10.5px",
        display: "grid",
        gridTemplateColumns: `repeat(${hasDescription ? 3 : 2}, 1fr)`,
    },

    header: {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: "400",
        fontSize: "13px"
    },
    body: {
        display: "grid",
        gridTemplateColumns: `repeat(${hasDescription ? 3 : 2}, 1fr)`,

    },
    row: {},
    cell: {
        padding: "15px 0 15px 15px",
        borderBottom: "solid",
        borderColor: "rgb(224, 224, 224)",
        borderWidth: "thin",

    },
    label: {
        display: "block",
        color: "rgba(0, 0, 0, 0.54)",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: "14px",
        margin: "10px 0 10px 0",
    }
    ,
    choiceLabel: {
        margin: "0 5px 0 5px",
    }
});

const ConfirmationNote = ({order, customer}) => {

    const style = css(hasDescription(order.items));

    return (
        <div style={style.container}>
            De klant heeft de algemene voorwaarden geaccepteerd en de opdracht tot '{order.description}' ondertekend

            <br/>

            <h5 style={style.header}>Bestelling aangemaakt op {date(order)}</h5>

            <div style={style.table}>
                <div key={1}
                     style={style.cell}>Dienst
                </div>
                <div key={2}
                     style={style.cell}>Prijs
                </div>
                <If key={3}
                    show={hasDescription(order.items)}>
                    <div style={style.cell}
                         key={3}>Uitleg
                    </div>
                </If>
                {
                    order.items.map((item, index) => [

                                        <div key={`1-${index}`}
                                             style={style.cell}>
                                            {item.name}
                                        </div>,
                                        <div key={`2-${index}`}
                                             style={style.cell}>
                                            {formatPrice(item.price)}
                                        </div>,
                                        <If key={`3-${index}`}
                                            show={hasDescription(order.items)}>
                                            <div key={3}
                                                 style={style.cell}>{item.description}</div>
                                        </If>
                                    ]
                    )}
            </div>

            <h5 style={style.header}> Opdracht ondertekend op {date(order.confirmedAt)}</h5>

            <label style={style.label}>Akkoord met de algemene voorwaarden</label>

            <input type="radio"
                   id="1"
                   name="Ja"
                   checked={true}
                   readOnly={true}
                   value="Ja"/>
            <label style={style.choiceLabel}
                   htmlFor="Ja">Ja
            </label>

            <input type="radio"
                   id="2"
                   name="Nee"
                   checked={false}
                   readOnly={true}
                   value="Nee"/>
            <label style={style.choiceLabel}
                   htmlFor="Nee">Nee
            </label>

            <label style={style.label}>Handtekening {fullName(customer)}</label>

            <img src={order.signature}
                 alt="ondertekening"/>

        </div>
    );
};

export default ConfirmationNote;
