/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 20-12-17.
 */

import React from "react";
import {connect} from "react-redux";
import {currentCustomer, documents} from "../../../../../state/selector/customer";
import securePage from "../../../SecurePage/SecurePage";
import {Card, CardContent, Grid, withStyles} from "@material-ui/core";
import LoggedOn from "../../../../component/Main/Base/LoggedOn/LoggedOn";
import columnStyles from "../../../../../style/columnStyles";
import withWidth from "@material-ui/core/withWidth/withWidth";
import singleColumn from "../util/singleColumn";
import DocumentUpload from "../../../../component/Documents/DocumentUpload";
import SmallCardHeader from "../../../../component/Card/SmallCardHeader";
import {customerClient} from "../../../../../api/relation/customer/CustomerClient";
import currentCustomerAction from "../../../../../action/creator/asynchonous/customer/currentCustomer";
import {roles} from "../../../../../api/login/LoginClient";
import MainPrivateCustomer from "../components/main/item/main/MainPrivateCustomer";
import GeneralInfo from "../components/main/item/GeneralInfo";
import RealEstate from "../components/main/item/RealEstate";
import {postClient} from "../components/main/postCustomer";
import Vehicles from "../components/main/item/Vehicles";
import {compose} from "redux";

const PortalPrivateCustomer = ({customer, documents, width, onAccept, classes}) => {

    return <LoggedOn user={true}
                     portal={true}>

        {/*<Tasks customer={customer}/>*/}

        <Grid item
              className={singleColumn(width) ? classes.singleColumn : classes.leftColumn}
              sm={12}
              md={6}>

            <MainPrivateCustomer customer={customer}
                                 classes={classes}
                                 onAccept={onAccept}
                                 usedByCustomer={true}
            />
            <GeneralInfo customer={customer}/>
        </Grid>
        <Grid item
              className={singleColumn(width) ? classes.singleColumn : classes.rightColumn}
              sm={12}
              md={6}>
            <RealEstate postCustomer={postClient}
                        customer={customer}/>
            <Vehicles postCustomer={postClient}
                      customer={customer}/>
            <Card>
                <CardContent>
                    <SmallCardHeader title="Documenten"/>
                    <DocumentUpload/>
                </CardContent>
            </Card>
        </Grid>
    </LoggedOn>;
};

const mapStateToProps = state => ({
    customer: currentCustomer(state),
    documents: documents(state),
});

const mapDispatchToProps = dispatch => ({
    onAccept:
        customer =>
            () =>
                customerClient.accept(customer)
                              .then(() => customerClient.fetchCustomer(customer.id))
                              .then(currentCustomerAction)
                              .then(dispatch),
})

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    securePage([roles.admin,
                roles.portalUser]),
    withStyles(columnStyles),
    withWidth()
)(PortalPrivateCustomer);

