import {logResult, throwErrors} from "./util";

/**
 * @param {object} cache
 * @returns {function(string): Promise<object>}
 */
const get = serviceUrl => {

    if (global.LOG_API_CALLS) {
        let text = "\n";
        text += `GET ${serviceUrl}\n`;
        console.log(text);
        text += "### [get()]\n";
        console.debug(text);
    }

    return fetch(serviceUrl, {
        method:  "get",
        headers: {
            // "Accept":       "application/json",
            // "Content-Type": "application/json",
        },
    }).then(throwErrors)
      .then(logResult(`[get()] Retrieved data from '${serviceUrl}'`, global.LOG_API_CALLS));
};

/**
 * @param {object} cache
 * @returns {function(string): Promise<object>}
 */
const getFile = (cache = {}) => serviceUrl => {
    const key = serviceUrl;

    if (global.LOG_API_CALLS) {
        let text = "\n +++++++++++++++++++++++++++++++++";
        text += `GET ${serviceUrl}\n`;
        console.debug(text);
        text += "Accept: application/pdf\n"
        text += `Content-Type: application/json\n`;
        text += "\n";
        text += "### [getFile()]\n";
        console.log(text);
    }

    if (!cache.hasOwnProperty(key)) {
        cache[key] = fetch(serviceUrl, {
            method:       "get",
            headers:      {
                "Accept":       "application/pdf",
                "Content-Type": "application/json",
            },
            responseType: 'arraybuffer',
        })
    }
    return cache[key]
        .then(throwErrors)
        .then(logResult(`[getFile()] Retrieved file from '${serviceUrl}'`, global.LOG_API_CALLS));

};

/**
 * @param {object} cache
 * @returns {function(string): Promise<object>}
 */
const getJson = (cache = {}) => serviceUrl => {
    const key = serviceUrl;

    if (global.LOG_API_CALLS) {
        let text = "\n";
        text += `GET ${serviceUrl}\n`;
        console.log(text);
        text += "Accept: application/json\n"
        text += `Content-Type: application/json\n`;
        text += "\n";
        text += "### [getJson()]\n";
        console.debug(text);
    }

    if (!cache.hasOwnProperty(key)) {
        cache[key] = fetch(serviceUrl, {
            method:  "get",
            headers: {
                "Accept":       "application/json",
                "Content-Type": "application/json",
            },
        }).then(throwErrors)
          .then(result => result.json());
    }

    return cache[key]
        .then(logResult(`[getJson()] Retrieved data from '${serviceUrl}'`, global.LOG_API_CALLS));
};

const getReq = () => serviceUrl => {

    if (global.LOG_API_CALLS) {
        let text = "\n";
        text += `GET ${serviceUrl}\n`;
        console.log(text);
        text += "Accept: application/json\n"
        text += `Content-Type: application/json\n`;
        text += "\n";
        text += "### [getReq()]\n";
        console.debug(text);
    }

    return fetch(serviceUrl, {
        method:  "get",
        headers: {
            "Accept":       "application/json",
            "Content-Type": "application/json",
        },
    }).then(throwErrors)
      .then(logResult(`[getReq()] Retrieved data from '${serviceUrl}'`, global.LOG_API_CALLS));
};


/**
 * @param {object} cache
 * @returns {function(string): Promise<object>}
 */
const getXml = (cache = {}) => serviceUrl => {
    const key = serviceUrl;

    if (global.LOG_API_CALLS) {
        let text = "\n";
        text += `GET ${serviceUrl}\n`;
        console.log(text);
        text += "\n";
        text += "### [getXml()]\n";
        console.debug(text);
    }

    if (!cache.hasOwnProperty(key)) {
        cache[key] = fetch(serviceUrl, {
            method:  "get",
            headers: {},
        }).then(throwErrors)
          .then(result => result.text());
    }
    return cache[key]
        .then(logResult(`[getXml()] Retrieved data from '${serviceUrl}'`, global.LOG_API_CALLS));
};


export {
    get,
    getXml,
    getReq,
    getJson,
    getFile,
};
