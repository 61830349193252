/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 25-9-18.
 */

import React from "react";
import LoggedOn from "../../../component/Main/Base/LoggedOn/LoggedOn";
import {Card, CardContent, CircularProgress, withStyles} from "@material-ui/core";
import CardMediaHeader from "../../../component/CardMediaHeader/CardMediaHeader";
import {tasks} from "../../../../api/process/task/tasks";
import {instancePath} from "../../../paths";
import {connect} from "react-redux";
import {push} from 'react-router-redux';

import {compose} from "redux";
import {processes} from "../../../../api/process/process/processes";
import List from "./instanceList/List";
import securePage from "../../SecurePage/SecurePage";
import {roles} from "../../../../api/login/LoginClient";

const styles = theme => ({
    root: {
        width: "100%",
        borderRadius: 2
    }
});

class ProcessType extends React.Component {

    // Typical state: {
    //    searchResults: {
    //       results: [],
    //       totalResults: 0,
    //       query: {
    //           page: 1,
    //           itemsPerPage: 5,
    //           sorts: [],
    //      },
    // }
    state = {
        searchResults: null,
    };

    componentDidMount() {
        processes(this.props.match.params.id)
            .then(searchResults => this.setState({searchResults: searchResults}));
    }

    render() {

        const {onClick, classes} = this.props;
        const searchResults = this.state.searchResults;

        return <LoggedOn>
            <Card className={classes.root}>
                <CardMediaHeader title="Proces Types"
                                 image="/images/handshake.jpg"/>
                <CardContent>
                    {
                        searchResults == null
                        ? <CircularProgress/>
                        : <List processes={searchResults}
                                onClickTask={onClick}
                                onPage={page => {
                                    this.setState({searchResults: null});
                                    return tasks({...searchResults.query, page})
                                        .then(searchResults => this.setState({searchResults: searchResults}));
                                }}/>
                    }

                </CardContent>
            </Card>
        </LoggedOn>
    }
}

const dispatchToHandlers = dispatch => ({
    onClick: process => () => dispatch(push(instancePath(process.id))),
});

const decorate = compose(
    connect(null, dispatchToHandlers),
    securePage([roles.admin]),
    withStyles(styles)
);

export default decorate(ProcessType);
