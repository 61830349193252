const userToResult =
    (onClick, onAction) =>
        user =>
            ({
                id:             user.id,
                userName:       user.userName,
                firstName:      user.firstName,
                lastName:       user.lastName,
                initials:       user.initials,
                insertPart:     user.insertPart,
                gender:         user.gender,
                maritialStatus: user.maritialStatus,
                phone:          user.phone,
                nationality:    user.nationality,
                address:        user.address,
                birthDate:      user.birthDate,
                image:          user.image,
                email:          user.email,
                onClick:        () => onClick(user),
                onAction:       () => onAction(user),
            });

export {
    userToResult,
}
