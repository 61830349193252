/**
 * We need to convert values like '€ 1.000,00' to the corresponding integer 100000 (the value in eurocents)
 * ie throwing away all non-digit will do the job
 *
 * @param {string} value
 */
const itemPrice = value => value.replaceAll(/\D/g, "")

const itemDescription = fieldName => {
    switch (fieldName) {
        case "toeslag_plus":
            return "€ 115,- per extra onderneming";
        case "aanvullend_9":
            return "1% van de verzekerde som";
        default:
            return "";
    }
}

/**
 *
 * @param question
 * @returns {Item}
 */
const item = question => ({
    name: question.description,
    description: itemDescription(question.field),
    price: itemPrice(question.value),
});

const items = form => form.groups[0]
    .questions
    .filter(question => question.field
                        !== "aanvraag_totaal")   // make sure legacy field is ignored (legacy field should be removed, but for now...)
    .filter(question => question.field
                        !== "bankgarantie_hypotheeksom")   // This is only asked in order to facilitate calculating the bankgarantie value, not actually part of the order
    .filter(question => question.type === "EURO"
                        && question.visible
                        === true)
    .map(item);

const orderDescription = form => form.groups[0]
    .questions
    .filter(question => question.field === "bestelling")
    .map(question => question.value)
    .reduce((acc, val) => acc + val, "");

/**
 *
 * @param form
 * @returns {Order}
 */
const order = form => ({
    description: orderDescription(form),
    items: items(form),
})

export default order;
