import React from "react";
import PropTypes from 'prop-types';
import {Drawer, Hidden, withStyles} from "@material-ui/core";

const styles = theme => ({
    sideMenuBar: theme.drawerPaper
});

/**
 * A permanent menu on regular screens, or an auto-hide menu on mobile screens
 * @param classes
 * @param children
 * @param open
 * @param onToggle
 * @return Component
 */
const MenuDrawer = ({classes, children, open, onToggle, user}) => (
    <div>
        <Hidden smDown>
            <Drawer className={classes.sideMenuBar}
                    variant="permanent"
                    anchor="left"
            >
                {children}
            </Drawer>
        </Hidden>

        <Hidden mdUp>
            <Drawer className={classes.sideMenuBar}
                    variant="temporary"
                    anchor={'left'}
                    open={open}
                    onClose={onToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}>
                {children}
            </Drawer>
        </Hidden>
    </div>);

MenuDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuDrawer);
