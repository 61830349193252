/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 3-6-19.
 */
/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 27-5-19.
 */
import React from 'react';

import Promise from "bluebird";
import BpmnJs from "bpmn-js/dist/bpmn-navigated-viewer.development"
import {Card, CardContent, withStyles} from "@material-ui/core";

const styles = theme => ({
    canvas: {height: "400px"}
});

class BpmnDiagram extends React.Component {

    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
    }

    componentWillUnmount() {
        if (this.viewer) {
            this.viewer.destroy();
        }
    }

    render() {

        const {xml, activeIds} = this.props;
        if (xml) {
            if (this.viewer) {
                this.viewer.destroy();
            }

            Promise.delay(100)
                   .then(() => {
                       // Calling this block during the next tick makes sure render() has
                       // finished (and therefore rendered the container), so that the
                       // container can be  referenced
                       this.viewer = new BpmnJs({container: this.containerRef.current});
                       this.viewer.importXML(xml, error => {
                           if (error) {
                               console.log(xml);
                               console.error(error);
                               return;
                           }

                           const canvas = this.viewer
                                              .get('canvas');
                           canvas.zoom('fit-viewport');
                           activeIds.forEach(id => canvas.addMarker(id, 'highlight'));
                       });
                   });
        }
        return (
            <Card>
                <CardContent>

                <div id="canvas"
                     className={this.props.classes.canvas}
                     ref={this.containerRef}/>
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(BpmnDiagram);
