import React from "react";
import {Icon, TableCell, TableRow} from "@material-ui/core";
import classnames from "classnames";

class SearchResult extends React.Component {

    state = {
        hover: false,
    };

    render() {

        const {
            result, onDelete, action, actionIcon, customerClasses, classes
        } = this.props;

        // console.log(`${result.id}: ${this.state.hover ? "BINGO" : ""}`);

        return (
            <TableRow className={classnames(this.state.hover ? classes.selection : "")}
                      onMouseOver={_ => this.setState({hover: true})}
                      onMouseOut={_ => this.setState({hover: false})}
                      key={result.id}>
                <TableCell className={classes.icon}
                           title={`Bekijk ${result.name}`}
                           onClick={result.onClick}>
                    <Icon color="primary">{result.type === "CLIENT" ? "person" : "business"}</Icon>
                </TableCell>
                <TableCell className={classnames(customerClasses(result), classes.icon)}
                           title={`Bekijk ${result.name}`}
                           onClick={result.onClick}>{result.name}</TableCell>
                <TableCell className={classnames(customerClasses(result), classes.icon)}
                           title={`Bekijk ${result.name}`}
                           onClick={result.onClick}>{result.address}</TableCell>
                <TableCell className={classnames(customerClasses(result), classes.icon)}
                           title={`Bekijk ${result.name}`}
                           onClick={result.onClick}>{result.date}</TableCell>
                <TableCell className={classnames(customerClasses(result), classes.icon)}
                           onClick={result.onClick}>
                    <Icon color="primary">remove_red_eye</Icon>
                </TableCell>
                <TableCell className={classes.icon}
                           title={typeof action === "function" ? `${action(result)} ${result.name}` : `${action} ${result.name}`}
                           onClick={result.onAction}>
                    <Icon color="primary">{typeof actionIcon === "function" ? actionIcon(result) : actionIcon}</Icon>
                </TableCell>
                {
                    onDelete ? <TableCell className={classes.icon}
                                          title={"Verwijder"}
                                          onClick={() => onDelete(result)}>
                        <Icon color="primary">delete_forever</Icon>
                    </TableCell> : null
                }

            </TableRow>)
    }
}

export default SearchResult;
