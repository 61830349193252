/**
 * Created by Darryl Amatsetam <darryl@bluehorizon.nl> on 22-11-17.
 */
import {applyMiddleware, createStore} from "redux";
import mainReducer from "../reducer/mainReducer";
import {compoundDispatch} from "../middleware/compoundDispatch";
import {promiseDispatch} from "../middleware/promiseDispatch";
import {routerMiddleware} from "react-router-redux";
import {composeWithDevTools} from 'redux-devtools-extension';
import {getStateFromLocalStorage, saveStateToLocalStorage} from "../../localStorage";
import throttle from 'lodash/throttle'

const logLast = store => (current, selector) => () => {
    const active = false;
    if (active) {
        let previous = current;
        current = selector(store.getState());
        if (previous !== current) {
            console.log("PREVIOUS", JSON.stringify(previous, null, 2));
            console.log("ACTION", JSON.stringify(store.getState().lastAction, null, 2));
            console.log("RESULT", JSON.stringify(current, null, 2));
        }
    }
};

const storeFactory = history => {

    const store = createStore(
        mainReducer,
        getStateFromLocalStorage(),
        composeWithDevTools(
            applyMiddleware(compoundDispatch, promiseDispatch, routerMiddleware(history))
        )
    );

    store.subscribe(throttle(() => saveStateToLocalStorage(store.getState()),
                             250));
    let current;
    store.subscribe(logLast(store)(current, state => state.serviceForm));

    if (process.env.NODE_ENV !== "production") {
        if (module.hot) {
            module.hot.accept("../reducer/mainReducer", () => {
                console.log(new Date(), "Hotloaded the redux reducers");
                store.replaceReducer(mainReducer)
            })
        }
    }
    return store
};

export default storeFactory;
