/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 26-9-18.
 */

import React from "react";
import {TableCell, TableRow} from "@material-ui/core";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import classnames from "classnames";
import {withStyles} from "@material-ui/core/index";


const styles = theme => ({
    tableRow: {
        cursor: "pointer",
    },
    selection: {
        backgroundColor: "#fcfce7",
    }
});

class Row extends React.Component {

    state = {
        anchorEl: null,
        hover: false,
    };

    handleClick = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    render() {
        const {anchorEl} = this.state;
        const {process, onClick, classes} = this.props;

        return (
            <TableRow className={classnames(classes.tableRow, this.state.hover ? classes.selection : "")}
                      onMouseOver={_ => this.setState({hover: true})}
                      onMouseOut={_ => this.setState({hover: false})}>
                <TableCell onClick={onClick}>{process.key}</TableCell>
                <TableCell onClick={onClick}>{process.id}</TableCell>
                <TableCell>
                    <IconButton onClick={this.handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={this.handleClose}>
                        <MenuItem onClick={onClick}>Bekijken</MenuItem>
                        <MenuItem onClick={onClick}>Sluiten</MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
        )
    }
}

export default withStyles(styles)(Row);
