/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 18-7-18.
 */

/**
 * Calculates a true  'n mod m'
 * (i.e. a number in [0, m), whereas the javascript 'modulo' operator '%' actually
 *       returns the remainder: n % m = n - m(n / m),  making: -7 % 3 = -1)
 * @param n
 * @param m
 * @return {number}
 */
const modulo = (n, m) => ((n % m) + m) % m;

/**
 * Increase n by one (wrapping around when exceeding max)
 *
 * @param {number} n
 * @param {number} max
 * @return {number}
 */
const increase = (n, max) => modulo(n + 1, max);

/**
 * Increase n by one (wrapping around when falling below zero)
 *
 * @param {number} n
 * @param {number} max
 * @return {number}
 */
const decrease = (n, max) => modulo(n - 1, max);


export {
    modulo,
    increase,
    decrease,
}
