/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-7-19.
 */
import React from "react";
import Promise from "bluebird";
import {setInstanceVariable} from "../../../../../../api/process/process/processes";
import {Forms} from "../../../../../../api/form/FormClient";
import QuestionForm from "../../../../../component/QuestionForm/smart/QuestionForm";
import {retrieveForm} from "../../../../../../action/creator/asynchonous/form/retrieveForm";
import {compose} from "redux";
import {connect} from "react-redux";
import {Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMediaHeader from "../../../../../component/CardMediaHeader/CardMediaHeader";
import {push} from "react-router-redux";
import {paths} from "../../../../../paths";
import insuranceProcessClient from "../../../../../../api/process/insuranceProcess/InsuranceProcessClient";
import Anchor from "../../../../../component/Anchor/Anchor";
import nop from "../../../../../../action/creator/nop";
import Documents from "./Documents";
import {closeTask} from "../../../../../../api/process/task/tasks";
import {promiseAction} from "../../../../../../middleware/promiseDispatch";
import {getAcceptedIdentifier, getMotivationIdentifier} from "../identifiers";
import {getAssessedFileQuestions, getFileQuestions, getUnApprovedFileQuestions} from "../fileQuestions";
import getData from "../getData";
import {getQuestionValue} from "../../../../../../util/form";

const filterDocuments = (documents, type) => documents.filter(({documentType}) => documentType === type);

/**
 *
 * @param {object} object
 * @return {number}
 */
const size = object => Object.keys(object).length;

/**
 * Task corresponding to the step 'Beoordeel aanvraag' in the  DOCUMENTED_REQUEST process
 *
 * It should present the user with all relevant information in the request, and allow the user to accept or decline the request
 *
 */
class AssessDocuments extends React.Component {

    state = {
        instance: null,
        agent: null,
        customer: null,
        request: null,
        variables: null,
        documents: null,
    };

    componentDidMount() {
        const {task} = this.props;
        const {processInstanceId} = task;
        getData(this)(processInstanceId);
        this.props.loadForm();
    }

    render() {

        const {task} = this.props;
        const {request, documents, variables} = this.state;

        let typeDocuments = [];
        let documentType = "";
        let approve = form => nop;

        if (typeof request !== "undefined" && request !== null && typeof documents !== "undefined" && documents !== null) {
            let unapprovedFileQuestions = getUnApprovedFileQuestions(request, variables);

            if (Object.entries(unapprovedFileQuestions).length === 0) {
                console.error("Failed invariant: all documents approved in AssesDocuments");
            }
            const [identifier, field] = Object.entries(unapprovedFileQuestions)[0];
            documentType = field.value;

            const isLastUnapproved = size(getUnApprovedFileQuestions(request, variables)) <= 1;
            const isLastUnAssessed = size(getFileQuestions(request)) - size(getAssessedFileQuestions(request, variables)) <= 1;

            typeDocuments = filterDocuments(documents, documentType);

            approve = form => {
                const setVariablesPromise =
                    getQuestionValue(form)("document_geaccepteerd") === "Ja"
                    ? Promise.all(
                        [
                            setInstanceVariable(task.processInstanceId)("Boolean", "allDocumentsAssessed", isLastUnAssessed),
                            setInstanceVariable(task.processInstanceId)("Boolean", "allDocumentsAccepted", isLastUnapproved),
                            setInstanceVariable(task.processInstanceId)("Boolean", getAcceptedIdentifier(identifier), true),

                        ])
                    : Promise.all(
                        [
                            setInstanceVariable(task.processInstanceId)("Boolean", "allDocumentsAssessed", isLastUnAssessed),
                            setInstanceVariable(task.processInstanceId)("Boolean", "allDocumentsAccepted", false),
                            setInstanceVariable(task.processInstanceId)("Boolean", getAcceptedIdentifier(identifier), false),
                            setInstanceVariable(task.processInstanceId)(
                                "String",
                                getMotivationIdentifier(identifier),
                                getQuestionValue(form)("document_motivatie")),
                        ]);
                return promiseAction(setVariablesPromise
                                         .then(_ => closeTask(task))
                                         .then(_ => push(paths.task)));
            };
        }

        if (this.state.instance && this.state.agent) {

            return [

                <Card key={1}>
                    <CardMediaHeader title={`Taak ${task ? task.name : ""}`}
                                     image="/images/handshake.jpg"/>

                    <Typography variant="h5">
                        {typeDocuments.length === 1 ? "Document" : "Documenten"} {documentType}</Typography>
                    <Documents documents={typeDocuments}/>,
                </Card>,

                <Card key={2}>
                    <QuestionForm endpoint={Forms.documentedRequestDocumentApproval}
                                  submitAction={approve}/>
                </Card>,
                <Card key={3}>
                    <Typography variant="h5">Process</Typography>
                    Deze taak maakt deel uit van een <Anchor href={`/proces/${task.processInstanceId}`}>process</Anchor>
                </Card>
            ];
        }
        return <div/>;
    }
}

const mapDispatchToProps = dispatch => ({
    back: task => () => dispatch(push(paths.task)),
    completeTask: task => () => insuranceProcessClient.completeTask(task.id),
    loadForm: () => dispatch(retrieveForm(Forms.documentedRequestDocumentApproval)),
});

const decorate = compose(
    connect(null, mapDispatchToProps)
);

export default decorate(AssessDocuments);
