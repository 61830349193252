import Dropzone from "react-dropzone";
import {userClient} from "../../../../../api/relation/identity/user/UserClient";
import React from "react";
import {withStyles} from "@material-ui/core";
import * as PropTypes from "prop-types";

const styles = theme => ({
    image: {
        width: "40%",
    },

    // Dropzone styles (copy of the styles in the dropzone package, adapted to the component)
    rejectedDropzone: {
        borderStyle:     'solid',
        borderColor:     '#c66',
        backgroundColor: '#eee',
    },
    disabledDropzone: {
        opacity: 0.5,
    },
    activeDropzone:   {
        borderStyle:     'solid',
        borderColor:     '#6c6',
        backgroundColor: '#eee',
    },
    defaultDropzone:  {
        width:        "100%",
        height:       "auto",
        borderWidth:  1,
        borderColor:  '#666',
        borderStyle:  'dashed',
        borderRadius: 5,
        marginTop:    "40px",
        padding:      "25px 25px 25px 25px",
    },

});

class ProfilePhoto extends React.Component {

    render() {
        const {user, updateUser, classes} = this.props;

        return [
            <img src={user.imageUrl} alt="profielfoto"
                 key={1}
                 className={classes.image}
            />,
            <Dropzone className={classes.defaultDropzone}
                      activeClassName={classes.activeDropzone}
                      rejectClassName={classes.rejectedDropzone}
                      key={2}
                      onDrop={files => {

                          if (Array.isArray(files) && files.length > 0) {
                              if (files.length > 1) {
                                  console.log("Expected only one file, got `" +
                                              files.length +
                                              "'");
                              }

                              const file = files[0];
                              const reader = new FileReader();
                              reader.onloadend = () => {
                                  const dataURL = reader.result;
                                  userClient.setImage(user, dataURL)
                                            .then(result => result.entity)
                                            .then(updateUser);
                              };
                              reader.readAsDataURL(file);

                          }

                      }}>
                <div>Sleep een nieuwe profielfoto naar dit kader, of klik
                    om een bestand te selecteren.
                </div>
            </Dropzone>,
        ];
    }
}

ProfilePhoto.propTypes = {
    user:       PropTypes.any,
    updateUser: PropTypes.any,
    classes:    PropTypes.any,
}

export default withStyles(styles)(ProfilePhoto);
