import React from "react";
import {withStyles} from "@material-ui/core";
import {Forms} from "../../../api/form/FormClient";
import BankgarantieProposal from "./BankgarantieProposal";
import DefaultProposal from "./DefaultProposal";
import BlissProposal from "./BlissProposal";

const styles = theme => ({
    orders: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: "30px"
    }
});


const formDescription = formType => {
    switch (formType) {
        case Forms.proposalBav:
            return "Offerte Beroepsaansprakelijkheids verzekering";
        case Forms.proposalHoreca:
            return "Offerte Horeca verzekering";
        case Forms.proposalVerzuim:
            return "Offerte Verzuim verzekering";
        case Forms.garantiestelling:
            return "Aanvraag Bankgarantie";
        case Forms.blissHypotheek:
            return "Offerteaanvraag";

        default:
            return `Offerte ${formType} verzekering`;
    }
};

const component = proposal => {
    switch (proposal.formType) {
        case Forms.garantiestelling:
            return <BankgarantieProposal proposal={proposal}
                                         formDescription={formDescription(proposal.formType)}/>;

        case Forms.blissHypotheek:
            return <BlissProposal proposal={proposal}
                                  formDescription={formDescription(proposal.formType)}/>;

        default:
            return <DefaultProposal proposal={proposal}
                                    formDescription={formDescription(proposal.formType)}/>;
    }
}

class Proposals extends React.Component {

    render() {

        const {proposals = [], classes} = this.props;
        return (
            <div className={classes.orders}>
                {
                    proposals.map((proposal, index) => component(proposal))
                }
            </div>
        )
    }
}

export default withStyles(styles)(Proposals);


