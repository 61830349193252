/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 22-11-17.
 */

import * as React from "react";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    Icon,
    Typography,
    withStyles,
} from "@material-ui/core";
import {withRouter} from "react-router";
import {push} from 'react-router-redux';
import {connect} from "react-redux";
import {compose} from "redux";
import {Field, reduxForm} from "redux-form";
import {getLoginActions} from "../../../action/creator/asynchonous/login";
import LoginField from "./LoginField";
import throwSubmissionError from "../../../util/throwSubmissionError";
import paths from "../../paths";
import initAction from "../../../action/creator/initAction";
import loginErrorAction from "../../../action/creator/loginErrorAction";
import Anchor from "../Anchor/Anchor";

const styles = theme => ({
    loginButton: {
        marginTop: 20,
        float:     "right",
        color:     "white",
    },
});

const LoginForm = ({onLogin, handleSubmit, onForgetPassword, onPartnerAccount, classes, loginLoading}) => {
    return (
        <Grid item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}>
            <div className="LoginForm">
                <Card className="login-card">
                    <CardHeader className="login-card-title"
                                title="Inloggen Mijn Bliss"/>
                    <CardContent className="login-card-content">
                        <form id="login-form"
                              onSubmit={handleSubmit(onLogin)}>
                            <Field name="email"
                                   id="email"
                                   component={LoginField(classes)}
                                   placeholder="Gebruikersnaam"
                                   icon="account_circle"/>

                            <br/>
                            <Field name="password"
                                   id="password"
                                   password={true}
                                   component={LoginField(classes)}
                                   placeholder="Wachtwoord"
                                   icon="lock"/>
                            <Button variant="contained"
                                    color="primary"
                                    className={classes.loginButton}
                                    type="submit">Inloggen <Icon>arrow_forward</Icon>
                            </Button>
                            {loginLoading && <CircularProgress className={classes.progress}
                                                               thickness={6}/>}
                        </form>
                        <br/>
                        <br/>
                        <br/>
                        <Typography variant="subtitle1">
                            <Anchor href="#!"
                                    onClick={onForgetPassword}>Wachtwoord vergeten
                            </Anchor>
                        </Typography>

                        <Typography variant="subtitle1">
                            <Anchor href="https://www.blisshypotheekadviseurs.nl/algemene-voorwaarden/">Algemene
                                voorwaarden
                            </Anchor>
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </Grid>);
};

const mapDispatcherToProps = dispatch => ({
    onLogin:          ({email, password}) => {
        dispatch(initAction);
        return getLoginActions(email, password).then(dispatch)
                                               .catch(error => {
                                                   dispatch(loginErrorAction);
                                                   throwSubmissionError("Inloggen mislukt")(error);
                                               })
    },
    onForgetPassword: email => dispatch(push(paths.passwordReminder)),
    onPartnerAccount: () => dispatch(push(paths.partnerAccount)),
});

export default compose(
    reduxForm(
        {
            form: 'loginForm',
        }),
    connect(state => ({loginLoading: state.loginLoading}), mapDispatcherToProps),
    withRouter,
    withStyles(styles),
)(LoginForm);

