import RelationClient from "../../RelationClient";
import {parseMulti} from "../../../../app/page/identity/User/View/role";

class UserClient {

    createJsonForNewUser(form) {
        let data = {};
        form.groups.forEach(function (group) {
            group.questions.forEach(function (question) {
                let field = question.field;

                if (field === "roles") {
                    data.roles = parseMulti(JSON.parse(question.value))
                } else {
                    data[field] = question.value;
                }

            });
        });
        return data;
    }

    /**
     * Returns a promise that will resolve once the User has been added the the store of Users.
     *
     * @param {object} form
     * @return {Promise<void>}
     */
    add(form) {
        let formJson = userClient.createJsonForNewUser(form);
        return RelationClient.putData(RelationClient.user, formJson);
    }

    /**
     * Returns a promise that will resolve once the store has updated the User
     *
     * @param {object} form
     * @return {Promise<object>}
     */
    update(user) {
        return RelationClient.postData(RelationClient.user, user);
    }

    delete(id) {
        return RelationClient.deleteData(RelationClient.user + "/" + id);
    }

    setImage(user, dataUrl) {
        return RelationClient.putData(`${RelationClient.user}image/${user.userName}/`, dataUrl);
    }

    /**
     *
     * @param (string) roleName
     */
    usersByRole(roleName) {
        return RelationClient.getData(`${RelationClient.user}roles/${roleName}/`)
                             .then(response => response.entity)
    }

}

const userClient = new UserClient();

export default UserClient;
export {
    userClient,
}
