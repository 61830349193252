/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-12-17.
 */
import FieldError from "./FieldError";
import KeycloakClient from "./keycloak/KeycloakClient";
import RelationClient from "../relation/RelationClient";
import decode from 'jwt-decode';
import darryl from "./image/darryl";
import marcel from "./image/marcel";
import peter from "./image/peter";
import marijn from "./image/marijn";
import richard from "./image/richard";
import alwin from "./image/alwin";
import yvonne from "./image/yvonne";
import {customerClient} from "../relation/customer/CustomerClient";
import michelle from "./image/michelle";
import patrick from "./image/patrick";
import samantha from "./image/samantha";
import wilco from "./image/wilco";
import wendy from "./image/wendy";
import tiffany from "./image/tiffany";
import alma from "./image/alma";
import sander from "./image/sander";
import {enhanceUser} from "../../util/profilePicture";

const useDummyLogin = () => `${process.env.REACT_APP_ENVIRONMENT}` === "create_dummy_data";

const getLoginData = (password, email) => {
    const loginData = new URLSearchParams();
    loginData.append('client_secret', KeycloakClient.getClientSecret());
    loginData.append('password', password);
    loginData.append('grant_type', 'password');
    loginData.append('client_id', KeycloakClient.getClientId());
    loginData.append('username', email);

    return loginData;
};

const roles = {
    portalUser:  "PORTAL_USER",
    eerste_lijn: "role_eerste_lijn",
    admin:       "role_admin",
    makelaar:    "role_makelaar",
    adviseur:    "role_apotheek_adviseur",
    notaris:     "role_notaris",
}

/**
 * If the request is not successfull the result should be processed as a thrown error
 * (Assuming the keycloak server sets a proper header, this is probably already handled by the throwErrors method in
 * HTTP.util.js)
 *
 * @param {Response} response
 * @return object
 * @throws Error
 */
const throwErrors = response => {
    if (response.error) {
        console.error("This message is UNEXPECTED!!!");
        throw new FieldError("email", response.error_description);
    }
    return response;
};

const getRoles = (token) => token.realm_access.roles.filter(role => role.startsWith("role_"));

const createLogin =
    token =>
        data =>
            ({
                id:       data.id,
                email:    data.email,
                name:     token.name,
                userName: token.preferred_username,
                roles:    getRoles(token),
                admin:    getRoles(token).includes(roles.admin),
                image:    data.image,
                imageUrl: data.imageUrl,
                fullName: data.fullName,
            });

class LoginClient {
    static logOn(email, password) {

        return KeycloakClient.postJson(KeycloakClient.createTokenUrl(),
                                       KeycloakClient.getTokenHeader(),
                                       getLoginData(password, email))
                             .then(throwErrors)
                             .then(loginResponse => decode(loginResponse.access_token))
                             .then(token => RelationClient.getData("user/" + token.preferred_username + "/")
                                                          .then(response => response.entity)
                                                          .then(user => enhanceUser(user, 256))
                                                          .then(userData => createLogin(token)(userData)),
                             )
                             .catch(error => {
                                 console.error(error);
                                 console.error("Login Problem",
                                               error.response.error_description);
                                 throw new FieldError("email",
                                                      error.response.error_description);
                             })
    }

    static forgotPassword(email) {
        return RelationClient.postData(RelationClient.user + "forgotPassword/" + email + "/", null)
                             .then(throwErrors)
                             .catch(error => {
                                 console.error("Login Problem", error);
                             })
    }
}

class DummyLoginClient {
    static logOn(email, password) {

        return Promise.resolve({
                                   email,
                                   password,
                               }).then(({email, password}) => {

                                           let accepted = false;
                                           switch (email) {

                                               case "vanderwerf@bluehorizon.nl":
                                                   if (password === 'wosgibdurzyebvo') {
                                                       accepted = true;
                                                   }
                                                   break;
                                               case "vanderhorst@bluehorizon.nl":
                                                   if (password === 'kogakbikgurnuda') {
                                                       accepted = true;
                                                   }

                                                   break;
                                               case "alwin@blisshypotheekadviseurs.nl":
                                                   if (password === 'ibvertyirryipvu') {
                                                       accepted = true;
                                                   }
                                                   break;
                                               case "yvonne@erasmusverzekeringen.nl":
                                                   if (password === 'hiwuhyrojemcofy') {
                                                       accepted = true;
                                                   }
                                                   break;
                                               case "michelle@preface.nl":
                                                   if (password === 'dovoifpowidfav') {
                                                       accepted = true;
                                                   }
                                                   break;

                                               case "patrick@blisshypotheekadviseurs.nl":
                                                   if (password === 'sondarciockagra') {
                                                       accepted = true;
                                                   }
                                                   break;

                                               case "samantha@blisshypotheekadviseurs.nl":
                                                   if (password === 'birsafokgufdava') {
                                                       accepted = true;
                                                   }
                                                   break;

                                               case "wilco@blisshypotheekadviseurs.nl":
                                                   if (password === 'yuofpennikuchan') {
                                                       accepted = true;
                                                   }
                                                   break;

                                               case "wendy@blisshypotheekadviseurs.nl":
                                                   if (password === 'fracbolfuajbihi') {
                                                       accepted = true;
                                                   }
                                                   break;

                                               case "alma@blisshypotheekadviseurs.nl":
                                                   if (password === 'rilefdeifbudcan') {
                                                       accepted = true;
                                                   }
                                                   break;

                                               case "tiffany@blisshypotheekadviseurs.nl":
                                                   if (password === 'ulyafwemlajrita') {
                                                       accepted = true;
                                                   }
                                                   break;

                                               case "jeroen@blisshypotheekadviseurs.nl":
                                                   if (password === 'barbibrantyorrip') {
                                                       accepted = true;
                                                   }
                                                   break;

                                               case "sander@blisshypotheekadviseurs.nl":
                                                   if (password === 'fraccardijceetir') {
                                                       accepted = true;
                                                   }
                                                   break;

                                               default:
                                                   throw new FieldError("email", "Gebruiker bestaat niet");
                                           }

                                           if (!accepted) {
                                               throw new FieldError("password", "Ongeldig wachtwoord");
                                           }

                                           switch (email) {
                                               case "darryl@bluehorizon.nl":
                                                   return {
                                                       email,
                                                       name:  "Darryl Amatsetam",
                                                       image: darryl,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };
                                               case "vanderwerf@bluehorizon.nl":
                                                   return {
                                                       email,
                                                       name:  "Marcel van der Werf",
                                                       image: marcel,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };
                                               case "terborg@bluehorizon.nl":
                                                   return {
                                                       email,
                                                       name:  "Peter ter Borg",
                                                       image: peter,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };
                                               case "mmoerman@schreuderverzekert.nl":
                                                   return {
                                                       email,
                                                       name:  "Marijn Moerman",
                                                       image: marijn,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };

                                               case "vanderhorst@bluehorizon.nl":
                                               case "richard@bluehorizon.nl":
                                                   return {
                                                       email,
                                                       name:  "Richard van der Horst",
                                                       image: richard,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };

                                               case "alwin@blisshypotheekadviseurs.nl":
                                                   return {
                                                       email,
                                                       name:  "Alwin Langerak",
                                                       image: alwin,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };

                                               case "yvonne@erasmusverzekeringen.nl":
                                                   return {
                                                       email,
                                                       name:  "Yvonne Langerak",
                                                       image: yvonne,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };
                                               case "michelle@preface.nl":
                                                   return {
                                                       email,
                                                       name:  "Michelle van der Horst",
                                                       image: michelle,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };

                                               case "patrick@blisshypotheekadviseurs.nl":
                                                   return {
                                                       email,
                                                       name:  "Patrick Hendriks",
                                                       image: patrick,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };

                                               case "samantha@blisshypotheekadviseurs.nl":
                                                   return {
                                                       email,
                                                       name:  "Samantha Mater",
                                                       image: samantha,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };

                                               case "wilco@blisshypotheekadviseurs.nl":
                                                   return {
                                                       email,
                                                       name:  "Wilco Aalbers",
                                                       image: wilco,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };

                                               case "wendy@blisshypotheekadviseurs.nl":
                                                   return {
                                                       email,
                                                       name:  "Wendy van den Arend",
                                                       image: wendy,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };

                                               case "alma@blisshypotheekadviseurs.nl":
                                                   return {
                                                       email,
                                                       name:  "Alma Zukic",
                                                       image: alma,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };

                                               case "tiffany@blisshypotheekadviseurs.nl":
                                                   return {
                                                       email,
                                                       name:  "Tiffany van der Wurff",
                                                       image: tiffany,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };

                                               case "jeroen@blisshypotheekadviseurs.nl":
                                                   return {
                                                       email,
                                                       name:  "Jeroen Slotboom",
                                                       image: alwin,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };

                                               case "sander@blisshypotheekadviseurs.nl":
                                                   return {
                                                       email,
                                                       name:  "Sander op 't Hof",
                                                       image: sander,
                                                       admin: true,
                                                       roles: [roles.admin],
                                                   };

                                               default:
                                                   return customerClient.authenticate(email, password).then(customer => ({
                                                                                                                email: customer.entity.email,
                                                                                                                name:  `${customer.entity.firstName} ${customer.entity.lastName}`,
                                                                                                                image: "",
                                                                                                                admin: false,
                                                                                                                roles: [roles.portalUser],
                                                                                                                id:    customer.entity.id,
                                                                                                            }),
                                                   )

                                           }

                                       },
        )
    }

    static forgotPassword(email) {
        return Promise.resolve();
    }
}

export default (useDummyLogin && false)? DummyLoginClient : LoginClient;

export {
    LoginClient,
    DummyLoginClient,
    roles,
}
