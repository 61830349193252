import React from "react";
import {connect} from "react-redux";
import {currentOrganization} from "../../../../../state/selector/organization";
import securePage from "../../../SecurePage/SecurePage";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    Grid,
    Icon,
    Typography,
    withStyles,
} from "@material-ui/core";
import LoggedOn from "../../../../component/Main/Base/LoggedOn/LoggedOn";
import {compose} from "redux";
import organizationCardStyles from "./organizationCardStyles";
import {userClient} from "../../../../../api/relation/identity/user/UserClient";
import {organizationUserClient} from "../../../../../api/relation/identity/organizationUser/OrganizationUserClient";
import {userToResult} from "../../../../../api/relation/identity/user/util";
import UserResults from "../../../../component/SearchResults/UserResults";
import {push} from "react-router-redux";
import {routeForUser, routeNewUserPage} from "../../../../../action/creator/router";
import {Forms} from "../../../../../api/form/FormClient";
import ModalForm from "../../../../component/form/ModalForm/ModalForm";
import {postOrganization} from "./postOrganization";
import organizationUpdater from "./updater/organizationUpdater";
import Item from "./markup/Item";
import {roles} from "../../../../../api/login/LoginClient";
import BigCardHeader from "../../../../component/Card/BigCardHeader";

class ViewOrganization extends React.Component {

    constructor() {
        super();
        this.state = {
            loaded:                 false,
            organizationUserResult: {
                users: [],
                count: 0,
            },
        };
    }

    componentDidMount() {
        const {organization} = this.props;
        this.reloadOrganizationUsers(organization);
    }

    reloadOrganizationUsers =
        organization =>
            organizationUserClient.findAll(organization.id)
                                  .then(data => this.setState({
                                                                  loaded:                 true,
                                                                  organizationUserResult: {
                                                                      users: data.entity.users,
                                                                      count: data.entity.count,
                                                                  },
                                                              }));

    render() {
        const {classes, organization, onNewUser, onDelete, onView} = this.props;
        const {loaded} = this.state;

        return (
            <LoggedOn>

                <Card>
                    <CardContent>
                        <Typography variant="h5">
                            {organization.name}
                        </Typography>

                        <Typography component="div">
                            <ModalForm formEndpoint={Forms.editOrganization}
                                       data={organization}
                                       adjust={true}
                                       submitAction={postOrganization(organizationUpdater)(organization)}/>
                        </Typography>

                        <Divider/>

                        <Typography variant="h3">
                            Algemene gegevens
                        </Typography>

                        <Grid container>
                            <Item object={organization}
                                  icon="home"
                                  getDescription={organization => organization.address}/>

                            <Item object={organization}
                                  icon="phone"
                                  getDescription={organization => organization.phone}/>

                            <Item object={organization}
                                  icon="mail_outline"
                                  getDescription={organization => organization.email}/>
                        </Grid>

                    </CardContent>
                </Card>;

                <Card>
                    <BigCardHeader title="Gebruikers"/>
                    <CardContent>

                        {loaded
                            ? <UserResults userResults={this.state.organizationUserResult}
                                           mapper={userToResult}
                                           onClick={onView}
                                           actionIcon="delete"
                                           action="Restaureer"
                                           onAction={user => onDelete(user).then(() => this.reloadOrganizationUsers())
                                                                           .catch(error => console.error(error))}/>
                            : <CircularProgress/>
                        }

                        <Button className={classes.button}
                                id="newuser"
                                variant="contained"
                                color="primary"
                                onClick={onNewUser(organization)}>
                            <Icon className={classes.icon}>add</Icon> Toevoegen
                        </Button>

                    </CardContent>
                </Card>

            </LoggedOn>
        );
    }
}

const mapStateToProps = state => ({
    organization: currentOrganization(state),
});

const mapDispatchToProps = dispatch => ({
    onNewUser: organization => () => routeNewUserPage("/new-user", Forms.user, organization).then(dispatch),
    onView:    user => routeForUser(user).then(dispatch),
    onDelete:  user => userClient.delete(user.id)
                                 .then(() => dispatch(push('/organization'))),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    securePage([roles.admin]),
    withStyles(organizationCardStyles),
)(ViewOrganization);
