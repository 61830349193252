import React from "react";
import {Grid, Icon, Typography} from "@material-ui/core";

class Item extends React.Component {
    render() {
        const {object, icon, getDescription, getDetails} = this.props;

        return [
            <Grid item
                  key={1}
                  sm={2}>
                <Icon color="primary">{icon}</Icon>
            </Grid>,
            <Grid item
                  key={2}
                  sm={8}>
                {getDescription(object)}
                {
                    getDetails ? <Typography>{getDetails(object)}</Typography> : null
                }
            </Grid>,
            <Grid item
                  key={3}
                  sm={2}>
            </Grid>
        ];
    }
}

export default Item;

