import React from "react";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 24-7-19.
 */


const Documents = ({documents}) => <div/>;

// const Documents = ({documents}) => documents.map(document => <FileViewer key={customerClient.documentUrl(document)}
//                                                                          fileType={customerClient.documentType(document)}
//                                                                          filePath={customerClient.documentUrl(document)}
//                                                                          onError={error => console.error(error)}/>);

export default Documents;
