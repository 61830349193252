import Promise from "bluebird";
import {instanceVariables, processInstance} from "../../../../../../api/process/process/processes";
import {cleanupVariables} from "../../../util";

const getValue =
    request =>
        fieldName => {
            if (request.hasOwnProperty("groups") && Array.isArray(request.groups)) {
                for (const group of request.groups) {
                    if (group.hasOwnProperty("fields")) {
                        for (const key of Object.keys(group.fields)) {
                            if (key === fieldName) {
                                return {
                                    description: fieldName,
                                    value:       group.fields[key].value,
                                };
                            }
                        }
                    }
                }
            }
            return null;
        }

const getValues =
    request => {
        const get = getValue(request);
        const data = [get("gewenste_datum"),
                      get("gewenst_dagdeel"),
                      get("gewenst_tijdvak")]
        return {
            values: data.filter(element => element !== null),
        }
    }

/**
 * Retrieves the data common to most DocumentedRequest tasks (processInstance, instanceVariables and documents), and
 * saves them to the contexts state
 *
 * @param {function} updateState
 * @param {string} processInstanceId
 * @return {function(string): Promise<null>}
 */
const loadProcess =
    (processInstanceId, updateState) =>
        Promise.all([processInstance(processInstanceId),
                     instanceVariables(processInstanceId),
                    ])
               .spread((instance, variables) => {
                   variables = cleanupVariables(variables);
                   return updateState({
                                          instance,
                                          agent:     variables.agent,
                                          customer:  variables.privateCustomer,
                                          request:   variables.form,
                                          variables: variables,
                                          values:    getValues(variables.form),
                                      })
               });

export {
    loadProcess,
}
