import React from "react";
import {Card, CardContent, Grid, withStyles} from "@material-ui/core";
import {compose} from "redux";
import {cleanupVariables} from "../util";
import {connect} from "react-redux";
import securePage from "../../SecurePage/SecurePage";
import columnStyles from "../../../../style/columnStyles";
import {friendlyNameFromDefinitionId} from "../../../../api/process/util/process";
import {routeForCustomer} from "../../../../action/creator/router";
import {fullName} from "../../../../util/customer";
import {isPresent} from "../../../../util/util";
import {activeActivityNames, activeUserTasks} from "./activities";
import {roles} from "../../../../api/login/LoginClient";
import Anchor from "../../../component/Anchor/Anchor";
import If from "../../../component/Optional/If";

const getActivityId = id => id.replace(/^.*:/i, "");

const getTask = (activity, tasks) => {
    for (const task of tasks) {
        if (task.name === activity.activityName) {
            return task;
        }
    }
    return null;
}

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 5-6-19.
 */
const InstanceDetails = ({instance, activities, instanceVariables, tasks, customer, dispatch}) => {

    const variables = cleanupVariables(instanceVariables)

    return <Grid container>
        <Grid item
              sm={12}
              md={12}>
            <Card>
                <CardContent>

                    <strong>Klant</strong>
                    <br/>
                    Process for <Anchor
                    onClick={() => routeForCustomer(customer).then(dispatch)}>{fullName(customer)}</Anchor>.
                    <br/>

                    <If show={isPresent(variables.nameAdvisor)}>
                        <br/>
                        <strong>Adviseur</strong>
                        <br/>
                        De adviseur van {fullName(customer)} is {variables.nameAdvisor}
                        <br/>
                    </If>

                    <If show={isPresent(variables.nameAssignee)}>
                        <br/>
                        <strong>Verantwoordelijke</strong>
                        <br/>
                        Dit '{friendlyNameFromDefinitionId(instance.definitionId)}' process is momenteel toegewezen
                        aan {variables.nameAssignee}
                        <br/>
                    </If>

                    <br/>
                    <strong>Taken</strong>
                    <ul>
                        {
                            activeUserTasks(activities).map(activity => getTask(activity, tasks))
                                                       .filter(isPresent)
                                                       .map(({id, name}) => {
                                                           return <li key={name}>
                                                               <Anchor href={`/taken/${getActivityId(id)}/`}>
                                                                   {name}
                                                               </Anchor>
                                                           </li>;
                                                       })
                        }
                    </ul>

                    <strong>Huidige activiteiten</strong>
                    <ul>
                        {
                            activeActivityNames(activities).map(name => <li key={name}>{name}</li>)
                        }
                    </ul>

                </CardContent>
            </Card>
        </Grid>
    </Grid>;
};

const mapDispatchToProps = dispatch => ({
    dispatch,
});

let decorate = compose(
    connect(null, mapDispatchToProps),
    securePage([roles.admin]),
    withStyles(columnStyles),
);

export default decorate(InstanceDetails);
