import {isPresent, now} from "../../../util/util";

const format = date => isPresent(date) ? date.format("DD MMMM YYYY") : "";

const clearTime = date => isPresent(date) ? date.set({
                                                         hour: 0,
                                                         minute: 0,
                                                         second: 0,
                                                         millisecond: 0,
                                                     })
                                          : date;

/**
 *
 * @param {Moment} d1
 * @param {Moment} d2
 * @return {number} the difference between the two dates in DAYS
 * */
const delta = (d1, d2) => d1.diff(d2, 'days');

const formatDeadline = date => {

    if (!isPresent(date)) {
        return "";
    }

    switch (delta(date, now())) {

        case 0:
            return "vandaag";
        case 1:
            return "morgen";
        case 2:
            return "overmorgen";
        case 3:
            return "3 dagen";
        case 4:
            return "4 dagen";
        case 5:
            return "5 dagen";
        case 6:
            return "6 dagen";
        case 7:
            return "1 week";
        default:
            return format(date);
    }
};

const formatSince = date => {
    if (!isPresent(date)) {
        return "";
    }

    switch (delta(date, now())) {

        case 0:
            return "sinds vandaag";
        case 1:
            return "sinds gisteren";
        case 2:
            return "sinds eergisteren";
        case 3:
            return "sinds 3 dagen";
        case 4:
            return "sinds 4 dagen";
        case 5:
            return "sinds 5 dagen";
        case 6:
            return "sinds 6 dagen";
        case 7:
            return "sinds 1 week";
        default:
            return `sinds  ${format(date)}`;
    }
}

const sort = {
    A_BEFORE_B: -1,
    A_AFTER_B: 1,
    EQUAL_RANK: 0,
}

/** 'normalizes' sort values */
const normalize = n => n === 0 ? 0 : n / Math.abs(n);

const compareDates = (a, b) => {
    if (a === b) {
        return sort.EQUAL_RANK;
    }
    if (a === null) {
        return sort.A_AFTER_B;
    }
    if (b === null) {
        return sort.A_BEFORE_B;
    }
    return normalize(delta(a, b));
}

const compareDossiersByDate =
    type =>
        (dossier1, dossier2) => {
            const date1 = dossier1.getDate(type);
            const date2 = dossier2.getDate(type);

            clearTime(date1);
            clearTime(date2);

            return compareDates(date1, date2);
        }

export {
    delta,
    format,
    formatDeadline,
    formatSince,
    compareDossiersByDate,
}
