/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 12-1-18.
 */

import React from "react"
import {Menu, MenuItem} from "@material-ui/core";
import {connect} from "react-redux";
import {logoffActions} from "../../../../action/creator/asynchonous/login";
import {routeForUser} from "../../../../action/creator/router";
import nop from "../../../../action/creator/nop";
import {empty} from "../../../../api/process/util";

const anchorOrigin = {
    vertical:   'top',
    horizontal: 'right',
};
const transformOrigin = {
    vertical:   'top',
    horizontal: 'right',
};

const ProfileMenu =
    ({person, anchor, onClose, onVisitProfile, onLogoff, classes}) =>
        <Menu classes={{paper: classes.paper}}
              open={Boolean(anchor)}
              onClose={onClose}
              anchorEl={anchor}
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}>
            {/*<MenuItem onClick={onClose}>Ingelogd als {person.name}</MenuItem>*/}
            <MenuItem onClick={onLogoff}>Uitloggen</MenuItem>
            <MenuItem onClick={hidden => onVisitProfile(person)}>Mijn gegevens</MenuItem>
        </Menu>;

const profileAction = user => {
    if (!empty(user) && !empty(user.id)) {
        return routeForUser(user);
    }
    else {
        // most likely a remnant: the user has logged with legacy code
        // needs to log off and on again, so that the login is set with extended value
        console.error("NO ID. Log on / Log off?");
        return Promise.resolve(nop);
    }
}

const mapDispatchToProps = dispatch => ({
    onVisitProfile: user => profileAction(user).then(dispatch),
    onLogoff:       () => dispatch(logoffActions()),
});

export default connect(null, mapDispatchToProps)(ProfileMenu);
