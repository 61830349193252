/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 25-9-18.
 */
import CamundaTaskClient from "./CamundaTaskClient";
import Promise from "bluebird";

/**
 * @typedef {object} Query
 * @property {function} page
 * @property {function} itemsPerPage
 * @property {function} sorts
 */

/** @type Query */
const defaultQuery = {
    page: 1,
    itemsPerPage: 10,
    sorts: [],
};

const taskCount =
    (query = defaultQuery) =>
        CamundaTaskClient.getTaskCount(query);

const searchTask =
    query => CamundaTaskClient.searchTask(query);

const tasks =
    (query = defaultQuery) => {
        query = {...defaultQuery, ...query};
        return Promise.all([CamundaTaskClient.getTaskCount(query),
                            CamundaTaskClient.getTasks(query)])
                      .spread(({count}, tasks) => {
                          return ({
                              results: tasks,
                              totalResults: count,
                              query: query,
                          });
                      });
    };

const task =
    id =>
        CamundaTaskClient.getTask(id);

const activities =
    query =>
        CamundaTaskClient.getActivities(query);

const closeTask =
    (task, variables) =>
        CamundaTaskClient.closeTask(task, variables);

const taskAttachments =
    id =>
        CamundaTaskClient.getTaskAttachments(id);

const taskAttachmentById =
    (taskId, attachmentId) =>
        CamundaTaskClient.getTaskAttachmentById(taskId, attachmentId);

export {
    taskCount,
    searchTask,
    tasks,
    task,
    closeTask,
    activities,
    taskAttachments,
    taskAttachmentById,
}
