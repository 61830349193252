/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 1-2-18.
 */
import React from "react";
import {formattedBirthdate, fullAddress} from "../../../../../../../../util/customer";
import {Button, Card, CardContent, Divider, Grid, Typography} from "@material-ui/core";
import Item from "../../markup/Item";
import ModalForm from "../../../../../../../component/form/ModalForm/ModalForm";
import {Forms} from "../../../../../../../../api/form/FormClient";
import {postBusiness} from "../../postCustomer";
import customerPropType from "../../../../util/customerPropType";
import customerUpdater from "../../updater/customerUpdater";
import addressUpdater from "../../updater/addressUpdater";
import BigCardHeader from "../../../../../../../component/Card/BigCardHeader";
import SmallCardHeader from "../../../../../../../component/Card/SmallCardHeader";

const MainBusinessCustomer = ({customer, onAccept}) =>
    <Card>
        <CardContent>
            {
                customer.customerStatus === "PROSPECT" ? <Button variant="contained"
                                                                 color='primary'
                                                                 onClick={onAccept(customer)}>
                                                           Accepteer als klant
                                                       </Button>
                                                       : null
            }

            <BigCardHeader title={`${customer.companyName}`}/>

            <Typography variant="body1"
                        component="div">
                Sinds {formattedBirthdate({birthDate: customer.foundationDate})}
                <ModalForm formEndpoint={Forms.editBusiness}
                           data={customer}
                           submitAction={postBusiness(customerUpdater)(customer)}/>
            </Typography>

            <Divider/>

            <SmallCardHeader title="Algemene gegevens"/>

            <Grid container>

                <Item object={customer}
                      icon="account_balance"
                      getDescription={customer => customer.companyName}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>

                <Item object={customer.addresses}
                      icon="home"
                      getDescription={fullAddress}
                      modalForm={<ModalForm formEndpoint={Forms.editAddress}
                                            data={customer.addresses}
                                            submitAction={postBusiness(addressUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="filter_4"
                      getDescription={customer => customer.chamberCommNo}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="style"
                      getDescription={customer => customer.legalForm}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="style"
                      getDescription={customer => customer.businessType}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="style"
                      getDescription={customer => customer.branch}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>
                <Item object={customer}
                      icon="phone"
                      getDescription={customer => customer.phone}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>
                <Item object={customer}
                      icon="phonelink_ring"
                      getDescription={customer => customer.mobile}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>
                <Item object={customer}
                      icon="business"
                      getDescription={customer => customer.forBusiness}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>
            </Grid>

            <Divider/>

            <SmallCardHeader title="Bedrijfsgegevens"/>

            <Grid container>
                <Item object={customer}
                      icon="date_range"
                      getDescription={customer => customer.foundationDate}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness2}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="filter_4"
                      getDescription={customer => customer.incomeTaxNo}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness2}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="date_range"
                      getDescription={customer => customer.employmentDateFirstEmployee}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness2}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="business"
                      getDescription={customer => customer.collectiveLabourAgreement}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness2}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="business"
                      getDescription={customer => customer.status}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness2}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="euro_symbol"
                      getDescription={customer => customer.yearlyPayroll}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness2}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      description="Jaaromzet"
                      getDescription={customer => customer.yearlyRevenue}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness2}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="business"
                      getDescription={customer => customer.collectionMethod}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness2}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="filter_4"
                      getDescription={customer => customer.ibanBankAcc}
                      modalForm={<ModalForm formEndpoint={Forms.editBusiness2}
                                            data={customer}
                                            submitAction={postBusiness(customerUpdater)(customer)}/>}/>
            </Grid>

        </CardContent>
    </Card>;

MainBusinessCustomer.propTypes = {
    customer: customerPropType,
};

export default MainBusinessCustomer;
export {
    MainBusinessCustomer,
}
