import React from "react";
import {withStyles} from "@material-ui/core/index";
import {orderClient} from "../../../api/relation/order/OrderClient";
import Order from "./Order";

const styles = theme => ({
    orders: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: "30px"
    }
});

const getOrders = customer => orderClient.getOrders(customer);

class Contract extends React.Component {

    state = {
        orders: [],
    }

    componentDidMount() {
        getOrders(this.props.customer)
            .then(orders => this.setState({orders: orders}));
    }

    render() {

        const {customer, classes} = this.props;
        return (
            <div className={classes.orders}>
                {
                    this.state.orders.map((order, index) =>
                                              <Order order={order}
                                                     customer={customer}
                                                     key={index}
                                                     index={index}/>
                    )
                }
            </div>
        );
    }
}

export default withStyles(styles)(Contract);
