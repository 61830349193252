/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-4-18.
 */
import React from "react";
import {Button, Card, Step, StepContent, StepLabel, Stepper} from "@material-ui/core";
import QuestionComponent from "../Question/QuestionComponent";
import PropTypes from 'prop-types';
import If from "../../Optional/If";
import {formPropType} from "../propType/form";
import {hasClasses} from "../../../../util/util";
import ReactDOM from "react-dom";
import scrollIntoView from 'scroll-into-view-if-needed'
import CardHeader from "semantic-ui-react/dist/commonjs/views/Card/CardHeader";

/**
 * 'fake' questions, such as those marked as 'INFO', should not be considered for scrolling.
 */
let isRealQuestion = ({type}) => type !== "INFO";

const firstErrorId = (form, step) => {
    const question = form.groups[step]
        .questions
        .filter(isRealQuestion)
        .find(question => question.valid === false);
    return question ? question.id : null;
};

const firstUnSubmittedId = (form, step) => {
    const question = form.groups[step]
        .questions
        .filter(isRealQuestion)
        .find(question => question.submit === false && question.visible === true);
    return question ? question.id : null;
};

class StepForm extends React.Component {

    componentDidUpdate() {

        if (this.firstError) {
            scrollIntoView(ReactDOM.findDOMNode(this.firstError), {scrollMode: 'if-needed', behavior: "smooth"})
        } else if (this.focusedQuestion) {
            scrollIntoView(ReactDOM.findDOMNode(this.focusedQuestion), {scrollMode: 'if-needed', behavior: "smooth"})
        } else if ((this.currentQuestion)) {
            scrollIntoView(ReactDOM.findDOMNode(this.currentQuestion), {scrollMode: 'if-needed', behavior: "smooth"})
        }
    }

    constructor(props) {
        super(props);
        this.firstError = null;
        this.currentQuestion = null;
        this.focusedQuestion = null;
    }

    render() {
        const {form, customer, currentStep, onStep, onSubmitQuestion, buttonText = null, classes} = this.props;
        return (
            <div>
                {
                    form.groups.length === 0
                        ? <Card>
                            <CardHeader>
                                {form.formType} formulier
                            </CardHeader>
                        </Card>
                        : <Stepper orientation="vertical"
                                   activeStep={currentStep}
                                   className={classes.stepper}>
                            {
                                form.groups.map(
                                    group => (
                                        <Step key={group.header}>
                                            <StepLabel onClick={currentStep === group.step ? null : onStep(group.step)}
                                                       classes={{
                                                           label: classes.label,
                                                           active: classes.stepLabel,
                                                       }}>
                                                {group.header}
                                            </StepLabel>
                                            <StepContent>
                                                {
                                                    group.questions
                                                        .filter(question => question.visible === true)
                                                        .map((question, index) =>
                                                            <QuestionComponent key={question.field}
                                                                               onSubmitQuestion={onSubmitQuestion(question)}
                                                                               customer={customer}
                                                                               question={question}
                                                                               ref={component => {
                                                                                   if (question.id === firstUnSubmittedId(form, currentStep)) {
                                                                                       this.currentQuestion = component;
                                                                                   }
                                                                                   if (question.id === firstErrorId(form, currentStep)) {
                                                                                       this.firstError = component;
                                                                                   }
                                                                               }}
                                                                               formComponent={this}/>)
                                                }
                                                <Button variant="contained"
                                                        className={classes.stepperButton}
                                                        color='primary'
                                                        onClick={onStep(currentStep + 1)}>
                                                    {currentStep === form.groups.length - 1 && buttonText != null ? buttonText : 'Volgende'}
                                                </Button>
                                                < If show={currentStep !== 0}>
                                                    <Button className={classes.previousButton}
                                                            onClick={onStep(currentStep - 1)}>Vorige</Button>
                                                </If>
                                            </StepContent>

                                        </Step>)
                                )
                            }
                        </Stepper>
                }
            </div>
        );
    }
}

StepForm.propTypes = {
    form: formPropType,
    currentStep: PropTypes.number.isRequired,
    onStep: PropTypes.func.isRequired,
    onSubmitQuestion: PropTypes.func.isRequired,
    classes: hasClasses(["stepper",
        "previousButton",
        "stepperButton",
        "label",
        "stepLabel",
        "activeStepLabel"])
};

export default StepForm;
