/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 1-9-17.
 */
import {preFill, prefillFileQuestions} from "./prefill";
import setDefaults from "./setDefaults";
import {getJson} from "../HTTP/get";
import {postJson} from "../HTTP/post";

const Forms = Object.freeze({
    businessCustomer: "BUSINESS_CUSTOMER",
    brokerBusinessCustomer: "BROKER_BUSINESS_CUSTOMER",
    consumerCustomer: "CONSUMER_CUSTOMER",
    partnerAccount: "PARTNER_ACCOUNT",
    editAddress: "EDIT_ADDRESS",
    editAutomobile: "EDIT_AUTOMOBILE",
    editProperty: "EDIT_PROPERTY",
    editFamilymember: "EDIT_FAMILY_MEMBER",
    editBusiness: "EDIT_BUSINESS",
    editBusiness2: "EDIT_BUSINESS_2",
    editBusinessPerson: "EDIT_BUSINESS_PERSON",
    organization: "ORGANIZATION",
    user: "USER",
    editOrganization: "EDIT_ORGANIZATION",
    editUser: "EDIT_USER",
    proposalIctBav: "PROPOSAL_BAV_ICT",
    proposalVerzuim: "PROPOSAL_VERZUIM",
    proposalBavSmart: "PROPOSAL_BAV_SMART",
    proposalHoreca: "PROPOSAL_HORECA",
    proposalBav: "PROPOSAL_BAV",
    proposalBca: "PROPOSAL_BCA",
    proposalSwv: "PROPOSAL_SWV",
    proposalAvb: "PROPOSAL_AVB",
    nextProposal: "NEXT_PROPOSAL",
    carInsurance: "CAR_INSURANCE",
    documentedRequestRequestApproval: "DOCUMENTEDREQUEST_REQUEST_APPROVAL",
    documentedRequestDocumentApproval: "DOCUMENTEDREQUEST_DOCUMENT_APPROVAL",
    documentedRequestAppointment: "DOCUMENTEDREQUEST_APPOINTMENT",
    blissHypotheek: "BLISS_HYPOTHEEKFORMULIER",
    garantiestelling: "AANVRAAG_GARANTIESTELLING",
    mogelijk: "VASTGOED_HYPOTHEEK",
    woningScan: "GREENFACTOR_WONING_SCAN",
    privateCustomerPersoonsGegevens: "PRIVATE_CUSTOMER_PERSOONSGEGEVENS",
    privateCustomerContact: "PRIVATE_CUSTOMER_CONTACT",
    privateCustomerOverig: "PRIVATE_CUSTOMER_OVERIG",
    bouwkundigekeuring: "BLISS_BOUWKUNDIGE_KEURING",
    setPassword: "SET_PASSWORD",
    blissAppointment: "BLISS_APPOINTMENT",
    newNote: "NEW_NOTE",
    customerApproval: "ONDERTEKENING",
    customerApprovalBankgarantie: "OndertekeningBankgarantie",
    hypotheekDossier: "AANVRAAG_PROCESS",
    assesCustomer: "ASSES_BANKGARANTIE",
    quickScan: "QUICK_SCAN",
});

class FormClient {

    static getServiceUrl(endpoint) {
        return `${process.env.REACT_APP_FORMSERVICE}/forms/${endpoint}`;
    }

    static getForm(endpoint, data = null, documents = null) {
        // Right now the endpoint ignores the data, so we still manually insert (prefill...) the data before returning it
        const formPromise = data ? postJson(FormClient.getServiceUrl(endpoint))(data) : getJson()(FormClient.getServiceUrl(endpoint));

        return formPromise.then(form => preFill(form, data))

            .then(form => prefillFileQuestions(form, documents))
            .then(form => this.postForm(endpoint,
                form)) // Make sure the businessrules get applied, might lead to prefilled forms that are invalid when the forms or businesrules change...
            .then(form => setDefaults(form));
    }

    static postForm(endpoint, form) {
        return postJson(FormClient.getServiceUrl(""))(form)
            .then(form => setDefaults(form));
    }
}

export default FormClient;
export {
    FormClient,
    Forms,
}
