/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 3-8-18.
 */

/**
 * source: based  on https://stackoverflow.com/a/16693578/4571037
 * Generates a GUID string.
 * @returns {String} The generated GUID.
 * @example af8a8416-6e18-a307-bd9c-f2c947bbb3aa
 * @author Slavik Meltser (slavik@meltser.info).
 * @link http://slavik.meltser.info/?p=142
 */
function guid() {
    function _p8(s) {
        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4)  : p;
    }

    return _p8() + _p8(true);
}

const createDummyData = () => `${process.env.REACT_APP_ENVIRONMENT}` === "create_dummy_data";

const template = JSON.parse("{\n"
                            + "                              \"data\": {\n"
                            + "                                  \"companyName\": \"xxx\",\n"
                            + "                                  \"chamberCommNo\": \"65897895\",\n"
                            + "                                  \"legalForm\": \"Rechtspersoon in oprichting\",\n"
                            + "                                  \"businessType\": \"ZZP\",\n"
                            + "                                  \"collectiveLabourAgreement\": \"CAO Voedingsindustrie\",\n"
                            + "                                  \"employmentDateFirstEmployee\": \"21-08-2018\",\n"
                            + "                                  \"incomeTaxNo\": \"1233445678985\",\n"
                            + "                                  \"foundationDate\": \"22-08-2018\",\n"
                            + "                                  \"status\": \"b\",\n"
                            + "                                  \"yearlyPayroll\": \"75000\",\n"
                            + "                                  \"yearlyRevenue\": \"150000\",\n"
                            + "                                  \"branch\": \"Maak een keuze\",\n"
                            + "                                  \"fte\": \"1\",\n"
                            + "                                  \"tradeAssociation\": \"a\",\n"
                            + "                                  \"sbiCode\": \"456\",\n"
                            + "                                  \"phone\": \"010-12345678\",\n"
                            + "                                  \"mobile\": \"06-mobiel\",\n"
                            + "                                  \"email\": \"vanderwerf@bluehorizon.nl\",\n"
                            + "                                  \"forBusiness\": \"06-za\",\n"
                            + "                                  \"collectionMethod\": \"Machtiging met nota\",\n"
                            + "                                  \"ibanBankAcc\": \"NL89INGB0006789485\",\n"
                            + "                                  \"initials\": \"M.J.W.\",\n"
                            + "                                  \"firstName\": \"Marcel\",\n"
                            + "                                  \"insertPart\": \"van der\",\n"
                            + "                                  \"lastName\": \"Werf\",\n"
                            + "                                  \"nationality\": \"De Nederlands\",\n"
                            + "                                  \"birthDate\": \"17-08-2018\",\n"
                            + "                                  \"maritialStatus\": \"ong\",\n"
                            + "                                  \"bsn\": \"bsn\",\n"
                            + "                                  \"idType\": \"soort lego\",\n"
                            + "                                  \"legitimationDocNum\": \"789\",\n"
                            + "                                  \"addresses\": {\n"
                            + "                                      \"postalCode\": \"3122VN\",\n"
                            + "                                      \"houseNo\": \"179\",\n"
                            + "                                      \"addition\": \"\",\n"
                            + "                                      \"streetName\": \"Valkenboslaan\",\n"
                            + "                                      \"city\": \"Utrecht\"\n"
                            + "                                  }\n"
                            + "                              }\n"
                            + "                          }");

const businessFormData = agent => {
    const dummyData = template.data;
    dummyData.companyName = `Blue Horizon ${guid()}`;
    return createDummyData() ? {...dummyData, ...agent} : agent;
};
export default businessFormData;
