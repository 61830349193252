import RelationClient from "../RelationClient";
import {fullName} from "../../../util/customer";
import {getJson} from "../../HTTP/get";
import {putJson} from "../../HTTP/put";

const noteTypes = {
    NEW_CUSTOMER:          "Nieuwe klant aangemaakt",
    NEW_APPOINTMENT:       "Nieuwe afspraak",
    CANCELLED_APPOINTMENT: "Afspraak geannuleerd",
    ACCEPT_CUSTOMER:       "Klant geaccepteerd",
    ARCHIVE_CUSTOMER:      "Klant gearchiveerd",
    UNARCHIVE_CUSTOMER:    "Klant gerestaureerd",
    PORTAL_INVITE:         "Uitnodiging klantportaal",
    PORTAL_ACTIVATION:     "Activatie klantportaal",
    TASK_CLOSED:           "Taak afgerond",
    PROCESS_DELETED:       "Process verwijderd",
    FACTUUR:               "Factuur",
}

/**
 * A Note is an object with two string fields: a description and a text
 *
 * @typedef {Object} Note
 * @property {string} description
 * @property {string} text
 */

class NoteClient {

    /**
     * Returns a promise that will resolve once the note has been added to the customers.
     *
     * @param {object} user
     * @param {object} customer
     * @param {string} description
     * @param {string} text
     * @return {Promise<Object>}
     */
    addFormNote(user, customer, description, text) {

        let data = {};
        data.description = description;
        data.text = text
        data.contributor = user.name;
        data.emailContributor = user.email;

        return putJson(RelationClient.getNoteEndpoint(customer))({data: data});
    }

    /**
     * Returns a promise that will resolve once the note has been added to the customers.
     *
     * @param {object} user
     * @param {object} customer
     * @param {Note} note
     * @return {Promise<Object>}
     */
    addNote(user, customer, note) {

        let data = {};
        data.description = note.description;
        data.text = note.text;
        data.contributor = user.name;
        data.emailContributor = user.email;

        return putJson(RelationClient.getNoteEndpoint(customer))({data: data});
    }

    /**
     * Returns a promise that will resolve once the note has been added to the customers.
     *
     * @param {object} customer
     * @param {string} description
     * @param {object}  text
     * @return {Promise<Object>}
     */
    addSystemNote(customer, description, text) {

        let data = {};
        data.text = text;
        data.contributor = "systeem";
        data.emailContributor = "info@blisshypotheekadviseurs.nl";
        data.description = description;

        return putJson(RelationClient.getNoteEndpoint(customer))({data: data});
    }

    /**
     * Returns a promise that will resolve once the note has been added to the customers.
     *
     * @param {object} customer
     * @param {Note} note
     * @return {Promise<Object>}
     */
    addCustomerNote(customer, note) {

        let data = {};
        data.description = note.description;
        data.text = note.text;
        data.contributor = fullName(customer);
        data.emailContributor = customer.email;

        return putJson(RelationClient.getNoteEndpoint(customer))({data: data});
    }

    getLastUpdate(customer) {
        return this.getNotes(customer).then(notes => notes.length == null? null
            : notes[0],
        )
    }

    getNotes(customer) {
        const customerId = typeof customer === "object"? customer.id : customer;
        return getJson()(RelationClient.getServiceUrl(RelationClient.noteEndpoint) + `?customerId=${customerId}`)
            .then(response => response.entity)
    }
}

const noteClient = new NoteClient();

export default NoteClient;
export {
    noteTypes,
    noteClient,
}
