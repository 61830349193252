// Not sure where it slipped in, apperently some of our images end in a final quote turns the dataUrl invalid
import {Avatar, Icon, TableCell, TableRow} from "@material-ui/core";
import {empty} from "../../../../../../api/process/util";
import React from "react";
import classnames from "classnames";
import {customerClient} from "../../../../../../api/relation/customer/CustomerClient";

const cleanImageData = string => {
    if (typeof string === 'string' && string.length > 0) {
        const lastChar = string.charAt(string.length - 1);
        if (lastChar === '"') {
            return string.slice(0, -1);
        }
    }
    return string;
}

/**
 *
 * @param string
 * @returns {*}
 */
const makeDataUrl =
    string =>
        string !== null && string.startsWith("data")
            ? string
            : `data:image/jpeg;base64,${cleanImageData(string)}`;

/**
 *
 * @param {object} user
 * @returns {string|null}
 */
const getAvatar = user => {
    if (!empty(user)) {
        if (!empty(user["attributes"])) {

            if (!empty(user["attributes"]["image"]) &&
                Array.isArray(user["attributes"]["image"]) &&
                user["attributes"]["image"].length >
                0) {
                const image = user["attributes"]["image"][0];
                if (image !== "null") {  // check for weird edgecase
                    return image;
                }
            }
        }
    }
    return null;
};

class ConsultantRow extends React.Component {

    state = {
        hover: false,
    };

    render() {
        const {consultingUser, reloadCustomer, classes} = this.props;

        return (
            <TableRow className={classnames(classes.tableRow, this.state.hover? classes.selection : "")}
                      onMouseOver={_ => this.setState({hover: true})}
                      onMouseOut={_ => this.setState({hover: false})}>
                <TableCell>
                    {
                        getAvatar(consultingUser.user)?
                            <Avatar alt={consultingUser.user.name}
                                    src={makeDataUrl(getAvatar(consultingUser.user))}
                                    className={classes.personaAvatar}/>
                            : null
                    }
                </TableCell>
                <TableCell>{consultingUser.user.firstName} {consultingUser.user.lastName}</TableCell>
                <TableCell>{consultingUser.user.email}</TableCell>
                <TableCell title={"Verwijder"}
                           onClick={() => customerClient.removeConsultant(consultingUser.consultant)
                                                        .then(_ => reloadCustomer())}>
                    <Icon color="primary">delete_forever</Icon>
                </TableCell>
            </TableRow>);
    }
}

export default ConsultantRow;
