/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 5-9-17.
 */
// TODO: serviceForm was form
const form = state => state["serviceForm"];

const formType = state => {

    const currentForm = form(state);
    if (typeof currentForm === "object") {

        if (currentForm.hasOwnProperty("formType")) {
            return currentForm.formType;
        }
        else if (currentForm.hasOwnProperty("type")) {
            console.error("EXPECTED formType-attribute to hold the type, using type-attribute");
            return currentForm.formType;
        }
    }
    throw new Error("Could not determine formtype\n" + JSON.stringify(currentForm, null, 2));
};

const formState = state => state["formState"];

export default form;

export {
    form,
    formType,
    formState,
}
