import defaultSkin from "./defaultSkin";

const blissSkin = {
    ...defaultSkin,
    id: "BLISS",
    images: {
        ...defaultSkin.images,
        personaBackground: null,
        logo: "/images/bliss-logo.png"
    },
    palette: {
        ...defaultSkin.palette,
        primary: {main: "#e6007e"},
        secondary: {main: "#888"},
        anchors: {
            ...defaultSkin.palette.anchors,
            regular: {main: "#fff"},
            hover: {main: "#fff"},
            visited: {main: "#f"},
        },
        personaBackground: {main: "#a3a7ae"},
        copyrightForeground: {main: "#e6007e"},
        copyrightBackground: {main: "#444"},
        footerForeground: {main: "#000000"},
        footerBackground: {main: "#a3a7ae"},
    },
    texts: {
        ...defaultSkin.texts,
        copyright: "Bliss Hypotheekadviseurs",
        company: "Bliss Hypotheekadviseurs"
    },
    overrides: {
        logo: {
            height: "auto",
            position: "relative",
            left: "-4px",
        },
        mainBackground: {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '70% 0%',
            backgroundImage: `url('/images/bliss-achtergrond.png')`
        },
    }
};

export default blissSkin;
