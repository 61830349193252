/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 29-1-18.
 */
import React from "react";
import PropTypes from 'prop-types';
import {FormControl, Input, InputLabel} from "@material-ui/core";
import Message from "../Message";
import questionPropType from "../../../propType/question";
import Tip from "../../Tip";

const stringOrNumber = PropTypes.oneOfType([PropTypes.string,
                                            PropTypes.number]);

const id = question => `question-${question["id"]}`;

/**
 * Finds the next input in the form to focus.
 * Quite imperfect (ignores dropdowns etc), but better than nothing
 * @param input
 * @param form
 * @return {*}
 */

const findNextInput = (input, form) => {
    const startIndex = Array.prototype.indexOf.call(form, input);
    let index = startIndex + 1;

    while (form.elements[index].type === "hidden" && startIndex !== index) {
        index = (index + 1) % form.length;
    }

    return form.elements[index];
};

const TextQuestion = ({question, value, onChange, onBlur, inputmode = "text", children, ...inputProps}) =>

    <div style={{display: "flex"}}>
        <FormControl disabled={question.locked}
                     error={!question.valid}
                     fullWidth>
            <InputLabel required={question.required}>{question.description}</InputLabel>
            <Input id={id(question)}
                   multiline={false}
                   value={value}
                   inputMode={inputmode}
                   onKeyPress={event => {
                       if (event.key === 'Enter') {
                           const nextInput = findNextInput(event.target, event.target.form);
                           nextInput.focus();
                           event.preventDefault();
                       }
                   }}
                   onChange={onChange}
                   onBlur={onBlur}
                   inputProps={inputProps}/>
            {children}
            <Message question={question}/>
        </FormControl>
        <Tip tip={question.tooltip}/>
    </div>;

TextQuestion.propTypes = {
    question: questionPropType.isRequired,
    value: stringOrNumber.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
};

export default TextQuestion;
export {
    TextQuestion,
    findNextInput,
}
