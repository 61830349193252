import React from "react";
import {fullName} from "../../../../../../util/customer";

const css = () => ({

    container: {
        margin: "0 10px 0 10px",
    },

    table: {
        width: "100%",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: "10.5px",
        display: "grid",

    },
    header: {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: "400",
        fontSize: "13px"
    },
    body: {
        display: "grid",


    },
    row: {},
    cell: {
        padding: "15px 0 15px 15px",
        borderBottom: "solid",
        borderColor: "rgb(224, 224, 224)",
        borderWidth: "thin",

    },
    label: {
        display: "block",
        color: "rgba(0, 0, 0, 0.54)",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: "14px",
        margin: "10px 0 10px 0",
    }
    ,
    choiceLabel: {
        margin: "0 5px 0 5px",
    }
});

const ConfirmationNote = ({customer, signature}) => {

    const style = css();

    return (
        <div style={style.container}>
            De klant heeft de algemene voorwaarden geaccepteerd en de aanvraag voor bankgarantie ondertekend

            <br/>

            <label style={style.label}>Akkoord met de algemene voorwaarden</label>

            <input type="radio"
                   id="1"
                   name="Ja"
                   checked={true}
                   readOnly={true}
                   value="Ja"/>
            <label style={style.choiceLabel}
                   htmlFor="Ja">Ja
            </label>

            <input type="radio"
                   id="2"
                   name="Nee"
                   checked={false}
                   readOnly={true}
                   value="Nee"/>
            <label style={style.choiceLabel}
                   htmlFor="Nee">Nee
            </label>

            <label style={style.label}>Handtekening {fullName(customer)}</label>

            <img src={signature}
                 alt="ondertekening"/>

        </div>
    );
};

export default ConfirmationNote;
