/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 8-7-19.
 */
import React from "react";
import {customerClient} from "../../../../../../../api/relation/customer/CustomerClient";
import Main from "./Main";

class Customer extends React.Component {

    state = {
        customer: null
    };

    componentDidMount() {
        let {customer} = this.props;

        // load the customer from db, in case changes we're  made since submitting the request
        customerClient.fetchCustomer(customer.id).then(customer => this.setState({customer}))
    }

    render() {
        const {customer} = this.props;

        return (
            <Main customer={customer}/>
        );
    }
}

export default Customer;
