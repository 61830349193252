/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 11-10-18.
 */

import React from "react";
import {FormControl} from "@material-ui/core";
import Message from "../Message";
import PropTypes from 'prop-types';
import questionPropType from "../../../propType/question";
import Tip from "../../Tip";
import {FormGroup} from "semantic-ui-react";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";

const id = (question, value) => `question-${question.id}-${value}`;

const MultiSelectQuestion = ({question, selection, onAddSelection, onRemoveSelection}) =>

    <div style={{display: "flex"}}>
        <FormControl disabled={question.locked}
                     error={!question.valid}
                     fullWidth>
            <FormLabel required={question.required} component="legend">{question.description}</FormLabel>
            <FormGroup>
                {Object.values(question.acceptableValues).map(
                    value => <FormControlLabel key={value}
                                               control={<Checkbox name={id(question, value)}
                                                                  checked={selection.includes(value)}
                                                                  onChange={event => event.target.checked
                                                                                     ? onAddSelection(value)
                                                                                     : onRemoveSelection(value)}
                                                                  color="primary"
                                                                  value={value}/>}
                                               label={value}/>
                )}

            </FormGroup>
            <Message question={question}/>
        </FormControl>
        <Tip tip={question.tooltip}/>
    </div>;

MultiSelectQuestion.propTypes = {
    question: questionPropType.isRequired,
    selection: PropTypes.array.isRequired,
    onAddSelection: PropTypes.func.isRequired,
    onRemoveSelection: PropTypes.func.isRequired,
};

export default MultiSelectQuestion;
