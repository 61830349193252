/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 27-8-18.
 */

/**
 * @typedef {Object} Agent
 * @property {string} agentOffice - The name of the agents office
 * @property {string} agentName - The agents name
 * @property {string} agentPhone - The agents phone
 * @property {string} agentEmail - The agents email address
 */

import {person} from "./person";

/**
 * @param state
 * @return {Agent|object} Either the current logged in user is returned as the agent, or
 *                        else the last agent-data that was submitted in the homescreen
 *                        form. If all else fails an empty object is returned.
 */
const agent = state => {

    const user = person(state);
    if (user && user.hasOwnProperty("name")) {
        return {
            agentOffice: "Blue Horizon",
            agentName: user.name,
            agentPhone: '0612345678',
            agentEmail: user.email
        }
    }

    if (state.agentAction) {
        if (state.agentAction.agentDetail) {
            return state.agentAction.agentDetail;
        }
    }
    return {};
};

export default agent;
