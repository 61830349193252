/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 29-1-18.
 */
import React from "react";
import PropTypes from 'prop-types';
import {Button, FormControl, InputLabel, Modal, withStyles} from "@material-ui/core";
import Message from "../Message";
import questionPropType from "../../../propType/question";
import Tip from "../../Tip";
import SignaturePadWrapper from "./SignaturePadWrapper";

const styles = theme => ({
    modal: {
        position: 'absolute',
        width: "50%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        borderRadius: 4,
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`,
    },
    submitButton: {
        width: "fit-content",
        maxHeight: 30
    },
    label: {
        position: "relative",
        minWidth: "200px",
        width: "fit-content",
        marginTop: -12,
        paddingRight: 10,
    },
    container: {
        width: "100%",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        width: "100%",
    },
    image: {
        height: 30,
    }
});

const stringOrNumber = PropTypes.oneOfType([PropTypes.string,
                                            PropTypes.number]);

class SignatureQuestion extends React.Component {

    state = {
        open: false,
    };

    render() {
        const {question, value, onChange, children, classes} = this.props;
        const {open} = this.state;

        const ClearButton = ({onClick}) =>
            <Button variant="contained"
                    color='secondary'
                    onClick={onClick}>
                Reset
            </Button>;

        const CancelButton = () =>
            <Button variant="contained"
                    color='secondary'
                    onClick={() => this.setState({open: false})}>
                Cancel
            </Button>;

        const DoneButton = ({getDataURI}) =>
            <Button variant="contained"
                    color='primary'
                    onClick={() => {
                        onChange(getDataURI());
                        this.setState({open: false});
                    }}>
                Klaar
            </Button>;

        return (
            <div style={{display: "flex"}}>
                <FormControl className={classes.container}
                             disabled={question.locked}
                             error={!question.valid}
                             fullWidth>

                    <div className={classes.row}>
                        {
                            value ? <img className={classes.image}
                                         src={value}
                                         alt="signature"/>
                                  : <InputLabel className={classes.label}
                                                required={question.required}>{question.description}</InputLabel>
                        }

                        <Button className={classes.submitButton}
                                variant="contained"
                                color='primary'
                                onClick={() => this.setState({open: true})}>
                            {value ? "Pas aan" : "Plaats handtekening"}
                        </Button>

                    </div>

                    {children}
                    <Message question={question}/>

                    <Modal open={open}
                           onClose={() => this.setState({open: false})}>
                        <div className={classes.modal}>

                            <SignaturePadWrapper dataUrl={value}
                                                 ClearButton={ClearButton}
                                                 CancelButton={CancelButton}
                                                 DoneButton={DoneButton}/>

                        </div>
                    </Modal>

                </FormControl>
                <Tip tip={question.tooltip}/>
            </div>
        );
    }
}

SignatureQuestion.propTypes = {
    question: questionPropType.isRequired,
    value: stringOrNumber.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignatureQuestion);
export {
    SignatureQuestion,
}
