/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 1-2-18.
 */
import React from "react";
import {formattedBirthdate, fullAddress, fullName} from "../../../../../../../../util/customer";
import {Button, Card, CardContent, Divider, Grid, Typography} from "@material-ui/core";
import Item from "../../markup/Item";
import ModalForm from "../../../../../../../component/form/ModalForm/ModalForm";
import {Forms} from "../../../../../../../../api/form/FormClient";
import {postClient} from "../../postCustomer";
import customerPropType from "../../../../util/customerPropType";
import Partner from "../Partner";
import Children from "../Children";
import customerUpdater from "../../updater/customerUpdater";
import addressUpdater from "../../updater/addressUpdater";
import BigCardHeader from "../../../../../../../component/Card/BigCardHeader";
import SmallCardHeader from "../../../../../../../component/Card/SmallCardHeader";
import WcIcon from '@material-ui/icons/Wc';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import FavoriteIcon from '@material-ui/icons/Favorite';
import RoomIcon from '@material-ui/icons/Room';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PaymentIcon from '@material-ui/icons/Payment';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

const MainPrivateCustomer = ({customer, onAccept, usedByCustomer = false}) => {
    return <Card>
        <CardContent>
            {
                customer.customerStatus === "PROSPECT" && !usedByCustomer ? <Button variant="contained"
                                                                                    color='primary'
                                                                                    onClick={onAccept(customer)}>
                                                                              Accepteer als klant
                                                                          </Button>
                                                                          : null
            }

            <BigCardHeader title={fullName(customer)}/>
            <Typography component="div">
                Geboren op {formattedBirthdate(customer)}
                <ModalForm formEndpoint={Forms.privateCustomerPersoonsGegevens}
                           data={customer}
                           submitAction={postClient(customerUpdater)(customer)}/>
            </Typography>

            <Divider/>

            <SmallCardHeader title="Adres"/>

            <Grid container>
                <Item object={customer.addresses}
                      icon="home"
                      getDescription={fullAddress}
                      modalForm={<ModalForm formEndpoint={Forms.editAddress}
                                            data={customer.addresses}
                                            submitAction={postClient(addressUpdater)(customer)}/>}/>
            </Grid>

            <SmallCardHeader title="Persoonsgegevens"/>

            <Grid container>

                <Item object={customer}
                      icon={<DateRangeIcon/>}
                      title="Geboortedatum"
                      getDescription={customer => formattedBirthdate(customer)}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerPersoonsGegevens}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon={<WcIcon/>}
                      title="Geslacht"
                      getDescription={customer => customer.gender}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerPersoonsGegevens}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="persons"
                      title="Naam"
                      getDescription={customer => fullName(customer)}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerPersoonsGegevens}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon={<RoomIcon/>}
                      title="Nationaliteit"
                      getDescription={customer => customer.nationality}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerPersoonsGegevens}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon={<FavoriteIcon/>}
                      title="Burgerlijke staat"
                      getDescription={customer => customer.maritialStatus}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerPersoonsGegevens}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="people"
                      title="Gezinssamenstelling"
                      getDescription={customer => customer.familyComposition}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerPersoonsGegevens}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>
            </Grid>

            <SmallCardHeader title="Contactgegevens"/>

            <Grid container>
                <Item object={customer}
                      icon="phone"
                      title="Vaste telefoon"
                      getDescription={customer => customer.phone}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerContact}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>
                <Item object={customer}
                      icon={<PhoneAndroidIcon/>}
                      title="Mobiel"
                      getDescription={customer => customer.mobile}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerContact}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="mail_outline"
                      title="Emailadres"
                      getDescription={customer => customer.email}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerContact}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>
            </Grid>

            <SmallCardHeader title="Overig"/>

            <Grid container>
                <Item object={customer}
                      icon={<PermIdentityIcon/>}
                      title="Bsn"
                      getDescription={customer => customer.bsn}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerOverig}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon="euro_symbol"
                      title="Inkomen"
                      getDescription={customer => customer.incomeRange}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerOverig}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon={<CreditCardIcon/>}
                      title="Bankrekeningnummer"
                      getDescription={customer => customer.ibanBankAcc}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerOverig}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon={<PaymentIcon/>}
                      title="Betaalmethode"
                      getDescription={customer => customer.collectionMethod}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerOverig}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon={<FingerprintIcon/>}
                      title="Soort legitimatie"
                      getDescription={customer => customer.idType}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerOverig}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>

                <Item object={customer}
                      icon={<FingerprintIcon/>}
                      title="Documentnummer legitimatie"
                      getDescription={customer => customer.legitimationDocNum}
                      modalForm={<ModalForm formEndpoint={Forms.privateCustomerOverig}
                                            data={customer}
                                            submitAction={postClient(customerUpdater)(customer)}/>}/>
            </Grid>

            <Divider/>
            <Partner customer={customer}/>
            <Divider/>
            <Children customer={customer}/>

        </CardContent>
    </Card>;
};

MainPrivateCustomer.propTypes = {
    customer: customerPropType,
};

export default MainPrivateCustomer;
export {
    MainPrivateCustomer,
}
