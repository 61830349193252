import ActionType from "../../action/ActionType";
import {getJson} from "../HTTP/get";
import {postFile, postJson} from "../HTTP/post";
import {putJson} from "../HTTP/put";
import {deleteJson} from "../HTTP/del";

class RelationClient {

    static clientEndpoint = "client/";
    static businessEndpoint = "business/";
    static customerEndpoint = "customer/";
    static consultantEndpoint = "consultant/";
    static organization = "organization/";
    static user = "user/";
    static orgUser = "orgMapping/";
    static documentEndpoint = "document/";

    static noteEndpoint = "note"
    static orderEndpoint = "order"

    static factuurEndpoint = "factuur"

    static suggestEndpoint = "suggest/customer";

    static fileUploadEndpoint = "document/";
    static fileMappingEndpoint = "documentMapping/";

    static getServiceUrl(endpoint) {
        return `${process.env.REACT_APP_RELATIONSERVICE}/${endpoint}`;
    }

    static getNoteEndpoint(customer) {
        return RelationClient.getServiceUrl(`${this.noteEndpoint}/${customer.id}`);
    }


    static getOrderEndpoint(customer) {
        return RelationClient.getServiceUrl(`${this.orderEndpoint}/${customer.id}`);
    }

    static getFactuurEndpoint() {
        return RelationClient.getServiceUrl(`${this.factuurEndpoint}`);
    }

    static getData(endpoint) {
        return getJson()(RelationClient.getServiceUrl(endpoint));
    }

    static postData(endpoint, data) {
        return postJson(RelationClient.getServiceUrl(endpoint), {})(data);
    }

    static putData(endpoint, data) {
        return putJson(RelationClient.getServiceUrl(endpoint))(data);
    }

    static postFile(endpoint, file) {
        return postFile(RelationClient.getServiceUrl(endpoint))(file);
    }

    static deleteData(endpoint) {
        return deleteJson(RelationClient.getServiceUrl(endpoint));
    }


}

/**
 * Transforms an error (of the kind reported by the Relations service) into errorAction
 * @param error:  an object with the actual response (as returned by the RelationManager) in error.response
 * @return {Action}
 */
const errorAction = error => ({
    type: ActionType.ERROR,
    message: "Problem adding Consumer",
    error: error.response.error,
});

export default RelationClient;
export {
    RelationClient,
    errorAction,
}
