
import {querystring, restApi} from "../util";
import {getJson, getXml} from "../../HTTP/get";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 25-9-18.
 */

const endpoints = {
    history: `${restApi}/history`,
    caseDefinition: `${restApi}/case-definition`,
};


/**
 *  @type {CaseClient}
 */
class CamundaCaseClient {

    static caseDiagramCache = {};

    static getCaseCount(query) {
        return getJson()(`${endpoints.history}/case-instance/count${querystring(query)}`);
    }

    static getCases(query) {
        return getJson()(`${endpoints.history}/case-instance${querystring(query)}`);
    }

    static getCase(id) {
        return getJson()(`${endpoints.history}/case-instance/${id}`);
    }

    static getCaseInstance(id) {
        return getJson()(`${endpoints.history}/case-instance/${id}`);
    }

    static getCaseDiagram(id) {
        return getXml(this.caseDiagramCache)(`${endpoints.caseDefinition}/${id}/diagram`);
    }

    static getHistoricCaseActivityInstances(query) {
        return getJson()(`${endpoints.history}/case-activity-instance${querystring(query)}`);
    }

    static getActivities(query) {
        return getJson()(`${endpoints.task}${querystring(query)}`);
    }
}

export default CamundaCaseClient;
