import React from "react";
import {Button, FormControl, Icon, InputLabel, MenuItem, Select} from "@material-ui/core";


const getName = user => user.firstName + " " + user.lastName


const userByName = (name, users) => {
    for (const user of users) {
        if (getName(user) === name) {
            return user;
        }
    }
    console.error("User not found");
}

class ConsultantForm extends React.Component {

    state = {
        selection: null
    }

    render() {
        const {handleSelection, users} = this.props;

        const onChange = event => this.setState({selection: event.target.value});

        const {selection} = this.state;
        return (
            <FormControl>
                <InputLabel>naam</InputLabel>
                <Select name={"consultant"}
                        onChange={onChange}
                        value={selection ? selection : ""}>
                    {
                        users.map(
                            u =>
                                <MenuItem key={getName(u)}
                                          value={getName(u)}>
                                    {getName(u)}
                                </MenuItem>
                        )
                    }
                </Select>
                <br/>
                <Button variant="contained"
                        color='primary'
                        onClick={() => handleSelection(userByName(selection, users))}
                        type="submit">Koppelen <Icon>arrow_forward</Icon>
                </Button>

            </FormControl>);
    }

}


export default ConsultantForm;
