/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 12-12-17.
 */

import {SubmissionError} from "redux-form";

/**
 *
 * @param {String} message
 * @return {function(String, String): void}
 */
const throwSubmissionError =
    message =>
        ({field, message: fieldMessage}) => {
            throw new SubmissionError({
                                          [field]: fieldMessage,
                                          "_error": message
                                      });
        };

export default throwSubmissionError;
