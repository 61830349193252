/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 20-12-17.
 */

import React from "react";
import {CircularProgress, withStyles} from "@material-ui/core";
import LoggedOn from "../../../../component/Main/Base/LoggedOn/LoggedOn";
import {customerClient} from "../../../../../api/relation/customer/CustomerClient";
import {noteClient} from "../../../../../api/relation/note/NoteClient";
import Notes from "../../../../component/Note/Notes";
import Orders from "../../../../component/Order/Orders";
import MiniPages from "../../../../component/MiniPage/MiniPages";
import MiniPage from "../../../../component/MiniPage/MiniPage";
import Documents from "../components/documents/Documents";
import NotesLabel from "../../../../component/Note/NotesLabel";
import Proposals from "../../../../component/Proposals/Proposals";
import DocumentsLabel from "../components/documents/DocumentsLabel";
import {proposalClient} from "../../../../../api/proposal/ProposalClient";
import ProposalLabel from "../../../../component/Proposals/ProposalLabel";
import {Consultants} from "../components/consultants/Consultants";
import PrivateCustomerMain from "../components/main/PrivateCustomerMain";

const styles = theme => ({
    tabs:    {
        backgroundColor: '#e0f2f1',
    },
    section: {
        display:  "flex",
        flexWrap: "wrap",
        margin:   '30px 30px -15px',
    },
    label:   {
        fontSize: "15px",
    },
});

const getNotes = customer => noteClient.getNotes(customer);

/**
 *
 * @param {function} stateUpdater state -> Promise
 * @param customerId
 * @returns {Promise<*>}
 */
const reloadCustomer =
    (stateUpdater, customerId) =>
        () => {
            return customerClient.fetchCustomer(customerId)
                                 .then(customer => stateUpdater({customer}));
        };

/**
 * @param {function} stateUpdater state -> Promise
 * @param customerId
 * @returns {Promise<*>}
 */
const reloadFullCustomer =
    (stateUpdater, customerId) =>
        () =>
            customerClient
                .fetchCustomer(customerId)
                .then(customer =>
                          Promise.all([stateUpdater({customer}),
                                       customerClient.documents(customer)
                                                     .then(response => response.entity)
                                                     .then(documents => stateUpdater({documents})),
                                       proposalClient.findProposals(
                                                         customer)
                                                     .then(
                                                         proposals => stateUpdater(
                                                             {proposals})),
                                      ]));

class PrivateCustomer extends React.Component {

    state = {
        notes:     [],
        documents: [],
        proposals: [],
        customer:  null,
    };

    setAsyncState = (newState) =>
        new Promise((resolve) => this.setState(newState, resolve));

    componentDidMount() {
        reloadFullCustomer(this.setAsyncState, this.props.match.params.id)();
        getNotes(this.props.match.params.id).then(notes => this.setState({notes: notes}));
    }

    render() {
        const {classes} = this.props;
        const {proposals, notes, customer, documents} = this.state;

        return customer?
            <LoggedOn>
                <MiniPages overwrites={classes} startPage={0}

                           miniPages={[
                               MiniPage.createWithIcon("Klantgegevens",
                                                       "person", <PrivateCustomerMain
                                       customer={customer}/>),
                               MiniPage.createWithIcon(
                                   <ProposalLabel proposals={proposals}
                                                  overwrites={classes}/>,
                                   "description",
                                   <Proposals proposals={proposals}/>),
                               MiniPage.createWithIcon(
                                   <NotesLabel notes={notes}
                                               overwrites={classes}/>,
                                   "access_time",
                                   <Notes customer={customer}
                                          notes={notes}/>),
                               MiniPage.createWithIcon("Bestellingen", "security", <Orders customer={customer}/>),
                               MiniPage.createWithIcon(
                                   <DocumentsLabel documents={documents}/>,
                                   "attach_file",
                                   <Documents/>),
                               MiniPage.createWithIcon(
                                   "Consultants",
                                   "attach_file",
                                   <Consultants customer={customer}
                                                reloadCustomer={reloadCustomer(this.setAsyncState,
                                                                               customer.id)}/>),
                           ]}/>
            </LoggedOn> :
            <LoggedOn>
                <CircularProgress/>
            </LoggedOn>;
    }
}

export default withStyles(styles)(PrivateCustomer)

