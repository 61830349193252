import {Forms} from "../../../../form/FormClient";
import blissForm2Customer from "./blissForm2Customer";
import garantieStellingForm2Customer from "./garantieStellingForm2Customer";
import garantieStellingFormVerkoper2Customer from "./garantieStellingFormVerkoper2Customer";
import bouwkundigeKeuring2Customer from "./bouwkundigeKeuring2Customer";
import businessForm2Customer from "./businessForm2Customer";
import mogelijkForm2Customer from "./mogelijkForm2Customer";
import quickScan2Customer from "./quickScan2Customer";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 04-09-20.
 */

const convert = formType => {

    switch (formType) {
        case Forms.blissHypotheek:
            return blissForm2Customer;
        case Forms.garantiestelling:
            return garantieStellingForm2Customer;
        case "VerkoperGarantiestelling":
            return garantieStellingFormVerkoper2Customer;
        case Forms.mogelijk:
            return mogelijkForm2Customer;
        case Forms.bouwkundigekeuring:
            return bouwkundigeKeuring2Customer;
        case Forms.businessCustomer:
            return businessForm2Customer;
        case Forms.quickScan:
            return quickScan2Customer;
        default:
            throw new Error(`Unknown formtype '${formType}'`);
    }
};

export default convert;
