import * as React from "react";
import {Card, CardContent, Grid} from "@material-ui/core";
import securePage from "../../../SecurePage/SecurePage";
import {promiseAction} from "../../../../../middleware/promiseDispatch";
import ActionType from "../../../../../action/ActionType";
import errorAction from "../../../../../action/creator/error";
import {organizationClient} from "../../../../../api/relation/identity/organization/OrganizationClient";
import route from "../../../../../action/creator/router";
import retrieving from "../../../../../action/creator/asynchonous/retrieving";
import QuestionForm from "../../../../component/QuestionForm/smart/QuestionForm";
import LoggedOn from "../../../../component/Main/Base/LoggedOn/LoggedOn";
import {Forms} from "../../../../../api/form/FormClient";
import BigCardHeader from "../../../../component/Card/BigCardHeader";
import {roles} from "../../../../../api/login/LoginClient";
import {connect} from "react-redux";
import {compose} from "redux";
import retrieveForm from "../../../../../action/creator/asynchonous/form/retrieveForm";

const remoteError = error => {
    console.error(`${error.message}: ${error.response.message} -- ${error.response.error}`);
    return errorAction("Error adding Organization")(error.response.message);
};

/**
 * @param {object} form
 * @return {Action}
 */
const addOrganizationAction = form => promiseAction(organizationClient.add(form)
        .then(() => route("/organizations")),
    retrieving(ActionType.asynchronous.form.post.POSTING),
    remoteError);


class NewOrganization extends React.Component {

    componentDidMount() {
        this.props.loadForm();
    }

    render() {
        return (<LoggedOn>
            <Grid item
                  lg={12}
                  md={12}>
                <Card>
                    <BigCardHeader title="Nieuwe organisaties"/>
                    <CardContent>
                        <QuestionForm endpoint={Forms.organization}
                                      submitAction={addOrganizationAction}/>
                    </CardContent>
                </Card>
            </Grid>
        </LoggedOn>);

    }
}

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
    loadForm: values => dispatch(retrieveForm(Forms.organization, values)),
});

connect(mapStateToProps, mapDispatchToProps)

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    securePage([roles.admin])
)(NewOrganization);


// const NewOrganization2 = ({form}) => (
//     <LoggedOn>
//         <Grid item
//               lg={12}
//               md={12}>
//             <Card>
//                 <BigCardHeader title="Nieuwe organisaties"/>
//                 <CardContent>
//                     <QuestionForm endpoint={Forms.organization}
//                                   submitAction={addOrganizationAction}/>
//                 </CardContent>
//             </Card>
//         </Grid>
//     </LoggedOn>
// );
//
// export default securePage([roles.admin])(NewOrganization);
