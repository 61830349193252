/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 1-10-18.
 */


export default {
    fullWidth: {
        padding: "10px",
        marginBottom: "30px",
    },
    floatRight: {
        padding: 10,
        float: "right",
        marginLeft: "80px",
        marginBottom: "80px",
    },
    paragraph: {
        marginBottom: "10px",
    },
    personaAvatar: {
        width: 128,
        height: 128,
    }
}
