import ContinuousStroke from "./ContinuousStroke";
import DiscreteStroke from "./DiscreteStroke";
import {distance} from "../geometry/point/Point";

class Samples {

    /**
     * @type {Array<Sample>}
     */
    samples = [];

    /**
     * @type {number}
     */
    count = 0;

    /**
     * @param {Sample} sample
     * @param {boolean} force
     */
    add(sample, force = false) {
        this.count++;

        if (this.samples.length === 0) {
            // To reduce the initial lag we kickstart the process by duplicating the first sample
            this.samples.push(sample);
            this.samples.push(sample);
        }
        else {
            if (force || this.keepSample(sample)) {
                this.samples.push(sample);
            }
        }

        // We need four samples to create a curve, the remainder may be discarded
        this.samples = this.samples.slice(-4);
    }

    /**
     * When the mouse moves slowly and the samples are close together, forcing the Bézier curves through all the
     * samples will cause the wobbliness of the mouse movement to show up in the interpolation results. If we drop
     * some samples the curve will still approximate them, but in a much smoother way.
     *
     * On the other  hand, when the mouse moves fast and too many samples are dropped, the curve will not approximate
     * the samples and the user will experience drag. So this is a bit of a balancing act.
     *
     * This method will choose whether a sample should be dropped or not.
     *
     * @param {Sample} sample
     * @return {boolean}
     */
    keepSample(sample) {

        /**
         * There are a couple of possible heuristics:
         * 1. Keep every other sample
         * 2. Make a choice based on the time difference with the previous sample
         * 3. Make a choice based on the difference in distance with the previous sample
         *
         * The final chosen heuristic is based simply based on manual trial and error of
         * various combinations of these heuristics.
         */

        const previous = this.samples.at(-1);
        //const deltaT = sample.time - previous.time;  // lag seems to start at 25/30, unworkable at 50
        const deltaD = distance(sample, previous);   // lag seems to start at 10, unworkable at 20, but at 10 everything still pretty wobbly
        return this.count % 2 === 0 || deltaD > 15;
    }

    /**
     * @param {Curves} curves
     * @return {Stroke}
     */
    getStroke(curves) {
        if (this.continuousStrokeIsPossible()) {
            // Ugly casting necessary because jsdoc does not recognize instances of a subclass as instances of the parent class
            return /** @type {Stroke} */ new ContinuousStroke(curves, this.samples);
        }
        else {
            return /** @type {Stroke} */ new DiscreteStroke(curves, this.samples);
        }
    }

    /**
     * A smooth curve between two samples (say p1 and p2) can be determined by
     * the ContinuousStroke class.
     *
     * In order for the new curve to also fit smoothly to the preceding curve
     * it's necessary to know the last sample p0 before p1.
     *
     * Similarly, the next sample p3 (following p2) must be known in order to connect
     * smoothly to the next curve between p2 and p3
     *
     * So in order for a smoothly fitting continuous curve to be created there must be at least 4 samples
     *
     * @return {boolean}
     */
    continuousStrokeIsPossible() {
        return this.samples.length >= 4;
    }
}

export default Samples;
