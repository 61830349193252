/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 14-6-18.
 */
import moment from "moment/moment";

const emptyProperty = () => ({
    startDate: moment().format("DD-MM-YYYY"),
    property: {
        address: {}
    }
});

/**
 * @param assetIndex
 * @return {updater}
 */
const propertyUpdater =
    (assetIndex = -1) =>
        (customer, form) => {

            let asset = assetIndex < 0 ? emptyProperty() : customer.customerAssets[assetIndex];

            form.groups.forEach(function (group) {
                group.questions.forEach(function (question) {
                    const field = question.field;

                    if (question.type === "BOOLEAN") {
                        question.value = question.value === "J";
                    }

                    if (field === "houseNo" ||
                        field === "addition" ||
                        field === "postalCode" ||
                        field === "streetName" ||
                        field === "city") {
                        asset.property.address[field] = question.parsedValue;
                    }
                    else {
                        asset.property[field] = question.parsedValue;
                    }
                });
            });

            if (assetIndex < 0) {
                if (!(customer.customerAssets instanceof Array)) {
                    customer.customerAssets = [];
                }
                customer.customerAssets.push(asset);
            }
            else {
                customer.customerAssets[assetIndex] = asset;
            }
            return customer;
        };

export default propertyUpdater;
