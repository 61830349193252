class Weights {

    /**
     * {number}
     */
    minWeight = 0.5;

    /**
     * {number}
     */
    maxWeight = 2.5;

    /**
     * @param {number} minWeight
     * @param {number} maxWeight
     */
    constructor(minWeight, maxWeight) {
        if (minWeight) {
            this.minWeight = minWeight;
        }
        if (maxWeight) {
            this.maxWeight = maxWeight;
        }
    }

    curveWeights(startVelocity, endVelocity) {
        return {
            startWeight: this.curveWeight(startVelocity),
            endWeight: this.curveWeight(endVelocity)
        };
    }

    curveWeight(velocity) {
        return Math.max(this.maxWeight / (velocity + 1), this.minWeight);
    }

    default() {
        return (this.minWeight + this.maxWeight) / 2;
    }

}

export default Weights;
