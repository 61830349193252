/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 31-07-19.
 */

/**
 * Removes unnecessary data from the the variable object:
 * From: {
 *     name: {
 *         type: "Boolean"
 *         value: true
 *         valueInfo: {
 *             ...
 *         }
 *     }
 *     nextName: ...
 * }
 *
 *
 * To: {
 *     name: true,
 *     nextName: ....
 * }
 *
 *
 * @param variables
 */
const cleanupVariables = variables => {
    let unpacked = {};
    Object.entries(variables)
          .forEach(([key, {value}]) => unpacked[key] = value);
    return unpacked;
};

export {
    cleanupVariables
};
