import {Grid, Icon, Typography} from "@material-ui/core";
import React from "react";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 8-7-19.
 */


const Item = ({object, icon, description, getDescription, getDetails}) => [
    <Grid item
          key={1}
          xs={2}>
        {icon ? <Icon color="primary">{icon}</Icon> : description}

    </Grid>,
    <Grid item
          key={2}
          xs={10}>
        {getDescription(object)}
        {
            getDetails ? <Typography>{getDetails(object)}</Typography> : null
        }
    </Grid>,];

export default Item;
