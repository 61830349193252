/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 11-12-17.
 */

import * as React from "react";
import {FormControl, FormHelperText, Input, InputLabel} from "@material-ui/core";

const LoginField = classes => ({input, meta: {error, invalid}, placeholder, id, password = false}) => {
    return (
        <FormControl fullWidth
                     error={invalid}
                     margin='normal'>
            <InputLabel>{placeholder}</InputLabel>
            <Input {...input} type={password ? "password" : "text"}
                   className={classes.root}
                   id={id}/>
            {invalid && <FormHelperText>{error}</FormHelperText>}
        </FormControl>);
};
export default LoginField;
