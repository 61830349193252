/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 22-11-17.
 */
import React from "react";
import LoginForm from "../../component/LoginForm/LoginForm";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import LoggedOff from "../../component/Main/Base/LoggedOff/LoggedOff";

const LoginPage = ({message, classes}) => (
    <LoggedOff header="Mijn Bliss">
        {message && <Card className="login-card">
            <CardHeader title="Er is een probleem"/>
            <CardContent className="login-card-content">
                {message}
            </CardContent>
        </Card>}
        <LoginForm/>
    </LoggedOff>
);

export default LoginPage;

