/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 03-08-19.
 */

import Promise from "bluebird";
import {instanceVariables, processInstance} from "../../../../../api/process/process/processes";
import {cleanupVariables} from "../../util";
import {customerClient} from "../../../../../api/relation/customer/CustomerClient";

/**
 * Retrieves the data common to most DocumentedRequest tasks (processInstance, instanceVariables and documents), and saves them to the contexts state
 *
 * @param {React} context
 * @return {function(string): Promise<null>}
 */
const getData =
    context =>
        processInstanceId =>
            Promise.all([processInstance(processInstanceId),
                         instanceVariables(processInstanceId)])
                   .spread((instance, variables) => {
                       variables = cleanupVariables(variables);
                       return Promise.all([context.setState({
                                                                instance,
                                                                agent: variables.agent,
                                                                customer: variables.customer,
                                                                request: variables.form,
                                                                variables: variables,
                                                            }),
                                           customerClient.documents(variables.customer)
                                                         .then(documents => context.setState({documents: documents.entity}))
                                          ]);
                   });

export default getData;
