import {fittedCurve} from "./BézierCurve";
import MovingAverage from "../../util/MovingAverage";
import Weights from "./Weights";

class Curves {

    /**
     * {Weights}
     */
    weights;

    /**
     * {MovingAverage}
     */
    velocities = new MovingAverage(0.1, 0.25, 0.65);

    /**
     * @param {number} minWeight
     * @param {number} maxWeight
     * @param {number[]|null} velocityWeights
     */
    constructor({minWeight, maxWeight, velocityWeights = null}) {
        this.weights = new Weights(minWeight, maxWeight);

        if (velocityWeights) {
            this.velocities = new MovingAverage(...velocityWeights)
        }
    }

    reset() {
        this.velocities.reset();
    }

    /**
     * @param  {Array<Sample>} samples
     * @return {BézierCurve}
     */
    newCurve(samples) {
        const weights = this.weights;

        const oldVelocity = this.velocities.average() > 0 ? this.velocities.average() : 1

        const newCurve = fittedCurve(samples);
        this.velocities.add(newCurve.velocity());

        const newVelocity = this.velocities.average();

        return newCurve.withWeights(weights.curveWeights(oldVelocity, newVelocity));
    }

}

export default Curves;
