/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 20-11-18.
 */
const styles = theme => ({
    icon: {
        cursor: "pointer",
    },
    customer: {},
    prospect: {
        color: "#ddd"
    },
    archived: {
        textDecoration: "line-through"
    },
    selection: {
        backgroundColor: "#fcfce7",
    }
});

export default styles;
