const nameFromDefinitionId = definitionId => definitionId.replace(/:.*/i, "");

const friendlyNameFromDefinitionId = definitionId => {

    switch (nameFromDefinitionId(definitionId)) {
        case "DOCUMENTED_REQUEST":
            return "Aanvraag met documenten";
        case "RESET_PASSWORD":
            return "Wachtwoord aanpassen";
        default:
            return "Bankgarantie";
        // default:
        //     return nameFromDefinitionId(definitionId)
    }
};

export {
    friendlyNameFromDefinitionId
};
