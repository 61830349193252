/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 22-11-17.
 */
import React from "react";
import CopyRight from "./CopyRight/CopyRight";
import {Grid, Typography, withStyles} from "@material-ui/core";
import skin, {getColor} from "../../../../style/skin/skin";
import Links from "./Links";
import {APP_INSTANCE, appInstance} from "../../../../../appInstance";
import {
    bankgarantieLinks,
    blissLinks,
    bouwkundigeKeuringLinks, erasmusLinks,
    mogelijkLinks,
    quickScanLinks,
    woningScanLinks,
} from "./skinLinks";

const footerStyles = theme => {
    const classes = {
        logo:         {
            width: "150px",
            ...theme.skin.overrides.logo,
        },
        footer:       {
            background: getColor(skin.palette.footerBackground),
            padding:    "20px 0 0 0",
        },
        columnHeader: {
            color:        getColor(skin.palette.footerForeground),
            marginBottom: 12,
        },
        container:    {
            marginBottom: "20px",
        },
    };
    if (theme.skin.images.logo) {
        classes.hasLogo = {width: 100};

    }
    return classes;
};

const footerLinks = () => {
    switch (appInstance()) {
        case APP_INSTANCE.BANKGARANTIE:
            return bankgarantieLinks;
        case APP_INSTANCE.BLISS:
            return blissLinks;
        case APP_INSTANCE.BOUWKUNDIGE_KEURING:
            return bouwkundigeKeuringLinks;
        case APP_INSTANCE.ERASMUS:
            return erasmusLinks;
        case APP_INSTANCE.MOGELIJK:
            return mogelijkLinks;
        case APP_INSTANCE.WONING_SCAN:
            return woningScanLinks;
        case APP_INSTANCE.QUICK_SCAN:
            return quickScanLinks;
        default:
            throw Error(`Could not determine footerLinks for AppInstance ${appInstance()}`);
    }
};

const Footer = ({classes}) =>
    <footer className={classes.footer}>
        <Grid container
              className={classes.container}
              direction="row"
              justify="center">
            <Grid item
                  xs={10}
                  lg={5}>
                <Typography variant="h5"
                            className={classes.columnHeader}>{skin.texts.company}</Typography>
                {
                    classes.hasLogo? <img className={classes.logo}
                                          src={skin.images.logo}
                                          alt="Logo"/>
                        : null
                }
            </Grid>
            <Grid item
                  xs={10}
                  lg={3}>
                {
                    footerLinks().length > 0? <Links links={footerLinks()}
                                                     classes={classes}/> : null
                }
            </Grid>
        </Grid>
        <CopyRight/>
    </footer>;

export default withStyles(footerStyles)(Footer);


