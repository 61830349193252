/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 6-9-17.
 */
import ActionType from "../../action/ActionType";

const status = (status = {value: null, count: 0}, action) => {
    if (action.type === ActionType.asynchronous.quote.status.RETRIEVED) {

        return {value: action.payload, count: status.count + 1};

        // return (status.startsWith(action.payload))
        //     ? `${status}.`
        //     : action.payload;
    }
    return status;
};
export default status;
