/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 30-8-18.
 */
import {Icon, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {customerClient} from "../../../api/relation/customer/CustomerClient";


const pdf2blob = pdf => {
    const base64Str = Buffer.from(pdf).toString('base64');
    const binaryString = window.atob(base64Str);
    const binaryLen = binaryString.length;

    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], {type: "application/pdf"});
}

const openPdf =
    pdf =>
        customerClient.getDocument(pdf)
            .then(data => data.arrayBuffer())
            .then(pdf2blob)
            .then(window.URL.createObjectURL)
            .then(objectURL => {

                const link = document.createElement('a');
                document.body.appendChild(link);
                link.href = objectURL;
                link.download = "Factuur.pdf";
                link.click();
                window.URL.revokeObjectURL(objectURL);
                link.remove();

            })

const Documents = ({documents = [], hideTypeColumn = false, onDeleteDocument}) =>
    <Table>
        <TableHead>
            <TableRow>
                {hideTypeColumn ? null : <TableCell/>}

                <TableCell>Titel</TableCell>
                <TableCell>Verwijder</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {documents.map(document =>
                <TableRow key={document.id}>
                    {hideTypeColumn ? null : <TableCell>{document.documentType}</TableCell>}
                    <TableCell style={{cursor: "pointer"}}
                               onClick={() => openPdf(document)}>
                        {document.title}
                    </TableCell>
                    <TableCell style={{cursor: "pointer"}}
                               onClick={() => onDeleteDocument(document)}

                    >
                        <Icon color="primary">delete</Icon>
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    </Table>;

export default Documents;
