/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 8-7-19.
 */
import React from "react";
import {Card, CardContent} from "@material-ui/core";
import BigCardHeader from "../../../../../component/Card/BigCardHeader";
import uploadTypes from "../../../../../component/Documents/UploadTypes";
import {withStyles} from "@material-ui/core/index";
import {fullName} from "../../../../../../util/customer";

const styles = theme => ({
    container: {
        marginBottom: "10px",
    },
    header: {
        fontWeight: "bold"
    }
});

const Request = ({customer, request, classes}) => {
    return <Card>
        <CardContent>
            <BigCardHeader title={`${fullName(customer)}`}/>

            <div component="nav"
                 aria-label="Main mailbox folders">
                {
                    request.groups
                           .map(group => Object.values(group.fields)
                                               .filter(({value}) => !Object.values(uploadTypes).includes(value))
                                               .map(({value, description}) =>
                                                        <div className={classes.container}
                                                             title={description}
                                                             key={description}>
                                                            <div className={classes.header}
                                                                 key={1}>
                                                                {description + " "}
                                                            </div>
                                                            <div className={classes.column}
                                                                 key={2}>{value}</div>
                                                        </div>))
                }
            </div>
        </CardContent>
    </Card>;
};

export default withStyles(styles)(Request)
