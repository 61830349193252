/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 11-10-18.
 */
import React from "react";
import PropTypes from "prop-types";
import {questionShape} from "../../../propType/question";
import MultiSelectQuestion from "../../presentation/MultiSelectQuestion/MultiSelectQuestion";

const getSelection = question => {
    let json = [];
    try {
        json = JSON.parse(question.value);
    } catch (error) {
        console.error(`Invalid value in question ${question.description}: '${question.value}' can't be parsed`);
    }

    if (!Array.isArray(json)) {
        console.error(`Invalid value in question ${question.description}: '${question.value}' is not an array`);
        return [];
    }
    return json
};

const MultiSelect = Presentation =>
    ({question, onSubmitQuestion}) => {

        const selection = getSelection(question);
        return <Presentation question={question}
                             selection={getSelection(question)}
                             onAddSelection={value => onSubmitQuestion(JSON.stringify([...selection,
                                                                                       value]))}
                             onRemoveSelection={value => onSubmitQuestion(JSON.stringify(selection.filter(selected => selected !== value)))}/>
    };

MultiSelect.propTypes = {
    question: PropTypes.shape(
        {
            ...questionShape,
            acceptableValues: PropTypes.object.isRequired
        }),
    onSubmitQuestion: PropTypes.func.isRequired,
};

export default MultiSelect(MultiSelectQuestion);
export {
    MultiSelect,
}
