import {userClient} from "../../../../../api/relation/identity/user/UserClient";
import ActionType from "../../../../../action/ActionType";
import {promiseAction} from "../../../../../middleware/promiseDispatch";
import retrieving from "../../../../../action/creator/asynchonous/retrieving";
import {errorAction} from "../../../../../api/relation/RelationClient";
import nop from "../../../../../action/creator/nop";

/**
 * Assumes that response is a response to one of the User apis of the Relations service.
 * Some of these service calls wrap the resulting organization in an 'entity' element (and some not)
 *
 * @param {Response} response
 * @return {object} Organization
 */
const extractUser = response => response.hasOwnProperty("entity")? response.entity : response;

/**
 * @param  {func} apiClient:  user -> Promise<Response>
 * @param {updater} updater:
 * @param {object} user
 * @param {object} submittedForm
 * @return {Promise.<Action>}
 */
const postUsr =
    apiClient =>
        updater =>
            user =>
                (submittedForm, followUp) =>
                    promiseAction(apiClient(updater(user, submittedForm))
                                      .then(extractUser)
                                      .then(followUp)
                                      .then(_ => nop),
                                  retrieving(ActionType.asynchronous.form.post.POSTING),
                                  errorAction,
                    );

const postUser = postUsr(userClient.update);
export {
    postUser,
};


