import {checkedGravatarThumbnail} from "./gravatar";
import {empty} from "../api/process/util";

const fallbackImage = new URL("/images/richard.png", window.location.href);

/**
 * @param user
 * @returns {boolean}
 */
function hasImage(user) {
    return !empty(user) && !empty(user.image);
}

/**
 * @param {object} user
 * @param {string|number} size
 * @param {string} fallback
 * @returns {Promise<string>}
 */
const imageUrl =
    (user, size, fallback) =>
        hasImage(user)
            ? Promise.resolve(user.image)
            : checkedGravatarThumbnail(user.email, size, fallback)
                .then(url => url.href)

const fullName = user => `${user.firstName} ${user.insertPart} ${user.lastName}`.replace(/\s+/g, ' ').trim()

/**
 * @param {object} user
 * @param {string|number} size
 * @param {string} fallback
 * @returns {Promise<object>}
 */
const enhanceUser =
    (user, size = 64, fallback = fallbackImage) =>
        imageUrl(user, size, fallback)
            .then(url => ({...user, imageUrl: url, fullName: fullName(user)}));

export {
    enhanceUser,
}

