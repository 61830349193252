/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-9-17.
 */
import ActionType from "../ActionType";

/**
 *
 * @param questionId
 * @param value
 * @return {Action}
 */
const updateQuestion = (questionId, value) => {
    return {
        type: ActionType.question.update,
        questionId: questionId,
        value: value,
    };
};
export default updateQuestion;
