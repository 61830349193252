/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 26-2-18.
 */
import PropTypes from 'prop-types';
import groupPropType from "./group";

const formShape = {
    valid: PropTypes.bool,
    groups: PropTypes.arrayOf(groupPropType),
};
const formPropType = PropTypes.shape(formShape);

export default formPropType;
export {
    formShape,
    formPropType,
}
