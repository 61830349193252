/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 6-9-17.
 */

import ActionType from "../../action/ActionType";

const requestId = (id = null, action) => {
    if (action.type === ActionType.asynchronous.quote.submit.RESULT) {
        return action.payload;
    }
    return id;
};
export default requestId;
