import {restrict} from "../../../util/util";
import Badge from "@material-ui/core/Badge";
import {withStyles} from "@material-ui/core";
import React from "react";

/**
 * @param {Object} object
 * @return {Object}
 */
const labelClasses =
    object =>
        restrict(object,
            ["label"]);

const merge = (classes, overwrites) => ({...classes, ...labelClasses(overwrites)})


/**
 * A React component to be used as label for the tabs containing the notesoverview
 * It shows the word Tijdlijn, joined with a badge describing the number/recency of notes
 *
 * @param notes
 * @param active
 * @param overwrites
 * @param classes
 * @return {JSX.Element}
 */
const ProposalLabel = ({proposals, active = false, overwrites = {}, classes = {}}) => {

    classes = merge(classes, overwrites);

    return proposals.length === 0
        ? <span className={classes.label}>Aanvragen</span>
        : <Badge color={"primary"}
                 badgeContent={proposals.length}>
            <span className={classes.label}>Aanvragen</span>
        </Badge>;
}

const labelStyles = {
    label: {},
}
export default withStyles(labelStyles)(ProposalLabel);
