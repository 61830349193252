import React from "react";
import PropTypes from "prop-types";

/**
 * Only shows the given children when a condition is met.
 *
 * (I think) This will evaluate the children before deciding to whether to show the children or not
 * Only use this component when the validity of the children is independent of the 'show' condition.
 *
 * Otherwise use the Optional component.
 */
class If extends React.Component {

    static propTypes = {
        show: PropTypes.bool.isRequired,
    };

    render() {
        return (this.props.show) ? [this.props.children] : [];
    }
}

export default If;
