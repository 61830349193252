/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 5-6-19.
 */

const activeActivities = activities => activities.childActivityInstances;

const activeActivityIds = activities => activeActivities(activities).map(({activityId}) => activityId);

const activeActivityNames = activities => activeActivities(activities).map(({activityName}) => activityName);

const activeUserTasks = activities => activeActivities(activities).filter(({activityType}) => activityType === "userTask");

export {
    activeActivityIds,
    activeActivityNames,
    activeUserTasks,
}
