/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 22-11-17.
 */
import React from "react";
import {Grid, withStyles} from "@material-ui/core";
import skin, {getColor} from "../../../../../style/skin/skin";
import {now} from "../../../../../util/util";

const styles = theme => ({
    copyright: {

        background: getColor(theme.skin.palette.copyrightBackground),
        padding: '20px 0'
    },
    copyrightText: {
        color: getColor(theme.skin.palette.copyrightForeground),
        //color: theme.palette.primary[500],
        fontWeight: '300'
    }
});

const CopyRight = ({classes}) => (
    <div className={classes.copyright}>
        <Grid container
              direction="row"
              justify="center">
            <Grid item
                  xs={10}
                  lg={5}>
                <div className={classes.copyrightText}>© {now().format("YYYY")} {skin.texts.copyright} </div>
            </Grid>
            <Grid item
                  xs={10}
                  lg={3}>

                <div className={classes.copyrightText}>Atlas v{global.appVersion}</div>
            </Grid>
        </Grid>
    </div>
);

export default withStyles(styles)(CopyRight);
