import {Table, TableBody, TableCell, TableHead, TableRow, withStyles} from "@material-ui/core";
import React from "react";
import {empty} from "../../../../../../api/process/util";
import ConsultantRow from "./ConsultantRow";


const styles = _ => ({
    table:         {
        marginLeft:  "25px",
        marginRight: "25px",
        width:       "95%",
    },
    tableRow:      {
        cursor: "pointer",
    },
    selection:     {
        backgroundColor: "#fcfce7",
    },
    personaAvatar: {

        width:  32,
        height: 32,
    },
});

class ConsultantTable extends React.Component {

    render() {
        const {consultingUsers, reloadCustomer, classes} = this.props;
        return (<Table className={classes.table}>
                {
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Naam</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                }

                <TableBody>
                    {
                        consultingUsers.map(
                            consultingUser => empty(consultingUser.user)
                                ? null
                                : <ConsultantRow consultingUser={consultingUser}
                                                 reloadCustomer={reloadCustomer}
                                                 classes={classes}
                                                 key={consultingUser.consultant.id}/>,
                        )
                    }
                </TableBody>
            </Table>
        );
    }
}

export default withStyles(styles)(ConsultantTable);
