/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 18-12-17.
 */
import ActionType from "../../../ActionType";
import CustomerClient from "../../../../api/relation/customer/CustomerClient";

const searchResultAction = searchResult => ({
    type: ActionType.search.searchResult,
    payload: searchResult,
});

const defaultSearch = {
    searchTerm: "",
    page: 1,
    itemsPerPage: 10,
    sorts: [{
        field: "createdAt",
        direction: "descending"
    }],
    archived: false,
    customerStatus: "CUSTOMER",
    column: [
        "firstName",
        "lastName"
    ]
};

const client = user => new CustomerClient(user);

const search =
    user =>
        args =>
            client(user).search({
                ...defaultSearch,
                ...args
            }).then(searchResultAction);

export default search;
export {
    defaultSearch,
    searchResultAction,
    search,
}
