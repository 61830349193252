import * as React from "react";
import {connect} from "react-redux";
import {retrieveForm} from "../../../action/creator/asynchonous/form/retrieveForm";
import QuestionForm from "../../component/QuestionForm/smart/QuestionForm";
import nop from "../../../action/creator/nop";

class FormTest extends React.Component {

    constructor() {
        super();
        this.state = {id: 0};
    }

    componentDidMount() {
        this.setState({id: this.props.match.params.id});
        return this.props.didMount()
    }

    render() {
        return <QuestionForm endpoint={'test/form/' + this.state.id}
                             submitAction={form => nop}
                             buttonText="Test"/>;
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return ({
        didMount: () => dispatch(retrieveForm('test/form/' + (typeof ownProps.match.params.id === "undefined" ? "" : ownProps.match.params.id)))
    });
};

export default connect(null, mapDispatchToProps)(FormTest);
export {
    FormTest
}
