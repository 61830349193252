import moment from "moment";
import Badge from "@material-ui/core/Badge";
import {withStyles} from "@material-ui/core";
import React from "react";
import {restrict} from "../../../../../../util/util";

/**
 * @param {Object} object
 * @return {Object}
 */
const labelClasses =
    object =>
        restrict(object,
            ["label"]);

const merge = (classes, overwrites) => ({...classes, ...labelClasses(overwrites)})

/**
 * @param {Array<Document>} documents
 * @param {number} days
 * @return {boolean}
 */
const hasActivity = (documents, days) => {
    const cutoff = moment().subtract(days, 'd');
    return documents === null ? false : documents
        .filter(document => moment(document.createdAt, "DD-MM-YYYY").isAfter(cutoff))
        .length > 0;
}


/**
 * @param {Array<Document>} documents
 * @param active
 * @return {String}
 */
const badgeColor = (documents, active) => hasActivity(documents, 3) || active ? 'secondary' : 'primary';

/**
 * A React component to be used as label for the tabs containing the notes overview
 * It shows the word Documenten, joined with a badge describing the number/recency of notes
 *
 * @param notes
 * @param active
 * @param overwrites
 * @param classes
 * @return {JSX.Element}
 */
const DocumentsLabel =
    ({documents, active = false, overwrites = {}, classes = {}}) => {

        classes = merge(classes, overwrites);

        return hasActivity(documents, 30) ?
            <Badge color={badgeColor(documents, active)}
                   badgeContent={documents.length}>
                <span className={classes.label}>Documenten</span>
            </Badge> :
            <span className={classes.label}>Documenten</span>;
    }

const labelStyles = {
    label: {},
}
export default withStyles(labelStyles)(DocumentsLabel);
