/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 25-9-18.
 */
import {restApi} from "../util";
import {getJson} from "../../HTTP/get";
import {postJson, postJsonNoContent} from "../../HTTP/post";
import {putJsonNoContent} from "../../HTTP/put";
import {deleteJson} from "../../HTTP/del";

const endpoints = {
    processType: `${restApi}/process-definition`,
    processes: `${restApi}/process-instance`,
    instance: `${restApi}/process-instance`,
    definition: `${restApi}/process-definition`,
};

const procesKeys = {
    hypotheekAanvraag: "HypotheekAanvraag"
}

class CamundaProcessClient {
    static instanceDiagramCache = {};

    /**
     *
     * @param {String} processDefinitionKey
     * @param {object} variables  e.g. {
     *                                    variableName1: {
     *                                       "value": 30,
     *                                     "type": "Double"
     *                                    }
     *                                    variableName1: {
     *                                       "value": 30,
     *                                       "type": "Double"
     *                                    }
     *                                 }
     */
    static startProcess(processDefinitionKey, variables) {
        const log = false;
        const data = {
            variables: variables,
            //businessKey: "Doom1", //    WHAT IS THIS BUSINESSKEY FOR ????
            withVariablesInReturn: true,
        }
        return postJson(`${endpoints.definition}/key/${processDefinitionKey}/start`, {}, log)(data);
    }

    static getProcessTypes() {
        return getJson()(`${endpoints.processType}`);
    }

    static getProcesses(key) {
        return getJson()(`${endpoints.processes}?processDefinitionKey=${key}`);
    }

    static getProcesList(key, query) {
        const data = {
            variables: query,
            "processDefinitionKey": key,
        }
        if (query) {
            data['variables'] = query
        }

        return postJson(`${endpoints.processes}`)(data);
    }

    static getInstance(instanceId) {
        return getJson()(`${endpoints.processes}/${instanceId}`);
    }

    static getProcessDiagram(processTypeId) {
        return getJson(this.instanceDiagramCache)(`${endpoints.processType}/${processTypeId}/xml`)
            .then(({bpmn20Xml}) => bpmn20Xml);
    }

    static getProcessActivities(processId) {
        return getJson(this.instanceDiagramCache)(`${endpoints.instance}/${processId}/activity-instances`)
    }

    static getInstanceVariables(processId) {
        return getJson()(`${endpoints.instance}/${processId}/variables`);
    }

    static setInstanceVariables(processId, type, name, value) {
        return putJsonNoContent(`${endpoints.instance}/${processId}/variables/${name}`)({value, type});
    }

    static setVariables(processId, variables) {
        const data = {
            modifications: variables,
        }
        return postJsonNoContent(`${endpoints.processes}/${processId}/variables`)(data);
    }

    /**
     *
     * @param customerId
     * @param {string} processDefinitionKey e.g "RESET_PASSWORD"
     * @returns {Promise<*>}
     */
    static findBycustomerId(customerId, processDefinitionKey = null) {
        let query = {
            "variables":
                [{
                    "name": "customerId",
                    "operator": "eq",
                    "value": `${customerId}`
                }]
        }
        if (processDefinitionKey) {
            query.processDefinitionKey = processDefinitionKey;
        }
        return postJson(`${endpoints.processes}`)(query).then(processes => processes.map(process => process.id));
    }

    static deleteByProcessId(processId) {
        return deleteJson(`${endpoints.processes}/${processId}`);
    }

}

export default CamundaProcessClient;
export {
    procesKeys,
}
