import ActionType from "../../action/ActionType";

const currentOrganization = (state = {}, {type, organization}) => {
    if (type === ActionType.CURRENT_ORGANIZATION) {
        return organization;
    }
    return state;
};

export default currentOrganization;
