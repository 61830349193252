/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 15-1-18.
 */
import React from "react";
import menuItems from "./menuItems";
import Persona from "./Persona/Persona";
import SideMenuGroup from "./SideMenuGroup/SideMenuGroup";
import {withStyles} from "@material-ui/core";
import {taskCount} from "../../../../../../api/process/task/tasks";
import portalMenuItems from "./portalMenuItems";
import Promise from "bluebird";
import {currentUserQuery} from "../../../../../page/process/task/TaskRestriction";

const styles = theme => ({
    root: {
        width: 270,
    }
});

class SideMenu extends React.Component {

    state = {
        myTasksCount: 0,
        allTasksCount: 0,
    };

    componentDidMount() {
        const {currentUser} = this.props;

        Promise.all([taskCount(currentUserQuery(currentUser)).then(result => result.count),
                     taskCount().then(result => result.count)])
               .spread((myTasksCount, allTasksCount) => this.setState({myTasksCount, allTasksCount}))
    }

    render() {
        const {person, classes, user} = this.props;
        const items = user ? portalMenuItems(this.state.allTasksCount) : menuItems(this.state.myTasksCount, this.state.allTasksCount)

        return <div className={classes.root}>
            <Persona person={person}/>
            {
                items.map((group, i) => <SideMenuGroup key={i}
                                                       group={group}/>)
            }
        </div>

    }
}

export default withStyles(styles)(SideMenu);
