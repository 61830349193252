const organizationToResult =
    (onClick, onAction) =>
        organization =>
            ({
                id: organization.id,
                name: organization.name,
                email: organization.email,
                phone: organization.phone,
                onClick: () => onClick(organization),
                onAction: () => onAction(organization),
            });

export {
    organizationToResult
}
