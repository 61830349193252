import React from "react";
import {Grid, Icon, Typography} from "@material-ui/core";
import {empty} from "../../../../../../api/process/util";

const isValue = value => !empty(value) && value !== "null";

class Item extends React.Component {
    render() {
        const {object, icon, title, getDescription, getDetails} = this.props;

        return (
            <Grid container>
                <Grid item
                      key={1}
                      sm={1}>
                    <span title={title}>
                         <Icon color="primary">{icon}</Icon>
                    </span>
                </Grid>
                <Grid item
                      key={2}
                      sm={11}>
                    {getDescription(object)}
                    {
                        getDetails?
                            <Typography>{isValue(getDetails(object))? getDetails(object) : ""}</Typography>
                            : ""
                    }
                </Grid>

            </Grid>
        );
    }
}

export default Item;

