/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 15-1-18.
 */

import React from "react";
import {Icon, IconButton, Typography} from "@material-ui/core";
import ProfileMenu from "./ProfileMenu";

class PersonalHeader extends React.Component {
    state = {
        anchor: null,
    };

    openMenu = event => {
        this.setState({...this.state, anchor: event.currentTarget});
    };

    closeMenu = () => {
        this.setState({...this.state, anchor: null});
    };

    render() {
        const {person, onHome, classes} = this.props;
        const {anchor} = this.state;

        return (
            <div className={classes.person}>
                {
                    onHome === null
                    ? null :
                    <IconButton color="inherit">
                        <Icon onClick={onHome}>view_module</Icon>
                    </IconButton>
                }

                {/*
                Temporary disabled until we actual have implemented email functionality
                <IconButton color="inherit">
                    <Icon>email</Icon>
                </IconButton>
                 */}
                <Typography variant="subtitle1"
                            color="inherit"
                            className={classes.name}
                            onClick={this.openMenu}>
                    Ingelogd als {person.fullName? person.fullName : person.name}
                </Typography>

                <IconButton color="inherit"
                            onClick={this.openMenu}>
                    <Icon>account_circle</Icon>
                </IconButton>

                <ProfileMenu classes={classes}
                             anchor={anchor}
                             onClose={this.closeMenu}
                             person={person}/>
            </div>
        );
    }
}

export default PersonalHeader;
