/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 16-7-18.
 */

import React from "react";
import {Grid, Typography, withStyles} from "@material-ui/core";

import Carousel from "../../../component/carousel/Carousel";

const styles = theme => ({
    root: {
        marginBottom: 30,
    },
    header: {
        color: theme.palette.primary[200],
        textAlign: "center",
        fontSize: 24,
        marginBottom: 30,
        fontWeight: 400,
    },
});

class Insurance extends React.Component {

    render() {
        const {name, children, classes} = this.props;

        return (
            <Grid container
                  className={classes.root}>
                <Grid item>

                    <Typography variant="h6"
                                className={classes.header}>
                        {name}
                    </Typography>

                    <Carousel carouselWidth={600}
                              itemWidth={240}
                              itemMargin={20}
                              offset={1}>
                        {children}
                    </Carousel>

                </Grid>
                <Grid item>
                    <Typography variant="h6">{name}</Typography>
                </Grid>
            </Grid>);
    }
}

export default withStyles(styles)(Insurance);
