/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 1-2-18.
 */
import React from "react";
import If from "../../../../../../component/Optional/If";
import {noteClient} from "../../../../../../../api/relation/note/NoteClient";
import Note from "../../../../../../component/Note/Note";

class GeneralInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {lastUpdate: null};
    }

    componentDidMount() {
        noteClient.getLastUpdate(this.props.customer)
                  .then(update => this.setState({lastUpdate: update}));
    }


    render() {
        return (
            [
                <If key={0}
                    show={typeof this.state.lastUpdate !== "undefined" && this.state.lastUpdate !== null}>

                    <Note note={this.state.lastUpdate}
                          index={0}
                          extraHeader={"Laatste aanpassing"}/>
                </If>
            ]);
    }
}

export default GeneralInfo;
