import {noteClient, noteTypes} from "../../../../../../api/relation/note/NoteClient";
import moment from "moment";

const getNotes = customer => noteClient.getNotes(customer);

const loadNotes = (customer, updateState) => getNotes(customer).then(notes => updateState({notes}));

/**
 * Tries to parse the string as a standard Dutch date.
 * If successfull it wil return fully written date, or else it will return the original string
 * @param {string} date
 * @returns {string}
 */
const formatDate = date => {
    const m = moment(date, "DD-MM-YYYY", true);
    if (m.isValid()) {
        return m.format("D MMMM YYYY");
    } else {
        return date;
    }
}

/**
 * @param appointment
 * @returns Note
 */
const note = appointment => {
    if (appointment.afspraak_gaat_door) {

        const hasOpmerkingen = typeof appointment.opmerkingen === "string" & appointment.opmerkingen.trim().length > 0;
        const opmerkingen = hasOpmerkingen? `\n\nOpmerking: ${appointment.opmerkingen}` : ``;

        return {
            description: noteTypes.NEW_APPOINTMENT,
            text:        `Op ${formatDate(appointment.gewenste_datum)} van ${appointment.gewenst_tijdvak}, met ${appointment.adviseur}. ${opmerkingen}`,
        }
    } else {
        return {
            description: noteTypes.CANCELLED_APPOINTMENT,
            text:        appointment.reden,
        }
    }
}

export {
    loadNotes,
    note,
};
