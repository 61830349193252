/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 20-12-17.
 */

import ActionType from "../ActionType";

/**
 *
 * @param groupIndex
 * @return {Action}
 */
const submitGroup = groupIndex => ({
    type: ActionType.group.submitGroup,
    groupIndex: groupIndex
});
export default submitGroup;
