/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 12-6-18.
 */
import {compoundAction} from "../../../../middleware/compoundDispatch";
import postForm from "../../../../action/creator/asynchonous/form/postForm";
import submitGroup from "../../../../action/creator/submitGroup";
import postFormForLaterStep from "../../../../action/creator/asynchonous/form/postFormForLaterStep";
import stepAction from "../../../../action/creator/asynchonous/form/stepAction";

/**
 * On receiving an event this handler will dispatch an action to post the current form and (regardless of validity) turn
 * back to the requested page.
 *
 * @param dispatch
 * @param endpoint
 * @param currentStep
 * @param requestedStep
 * @return function(object): void
 */
const onEarlierStep = dispatch => endpoint => (currentStep, requestedStep) =>
    event => dispatch(compoundAction(postForm(endpoint),
                                     stepAction(requestedStep)));

/**
 * This  eventHandler will submit the current group and if the current group is valid proceed to the requested group (or the next invalid group)
 *
 * @param dispatch
 * @param endpoint
 * @param currentStep
 * @param requestedStep
 * @param {function(object): Action} submitAction  actionCreator that takes a form and uses it to create the action to be  taken when the
 *                                       requestedStep is larger than the number of available steps
 * @return function(object): void
 */
const onLaterStep = dispatch => endpoint => submitAction => (currentStep, requestedStep) =>
    event => dispatch(compoundAction(submitGroup(currentStep),
                                     postFormForLaterStep(endpoint,
                                                          currentStep,
                                                          requestedStep,
                                                          submitAction)));

/**
 * This eventHandler will determine whether the requestedStep is forwards or backwards de defer to onLaterStep or onEarlierStep accordingly
 * @param endpoint
 * @param submitAction
 * @param currentStep
 * @param requestedStep
 * @return function(object): void
 */
const onStep = dispatch => endpoint => submitAction => currentStep => requestedStep =>
    (currentStep <= requestedStep) ? onLaterStep(dispatch)(endpoint)(submitAction)(currentStep, requestedStep)
                                   : onEarlierStep(dispatch)(endpoint)(currentStep, requestedStep);

export default onStep;

