/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 18-12-17.
 */
import moment from "moment";
import RelationClient from "../RelationClient";
import {defaultSearch} from "../../../action/creator/asynchonous/search/search";
import Promise from "bluebird";
import {getAutosuggest} from "./autosuggest";
import {isClient} from "./util";
import {Forms} from "../../form/FormClient";
import convert from "./conversion/customer/convert";
import CamundaProcessClient from "../../process/process/CamundaProcessClient";
import {empty} from "../../process/util";
import {getFile} from "../../HTTP/get";

/**
 * @typedef {Object} Sort
 * @param {string} field
 * @param {string} direction ascending|descending
 */

/**
 * @typedef {Object} Search
 * @param {string} searchTerm
 * @param {number} page
 * @param {array.<Sort>} sorts  Specifies to sort the results by the first entry, decide draws by the second, third,
 *     ... and so on
 * @param {string} type consumer|business
 * @param {boolean} archived
 */

/**
 * @param customer
 * @return {string}
 */
const endpoint = customer => isClient(customer)
    ? RelationClient.clientEndpoint
    : RelationClient.businessEndpoint;

class CustomerClient {

    constructor(user) {
        this.user = user;
    }

    /**
     * Returns a promise that will resolve once the customer has been added the the store of customers.
     *
     * @param {object} form
     * @param formType
     * @return {Promise<object>}
     */
    addClient(form, formType) {

        if (formType === Forms.woningScan) {
            console.error("WOMINGSCAN step: returning placeholder step");
            return Promise.resolve({entity: null});
        }
        return RelationClient.putData(RelationClient.clientEndpoint, convert(formType)(form));
    }

    /**
     * Returns a promise that will resolve once the store has updated the consumer
     * [NB this is the update as used in the Customer page, it doesn't need a 'convert' function,
     *     because the forms use fields precisely matching the fields in a customer object]
     *
     * @param {object} customer
     * @return {Promise<object>}
     */
    updateClient(customer) {
        return RelationClient.postData(RelationClient.clientEndpoint, {data: customer});
    }

    /**
     * Returns a promise that will resolve once the store has updated the consumer
     * [NB this is the update as used in the homepage]
     * @param customer
     * @param {object} form
     * @param formType
     * @return {Promise<object>}
     */
    updateClient2(customer, form, formType) {
        return RelationClient.postData(RelationClient.clientEndpoint, convert(formType)(form, customer));
    }

    /**
     * Returns a promise that will resolve once the customer has been added the the store of customers.
     *
     * @param {object} form
     * @param {String} formType
     * @return {Promise<void>}
     */
    addBusiness(form, formType) {
        return RelationClient.putData(RelationClient.businessEndpoint, convert(Forms.businessCustomer)(form));
    }

    /**
     * Returns a promise that will resolve once the store has updated the businesscustomer
     *
     * @param {object} customer
     * @return {Promise<object>}
     */
    updateBusiness(customer) {
        return RelationClient.postData(RelationClient.businessEndpoint, {data: customer});
    }

    /**
     * Returns a promise that will resolve once the customer with id customerId has been "deleted".
     *
     * @param {object} customer
     * @return {Promise<void>}
     */
    archive(customer) {
        return RelationClient.putData(`${endpoint(customer)}archive/${customer.id}`);
    }

    /**
     * Returns a promise that will resolve once the customer with id customerId has been restored.
     *
     * @param {object} customer
     * @return {Promise<void>}
     */
    unArchive(customer) {
        return RelationClient.putData(`${endpoint(customer)}restore/${customer.id}`);
    }

    /**
     * Returns a promise that will resolve once the customer with id customerId has been accepted.
     *
     * @param {object} customer
     * @return {Promise<*>}
     */
    accept(customer) {
        return RelationClient.putData(`${RelationClient.customerEndpoint}accept/${customer.id}`);
    }

    /**
     *
     * @param {object} customer
     */
    deleteCustomer(customer) {
        // first delete all processes that have customer.id as customerId variable
        // Then delete the customer itself
        return CamundaProcessClient.findBycustomerId(customer.id)
                                   .then(processIds =>
                                             Promise.all(processIds.map(
                                                 processId => CamundaProcessClient.deleteByProcessId(processId))))
                                   .then(_ => RelationClient.deleteData(`${RelationClient.customerEndpoint}delete/${customer.id}`));

    }

    getLastUpdate(customer) {
        return Promise.resolve({
                                   update: moment().subtract(6, 'days').calendar(),
                                   name:   "Alwin Langerak",
                               })
    }

    /**
     * @param {Search} search
     * @return {Promise<SearchResults>}
     */
    search(search = defaultSearch) {

        let user = this.user;
        console.assert(!empty(user), "Error: searching without checking the user");

        return RelationClient.postData(`${RelationClient.customerEndpoint}search`,
                                       {
                                           ...search,
                                           user: {
                                               name:  user.userName,
                                               email: user.email,
                                           },
                                       })
                             .then(data => ({
                                     results:      data.customers,
                                     totalResults: data.totalCustomers,
                                     search:       data.search,
                                 }
                             ));
    }

    /**
     * @param  {String} term
     * @param {String} customerStatus
     * @return {Promise.<array.<SuggestionType>>}
     */
    suggestions(term, customerStatus) {
        return RelationClient.postData(RelationClient.suggestEndpoint, getAutosuggest(customerStatus)(term))
                             .then(data => data.results);
    }

    uploadDocument(customer, file, type) {
        return RelationClient.postFile(`${RelationClient.fileUploadEndpoint}${customer.id}/document-upload`, file)
                             .then(({originalFileName, ext, savedFileName}) =>
                                       RelationClient.putData(`${RelationClient.fileMappingEndpoint}`, {
                                           data: {
                                               active:          true,
                                               documentType:    type,
                                               ext:             ext,
                                               orginalFileName: originalFileName,
                                               customer:        {
                                                   id: customer.id,
                                               },
                                               path:            savedFileName,
                                               title:           file.name,
                                           },
                                       }),
                             );
    }

    documents(customer) {
        return RelationClient.getData(`${RelationClient.fileMappingEndpoint}fetchDocuments/${customer.id}`);
    }

    /**
     *
     *
     * @param {Object} document something like
     *  {
     *     "id": 4,
     *     "createdAt": "30-06-2019",
     *     "active": false,
     *     "modifiedAt": null,
     *     "createdBy": null,
     *     "exprityDate": null,
     *     "documentType": "Medewerkerlijst",
     *     "title": "abnamro_uitlijning.png",
     *     "path": "abnamro_uitlijning_50_20190630Sun_02_40_53.png",
     *     "ext": "png",
     *     "orginalFileName": "abnamro_uitlijning",
     *     "reference": null,
     *     "referenceType": null
     *  }
     * @return {string}
     */
    documentUrl(document) {
        //"http://localhost:1996/document/abnamro_uitlijning_50_20190630Sun_02_40_53.png"
        return RelationClient.getServiceUrl(`${RelationClient.documentEndpoint}${document.path}`);
    }

    /**
     * returns the documennt-type as expected by <FileViewer>, eg 'png' for a Portable Network Graphics image
     * @param document see the docs for documentUrl
     * @return {string}
     */
    documentType(document) {
        return document.ext;
    }

    getDocument(document) {
        const url = customerClient.documentUrl(document).trim();
        return getFile()(url);
    }

    deleteDocument(document) {
        return RelationClient.deleteData(`${RelationClient.fileMappingEndpoint}${document.id}`)
    }

    fetchCustomer(id) {
        return RelationClient.getData(`${RelationClient.customerEndpoint}fetch/${id}`);
    }

    setPassword(email, password, token) {
        return RelationClient.putData(`${RelationClient.customerEndpoint}setpassword/${email}/${password}/${token}`, {})
                             .then(object => object.entity);
    }

    authenticate(email, password) {
        return RelationClient.getData(`${RelationClient.customerEndpoint}authenticate/${email}/${password}`);
    }

    getEmail(token) {
        return RelationClient.getData(`${RelationClient.customerEndpoint}email/${token}`)
                             .then(object => object.entity)
    }

    addConsultant(customer, consultant, role) {
        return RelationClient.getData(`${RelationClient.consultantEndpoint}${customer.id}/${consultant.name}/${role}`);
    }

    removeConsultant(consultant) {
        return RelationClient.deleteData(`${RelationClient.consultantEndpoint}${consultant.id}`);
    }
}

// At some point we need to seperate out the usages that use the user vs the usages that ignore the user
// and either split this class up methods that use the user, or else incorperate the user in all calls to Relations,
// (maybe for authentication)
// For now we keep instantiating the client with a null-user in the locations that don't need the user
// And move to proper instantiating in the couple of locations that do (currently only the search-functions).
const customerClient = new CustomerClient();

export default CustomerClient;
export {
    customerClient,
}
