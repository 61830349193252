/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 19-12-17.
 */
import {customerClient} from "../../../../api/relation/customer/CustomerClient";

const archive = (customer, searchRequest) => customerClient.archive(customer)
                                                           .then(() => searchRequest);

const unArchive = (customer, searchRequest) => customerClient.unArchive(customer)
                                                             .then(() => searchRequest);

const accept = (customer, searchRequest) => customerClient.accept(customer)
                                                          .then(() => searchRequest);

const deleteCustomer = (customer, searchRequest) => customerClient.deleteCustomer(customer)
                                                                  .then(() => searchRequest);

export {
    archive,
    unArchive,
    accept,
    deleteCustomer
}


