/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 12-6-18.
 */

import {compoundAction} from "../../../../middleware/compoundDispatch";
import updateQuestion from "../../../../action/creator/updateQuestion";
import postForm from "../../../../action/creator/asynchonous/form/postForm";


/**
 * Dispatches an action to set the questions value and post the resulting form
 * @param dispatch
 * @param endpoint
 * @return () -> ()
 */
const onSubmitQuestion = dispatch => endpoint => question => value =>
    dispatch(compoundAction(
        updateQuestion(question["id"], value),
        postForm(endpoint),
    ));

export default onSubmitQuestion;
