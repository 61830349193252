// noinspection JSNonASCIINames,NonAsciiCharacters

import {diff, distance, midPoint, sum} from "../../geometry/point/Point";
import Dot from "../../geometry/point/Dot";
import {LineSegment} from "../../geometry/LineSegment";
import {velocity} from "../../geometry/point/Sample";

/**
 * Cubic Bézier curve with variable weight (thickness)
 *
 */
class BézierCurve {

    /**
     * @type {Point}
     */
    startPoint;

    /**
     * @type {Point}
     */
    control1;

    /**
     * @type {Point}
     */
    control2;

    /**
     * @type {Point}
     */
    endPoint;

    /**
     * @type {number}
     */
    startWeight = 1;

    /**
     * @type {number}
     */
    endWeight = 1;

    /**
     * @param {Point} startPoint
     * @param {Point} control1
     * @param {Point} control2
     * @param {Point} endPoint
     */
    constructor(startPoint, control1, control2, endPoint) {
        this.startPoint = startPoint;
        this.control1 = control1;
        this.control2 = control2;
        this.endPoint = endPoint;
    }

    /**
     * @param {number} t: a number in the interval [0,1]
     * @return {Dot}
     */
    at(t) {
        return new Dot(
            sum(
                this.startPoint.scale((1.0 - t) ** 3),
                this.control1.scale(3.0 * (1.0 - t) ** 2 * t),
                this.control2.scale(3.0 * (1.0 - t) * t ** 2),
                this.endPoint.scale(t ** 3),
            ),
            this.startWeight + t ** 3 * (this.endWeight - this.startWeight),
        );
    }

    /**
     * @return {number}
     */
    length() {
        const delta = 1 / 10;

        let length = 0;
        let previous = null;

        for (let t = 0; t <= 1; t += delta) {
            const current = this.at(t);
            if (previous) {
                length += distance(previous, current);
            }
            previous = current;
        }
        return length;
    }

    /**
     * @return {number}
     */
    velocity() {
        return velocity(this.startPoint, this.endPoint);
    }

    /**
     * @param {number} startWeight
     * @param {number} endWeight
     */
    withWeights({startWeight, endWeight}) {
        this.startWeight = startWeight;
        this.endWeight = endWeight;
        return this;
    }
}

/**
 * Returns a Bézier curve, between the samples s1 and s2, that will connect smoothly to similarly
 * created preceding curve between prev and s1, and will also connect smoothly to the similarly created
 * following curve between s2 and next
 *
 * @param  {Sample} prev
 * @param  {Sample} s1
 * @param  {Sample} s2
 * @param  {Sample} next
 * @param  {Array<Point>} rest
 * @return {BézierCurve}
 */
function fittedCurve([prev, s1, s2, next, ...rest]) {
    console.assert(typeof prev !== "undefined", prev, "s1 undefined");
    console.assert(typeof s1 !== "undefined", s1, "s2 undefined");
    console.assert(typeof s2 !== "undefined", s2, "s3 undefined");
    console.assert(typeof next !== "undefined", next, "s4 undefined");
    console.assert(rest.length === 0, rest, "Called with too much points");

    return new BézierCurve(s1,
                           tangent(prev, s1, s2).end,
                           tangent(s1, s2, next).start,
                           s2);
}

/**
 *  The tangent() function is used in the creation of a smooth curve through the samples (passing
 *  from previous through current to next) as the combination of two Bézier curves:  a curve b1 between
 *  previous and current and a curve b2 between current and next. It is required that both
 *  curves connect smoothly.
 *
 *  The last leg of a Bézier curve (the line from the last control-point to the endpoint) is
 *  tangent to the curve in the endpoint. Similarly, the first leg is tangent in the starting point.
 *
 *  So in order for the two curves to connect smoothly, the last leg of the first curve must in line with
 *  the first leg of the last curve. In other words the line segment from b1s last control-point to b2s first
 *  control-point  must pass through the current sample. There are many such segments to choose from, this function
 *  chooses such a line segment.
 *
 *  The direction of the segment determines the tangent of the curve in the midpoint, and the size (and the relative size
 *  of the two legs) determines how fast the curve will bend toward the surrounding samples.
 *
 *  It makes sense that the tangent of the curve in the midpoint is parallel to the line between the
 *  samples surrounding it, so that must be the direction of the segment.
 *
 *  In order to balance the bends, the size of the segment will be half the distance between previous and
 *  next (ie equal to the size of the segment between the midpoints of the lines between previous/current
 *  and current/next).
 *
 *  Even more balance can be gained by making the leg that corresponds to the surrounding sample that is the
 *  furthest away from the midpoint the longest.
 *
 * @param {Sample} previous
 * @param {Sample} current
 * @param {Sample} next
 * @return {LineSegment} A line segment through the current sample that represents the tangent
 *                       of the stroke. Its start and endpoints are to be used as control-points
 *                       in Bézier curves.
 * @private
 */
function tangent(previous, current, next) {

    const mid12 = midPoint(previous, current);
    const mid23 = midPoint(current, next);

    const offset = distance(current, next) / (distance(previous, current) + distance(current, next));

    const center = sum(mid23, diff(mid12, mid23).scale(offset));
    const displacement = diff(current, center);

    return new LineSegment(mid12, mid23).translate(displacement);
}

export {
    BézierCurve,
    fittedCurve,
    tangent,
}
