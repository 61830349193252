import {logResult, throwErrors} from "./util";

/**
 * @returns {function(string): Promise<any>}
 */
const deleteJson = serviceUrl => {

    if (global.LOG_API_CALLS) {
        let text = "\n";
        text += `DELETE ${serviceUrl}\n`;
        console.log(text);
        text += "Accept: application/json\n"
        text += `Content-Type: application/json\n`;
        text += "\n";
        text += "### [deleteJson()]\n";
        console.debug(text);
    }

    return fetch(serviceUrl, {
        method:  "delete",
        headers: {
            "Accept":       "application/json",
            "Content-Type": "application/json",
        },
    }).then(throwErrors)
      .then(logResult(`[deleteJson()] Deleted data from '${serviceUrl}'`, global.LOG_API_CALLS));
};

export {deleteJson};
