import blissSkin from "./blissSkin";

const quickScanSkin = {
    ...blissSkin,
    id: "QUICK_SCAN",
    // texts: {
    //     ...blissSkin.texts,
    //     copyright: "Bankgarantie.com",
    //     company: "Bankgarantie.com"
    // },
};

export default quickScanSkin;
