import {push} from "react-router-redux";

import ActionType from "../../ActionType";
import LoginClient from "../../../api/login/LoginClient";
import {compoundAction} from "../../../middleware/compoundDispatch";
import paths from "../../../app/paths";

const loginAction = loginResult =>
    ({
        type: ActionType.asynchronous.login.login,
        ...loginResult,
    });

const forgotPasswordAction = user => ({
    type: ActionType.asynchronous.login.forgotPassword,
    ...user,
});

const logoffAction = () => ({
    type: ActionType.asynchronous.login.logoff,
});

/**
 * @param email
 * @param password
 * @return {Promise<{type: Action}>}
 */
const getLoginActions = (email, password) => LoginClient.logOn(email, password)
                                                        .then(loginResult => {
                                                            return compoundAction(
                                                                loginAction(loginResult),
                                                                push(loginResult.admin ? paths.dashboard : paths.user));
                                                        });

const forgotPassword = (email) => LoginClient.forgotPassword(email).then(_ => push("/"));

const logoffActions = () => compoundAction(logoffAction(),
                                           push(paths.root));

export {
    loginAction,
    getLoginActions,
    forgotPassword,
    logoffActions,
    forgotPasswordAction,
};
