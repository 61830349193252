/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 18-4-18.
 */
import React from "react";
import {postClient} from "../postCustomer";
import {Forms} from "../../../../../../../api/form/FormClient";
import ModalForm from "../../../../../../component/form/ModalForm/ModalForm";
import Header from "../markup/Header";
import {Grid} from "@material-ui/core";
import {stringify} from "../../../../../../../util/util";
import Item from "../markup/Item";
import {familyMemberUpdater} from "../updater/familyMemberUpdater";
import SmallCardHeader from "../../../../../../component/Card/SmallCardHeader";

const isPartner = familyMember => familyMember.familyMemberType === "Spouse11";

const flatten = x => stringify(x);
const description = familyMember => `${familyMember.initials} ${familyMember.insertPart} ${familyMember.lastName}`;

const getPartner = customer => {
    if (customer.customerFamilyMembers === null) {
        return null;
    }
    const partners = customer.customerFamilyMembers
                             .filter(isPartner);
    if (partners.length === 0) {
        return null;
    }
    if (partners.length > 1) {
        console.error("Customer has multiple partners", JSON.stringify(partners, null, 2));
        throw new Error("Customer has multiple partners");
    }
    return partners[0];
};

const Partner = ({customer}) => {

    const partner = getPartner(customer);
    return partner === null
           ? <Grid container>
               <Header title="Nog geen partner gegevens"

                       modalForm={<ModalForm formEndpoint={Forms.editFamilymember}
                                             data={{}}
                                             icon="add_circle"
                                             submitAction={postClient(familyMemberUpdater("Spouse11"))(customer)}/>}/>
           </Grid>
           : [<SmallCardHeader title="Partner"/>,
              <Grid key={2}
                    container>
                  <Item object={partner}
                        icon="person"
                        getDescription={description}
                        modalForm={<ModalForm formEndpoint={Forms.editFamilymember}
                                              data={flatten(partner)}
                                              submitAction={postClient(familyMemberUpdater("Spouse11", 0))(customer)}/>}/>
              </Grid>]
};

export default Partner;
export {
    isPartner,
    getPartner
}
