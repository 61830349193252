import React from "react";
import {connect} from "react-redux";
import {currentUser} from "../../../../../state/selector/user";
import securePage from "../../../SecurePage/SecurePage";
import {Card, CardContent, CircularProgress, Divider, Grid, Typography} from "@material-ui/core";
import LoggedOn from "../../../../component/Main/Base/LoggedOn/LoggedOn";
import {compose} from "redux";
import {Forms} from "../../../../../api/form/FormClient";
import ModalForm from "../../../../component/form/ModalForm/ModalForm";
import {postUser} from "./postUser";
import userUpdater from "./updater/userUpdater";
import Item from "./markup/Item";
import {roles} from "../../../../../api/login/LoginClient";
import {empty} from "../../../../../api/process/util";
import RelationClient from "../../../../../api/relation/RelationClient";
import {formatForMulti} from "./role";
import DateRangeIcon from "@material-ui/icons/DateRange";
import WcIcon from "@material-ui/icons/Wc";
import RoomIcon from "@material-ui/icons/Room";
import FavoriteIcon from "@material-ui/icons/Favorite";
import RoleItem from "./markup/RoleItem";
import ProfilePhoto from "./ProfilePhoto";
import {enhanceUser} from "../../../../../util/profilePicture";

const userName = user =>
    (empty(user.firstName)? " " : user.firstName + " ")
    + (empty(user.insertPart)? "" : user.insertPart + " ")
    + (empty(user.lastName)? "" : user.lastName);

const displayName = user => empty(user)
    ? "Gebruiker"
    : userName(user).trim() === ""
        ? `Gebruiker ${user.email}`
        : userName(user)

const reload =
    element =>
        userId =>
            RelationClient.getData("user/id/" + userId + "/")
                          .then(response => response.entity)
                          .then(user => enhanceUser(user, 256).then(enhancedUser => ({user, enhancedUser})))
                          .then(({user, enhancedUser}) => element.setState({
                                                                               user,
                                                                               enhancedUser,
                                                                               loaded: true,
                                                                           }))

class ViewUser extends React.Component {

    state = {
        loaded:       false,
        user:         null,
        toBeUploaded: {},
    }

    componentDidMount() {

        const userId = this.props.match.params.id
        reload(this)(userId);
    }

    render() {
        const {user, enhancedUser, loaded} = this.state;

        let roles = [];
        let data = {...user};
        if (loaded) {
            roles = user.roles;
            data.roles = formatForMulti((data.roles));
        }

        return (
            <LoggedOn>

                <Card>
                    <CardContent>
                        <Typography variant="h5">
                            {displayName(user)}
                        </Typography>

                        {
                            loaded? [
                                <Typography component="div"
                                            key={1}>
                                    <ModalForm formEndpoint={Forms.editUser}
                                               data={data}
                                               submitAction={
                                                   form =>
                                                       postUser(userUpdater)(user)(form,
                                                                                   _ => reload(this)(user.id))
                                               }
                                               adjust={true}
                                    />
                                </Typography>,
                                <Divider key={2}/>,
                                <Typography variant="h3" key={3}>
                                    Algemene gegevens
                                </Typography>,
                                <Grid container key={4}>
                                    <Grid item xs={6}>
                                        <Item object={user}
                                              icon="persons"
                                              title="Gebruikersnaam"
                                              getDescription={user => user.userName}/>
                                        <Item object={user}
                                              icon="persons"
                                              title="Voornaam"
                                              getDescription={user => user.firstName}/>
                                        <Item object={user}
                                              icon="persons"
                                              title="Initialen"
                                              getDescription={user => user.initials}/>
                                        <Item object={user}
                                              icon="persons"
                                              title="Tussenvoegsel"
                                              getDescription={user => user.insertPart}/>
                                        <Item object={user}
                                              icon="persons"
                                              title="Achternaam"
                                              getDescription={user => user.lastName}/>
                                        <Item object={user}
                                              icon={<WcIcon/>}
                                              title="Geslacht"
                                              getDescription={user => user.gender}/>
                                        <Item object={user}
                                              icon={<FavoriteIcon/>}
                                              title="Burgerlijke staat"
                                              getDescription={user => user.maritialStatus}/>
                                        <Item object={user}
                                              icon={<RoomIcon/>}
                                              title="Nationaliteit"
                                              getDescription={user => user.nationality}/>
                                        <Item object={user}
                                              icon={<DateRangeIcon/>}
                                              title="Geboortedatum"
                                              getDescription={user => user.birthDate}/>

                                        <Item object={user}
                                              icon="phone"
                                              title="Telefoonnummer"
                                              getDescription={user => user.phone}/>

                                        <Item object={user}
                                              icon="mail_outline"
                                              title="E-mail"
                                              getDescription={user => user.email}/>
                                        <RoleItem roles={roles}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ProfilePhoto user={enhancedUser} onUploaded={_ => reload(this)(user.id)}/>
                                    </Grid>
                                </Grid>,

                            ] : <CircularProgress/>
                        }
                    </CardContent>
                </Card>;

            </LoggedOn>
        );
    }
}

const mapStateToProps = state => ({
    user: currentUser(state),
});

export default compose(
    connect(mapStateToProps),
    securePage([roles.admin]),
)(ViewUser);
