/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 18-12-17.
 */
import ActionType from "../../../action/ActionType";
import {defaultSearch} from "../../../action/creator/asynchonous/search/search";

const initialState = {
    results: [],
    totalResults: 0,
    search: defaultSearch,
};

const searchResults = (state = initialState, {type, payload}) => {
    if (type === ActionType.search.searchResult) {
        return payload;
    }
    return state;
};

export default searchResults;
export {
    initialState,
    searchResults,
}
