import defaultSkin from "./defaultSkin";

const mogelijkSkin = {
    ...defaultSkin,
    id: "MOGELIJK",
    images: {
        ...defaultSkin.images,
        personaBackground: null,
        logo: "/images/mogelijk-vastgoed-financieringen-logo.svg"
    },
    palette: {
        ...defaultSkin.palette,
        primary: {main: "#e2edf7"},
        primaryText: {main: "#607d8b"},
        anchors: {
            ...defaultSkin.palette.anchors,
            regular: {main: "#fff"},
            hover: {main: "#fff"},
            visited: {main: "#f"},
        },
        personaBackground: {main: "#e2edf7"},
        copyrightForeground: {main: "#fff"},
        copyrightBackground: {main: "#43a047"},
        footerForeground: {main: "#e2edf7"},
        footerBackground: {main: "#e2edf7"},
    },
    texts: {
        ...defaultSkin.texts,
        company: "Mogelijk Vastgoed"
    },
    overrides: {
        logo: {
            height: "auto",
            position: "relative",
            left: "-4px",
        },
        mainBackground: {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '70% 0%',
            backgroundImage: `url('/images/mogelijk-header-home-visual.png')`
        },
    }
}

export default mogelijkSkin;
