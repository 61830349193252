/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 11-7-18.
 */
import React from "react";
import {Grid, Typography} from "@material-ui/core";
import classNames from "classnames";

const Premium = ({classes}) =>
    <Grid container
          className={classNames(classes.premium, classes.pageHeader)}>
        <Grid item>
            <Typography variant="h6"
                        className={classes.premium}>
                De premie bedraagt € 150,00 per maand vanaf 11 mei 2018
            </Typography>
            <Typography className={classes.premium}>
                De premie is inclusief kosten (€ 2,50), pakketkorting (€7,30), provisie en assurantiebelasting.
            </Typography>
        </Grid>
    </Grid>;

export default Premium;
