import withWidth from "@material-ui/core/withWidth/withWidth";
import SignaturePad from "../../../../SignaturePad/SignaturePad";
import React from "react";

/**
 * TODO: this currently only supports a fullscreen lg variant;
 *       the other variants should be easy to add.
 * @param width
 * @return {{width: number, height: number}}
 */
const canvasSize = width => {

    switch (width) {
        case "lg" :
            return {width: 720, height: 150}
        case "xs":
        case "sm ":
        case "md":
        case "xl":
        default:
            return {width: 720, height: 150}
    }
}

/**
 * React component that passes all it's props to the actual SignaturePad,
 * along with a {width, height} object that determined dynamically, based on the
 * actual width of the containing webpage.
 */
const SignaturePadWrapper =
    withWidth()(
        ({width, ...props}) =>
            <SignaturePad size={canvasSize(width)}
                          {...props} />
    );

export default SignaturePadWrapper;
