const defaultLinks = [
    {
        description: "Intakebalie",
        link:        "mailto:info@bgreenfactor.nl",
    },
    {
        description: "Algemene voorwaarden",
        link:        "#",
    },
    {
        description: "Privacy statement",
        link:        "#",
    },
];


 const bankgarantieLinks = defaultLinks;
 const blissLinks = [
    {
        description: "Intakebalie",
        link:        "mailto:info@blisshypotheekadviseurs.nl",
    },
    {
        description: "Telefoon 085-0470222",
        link:        "tel:085-0470222",
    },
    {
        description: "Algemene voorwaarden",
        link:        "https://www.blisshypotheekadviseurs.nl/algemene-voorwaarden/",
    },
    {
        description: "Privacy statement",
        link:        "https://www.blisshypotheekadviseurs.nl",
    },
];
 const bouwkundigeKeuringLinks = defaultLinks;
 const erasmusLinks = defaultLinks;
 const mogelijkLinks = defaultLinks;
 const woningScanLinks = [
    {
        description: "Intakebalie",
        link:        "mailto:info@bgreenfactor.nl",
    },
    {
        description: "Algemene voorwaarden",
        link:        "#",
    },
    {
        description: "Privacy statement",
        link:        "#",
    },
];
 const quickScanLinks = defaultLinks;

 export {
     bankgarantieLinks,
     blissLinks,
     bouwkundigeKeuringLinks,
     erasmusLinks,
     mogelijkLinks,
     woningScanLinks,
     quickScanLinks,
 }
