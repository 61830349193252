import Tabs from "@material-ui/core/Tabs";
import classnames from "classnames";
import React from "react";
import {withStyles} from "@material-ui/core";

const style = theme => ({
    tabs: {
        width: "100%"
    },
    section: {
        width: "100%"
    }
})

class MiniPages extends React.Component {

    state = {
        index: this.props.startPage ? this.props.startPage : 0,
    }

    render() {

        const {index} = this.state;
        const {miniPages, classes = {}, overwrites = {}} = this.props;

        return [
            <Tabs className={classnames(classes.tabs, overwrites.tabs)}
                  key="TABS"
                  indicatorColor="primary"
                  textColor="primary"
                  value={index}
                  onChange={(_, index) => this.setState({index})}>
                {miniPages.map((miniPage, tab) => miniPage.getTab(tab, index === tab, overwrites))}
            </Tabs>,
            <section key="SECTION"
                className={classnames(classes.section, overwrites.section)}>
                {miniPages[this.state.index].getContent()}
            </section>
        ]
    }
}

export default withStyles(style)(MiniPages);
