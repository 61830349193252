/**
 * FILE questions are implemented as hidden input fields, with an upload-widget shown instead.
 * On successful upload the hidden input it set to the uploads documentType (eg "Medewerkers  lijst"),
 * and this value is used in the FormService for validation.
 *
 * The upload-widget saves files to the relations service, and a list of all of a customers documents
 * is kept in the redux state.
 *
 * Whenever that list changes (a document gets deleted...), the corresponding changes should be reflected in the form,
 * so the form-reducer should listen to the RETRIEVED_ALL_DOCUMENTS action as well as the regular form-actions
 *
 *
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 24-8-18.
 */
import ActionType from "../../action/ActionType";
import {prefillFileQuestions} from "../../api/form/prefill";


const documents = (form, {type, documents}) => {
    if (type === ActionType.asynchronous.documents.RETRIEVED_ALL) {
        return prefillFileQuestions(form, documents);
    }
    return form;
};
export default documents;
