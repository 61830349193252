/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 14-6-18.
 */
import moment from "moment/moment";

function emptyMember(type) {
    return {
        familyMemberType: type,
        //birthDate: moment().format("DD-MM-YYYY"),
        startDate: moment().format("DD-MM-YYYY"),
        active: true,
    }
}

/**
 *
 * @param type
 * @param memberIndex
 * @return {updater}
 */
const familyMemberUpdater =
    (type, memberIndex = -1) =>
        (customer, form) => {

            let member = memberIndex < 0 ? emptyMember(type) : customer.customerFamilyMembers[memberIndex];

            form.groups.forEach(function (group) {
                group.questions.forEach(function (question) {
                    const field = question.field;
                    member[field] = question.parsedValue;
                });
            });

            if (memberIndex < 0) {
                if (!(customer.customerFamilyMembers instanceof Array)) {
                    customer.customerFamilyMembers = [];
                }
                customer.customerFamilyMembers.push(member);
            }
            else {
                customer.customerFamilyMembers[memberIndex] = member;
            }

            return customer;
        };

export {
    familyMemberUpdater
};
