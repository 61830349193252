/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 1-9-17.
 */
import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";

import form from "./form/form";
import quote from "./quote/quote";
import {reducer as formReducer} from 'redux-form'
import login from "./login/login";
import searchResults from "./customer/search/searchResults";
import currentCustomer from "./customer/currentCustomer";
import currentStep from "./form/currentStep";
import bundleReducers from "./bundleReducers";
import logoff from "./login/logoff";
import error from "./error";
import currentOrganization from "./organization/currentOrganization";
import agentAction from "./agent/agentAction";
import currentUser from "./user/currentUser";
import documents from "./documents/documents";
import formState from "./form/formState";
import loginLoading from "./login/loginLoading";

function lastAction(state = null, action) {
    return action;
}

/**
 *
 * @type {Reducer<any>}
 */
const mainReducer = combineReducers(
    {
        serviceForm: form(),
        quote: quote,
        router: routerReducer,
        form: formReducer,
        formState,
        searchResults: searchResults,
        currentCustomer,
		currentOrganization,
        agentAction,
		currentUser,
        currentStep,
        documents,
        login: bundleReducers({},
                              [login,
                               logoff]),
        errors: error,
        lastAction,
        loginLoading:loginLoading,
    },
);
export default mainReducer;
