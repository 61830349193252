/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 10-10-18.
 */

/**
 * Uses the data to set the values to known questions
 * @param form
 * @param data
 * @return {*}
 */
let preFill = (form, data = null) => {
    if (data) {
        let preFilled = {...form};
        for (let group of preFilled.groups) {
            for (let question of group.questions) {
                if (question.submit === false) {
                    const field = question.field;

                    if (["houseNo",
                         "addition",
                         "streetName",
                         "postalCode",
                         "city",].includes(field)) {
                        const address = data.addresses;
                        if (address && address.hasOwnProperty(field)) {
                            question.value = address[field] === null ? "" : String(address[field]);
                            question.submit = true;
                        }
                    }
                    else if (data.hasOwnProperty(field)) {
                        question.value = data[field] === null ? "" : String(data[field]);
                        question.submit = true;
                    }
                }
            }
        }
        return preFilled;
    }
    return form;
};

/**
 * If there's a match between the documentTypes and the types in acceptableValues
 * the first match will be inserted into the questions value attribute.
 * When there's no match the value attribute will be reset.
 *
 * (non-File questions willl be returned as is.)
 * @param question
 * @param documents
 * @return {*}
 */
const prefillFileQuestion = (question, documents) => {
    if (question.type === "FILE") {
        for (const {documentType} of documents) {
            if (Object.values(question.acceptableValues)
                      .includes(documentType)) {
                return {
                    ...question,
                    value: documentType
                }
            }
        }
        return {
            ...question,
            value: ""
        }
    }
    return question;
};

/**
 *
 * FILE questions are implemented as hidden input fields, with an upload-widget shown instead.
 * On successful upload the hidden input it set to the uploads documentType (eg "Medewerkers  lijst"),
 * and this value is used in the FormService for validation.
 *
 * If a list of uploaded documents is allready available, this function can be used to set all
 * FILE question-values corresponding to the types availabe in the list
 *
 * @param form
 * @param documents
 * @return {{groups: *}}
 */
const prefillFileQuestions = (form, documents) => documents ? {
    ...form,
    groups: form.groups.map(group => {
        return {
            ...group,
            questions: group.questions.map(question => prefillFileQuestion(question, documents)),
        };
    }),
} : form;

export {
    preFill,
    prefillFileQuestions,
}
