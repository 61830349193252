import React from "react";
import {Forms} from "../../../../../../api/form/FormClient";
import {retrieveForm} from "../../../../../../action/creator/asynchonous/form/retrieveForm";
import {compose} from "redux";
import {connect} from "react-redux";
import {ButtonForm} from "../../../../../component/form/ModalForm/ModalForm";
import {startProcess} from "../../../../../../api/process/process/processes";
import {procesKeys} from "../../../../../../api/process/process/CamundaProcessClient";
import {dossierVariables} from "./variables";
import {setAdvisor, setAssignee} from "../../../../process/task/assignee";
//import getQuestionValue from "../../../../process/task/getQuestionValue";
import {promiseAction} from "../../../../../../middleware/promiseDispatch";
import {push} from "react-router-redux";
import paths from "../../../../../paths";
import {getQuestionValue} from "../../../../../../util/form";

const assignee = (form, user, customer) => getQuestionValue(form)("status") === "Klant" ? customer : user;

const newDossier =
    user =>
        customer =>
            form => {

                const startPromise = startProcess(procesKeys.hypotheekAanvraag, dossierVariables(form, customer))
                    .then(process => Promise.all([
                                                     setAdvisor(process.id)(user),
                                                     setAssignee(process.id)(assignee(form, user, customer)),
                                                 ]));
                return promiseAction(startPromise.then(_ => push(paths.cases)));
            };

class NewDossier extends React.Component {

    state = {
        notes: [],
    }

    componentDidMount() {
        this.props.loadForm();
    }

    render() {
        let {customer, user, description = "Nieuwe HypotheekAanvraag"} = this.props;

        return (<ButtonForm description={description}
                            formEndpoint={Forms.hypotheekDossier}
                            submitAction={newDossier(user)(customer)}/>);
    }
}

const mapStateToProps = _ => ({})

const mapDispatchToProps = dispatch => ({
    loadForm: () => dispatch(retrieveForm(Forms.hypotheekDossier)),

});

const decorate = compose(connect(mapStateToProps, mapDispatchToProps));

export default decorate(NewDossier);

