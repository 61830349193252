const organizationCardStyles = theme => ({
    divider: {
        margin: "10px 0px 10px 0px",
    },
    title: {
        fontSize: 14,
        margin: "5px 0px 10px 0px",
    },
    card: {
        marginBottom: 15,
    },
    button:          {
        float:  "right",
        margin: "20px 0 20px 0",
    },
});
export default organizationCardStyles;
