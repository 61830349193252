/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-9-17.
 */

const retrieving = type => {
    return {
        type: type,
    }
};

export default retrieving;
