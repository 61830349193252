import "babel-polyfill";
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import React from "react";
import ReactDOM from "react-dom";
import 'semantic-ui-css/semantic.min.css';
import createHistory from 'history/createBrowserHistory'
import 'typeface-roboto'

import WebApp from "./main/app/WebApp";
import storeFactory from "./main/state/store";
import theme from "./main/style/theme";
import config from "./config";
import registerServiceWorker from "./main/util/registerServiceWorker";

import packageJson from '../package.json';
import upgrade from "./main/util/upgrade";
import {debug} from "./main/util/debug";

global.appVersion = packageJson.version;
console.log(`Starting the Atlas WebApp v${packageJson.version}`);

global.debug = debug;

config();

const history = createHistory();
const store = storeFactory(history);

const render = () => ReactDOM.render(
    <WebApp store={store}
            history={history}
            theme={theme}/>,
    document.getElementById("webapp"),
);

upgrade().then(
    _ => {
        render();
        registerServiceWorker();
        if (process.env.NODE_ENV !== "production") {
            if (module.hot) {
                module.hot.accept('./main/app/WebApp', () => {
                    console.clear();
                    console.log(new Date(), "Hotloaded the react components");
                    global.appVersion = packageJson.version;
                    render();
                });
            }
        }
    },
);
