/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 3-4-18.
 */
import React from "react";
import PropTypes from "prop-types"
import ModalPresentation from "./Presentation"
import {connect} from "react-redux/";
import retrieveForm from "../../../../action/creator/asynchonous/form/retrieveForm";
import ButtonPresentation from "./ButtonPresentation";

/**
 * @param Presentation, function/component that conforms to the Modal presentationInterface
 * @return {React}
 */
const ModalForm = Presentation =>
    class Container extends React.Component {

        static propTypes = {
            formEndpoint: PropTypes.string.isRequired,
            data:         PropTypes.object,
            submitAction: PropTypes.func.isRequired,
            retrieveForm: PropTypes.func.isRequired,
        };

        state = {
            open: false,
        };

        constructor(props) {
            super(props);
            this.handleOpen = this.handleOpen.bind(this);
            this.handleClose = this.handleClose.bind(this);
        }

        handleOpen() {
            this.props.retrieveForm();
            this.setState({open: true});
        }

        handleClose() {
            this.setState({open: false});
        }

        render() {
            return <Presentation open={this.state.open}
                                 onOpen={this.handleOpen}
                                 onClose={this.handleClose}
                                 {...this.props}/>;
        }
    };

const dispatchProps = dispatch => ({
    dispatch,
});

const mergeProps = (_, {dispatch}, ownProps) => ({
    ...ownProps,
    retrieveForm: () => dispatch(retrieveForm(ownProps.formEndpoint, ownProps.data)),
});

const ButtonForm = connect(null, dispatchProps, mergeProps)(ModalForm(ButtonPresentation));
const IconForm = connect(null, dispatchProps, mergeProps)(ModalForm(ModalPresentation))

export default connect(null, dispatchProps, mergeProps)(ModalForm(ModalPresentation));

export {
    ModalForm,
    ButtonForm,
    IconForm,
}


