import React from "react";
import {withStyles} from "@material-ui/core";
import PropTypes from 'prop-types';

import TextQuestion, {NumericText} from "./container/Text/TextQuestion";
import TextAreaQuestion from "./container/TextArea/TextAreaQuestion";
import ChoiceQuestion from "./container/Choice/ChoiceQuestion";
import BooleanQuestion from "./container/Boolean/BooleanQuestion";
import DateQuestion from "./container/Date/DateQuestion";
import AutosuggestTextQuestion from "./container/AutosuggestText/AutosuggestTextQuestion";
import questionPropType from "../propType/question";
import Info from "./presentation/Info/Info";
import FileQuestion from "./container/FileQuestion/FileQuestion";
import MultiSelect from "./container/MultiSelect/MultiSelect";
import SignatureQuestion from "./container/Signature/SignatureQuestion";

const styles = theme => ({
    root: {
        marginBottom: 20
    }
});

class QuestionComponent extends React.Component {

    constructor(props) {
        super(props);
        this.question = null;
    }

    state = {focused: false};

    render() {
        const {question, customer, onSubmitQuestion, formComponent, classes} = this.props;
        const component = (type, customer) => {
            switch (type) {
                case "TEXT":
                case "PERCENTAGE":
                    return <NumericText question={question}
                                        onSubmitQuestion={onSubmitQuestion}/>;
                case "EURO":
                    return <TextQuestion question={question}
                                         onSubmitQuestion={onSubmitQuestion}/>;
                case "TEXT_AREA":
                    return <TextAreaQuestion question={question}
                                             onSubmitQuestion={onSubmitQuestion}/>;
                case "CHOICE":
                    return <ChoiceQuestion question={question}
                                           onSubmitQuestion={onSubmitQuestion}/>;
                case "MULTI_SELECT":
                    return <MultiSelect question={question}
                                        onSubmitQuestion={onSubmitQuestion}/>;
                case "BOOLEAN":
                    return <BooleanQuestion question={question}
                                            onSubmitQuestion={onSubmitQuestion}/>;
                case "DATE":
                    return <DateQuestion question={question}
                                         onSubmitQuestion={onSubmitQuestion}/>;
                case "INFO":
                    return <Info question={question}/>;
                case "AUTOCOMPLETE_TEXT":
                    return <AutosuggestTextQuestion question={question}
                                                    onSubmitQuestion={onSubmitQuestion}/>;
                case "FILE":
                    return <FileQuestion question={question}
                                         customer={customer}
                                         onSubmitQuestion={onSubmitQuestion}/>;
                case "SIGNATURE":
                    return <SignatureQuestion question={question}
                                              onSubmitQuestion={onSubmitQuestion}/>;


                default:
                    console.error(`QuestionType '${type}' not supported`);
                    return null;
            }
        };

        return (
            <div className={classes.root}
                 id={`QuestionComponent_${this.props.question.field}`}
                 ref={ref => this.question = ref}
                 onFocus={() => {
                     if (formComponent) {
                         formComponent.focusedQuestion = this.question;
                     }
                 }}
                 onBlur={() => {
                     if (formComponent && formComponent.focusedQuestion === this.question) {
                         // seems to make sense to unset the question, but leads to 'shocking' scrolling behaviour
                         //  formComponent.focusedQuestion = null;
                     }
                 }}>
                {
                    component(question.type, customer)
                }
            </div>
        );
    }
}

QuestionComponent.propTypes = {
    question: questionPropType.isRequired,
    onSubmitQuestion: PropTypes.func.isRequired,
};

export default withStyles(styles)(QuestionComponent);
export {
    QuestionComponent,
}
