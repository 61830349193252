/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 1-2-18.
 */
import React from "react";
import {Card, CardContent, Grid} from "@material-ui/core";
import Item from "../markup/Item";
import ModalForm from "../../../../../../component/form/ModalForm/ModalForm";
import {Forms} from "../../../../../../../api/form/FormClient";
import Header from "../markup/Header";
import {stringify} from "../../../../../../../util/util";
import {fullAddress} from "../../../../../../../util/customer";
import propertyUpdater from "../updater/propertyUpdater";

const description = asset => fullAddress(asset.property.address);

const exists = variable => typeof variable !== "undefined" && variable !== null;

const isProperty = asset => exists(asset) &&
                            exists(asset.property);

const flatten = asset => {
    let flattened = {...asset.property, ...asset.property.address};
    delete flattened.address;
    flattened.solarPanel = flattened.solarPanel ? 'J' : 'N';
    return stringify(flattened);
};

const RealEstate = ({customer, postCustomer}) =>
    <Card>
        <CardContent>

            <Grid container>
                <Header title="Woningen"
                        modalForm={<ModalForm formEndpoint={Forms.editProperty}
                                              data={{}}
                                              icon="add_circle"
                                              submitAction={postCustomer(propertyUpdater())(customer)}/>}/>
            </Grid>

            <Grid container>
                {
                    customer.customerAssets === null
                    ? null
                    : customer.customerAssets
                              .filter(isProperty)
                              .map((asset, index) => <Item key={index}
                                                           object={asset}
                                                           icon="home"
                                                           getDescription={description}
                                                           modalForm={<ModalForm formEndpoint={Forms.editProperty}
                                                                                 data={flatten(asset)}
                                                                                 submitAction={postCustomer(propertyUpdater(index))(customer)}/>}/>)
                }
            </Grid>
        </CardContent>
    </Card>;

export default RealEstate;
