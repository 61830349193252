const getAssignee = name => {
    switch (name) {
        case "Richard van der Horst":
            return {
                name:  "Richard van der Horst",
                email: "richard@bluehorizon.nl",
            };
        case "Alwin Langerak":
            return {
                name:  "Alwin Langerak",
                email: "alwin@blisshypotheekadviseurs.nl",
            };
        case "Samantha Mater":
            return {
                name:  "Samantha Mater",
                email: "samantha@blisshypotheekadviseurs.nl",
            };

        case "Jeroen Slotboom":
            return {
                name:  "Jeroen Slotboom",
                email: "jeroen@blisshypotheekadviseurs.nl",
            };

        case "Sander op 't Hof":
            return {
                name:  "Sander op 't Hof",
                email: "sander@blisshypotheekadviseurs.nl",
            };

        default:
            return {
                name:  "Alwin Langerak",
                email: "alwin@blisshypotheekadviseurs.nl",
            };
    }
}

export {
    getAssignee,
};
