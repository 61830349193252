import moment from "moment";

const total = items => items.reduce((acc, cur) => acc + Number(cur.price), 0);

const hasTotal = items => items.reduce((acc, cur) => acc || cur.total, false)

const formatPrice = price => {

    if (price === 0) {
        return `€ 0,00`;
    }

    const string = `${price}`
    const cents = string.slice(-2);
    const euros = string.slice(0, -2);

    return `€ ${euros.toLocaleString('nl-NL')},${cents}`
};

const formatDate = date => moment(date).format("D MMMM YYYY");

const date = note => formatDate(note.creationTime);

const hasDescription = items => items.reduce((acc, cur) => acc || cur.description !== "", false);

export {
    total,
    hasTotal,
    formatPrice,
    formatDate,
    date,
    hasDescription,
}
