/**
 * TODO:Add Documentation to the class
 */
import React from "react";
import CustomerMenuItems from "./CustomerMenuItems";
import CustomerTopMenuItem from "./CustomerTopMenuItem/CustomerTopMenuItem";
import {Grid, withStyles} from "@material-ui/core";
import {compose} from "redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import singleColumn from "../../util/singleColumn";

// TODO: Remove; this class will be obsolete as soon as the PortalPrivateCustomer page is updated to the new setup

const styles = theme => ({
    singleColumnRoot: {
        display: "block",
        backgroundColor: '#e0f2f1',
        padding: "0px 2px 3px 4px"
    },
    doubleColumnRoot: {
        backgroundColor: '#e0f2f1',
        padding: "0px 2px 3px 4px"
    }
});

const CustomerTopMenu = ({customer, width, classes}) => (
    <Grid container
          className={singleColumn(width)? classes.singleColumnRoot: classes.doubleColumnRoot}>
        {CustomerMenuItems(customer).map((item, i) => <CustomerTopMenuItem key={i}
                                                                 item={item}/>)}
    </Grid>
);

const decorate = compose(
    withWidth(),
    withStyles(styles)
);

export default decorate(CustomerTopMenu);
