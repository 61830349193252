import paths from "../../../../../paths"
import {fullName} from "../../../../../../util/customer";

const CustomerMenuItems = customer => [
    {
        name: customer ? fullName(customer) : "Klantgegevens",
        icon: "person",
        url: paths.privateCustomer,
        colSize: 2
    },
    {
        name: "Adviezen",
        icon: "description",
        url: paths.offers,
        colSize: 2
    },
    {
        name: "Tijdlijn",
        icon: "access_time",
        url: paths.timeline,
        colSize: 2
    },
    {
        name: "Bestellingen",
        icon: "security",
        url: paths.contracts,
        colSize: 3
    },
    {
        name: "Documenten",
        icon: "attach_file",
        url: paths.documents,
        colSize: 3
    }
];

export default CustomerMenuItems;
