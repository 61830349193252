/**
 * Component that contains the generic parts of the Carousel
 *
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 18-7-18.
 * With thanks to https://medium.com/@incubation.ff/build-your-own-css-carousel-in-react-part-one-86f71f6670ca
 */
import React from "react";
import Swipeable from 'react-swipeable'
import {withStyles} from "@material-ui/core";
import {decrease, increase, modulo} from "../../../util/math";
import Promise from "bluebird";
import {itemStyle, viewportStyles} from "./styles";
import Repeat from "./Repeat"
import {compose} from "redux";
import CarouselPresentation from "./Presentation";
import throttle from 'lodash/throttle';

const INCREASE_CORRECTION = -1;
const DECREASE_CORRECTION = 1;

const translate = noItems => item => `translateX(calc(-${item * 100 / noItems}%))`;

const Carousel = Presentation =>
    class ViewPort extends React.Component {

        constructor(props) {
            super(props);
            const {children, offset} = props;
            this.state = {
                centered: 1, // the index of the child that should be centered
                sliding: false,
                slidingStyle: {
                    transition: 'none',
                    transform: translate(children.length)(offset),
                }
            };
        }

        slide = (newCentered, correction) => {
            const {children, offset} = this.props;
            const transform = translate(children.length);

            /**
             * Reorder the elements, but (immediately) adjust the sliders position so that it appears unchanged
             */
            const setupSliding = () => this.setState({
                                                         sliding: true,
                                                         centered: newCentered,
                                                         slidingStyle: {
                                                             transition: 'none',
                                                             transform: transform(offset - correction),

                                                         }
                                                     });

            /**
             * (Slowly) move the slider to the correct position
             */
            const transition = () => this.setState({
                                                       slidingStyle: {
                                                           transition: 'transform 1s ease',
                                                           transform: transform(offset),
                                                       }
                                                   }
            );

            const stopSliding = () => this.setState({
                                                        sliding: false,
                                                    });

            if (!this.state.sliding) {
                Promise.resolve()
                       .then(setupSliding)
                       .delay(1)
                       .then(transition)
                       .delay(1000)
                       .then(stopSliding);
            }
        };

        render() {
            const {carouselWidth, itemWidth, itemMargin, children, classes} = this.props;
            const {slidingStyle, centered} = this.state;

            const getOrder = index => modulo(index + this.state.centered, this.props.children.length);

            const slideRight = () => this.slide(increase(centered, children.length), INCREASE_CORRECTION);
            const slideLeft = () => this.slide(decrease(centered, children.length), DECREASE_CORRECTION);

            return (
                <Presentation onSlideLeft={slideLeft}
                              onSlideRight={slideRight}>
                    <Swipeable className={classes.viewport}
                               style={{maxWidth: `${carouselWidth}px`}}
                               trackMouse={true}
                               onSwipingLeft={throttle(slideLeft, 500, {trailing: false})}
                               onSwipingRight={throttle(slideRight, 500, {trailing: false})}>
                        <div className={classes.slider}
                             style={{
                                 ...slidingStyle,
                                 width: `${children.length * (itemWidth + itemMargin)}px`
                             }}>
                            {React.Children.map(children,
                                                (child, index) => React.cloneElement(child, {
                                                    index: index,
                                                    order: getOrder(index),
                                                    style: itemStyle(itemWidth, itemMargin),
                                                }))}
                        </div>
                    </Swipeable>
                </Presentation>
            );
        }
    };

// NB getFactor/getOffset are still a bit wobbly!
// getFactor should calculate the factor to recreate the children array to fill a width of
// minimal three times the viewport (so that offsetting  the array with arraylength in either
// way, still shows children)
// offset is a prop that can be given to Carousel to control which item is shown in the middle
// getOffset should determine the correct value the recreated array.
const getFactor = ({carouselWidth, itemWidth, itemMargin, children,}) => {
    const visibleItems = Math.ceil(carouselWidth / (itemWidth + itemMargin));
    const length = React.Children.toArray(children).length;
    return 3 * Math.ceil(visibleItems / length);
};
const getOffset = ({offset, ...rest}) => offset + getFactor(rest) / 3;

const decorate = compose(
    withStyles(viewportStyles),
    Repeat(getFactor, getOffset)
);

export default decorate(Carousel(CarouselPresentation));
