/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 16-5-18.
 */

const styles = theme => ({
    root: {
        marginBottom: "20px",
        width: "100%",
        padding: "0 13px",
        display: "flex",
        flexDirection: "row"
    },
    form: {
        width: "100%",
        padding: "0 13px",
        display: "flex",
        flexDirection: "row"
    },
    formControl: {
        width: "100%",
    },
    icon: {
        fontSize: 31,
        marginLeft: "0",
        marginTop: "30px",
        marginRight: "10px",
    },
    button: {
        maxHeight: "36px",
        margin: "21px 0 0 0",
        float: "right",
    }
});

export default styles;
