/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 24-7-18.
 */
import {repeat} from "../../../util/array";
import React from "react";

/**
 * @param getFactor
 * @param getOffset
 *
 * @return A component decorator that repeats the given children factor times
 */
const Repeat = (getFactor, getOffset) => Component => ({children, ...props}) => {

    children = React.Children.toArray(children);
    const multiplier = getFactor({...props, children});

    if (props.hasOwnProperty('offset')) {
        props = {...props, offset: getOffset({...props, children})}
    }

    return <Component {...props}>
        {React.Children.map(
            repeat(multiplier)(children),
            (child, index) => React.cloneElement(child, {key: index}))
        }
    </Component>;
};

export default Repeat;
