/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 22-11-17.
 */
import React from "react";
import {Typography} from "@material-ui/core";
import Anchor from "../../Anchor/Anchor";

const Links = ({links, classes}) =>
    <div>
        <Typography variant="h5"
                    className={classes.columnHeader}>Links</Typography>
        {
            links.map((link, index) => <Typography variant="subtitle1" key={index}>
                <Anchor href={link.link}>
                    {link.description}
                </Anchor>
            </Typography>)
        }
    </div>;

export default Links
