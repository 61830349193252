import {getQuestionValue} from "../../../../../../util/form";

/**
 * The question to select an adviseur  is implemented with a multiselect for aesthetic reasons,
 * but only one selection is possible.
 *
 * So we need to convert the stringRepresentation to an array and then select the only entry
 *
 * @param stringRepresentation
 * @returns {string|null}
 */
const firstAdviseur = stringRepresentation => {
    const adviseurs = JSON.parse(stringRepresentation);
    return Array.isArray(adviseurs) && adviseurs.length > 0? adviseurs[0] : null;
}

const getAppointment =
    form => ({
        gewenste_datum:     getQuestionValue(form)("gewenste_datum"),
        gewenst_tijdvak:    getQuestionValue(form)("gewenst_tijdvak"),
        opmerkingen:        getQuestionValue(form)("opmerkingen"),
        reden:              getQuestionValue(form)("reden"),
        telefoonnummer:     getQuestionValue(form)("telefoonnummer"),
        adviseur:           firstAdviseur(getQuestionValue(form)("adviseur")),
        afspraak_gaat_door: getQuestionValue(form)("afspraak_kan_plaatsvinden") === "Ja",
    })

export {
    getAppointment,
};
