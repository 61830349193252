/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 21-12-17.
 */
const styles = theme => ({
    progressContainer: {
        minHeight: 60
    },
    stepper: {
        paddingLeft: 0,
        width: '100%'
    },
    previousButton: {
        marginLeft: 10,
        marginTop: 20,
        backgroundColor: 'rgba(0, 0, 0, 0)'
    },
    stepperButton: {
        marginLeft: 10,
        marginTop: 20
    },
    plainButton: {
        float: "right",
        marginRight: 10,
        marginTop: 20
    },
    label: {
        fontSize: "15px",
        fontWeight: "normal",
    },
    stepLabel: {
        fontSize: "15px",
        fontWeight: "normal",
        margin: "0 0 0 12px",
        color: theme.palette.primary[500],
    },
    activeStepLabel: {
        fontWeight: "bold",
    },
});
export default styles
