/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 23-8-18.
 */
import React from "react";
import Dropzone from "react-dropzone";
import {Grid, Modal, Typography} from "@material-ui/core";
import {currentCustomer, documents} from "../../../state/selector/customer";
import {connect} from "react-redux";
import {customerClient} from "../../../api/relation/customer/CustomerClient";
import {deleteDocumentAction, loadDocumentsAction} from "../../../action/creator/asynchonous/customer/document";
import Documents from "./Documents";
import typeSelector from "./typeSelector";
import {createUpload} from "./uploads";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    container: {
        padding: "0px 24px 0px 24px",
    },
    documents: {
        flexGrow:1,
    },
    dropzone: {
        margin: "0px 20px 0px 20px",
    },
    modal: {
        position: 'absolute',
        width: "50%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        borderRadius: 4,
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`,
    },
    clickable: {
        cursor: "pointer",
    }
});

class DocumentUpload extends React.Component {

    state = {
        inProgress: [],
        toBeUploaded: [],
    };

    componentDidMount() {
        this.props.loadDocuments(this.props.customer);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.toBeUploaded.length > 0) {
            this.state.toBeUploaded.forEach(
                upload => customerClient.uploadDocument(this.props.customer, upload.file, upload.type)
                    .then(() => this.props.loadDocuments(this.props.customer)));
            this.setState({toBeUploaded: []});
        }
    }

    render() {
        const {documents, onDeleteDocument, typeFilter = _ => true, classes} = this.props;
        const {inProgress} = this.state;

        return <Grid container className={classes.container}>
            <Grid item
                  className={classes.documents}
            >
                <Documents documents={documents}
                           onDeleteDocument={onDeleteDocument}/>
            </Grid>
            <Grid item
                  className={classes.dropzone}
            >
                <Dropzone onDrop={files => this.setState({
                    inProgress: [...inProgress,
                        ...files.map(createUpload())]
                })}>
                    <div>Sleep het document naar dit kader, of klik om een bestand te selecteren</div>
                </Dropzone>

                <Modal open={inProgress.length > 0}
                       onClose={_ => this.setState({inProgress: []})}>
                    <div className={classes.modal}>
                        <Typography>Wat voor soort document wilt u uploaden?</Typography>
                        {inProgress.map(typeSelector(this, typeFilter))}
                    </div>
                </Modal>

            </Grid>
        </Grid>;
    }
}

const stateToProps = state => ({
    customer: currentCustomer(state),
    documents: documents(state) ? documents(state) : [],
});

const dispatchToProps = dispatch => ({
    loadDocuments: customer => dispatch(loadDocumentsAction(customer)),
    onDeleteDocument: document => dispatch(deleteDocumentAction(document)),
});

const decorate = compose(
    connect(stateToProps, dispatchToProps),
    withStyles(styles)
);
const ConnectedDocumentUpload = decorate(DocumentUpload);
const UnConnectedDocumentUpload = withStyles(styles)(DocumentUpload);

export default ConnectedDocumentUpload;
export {
    UnConnectedDocumentUpload as DocumentUpload,
};

