/**
 * @type {Reducer}
 */
import bundleReducers from "../bundleReducers";
import handleRetrievedForm from "./handleRetrievedForm";
import updateQuestionValue from "./updateQuestionValue";
import submitGroup from "./submitGroup";
import handleRetrievingForm from "./handleRetrievingForm";
import documents from "./documents";

const initialState = {groups: []};

const form = () => {
    return bundleReducers(initialState, [
        handleRetrievingForm,
        handleRetrievedForm,
        updateQuestionValue,
        submitGroup,
        documents
    ], true);
};
export default form;
export {
    form,
    initialState,
}

