import {Divider, List, ListSubheader, withStyles} from "@material-ui/core";
import * as React from "react";
import SideMenuItem from "../SideMenuItem/SideMenuItem";

const styles = theme => ({
    menuList: {
        width: '100%'
    },
    menuSubHeader: {
        padding: '0 32px',
        width: '100%',
        fontSize: '110%'
    }
});

const SideMenuGroup = ({group, classes}) => {
    let list = (items) =>
        group.hasOwnProperty('group')
            ? <List subheader={
                <ListSubheader className={classes.menuSubHeader}>{group.group}</ListSubheader>
            }
                    className={classes.menuList}>{items}</List>
            : <List className={classes.menuList}>{items}</List>;

    return <div>
        {list(
            group.items.map((item, i) => <SideMenuItem key={i}
                                                       item={item}/>)
        )}
        <Divider/>
    </div>
};

export default withStyles(styles)(SideMenuGroup);
