import React from "react";
import {Button, Card, CardContent, CircularProgress, Modal, Typography, withStyles} from "@material-ui/core";
import RelationClient from "../../../../../../api/relation/RelationClient";
import * as PropTypes from "prop-types";
import ConsultantForm from "./ConsultantForm";
import {customerClient} from "../../../../../../api/relation/customer/CustomerClient";
import ConsultantTable from "./ConsultantTable";
import {info} from "../../../../../../util/util";
import CardHeader from "@material-ui/core/CardHeader";

const styles = theme => ({
    root:            {
        float: "right",
    },
    modal:           {
        position:        'absolute',
        width:           "50%",
        backgroundColor: theme.palette.background.paper,
        boxShadow:       theme.shadows[5],
        padding:         theme.spacing.unit * 4,
        borderRadius:    4,
        top:             "50%",
        left:            "50%",
        transform:       `translate(-50%, -50%)`,
        maxHeight:       "calc(100% - 96px)",
        overflowY:       "auto",
    },
    clickable:       {
        cursor: "pointer",
    },
    clickableHeader: {
        cursor:    "pointer",
        marginTop: 16,
    },
    button:          {
        float:  "right",
        margin: "20px 35px 20px 0",
    },
    paragraph:       {
        marginLeft: "25px",
    },
});

const consultingUsers =
    users =>
        customer =>
            roleName =>
                customer.consultants
                        .filter(consultant => consultant.role === roleName)
                        .map(consultant => ({
                            user:       findConsultantUser(users)(consultant),
                            consultant: consultant,
                        }));

const findConsultantUser =
    users =>
        consultant =>
            users.find(user => user.email === consultant.email);


const description = roleName => {
    switch (roleName) {
        case "role_notaris":
            return "Notaris";
        case "role_apotheek_adviseur":
            return "Adviseur";
        case "role_makelaar":
            return "Makelaar";
        case "role_eerste_lijn":
            return "Eerste lijn";
        default:
            console.error("Onbekende rol: '" + roleName + "'");
    }
};

const descriptionMulti = roleName => {
    switch (roleName) {
        case "role_notaris":
            return "Notarissen";
        case "role_apotheek_adviseur":
            return "Adviseurs";
        case "role_makelaar":
            return "Makelaars";
        case "role_eerste_lijn":
            return "Eerste lijns";
        default:
            console.error("Onbekende rol: '" + roleName + "'");
    }
};


ConsultantForm.propTypes = {users: PropTypes.arrayOf(PropTypes.any)};


class Role extends React.Component {

    state = {
        open:  false,
        users: [],
        loaded: false
    }

    componentDidMount() {
        const {roleName} = this.props;
        RelationClient.getData(`user/role/${roleName}/`)
                      .then(result => result.entity)
                      .then(users => this.setState({users, loaded: true}))
    }

    render() {
        const {customer, reloadCustomer, roleName, role, classes} = this.props;
        const {users, loaded, open} = this.state;

        const onClick = () => this.setState({open: true});

        const handleSelection = customer => selection => {
            customerClient.addConsultant(customer, selection, roleName).then(info())
                          .then(_ => reloadCustomer())
            this.setState({open: false});
        };

        return (
            <Card>
                <CardContent>
                    <CardHeader title={`Gekoppelde ${descriptionMulti(roleName)}`}/>

                    {
                        loaded
                            ? consultingUsers(users)(customer)(roleName).length > 0
                                ? <ConsultantTable consultingUsers={consultingUsers(users)(customer)(roleName)}
                                                   reloadCustomer={reloadCustomer}/>
                                : <Typography variant="body1" className={classes.paragraph}>
                                    <br/>
                                    Er is nog geen consultant gekoppeld
                                    <br/>
                                </Typography>
                            : <CircularProgress/>
                    }

                    <br/>
                    <Button className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={onClick}
                            type="submit">{description(roleName)} koppelen
                    </Button>

                    <Modal open={open}
                           onClose={() => this.setState({open: false})}>
                        <div className={classes.modal}>
                            <ConsultantForm users={users} handleSelection={handleSelection(customer)} role={role}/>
                        </div>
                    </Modal>
                </CardContent>
            </Card>
        )
    }
}

export default withStyles(styles)(Role);
