import React from "react";
import Promise from "bluebird";
import {instanceVariables, processesList} from "../../../api/process/process/processes";
import Dossier from "./model/Dossier";
import {compareDossiersByDate} from "./date";
import {Card, CardContent, CircularProgress, withStyles} from "@material-ui/core";
import DossierList from "./list/DossierList";
import {customerClient} from "../../../api/relation/customer/CustomerClient";
import {dossierVariableNames} from "../../page/customer/Customer/components/dossier/variables";
import {push} from "react-router-redux";
import {instancePath} from "../../paths";
import {routeCustomer} from "../../../action/creator/router";
import {compose} from "redux";
import {connect} from "react-redux";
import CardHeader from "@material-ui/core/CardHeader";
import CardMediaHeader from "../CardMediaHeader/CardMediaHeader";
import Filter from "./Filter";
import {currentUser} from "../../../state/selector/user";

const styles = theme => ({
    filter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: "20px",
    }
});

let typeFilters = {
    starter: "Aankoop woning (starter)",
    doorstromer: "Aankoop woning (doorstromer)",
    oversluiter: "Oversluiten",
    verhogen: "Hypotheek verhogen",
    beeindiging: "Relatiebeeindiging",
    verhuur: "Verhuurhypotheek",
    none: "Alle dossiers"
};
let statusFilters = {
    adviseur: "Adviseur",
    notaris: "Notaris",
    makelaar: "Makelaar",
    klant: "Klant",
    verstrekker: "Hypotheekverstrekker",
    binnenDienst: "Binnendienst",
    none: "Alle dossiers"
};
let userFilters = {
    my: "Mijn dossiers",
    open: "Mijn open dossiers",
    none: "Alle Dossiers"
};

const hasUserChoice =
    user =>
        choice =>
            dossier => {
                switch (choice) {
                    case "my":
                        return dossier.getAdviseur() === user.name;
                    case "open":
                        return dossier.getAdviseur() === user.name && dossier.getStatus() === "Adviseur";
                    case "none":
                    default:
                        return true;
                }
            };

const hasStatusChoice =
    choice =>
        dossier =>
            choice === "none" ? true : dossier.getStatus() === statusFilters[choice];

const hasTypeChoice =
    choice =>
        dossier =>
            choice === "none" ? true : dossier.getAanvraag() === typeFilters[choice];

class Dossiers extends React.Component {

    state = {
        dossiers: [],
        userFilter: "my",
        statusFilter: "none",
        typeFilter: "none",
    };

    /* promisified version of setState */
    updateState = newState => new Promise(resolve => this.setState(newState, resolve));

    componentDidMount() {

        // Prefill state with intermediate results
        // And task by task fill in the details (customername/processname)
        // (and finally sort the results)

        processesList("HypotheekAanvraag", this.props.query)
            .then(dossiers => dossiers.map(dossier => new Dossier(dossier)))
            .then(dossiers => this.updateState({dossiers})
                                  .then(_ => Promise.each(dossiers,
                                                          (dossier, index) =>
                                                              instanceVariables(dossier.process.id)
                                                                  .then(variables => {
                                                                      dossiers[index].setVariables(variables)
                                                                      return this.updateState({dossiers});
                                                                  })
                                                                  .then(_ => dossier.getCustomerId() === null
                                                                             ? Promise.resolve(null)
                                                                             : customerClient.fetchCustomer(dossier.getCustomerId())
                                                                                             .catch(error => console.error(error)))
                                                                  .then(customer => {
                                                                            dossiers[index].setCustomer(customer);
                                                                            return this.updateState({dossiers});
                                                                        }
                                                                  ))))
            .then(_ => this.updateState({dossiers: this.state.dossiers.sort(compareDossiersByDate(dossierVariableNames.DEADLINE_FIRST))}));
    }

    render() {

        const {title = "Dossier", showCustomer = true, mediaHeader = false, showAllways = false, onClickDossier, onClickCustomer, user, classes} = this.props;

        const dossiers = this.state.dossiers
                             .filter(hasUserChoice(user)(this.state.userFilter))
                             .filter(hasStatusChoice(this.state.statusFilter))
                             .filter(hasTypeChoice(this.state.typeFilter));

        const show = showAllways || dossiers.length > 0;

        const filterEventHandler = filter => event => this.updateState({[filter]: event.target.value})

        return show
               ? <Card>
                   {
                       mediaHeader ? <CardMediaHeader title={title}
                                                      image="/images/handshake.jpg"/> : <CardHeader title={title}/>
                   }
                   <CardContent>

                       <div className={classes.filter}>
                           <Filter name="Eigen dossiers"
                                           current={this.state.userFilter}
                                           onChange={filterEventHandler("userFilter")}
                                           filters={userFilters}/>
                           <Filter name="Status"
                                   current={this.state.statusFilter}
                                   onChange={filterEventHandler("statusFilter")}
                                   filters={statusFilters}/>
                           <Filter name="Type"
                                   current={this.state.typeFilter}
                                   onChange={filterEventHandler("typeFilter")}
                                   filters={typeFilters}/>
                       </div>

                       {
                           dossiers === null ? <CircularProgress/>
                                             : <DossierList dossiers={dossiers}
                                                            showCustomer={showCustomer}
                                                            onClickDossier={onClickDossier}
                                                            onClickCustomer={onClickCustomer}/>
                       }
                   </CardContent>
               </Card>
               : null
    }
}



const mapStateToProps = state => ({
    user: currentUser(state),
});

const dispatchToHandlers = dispatch => ({
    onClickDossier: dossier => () => dispatch(push(instancePath(dossier.process.id))),
    onClickCustomer: customer => () => customer ? routeCustomer(customer.id).then(dispatch) : Promise.resolve(),
});

const decorate = compose(
    connect(mapStateToProps, dispatchToHandlers),
    withStyles(styles),
);

export default decorate(Dossiers);
