/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 8-7-19.
 */
import React from "react";
import {Card, CardContent, ListItemText} from "@material-ui/core";
import BigCardHeader from "../../../../../component/Card/BigCardHeader";
import {customerClient} from "../../../../../../api/relation/customer/CustomerClient";
import List from "@material-ui/core/List";
import ListItem from "semantic-ui-react/dist/commonjs/elements/List/ListItem";
import Grid from "@material-ui/core/Grid";
import {fullName} from "../../../../../../util/customer";

// const getDocumentFields = request => request.groups
//                                             .map(group => Object.values(group.fields)
//                                                                 .filter(({value}) => Object.values(uploadTypes).includes(value)));

class Documents extends React.Component {

    state = {
        documents: [],
        selectedDocument: null,
    };

    componentDidMount() {
        const {customer} = this.props;
        customerClient.documents(customer)
                      .then(documents => this.setState({documents: documents.entity}));
    }

    render() {
        const {customer} = this.props;
        //const {selectedDocument} = this.state;

        //const documents = this.state.documents;
        const documents = []; // FIXME: use the documents from Relations; this was working for businessCustomers and
                              //        stopped  working  on the move to privateCustmomers

        return (
            <Card>
                <CardContent>
                    <BigCardHeader title={`${fullName(customer)}`}/>

                    <Grid container>
                        <Grid item
                              xs={12}
                              md={4}>
                            <List>
                                {
                                    documents.map(document => <ListItem key={document.id}>
                                        <ListItemText primary={document.documentType}
                                                      secondary={document.title}
                                                      onClick={_ => this.setState({selectedDocument: document})}

                                        />
                                    </ListItem>)
                                }
                            </List>
                        </Grid>
                        <Grid item
                              xs={12}
                              md={8}>
                            {
                                // selectedDocument ? <FileViewer key={customerClient.documentUrl(selectedDocument)}
                                //                                fileType={customerClient.documentType(selectedDocument)}
                                //                                filePath={customerClient.documentUrl(selectedDocument)}
                                //                                onError={error => console.error(error)}/>
                                //                  : null
                            }

                        </Grid>
                    </Grid>
                </CardContent>
            </Card>);
    }
}

export default Documents;
