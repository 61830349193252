/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 14-12-17.
 */
import * as React from "react";
import {Button, Card, CardContent, Grid, Icon, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {compose} from "redux";
import securePage from "../../SecurePage/SecurePage";
import CardMediaHeader from "../../../component/CardMediaHeader/CardMediaHeader";
import LoggedOn from "../../../component/Main/Base/LoggedOn/LoggedOn";
import {Forms} from "../../../../api/form/FormClient";
import {routeFormPage} from "../../../../action/creator/router";
import paths from "../../../paths";
import {roles} from "../../../../api/login/LoginClient";

const styles = theme => ({
    icon: {
        marginRight: 10
    }
});

const NewCustomer = ({classes, onNewConsumer, onNewBusiness}) =>
    <LoggedOn>
        <div style={{width: "100%"}}>

            <Card>
                <CardMediaHeader title=" Nieuwe klant"
                                 image="/images/handshake.jpg"/>
                <CardContent>
                    <Grid container>
                        <Grid item
                              lg={6}>
                            <Button variant="contained"
                                    color='primary'
                                    onClick={onNewBusiness}>
                                <Icon className={classes.icon}>business</Icon> Zakelijk
                            </Button>
                        </Grid>

                        <Grid item
                              lg={6}>
                            <Button variant="contained"
                                    color='primary'
                                    onClick={onNewConsumer}>
                                <Icon className={classes.icon}>person</Icon>
                                Particulier
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    </LoggedOn>;

const mapDispatchToProps = dispatch => ({
    onNewConsumer: () => routeFormPage(paths.newPrivateCustomer, Forms.consumerCustomer).then(dispatch),
    onNewBusiness: () => routeFormPage(paths.newBusinessCustomer, Forms.brokerBusinessCustomer).then(dispatch),
});

export default compose(
    withStyles(styles),
    connect(null, mapDispatchToProps),
    securePage([roles.admin])
)(NewCustomer);



