/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 18-7-18.
 */

const viewportStyles = theme => ({
    viewport: {
        overflow: "hidden",
        padding: "0 0 0 0",
    },

    slider: {
        display: "flex",
        margin: "0 0 0 0",
        padding: "0 0 0 0",
    },
});

const itemStyle = (itemWidth, itemMargin) => ({
    maxWidth: `${itemWidth}px`,
    minWidth: `${itemWidth}px`,
    width: `${itemWidth}px`,
    marginRight: `${itemMargin}px`,
});

export {
    viewportStyles,
    itemStyle,
}
