import RelationClient from "../../RelationClient";

class OrganizationClient {

    createJsonForNewOrganization(form) {
        let data = {};
        form.groups.forEach(function (group) {
            group.questions.forEach(function (question) {
                let field = question.field;
                    data[field] = question.parsedValue;
            });
        });
        return data;
    }

    /**
     * Returns a promise that will resolve once the Organization has been added the the store of organizations.
     *
     * @param {object} form
     * @return {Promise<void>}
     */
    add(form) {
        console.log("ADDING Organization", JSON.stringify(form, null, 2));
        let formJson = organizationClient.createJsonForNewOrganization(form);
        return RelationClient.putData(RelationClient.organization, formJson);
    }

     /**
         * Returns a promise that will resolve once the store has updated the Organization
         *
         * @param {object} form
         * @return {Promise<object>}
         */
        update(organization) {
            return RelationClient.postData(RelationClient.organization, organization);
        }

        findAll(){
            return RelationClient.getData(RelationClient.organization + "findAll");
        }
		
		delete(id){
            return RelationClient.deleteData(RelationClient.organization + "/" + id);
        }

}

const organizationClient = new OrganizationClient();

export default OrganizationClient;
export {
    organizationClient,
}
