/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 14-6-18.
 */

/**
 * Use the form-data to update the plain attributes of the customer
 * @updater
 */
const customerUpdater = (customer, form) => {
    form.groups.forEach(function (group) {
        group.questions.forEach(function (question) {
            const field = question.field;

            if (field.includes(".")) {
                console.error("TODO TODO TODO . . . ");
            }

            customer[field] = question.parsedValue;
        });
    });
    return customer;
};

export default customerUpdater;
