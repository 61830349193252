/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 8-7-19.
 */
import React from "react";


import {withStyles} from "@material-ui/core/index";
import {Table, TableBody, TableCell, TableRow} from "@material-ui/core";


const styles = theme => ({
    container: {
        marginTop: "30px",
        marginBottom: "10px",
    },
    header: {
        fontWeight: "bold"
    }
});

const FormData = ({data, classes}) => {

    return <Table className={classes.container}>
        {/*<TableHead>*/}
        {/*    <TableRow>*/}
        {/*        <TableCell>Vraag</TableCell>*/}
        {/*        <TableCell>Antwoord</TableCell>*/}

        {/*    </TableRow>*/}
        {/*</TableHead>*/}
        <TableBody>
            {
                data.map((item, index) =>
                    <TableRow key={index}>
                        <TableCell>{item.field}</TableCell>
                        <TableCell>{item.value}</TableCell>
                    </TableRow>
                )
            }
        </TableBody>
    </Table>

};

export default withStyles(styles)(FormData)
