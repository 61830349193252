/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 19-4-18.
 */
import React from "react";
import {postClient} from "../postCustomer";
import {Forms} from "../../../../../../../api/form/FormClient";
import ModalForm from "../../../../../../component/form/ModalForm/ModalForm";
import Header from "../markup/Header";
import Item from "../markup/Item";
import {stringify} from "../../../../../../../util/util";
import {isPartner} from "./Partner";
import {Grid} from "@material-ui/core";
import {familyMemberUpdater} from "../updater/familyMemberUpdater";

const isChild = familyMember => !isPartner(familyMember);

const flatten = x => stringify(x);
const description = familyMember => `${familyMember.initials} ${familyMember.insertPart} ${familyMember.lastName}`;

const Children = ({customer}) =>
    <div>
        <Grid container>
            <Header title="Kinderen"
                    modalForm={<ModalForm formEndpoint={Forms.editFamilymember}
                                          data={{}}
                                          icon="add_circle"
                                          submitAction={postClient(familyMemberUpdater("child"))(customer)}/>}/>
        </Grid>
        <Grid container>
            {
                customer.customerFamilyMembers === null
                ? null
                : customer.customerFamilyMembers
                          .filter(isChild)
                          .map((familyMember, index) => <Item key={index}
                                                              object={familyMember}
                                                              icon="person"
                                                              getDescription={description}
                                                              modalForm={<ModalForm formEndpoint={Forms.editFamilymember}
                                                                                    data={flatten(familyMember)}
                                                                                    submitAction={
                                                                                        postClient(familyMemberUpdater("child", index))(customer)
                                                                                    }/>}/>)
            }
        </Grid>
    </div>;

export default Children;
