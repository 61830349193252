import Stroke from "./Stroke";
import Dot from "../geometry/point/Dot";

/**
 * This will be used to draw remaining samples that
 * are not part of a curve
 * (i.e. if the 'curve' is aborted before there are three samples)
 */
class DiscreteStroke extends Stroke {

    /**
     * @param {CanvasRenderingContext2D} renderingContext
     */
    draw(renderingContext) {
        this.samples.forEach(
            sample => this.drawSample(renderingContext, sample));
    }

    /**
     * @param {CanvasRenderingContext2D} renderingContext
     * @param {Sample} sample
     */
    drawSample(renderingContext, sample) {

        renderingContext.beginPath();
        this.drawDot(renderingContext, new Dot(sample,
                                               this.curves.weights.default()));
        renderingContext.closePath();
        renderingContext.fill();
    }
}

export default DiscreteStroke;
