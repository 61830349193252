/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-9-17.
 */

import FormClient from "../../../../api/form/FormClient";
import ActionType from "../../../ActionType";
import retrieving from "../retrieving";
import error from "../../error";
import retrieved from "../retrieved";
import {promiseAction} from "../../../../middleware/promiseDispatch";

/**
 * @return {Action}
 */
const retrieveForm = (endpoint, data = null, documents = null) =>
    promiseAction(
        FormClient.getForm(endpoint, data, documents)
                  .then(retrieved(ActionType.asynchronous.form.get.RETRIEVED)),
        retrieving(ActionType.asynchronous.form.get.RETRIEVING),
        error("Problem fetching QuestionForm"),
    );

/**
 * On receiving an event this handler wil dispatch an action to retrieve the form (as specified by endpoint)
 * @param dispatch
 * @param endpoint
 * @param data
 * @return event -> ()
 */
const dispatchRetrieveForm = dispatch => (endpoint, data) =>
    event => dispatch(retrieveForm(endpoint, data));

export default retrieveForm;
export {
    retrieveForm,
    dispatchRetrieveForm,
}
