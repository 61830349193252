/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 25-9-18.
 */

import React from "react";
import LoggedOn from "../../../component/Main/Base/LoggedOn/LoggedOn";
import {Card, CardContent, CircularProgress, withStyles} from "@material-ui/core";
import CardMediaHeader from "../../../component/CardMediaHeader/CardMediaHeader";
import {taskPath} from "../../../paths";
import {connect} from "react-redux";
import {push} from 'react-router-redux';
import TaskList from "./taskList/TaskList";
import {compose} from "redux";
import loadTasks from "../../../../api/process/task/loadTasks";
import securePage from "../../SecurePage/SecurePage";
import {roles} from "../../../../api/login/LoginClient";
import {currentUser} from "../../../../state/selector/user";
import TaskRestriction, {currentUserQuery} from "./TaskRestriction";

const styles = theme => ({
    root: {
        width: "100%",
        borderRadius: 2
    }
});

class Tasks extends React.Component {

    state = {
        searchResults: null,
    };

    componentDidMount() {
        const {restriction, user} = this.props;
        const query = (restriction === TaskRestriction.CurrentUser) ? currentUserQuery(user) : null;
        loadTasks(this, query);
    }

    render() {

        const {onClick, classes, restriction} = this.props;
        const searchResults = this.state.searchResults;

        return <LoggedOn>
            <Card className={classes.root}>
                <CardMediaHeader title="Taken"
                                 image="/images/handshake.jpg"/>
                <CardContent>
                    {
                        searchResults == null
                        ? <CircularProgress/>
                        : <TaskList tasks={searchResults.results}
                                    totalResults={searchResults.totalResults}
                                    query={searchResults.query}
                                    onClickTask={onClick}
                                    showAssignee={restriction === TaskRestriction.None}
                                    onPage={page => {
                                        this.setState({searchResults: null});
                                        return loadTasks(this, {...searchResults.query, page});
                                    }}/>
                    }

                </CardContent>
            </Card>
        </LoggedOn>
    }
}

const mapStateToProps = state => ({
    user: currentUser(state),
});

const dispatchToHandlers = dispatch => ({
    onClick: task => () => dispatch(push(taskPath(task.id))),
});

const decorate = compose(
    connect(mapStateToProps, dispatchToHandlers),
    securePage([roles.admin]),
    withStyles(styles)
);

export default decorate(Tasks);
