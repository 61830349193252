/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 19-12-17.
 */
import {push} from 'react-router-redux';
import CustomerClient, {customerClient} from "../../api/relation/customer/CustomerClient";
import {defaultSearch, searchResultAction} from "./asynchonous/search/search";
import {compoundAction} from "../../middleware/compoundDispatch";
import currentCustomer from "./asynchonous/customer/currentCustomer";
import retrieveForm from "./asynchonous/form/retrieveForm";
import {errorAction} from "../../api/relation/RelationClient";
import currentOrganization from "./asynchonous/organization/currentOrganization";
import currentUser from "./asynchonous/user/currentUser";

import paths, {businessCustomerPath, privateCustomerPath, userPath} from "../../app/paths"
import {initialState} from "../../reducer/customer/search/searchResults";

const client = user => new CustomerClient(user);

/**
 *
 * @param url
 * @param {object} user
 * @return {Promise.<Action>}
 */
const route = (url, user) => {
    url = url? url : '/#!';

    switch (url) {
        case paths.dashboard:
            return Promise.resolve(compoundAction(searchResultAction(initialState),
                                                  push(url)));
        case paths.customers:
            console.assert(user !== null, "Can't search without user!");
            return client(user).search(defaultSearch)
                               .then(searchResult => compoundAction(searchResultAction(searchResult),
                                                                    push(url)))
                               .catch(errorAction);
        case paths.archive:
            console.assert(user !== null, "Can't search without user!");
            return client(user).search({...defaultSearch, customerStatus: "ARCHIVED"})
                               .then(searchResult => compoundAction(searchResultAction(searchResult),
                                                                    push(url)))
                               .catch(errorAction);
        case paths.prospects:
            console.assert(user !== null, "Can't search without user!");
            return client(user).search({...defaultSearch, customerStatus: "PROSPECT"})
                               .then(searchResult => compoundAction(searchResultAction(searchResult),
                                                                    push(url)))
                               .catch(errorAction);
        default:
            return Promise.resolve(push(url));
    }
};

const routeCustomer = customerId => {
    return customerClient.fetchCustomer(customerId)
                         .then(customer =>
                                   route(customer.customerType === "CLIENT"
                                             ? privateCustomerPath(customerId)
                                             : businessCustomerPath(customerId))
                                       .then(routeAction =>
                                                 compoundAction(currentCustomer(customer),
                                                                routeAction)));
};

const routeForCustomer = customer => {
    return route(customer.customerType === "CLIENT"
                     ? privateCustomerPath(customer.id)
                     : businessCustomerPath(customer.id))
        .then(routeAction => compoundAction(currentCustomer(customer),
                                            routeAction));
}

const routePortalCustomer = customerId => {
    return customerClient.fetchCustomer(customerId).then(customer => route(paths.portalCustomer)
        .then(routeAction => compoundAction(currentCustomer(customer),
                                            routeAction)));
};

const routeOrganization = organization => {
    return route("/organization")
        .then(routeAction => compoundAction(currentOrganization(organization),
                                            routeAction));
};

const routeNewUserPage = (url, endpoint, organization) => {
    return route(url).then(routeAction => compoundAction(currentOrganization(organization), retrieveForm(endpoint),
                                                         routeAction))
};

const routeFormPage = (url, endpoint) => route(url).then(routeAction => compoundAction(retrieveForm(endpoint),
                                                                                       routeAction));

const routeUser = user => {
    return route("/user")
        .then(routeAction => compoundAction(currentUser(user),
                                            routeAction));
};

const routeForUser = user => route(userPath(user.id), user)

export default route;
export {
    route,
    routeCustomer,
    routeFormPage,
    routeOrganization,
    routeNewUserPage,
    routeUser,
    routeForUser,
    routePortalCustomer,
    routeForCustomer,
}
