/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 23-5-18.
 */
import React from "react";
import {FormControl, Input, InputLabel, withStyles} from "@material-ui/core";
import Suggestions from "./Suggestions";

const styles = theme => ({
    root: {
        width: "98%",
    },
    dropdown: {
        width: "100%",
        position: "absolute",
    }
});

/**
 * searchFieldPresentation needs to be of signature
 *     ({suggestions, onBlur}) =>
 *         ({getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex}) => Component,
 * but that makes it impossible to inject a classes-object with withStyles()()
 *
 * So we create an uncurried version of what we need, inject the classes, then simply call the result from the
 * curried version (searchFieldPresentation)
 *
 * @param suggestions
 * @param onBlur
 * @param getInputProps
 * @param getItemProps
 * @param isOpen
 * @param inputValue
 * @param selectedItem
 * @param highlightedIndex
 * @param classes
 * @return {*}
 * @constructor
 */
const BarePresentation = ({suggestions, onBlur, getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex, classes}) =>
    <FormControl className={classes.root}
                 error={false}
                 margin='normal'>
        <InputLabel>Zoekterm</InputLabel>
        <Input inputProps={getInputProps()}/>

        <Suggestions suggestions={suggestions}
                     isOpen={isOpen}
                     highlightedIndex={highlightedIndex}
                     itemPropGetter={getItemProps}
                     classes={classes}/>
    </FormControl>
const Presentation = withStyles(styles)(BarePresentation);

/**
 * Given suggestions and onBlur, this functions returns
 * a stateless functional component conforming to the signature of the Downshift render-prop
 *
 * @param {string} currentSearchTerm
 * @param {array.<SuggestionType>} suggestions
 * @return {React}
 */
const searchFieldPresentation =
    ({suggestions, onBlur}) =>
        ({getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex}) =>
            <div /* NB. DownShift requires a 'native' root element, hence the wrapping div */ style={{flexGrow: 4}}>
                <Presentation suggestions={suggestions}
                              onBlur={onBlur}
                              getInputProps={getInputProps}
                              getItemProps={getItemProps}
                              isOpen={isOpen}
                              inputValue={inputValue}
                              selectedItem={selectedItem}
                              highlightedIndex={highlightedIndex}/>
            </div>;

export default searchFieldPresentation;
