import {delta, format, formatDeadline} from "../date";
import {now} from "../../../../util/util";

const hasPassed = date => date && date.isBefore(now());

class DossierDate {
    name;
    expiry;
    isCompleted;
    completion;

    constructor(name, expiry, isCompleted, completion) {
        this.name = name;
        this.expiry = expiry;
        this.isCompleted = isCompleted;
        this.completion = completion;
    }

    hasExpired() {
        return hasPassed(this.expiry);
    }

    almostExpired() {
        return !this.isCompleted && !this.hasExpired() && this.expiry && delta(this.expiry, now()) < 4;
    }

    getIcon() {
        if (this.isCompleted) {
            return "check"
        }
        return null;
    }

    showProblems() {
        return !["KOOPOVEREENKOMST"].includes(this.name);
    }

    getClass() {
        return this.isCompleted ? "done"
                                : this.showProblems() && this.hasExpired() ? "problem"
                                                                           : "inProgress";
    }
    getTitle() {
        if (this.isCompleted) {
            return `Sinds ${format(this.completion)}`
        }
    }

    getDescription() {
        return this.isCompleted ? ""
                                : formatDeadline(this.expiry);
    }

    getBadge() {
        if (this.almostExpired()) {
            return delta(this.expiry, now())
        }
        return null;
    }

    toString() {
        return `Dossier:
            expiry       : ${this.expiry}, isCompleted: ${this.isCompleted}, completion: ${this.completion}
            icon         : ${this.getIcon()}
            class        : ${this.getClass()}
            title        : ${this.getTitle()}
            description  : ${this.getDescription()}
            badge        : ${this.getBadge()}`;
    }
}

export default DossierDate;
