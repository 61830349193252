/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 14-6-18.
 */
/**
 * Use the form-data to update the address attribute of the customer
 * @updater
 */
const addressUpdater = (customer, form) => {
    form.groups.forEach(function (group) {
        group.questions.forEach(function (question) {
            const field = question.field;
            customer.addresses[field] = question.parsedValue;
        });
    });
    return customer;
};

export default addressUpdater;
