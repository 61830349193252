/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 15-8-18.
 */
import React from "react";
import LoggedOff from "../../component/Main/Base/LoggedOff/LoggedOff";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {connect} from "react-redux";
import {compose} from "redux";
import {push} from 'react-router-redux';
import paths from "../../paths";
import QuestionForm from "../../component/QuestionForm/smart/QuestionForm";
import {Forms} from "../../../api/form/FormClient";
import {retrieveForm} from "../../../action/creator/asynchonous/form/retrieveForm";
import {currentCustomer, documents} from "../../../state/selector/customer";
import {compoundAction} from "../../../middleware/compoundDispatch";
import Typography from "@material-ui/core/Typography/Typography";
import BigCardHeader from "../../component/Card/BigCardHeader";
import withStyles from "@material-ui/core/styles/withStyles";

const newProposalAction = documents => form => compoundAction(push(paths.request),
                                                              retrieveForm(Forms.blissHypotheek, null, documents));

const styles = theme => ({
    typography: {
        marginBottom: "10px"
    },
});

class ThankYou extends React.Component {

    componentDidMount() {
        return this.props.didMount()
    }

    render() {
        const {customer, classes} = this.props;
        return (
            <LoggedOff>
                <Grid item
                      lg={12}
                      md={12}>
                    <Card>
                        <CardContent>
                            <BigCardHeader title="Wij gaan u zo goed mogelijk helpen"/>
                            <Typography className={classes.typography}
                                        variant="body1">
                                Om de gratis intake zo goed mogelijk voor te kunnen bereiden en in te plannen, nemen we spoedig telefonisch contact met u op.
                                Binnen twee dagen bellen we u op. </Typography>

                            <Typography className={classes.typography}
                                        variant="body1">
                                We hebben mogelijk aanvullende informatie nodig. Om voor u vrijblijvend een aantal berekeningen te kunnen maken en uw vragen te
                                kunnen beantwoorden, wilt de adviseur zich goed voorbereiden op de afspraak. Op die manier haalt u het meest uit een gratis
                                intake gesprek en zullen we kijken of we u ergens bij kunnen helpen. </Typography>

                            <Typography className={classes.typography}
                                        variant="body1">
                                Hartelijke groet,
                            </Typography>

                            <Typography className={classes.typography}
                                        variant="body1">
                                Yvonne Andeweg </Typography>
                        </CardContent>
                    </Card>

                    {
                        customer.type === "CLIENT"
                        ? <Card>
                            <CardHeader title="Nieuwe offerteaanvraag"/>
                            <CardContent>
                                <QuestionForm endpoint={Forms.nextProposal}
                                              submitAction={newProposalAction(documents)}
                                              header={`Vervolgaanvraag voor ${customer.companyName}`}
                                              buttonText="Nieuwe aanvraag"/>
                                <br/>
                                <br/>
                                <br/>
                            </CardContent>

                        </Card>
                        : null
                    }
                </Grid>
            </LoggedOff>
        );
    }
}

const mapStateToProps = state => ({
    customer: currentCustomer(state),
    documents: documents(state),
});

const mapDispatchToProps = dispatch => ({
    onBack: () => dispatch(push(paths.root)),
    didMount: () => dispatch(retrieveForm(Forms.nextProposal)),
});

const decorate = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
);

export default decorate(ThankYou);
