const clearCache = () => {
    if (caches) {
        return caches.keys()
                     .then(keys => Promise.all(keys.map(key => caches.delete(key))))
    }
    else {
        return Promise.resolve();
    }
}

const upgrade = () =>

    process.env.NODE_ENV === "production" ? fetch('/version.json')
                                              .then((response) => response.json())
                                              .then(json => json.version)
                                              .then((latestVersion) => {

                                                  if (latestVersion !== global.appVersion) {
                                                      console.info(`Current: '${global.appVersion}'`);
                                                      console.info(`Latest: '${latestVersion}'`);
                                                      console.info(`Busting the Cache. Upgrading the Atlas WebApp to version  '${latestVersion}'`)
                                                      clearCache()
                                                          .then(_ => window.location.reload())
                                                          .then(_ => console.info(`Upgraded the Atlas WebApp to version  '${latestVersion}'`));
                                                  } else {
                                                      console.log(`Staying on Atlas WebApp version v${latestVersion}`);
                                                  }
                                              })
                                          : Promise.resolve()
                                                   .then(_ => console.log("Skipping version check on development environment"));

export default upgrade;
