/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 14-6-18.
 */
import moment from "moment/moment";

const emptyAutomobile = () => ({
    startDate: moment().format("DD-MM-YYYY"),
    vehicle: {
        automobile: {
            assetSubType: "car",
            mopedType: "moped",
        }
    }
});

/**
 * Use the form-data to update the vehicle/automobile asset attributes of the customer
 * @param assetIndex
 * @return {updater}
 */
const automobileUpdater =
    (assetIndex = -1) =>
        (customer, form) => {
            let asset = assetIndex < 0 ? emptyAutomobile() : customer.customerAssets[assetIndex];

            form.groups.forEach(function (group) {
                group.questions.forEach(function (question) {
                    const field = question.field;

                    if (field === "registrationNo") {
                        asset.vehicle[field] = question.parsedValue;
                    }
                    else {
                        asset.vehicle.automobile[field] = question.parsedValue;
                    }
                });
            });

            if (assetIndex < 0) {
                if (!(customer.customerAssets instanceof Array)) {
                    customer.customerAssets = [];
                }
                customer.customerAssets.push(asset);
            }
            else {
                customer.customerAssets[assetIndex] = asset;
            }

            return customer;
        };

export default automobileUpdater;
