/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-9-17.
 */
import React from "react";
import PropTypes from "prop-types";
import DateQuestion from "../../presentation/Date/DateQuestion";
import questionPropType from "../../../propType/question";
import moment from "moment/moment";

const toMoment = question => question.value ? moment(question.value, "DD-MM-YYYY") : null;

const Date = Presentation =>

    class DateContainer extends React.Component {

        static propTypes = {
            question: questionPropType.isRequired,
            onSubmitQuestion: PropTypes.func.isRequired,
        };

        constructor(props) {
            super(props);
            this.state = {
                value: toMoment(props.question)
            };

            this.onChange = this.onChange.bind(this);
        }

        onChange(value) {
            this.setState({...this.state, value});
            this.props.onSubmitQuestion(value.format("DD-MM-YYYY"));
        }

        componentWillReceiveProps(nextProps) {
            if (nextProps.question) {
                this.setState({value: toMoment(nextProps.question)})
            }
        }

        render() {
            return <Presentation question={this.props.question}
                                 value={this.state.value}
                                 onChange={this.onChange}/>
        }
    };

export default Date(DateQuestion);
export {
    Date,
}
