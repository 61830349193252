/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 7-2-18.
 */
import React from "react";
import {CircularProgress, withStyles} from "@material-ui/core";
import PropTypes from 'prop-types';
import styles from "./styles";
import {hasClasses} from "../../../../util/util";
import {formPropType} from "../propType/form";
import PlainForm from "./PlainForm";
import StepForm from "./StepForm";

/**
 * @param {object} form
 * @param {string} formState
 * @param {number} currentStep
 * @param {function} onStep: number -> event -> ()
 * @param {function} onSubmitQuestion: question -> value -> ()
 * @param header
 * @param buttonText
 * @param {object} classes
 * @return {React}
 */
const QuestionForm = ({
                          form,
                          customer,
                          formState,
                          currentStep,
                          onStep,
                          onSubmitQuestion,
                          header = null,
                          buttonText = null,
                          classes
                      }) => {


    return form.groups.length === 0
        ? <CircularProgress/>
        : (<form>
            {
                form.groups.length === 1
                    ? <PlainForm form={form}
                                 onStep={onStep}
                                 header={header}
                                 buttonText={buttonText}
                                 onSubmitQuestion={onSubmitQuestion}
                                 classes={classes}/>
                    : <StepForm form={form}
                                customer={customer}
                                header={header}
                                currentStep={currentStep}
                                onStep={onStep}
                                onSubmitQuestion={onSubmitQuestion}
                                buttonText={buttonText}
                                classes={classes}/>
            }
        </form>);
};

QuestionForm.propTypes = {
    form: formPropType,
    formState: PropTypes.string.isRequired,
    currentStep: PropTypes.number.isRequired,
    onStep: PropTypes.func.isRequired,
    onSubmitQuestion: PropTypes.func.isRequired,
    classes: hasClasses(["stepper",
        "previousButton",
        "stepperButton",
        "plainButton",
        "label",
        "stepLabel",
        "activeStepLabel"])
};

export default withStyles(styles)(QuestionForm);
