/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 9-5-18.
 */
import {flatten} from "../../../util/array";

const defaultAutosuggest = {
    searchTerm: "",
    page: 1,
    itemsPerPage: 5,
    sorts: [{
        field: "firstName",
        direction: "ascending"
    }],
    searchColumnList: [
        "firstName",
        "lastName"
    ],
    selectFields: [
        "firstName",
        "lastName",
    ],
    archived: false,
    className: "nl.bluehorizon.insurance.relations.model.Customer"
};

const getAutosuggest =
    customerStatus =>
        term => {
            const autosuggest = {...defaultAutosuggest};
            autosuggest.searchTerm = term;
            if (customerStatus !== null) {
                autosuggest.customerStatus = customerStatus;
            }
            if (customerStatus === "ALL") {
                delete autosuggest.customerStatus;
            }

            return autosuggest;
        };

/**
 * @typedef {string} SuggestionType
 */

/**
 * If the format of the suggestions returned by the suggestion-service get's changed, this should
 * be the only function that needs to be upodated to the new format
 *
 * @param {SuggestionType} suggestion a Suggestion as returned by the suggestion-service
 * @return {string}
 */
const suggestionToString = suggestion => suggestion;

/**
 * Collects all the suggestion in a suggestion-result such as:
 * [{
 *     "firstName": "AnkurB",
 *     "lastName": "Singhal"
 *  }, {
 *     "firstName": "Marcel",
 *     "lastName": "Werf"
 *  }]
 *
 * into an array ["AnkurB", "Singhal", "Marcel", "Werf"]
 * @return {array.<SuggestionType>}
 */
const collect = autoSuggestResult => flatten(autoSuggestResult.map(Object.values));

export {
    getAutosuggest,
    collect,
    suggestionToString,
}
