import {getAssignee} from "./assignee";
import {empty} from "../../../../../../api/process/util";
import {informal} from "../../../../identity/User/View/role";
import {customerClient} from "../../../../../../api/relation/customer/CustomerClient";
import {noteClient} from "../../../../../../api/relation/note/NoteClient";
import {userClient} from "../../../../../../api/relation/identity/user/UserClient";

const loadConsultants =
    updateState =>
        userClient.usersByRole(`role_apotheek_adviseur`)
                  .then(consultants => updateState({consultants}))

const getConsultant = (consultants, adviseur) => {
    const email = getAssignee(adviseur).email;

    for (const consultant of consultants) {
        if (consultant.email === email) {
            return consultant;
        }
    }
}

/**
 * AARGH. Streamline the consultants, the user loaded on logon is slightly different from the ones loaded
 *        via the UserClient
 *
 * @param consultant
 * @returns {{roles, name, fullname, email}}
 */
const streamlineConsultant = consultant => {
    if (empty(consultant)) {
        return null;
    }

    global.debug.red(consultant);

    if (Object.keys(consultant).includes("name")) {
        return ({
            fullname: consultant.name,
            name:     consultant.userName,
            email:    consultant.email,
            roles:    consultant.roles,
        });
    } else {
        return ({
            fullname: consultant.firstName + " " + consultant.lastName,
            name:     consultant.userName,
            email:    consultant.email,
            roles:    consultant.roles,
        });
    }
}

const addConsultant = (user, customer, consultant, role) => {

    consultant = streamlineConsultant(consultant);

    if (!empty(consultant) && Array.isArray(consultant.roles) && consultant.roles.includes(role)) {
        const note = {
            description: "Consultant gekoppeld",
            text:        `${consultant.fullname} is aan deze klant gekoppeld als ${informal(role)}`,
        }
        return customerClient.addConsultant(customer, consultant, role)
                             .then(_ => noteClient.addNote(user, customer, note));
    }
}

export {
    loadConsultants,
    getConsultant,
    addConsultant,
}
