
/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 19-12-18.
 */
import React from "react";
import {Typography, withStyles} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";

const styles = theme =>
    ({
        root: {
            paddingLeft: 0
        },
        typography: {
            fontSize: "1.75rem",
            fontWeight: 400,

        }
    });

const BigCardHeader = ({title, classes}) =>
    <CardHeader className={classes.root}
                disableTypography={true}
                title={
                    <Typography className={classes.typography}
                                variant="h2"
                                component="h2">
                        {title}
                    </Typography>
                }/>;

export default withStyles(styles)(BigCardHeader);
