import HTMLReactParser from "html-react-parser";

/**
 * Quick and dirty test to see if we need to treat the string as Html or as plain text
 *
 * @param {String} string
 * @return {boolean}
 */
const containsTags = string => {

    const emptyTag = /\/>/
    const containsEmptyTag = emptyTag.test(string);

    const startTag = /<[a-zA-Z]+/;
    const endTag = /<\/[a-zA-Z]+/;
    const containsBalancedTag = startTag.test(string) && endTag.test(string);

    return containsEmptyTag || containsBalancedTag;
};

/**
 * If the question description contains a tag we assume we're dealing with valid html and return a parsed version,
 * or else just return the plain string (and don't risk the parser giving an error)
 *
 * @param {String} question
 * @return {string | JSX.Element | JSX.Element[]}
 */
const description = question => containsTags(question.description) ? HTMLReactParser(question.description) : question.description;

export {
    description,
}
