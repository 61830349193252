import * as React from "react";
import {Card, CardContent, Grid} from "@material-ui/core";
import securePage from "../../../SecurePage/SecurePage";
import {promiseAction} from "../../../../../middleware/promiseDispatch";
import ActionType from "../../../../../action/ActionType";
import errorAction from "../../../../../action/creator/error";
import {customerClient} from "../../../../../api/relation/customer/CustomerClient";
import {routeCustomer} from "../../../../../action/creator/router";
import retrieving from "../../../../../action/creator/asynchonous/retrieving";
import QuestionForm from "../../../../component/QuestionForm/smart/QuestionForm";
import LoggedOn from "../../../../component/Main/Base/LoggedOn/LoggedOn";
import {Forms} from "../../../../../api/form/FormClient";
import BigCardHeader from "../../../../component/Card/BigCardHeader";
import {roles} from "../../../../../api/login/LoginClient";

const remoteError = error => {
    console.error(`${error.message}: ${error.response.message} -- ${error.response.error}`);
    return errorAction("Error adding BusinessCustomer")(error.response.message);
};

/**
 * @param {object} form
 * @return {Action}
 */
const addBusinessAction = form => promiseAction(customerClient.addBusiness(form)
                                                              .then(response => response.entity)
                                                              .then(customer => routeCustomer(customer.id)),
                                                retrieving(ActionType.asynchronous.form.post.POSTING),
                                                remoteError);

const NewBusinessCustomer = () => (
    <LoggedOn>
        <Grid item
              lg={12}
              md={12}>
            <Card>
                <CardContent>
                    <BigCardHeader title="Nieuwe zakelijke klant"/>
                    <QuestionForm endpoint={Forms.brokerBusinessCustomer}
                                  submitAction={addBusinessAction}/>
                </CardContent>
            </Card>
        </Grid>
    </LoggedOn>
);

export default securePage([roles.admin])(NewBusinessCustomer);


