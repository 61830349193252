/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 25-7-19.
 */

const getCamundaIdentifier = (identifier) => `file-${identifier}`;

const getAcceptedIdentifier = identifier => `${getCamundaIdentifier(identifier)}-accepted`;
const getMotivationIdentifier = identifier => `${getCamundaIdentifier(identifier)}-motivation`;

export {
    getAcceptedIdentifier,
    getMotivationIdentifier,
}
