import defaultSkin from "./defaultSkin";

const erasmusSkin = {
    ...defaultSkin,
    id: "ERASMUS",
    images: {
        ...defaultSkin.images,
        background1: "/images/bliss-achtergrond.png",
        background2: "/images/bliss-achtergrond.png",
        personaBackground: null,
        logo: null,
    },
    palette: {
        ...defaultSkin.palette,
        primary: {main: "#1e72bd"},
        anchors: {
            ...defaultSkin.palette.anchors,
            regular: {main: "#fff"},
            hover: {main: "#fff"},
            visited: {main: "#f"},
        },
        personaBackground: {main: "#171c54"},
        copyrightForeground: {main: "#000000"},
        copyrightBackground: {main: "#ffffff"},
        footerForeground: {main: "#ffffff"},
        footerBackground: {main: "#171c54"},
    },
    texts: {
        ...defaultSkin.texts,
        company: "Erasmus Verzekeringen"
    },
    overrides: {
        ...defaultSkin.overrides,
        mainBackground: {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '70% 0%',
            backgroundImage: `url('/images/bliss-achtergrond.png')`
        }
    }
}

export default erasmusSkin;
