import {Card, CardContent, Divider, Grid, Typography} from "@material-ui/core";
import BigCardHeader from "../../../../../../component/Card/BigCardHeader";
import {formattedBirthdate, fullAddress, fullName} from "../../../../../../../util/customer";
import SmallCardHeader from "../../../../../../component/Card/SmallCardHeader";
import React from "react";
import Item from "./Item";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 8-7-19.
 */



const Main = ({customer}) =>
    <Card>
        <CardContent>
            <BigCardHeader title={`${fullName(customer)}`}/>

            <Typography variant="body1"
                        component="div">
                Sinds {formattedBirthdate({birthDate: customer.foundationDate})}
            </Typography>

            <Divider/>

            <Grid container>
                <Grid item
                      xs={6}>

                    <SmallCardHeader title="Algemene gegevens"/>
                    <Grid container>

                        <Item object={customer}
                              icon="account_balance"
                              getDescription={customer => customer.companyName}/>

                        <Item object={customer.addresses}
                              icon="home"
                              getDescription={fullAddress}/>

                        <Item object={customer}
                              icon="filter_4"
                              getDescription={customer => customer.chamberCommNo}/>

                        <Item object={customer}
                              icon="style"
                              getDescription={customer => customer.legalForm}/>

                        <Item object={customer}
                              icon="style"
                              getDescription={customer => customer.businessType}/>

                        <Item object={customer}
                              icon="style"
                              getDescription={customer => customer.branch}/>
                        <Item object={customer}
                              icon="phone"
                              getDescription={customer => customer.phone}/>
                        <Item object={customer}
                              icon="phonelink_ring"
                              getDescription={customer => customer.mobile}/>
                        <Item object={customer}
                              icon="business"
                              getDescription={customer => customer.forBusiness}/>
                    </Grid>

                </Grid>
                <Grid item
                      xs={6}>

                    <SmallCardHeader title="Bedrijfsgegevens"/>
                    <Grid container>


                        <Item object={customer}
                              icon="date_range"
                              getDescription={customer => customer.foundationDate}/>

                        <Item object={customer}
                              icon="filter_4"
                              getDescription={customer => customer.incomeTaxNo}/>

                        <Item object={customer}
                              icon="date_range"
                              getDescription={customer => customer.employmentDateFirstEmployee}/>

                        <Item object={customer}
                              icon="business"
                              getDescription={customer => customer.collectiveLabourAgreement}/>

                        <Item object={customer}
                              icon="business"
                              getDescription={customer => customer.status}/>

                        <Item object={customer}
                              icon="euro_symbol"
                              getDescription={customer => customer.yearlyPayroll}/>

                        <Item object={customer}
                              description="Jaaromzet"
                              getDescription={customer => customer.yearlyRevenue}/>

                        <Item object={customer}
                              icon="business"
                              getDescription={customer => customer.collectionMethod}/>

                        <Item object={customer}
                              icon="filter_4"
                              getDescription={customer => customer.ibanBankAcc}/>
                    </Grid>
                </Grid>
            </Grid>

            <Divider/>

        </CardContent>
    </Card>;

export default Main;
