const styles = theme => ({
    root: {
        padding: 10,
    },
    header: {
        paddingBottom: 0,
    },
    content: {
        paddingTop: 0
    }
});
export default styles;