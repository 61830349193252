import * as React from "react";
import {Grid, withStyles} from "@material-ui/core";
import {compose} from "redux";
import {connect} from "react-redux";
import Message from "../../Message/Message";

const styles = theme => ({
    mainBody: {
        minHeight: 1100,
        marginBottom: "30px",
    }
});

const MainBody = ({children, pageHeader, pageFooter, errors, onCloseError, classes}) =>
    <div>
        {pageHeader}
        <main key="MAIN_KEY"
            className={classes.mainBody}>
            <Grid key="GRID_KEY"
                container>
                {children}
            </Grid>
            {
                errors.map((error, index) => <Message error={error}
                                                      index={index}
                                                      key={index}/>)
            }
        </main>
        {pageFooter}
    </div>;

const mapStateToProps = state => ({
    errors: state.errors,
});

const decorate = compose(
    connect(mapStateToProps),
    withStyles(styles)
);

export default decorate(MainBody);
