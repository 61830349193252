import * as React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {Card, CardContent, CardHeader, withStyles} from "@material-ui/core";
import securePage from "../../../SecurePage/SecurePage";
import {promiseAction} from "../../../../../middleware/promiseDispatch";
import ActionType from "../../../../../action/ActionType";
import errorAction from "../../../../../action/creator/error";
import {userClient} from "../../../../../api/relation/identity/user/UserClient";
import route from "../../../../../action/creator/router";
import retrieving from "../../../../../action/creator/asynchonous/retrieving";
import QuestionForm from "../../../../component/QuestionForm/smart/QuestionForm";
import LoggedOn from "../../../../component/Main/Base/LoggedOn/LoggedOn";
import {Forms} from "../../../../../api/form/FormClient";
import styles from "../styles";
import {organizationUserClient} from "../../../../../api/relation/identity/organizationUser/OrganizationUserClient";
import {currentOrganization} from "../../../../../state/selector/organization";
import {roles} from "../../../../../api/login/LoginClient";

const remoteError = error => {
    console.error(`${error.message}: ${error.response.message} -- ${error.response.error}`);
    return errorAction("Error adding User")(error.response.message);
};

const NewUser = ({form, classes, organization}) => (
    <LoggedOn>
        <Card className={classes.root}>
            <CardHeader title="Nieuwe werknemer"
                        className={classes.header}/>
            <CardContent className={classes.content}>
                <QuestionForm endpoint={Forms.user}
                              submitAction={addUserAction(organization, form)}/>
            </CardContent>
        </Card>
    </LoggedOn>
);

/**
 * @param {object} form
 * @return {Action}
 */
const addUserAction = organization => form => promiseAction(
    userClient.add(form)
              .then(user => organizationUserClient.createMapping(user.entity.userName, organization.id))
              .then(() => route("/organization"))
              .catch(error => console.error(error)),
    retrieving(ActionType.asynchronous.form.post.POSTING),
    remoteError);

const mapStateToProps = state => ({
    organization: currentOrganization(state),
});

export default compose(
    connect(mapStateToProps),
    securePage([roles.admin]),
    withStyles(styles),
)(NewUser);
