/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 3-6-19.
 */

import React from "react";
import {Button, Card, CardContent, CircularProgress, withStyles} from "@material-ui/core";
import CardMediaHeader from "../../../component/CardMediaHeader/CardMediaHeader";
import LoggedOn from "../../../component/Main/Base/LoggedOn/LoggedOn";
import teal from "@material-ui/core/colors/teal";

import paths from "../../../paths";
import {connect} from "react-redux";
import {push} from 'react-router-redux';
import {compose} from "redux";
import BpmnDiagram from "../diagram/BpmnDiagram";
import Promise from "bluebird";
import InstanceDetails from "./InstanceDetails";
import {activeActivityIds} from "./activities";
import securePage from "../../SecurePage/SecurePage";
import {promiseAction} from "../../../../middleware/promiseDispatch";
import {noteClient, noteTypes} from "../../../../api/relation/note/NoteClient";
import retrieving from "../../../../action/creator/asynchonous/retrieving";
import {errorAction} from "../../../../api/relation/RelationClient";
import {currentCustomer} from "../../../../state/selector/customer";
import {currentUser} from "../../../../state/selector/user";
import CamundaProcessClient from "../../../../api/process/process/CamundaProcessClient";
import {fullName} from "../../../../util/customer";
import {friendlyNameFromDefinitionId} from "../../../../api/process/util/process";
import MiniPage from "../../../component/MiniPage/MiniPage";
import MiniPages from "../../../component/MiniPage/MiniPages";
import Variables from "./Variables";
import mainPage from "./mainPage/mainPage";
import {
    activities as getActivities,
    diagram,
    instanceVariables,
    processInstance,
} from "../../../../api/process/process/processes";
import {customerClient} from "../../../../api/relation/customer/CustomerClient";
import {tasks} from "../../../../api/process/task/tasks";
import {processInstanceQuery} from "../task/TaskRestriction";
import ActionType from "../../../../action/ActionType";
import {roles} from "../../../../api/login/LoginClient";

const styles = theme => ({
    root: {
        width:        "100%",
        borderRadius: 2,
    },
});

const note = process => ({
    description: noteTypes.PROCESS_DELETED,
    text:        `Administratief opgeschoond: process '${process.id}' verwijderd`,
})

const customerName = customer => customer === null? "" : ` voor ${fullName(customer)}`;

const load =
    (processId, update) =>
        () => Promise.all([processInstance(processId),
                           getActivities(processId),
                           instanceVariables(processId),
                          ])
                     .spread((instance, activities, instanceVariables) =>
                                 Promise.all([update({instance, activities, instanceVariables}),
                                              instanceVariables.hasOwnProperty("customerId")
                                                  ? customerClient.fetchCustomer(instanceVariables.customerId.value)
                                                                  .then(customer => update({customer}))
                                                  : Promise.resolve(),
                                              tasks(processInstanceQuery(instance))
                                                  .then(tasks => update({tasks: tasks.results})),
                                             ])
                                        .then(_ => instance),
                     )
                     .then(instance => diagram(instance.definitionId))
                     .then(xml => update({xml}))
                     .then(_ => console.log("RELOADED"))

class Instance extends React.Component {

    state = {
        instance:          null,
        instanceVariables: null,
        activities:        null,
        tasks:             [],
        customer:          null,
        xml:               null,
    };

    /* promisified version of setState */
    updateState = newState => new Promise(resolve => this.setState(newState, resolve));

    componentDidMount() {
        const processId = this.props.match.params.id;
        load(processId, this.updateState.bind(this))();
    }

    render() {
        const processId = this.props.match.params.id;
        const {instance, activities, instanceVariables, customer, tasks} = this.state;
        const {deleteProcess, user} = this.props;

        return customer? <LoggedOn>
            <Card className={this.props.classes.root}>

                <CardMediaHeader title={`Process ${instance
                    ? friendlyNameFromDefinitionId(instance.definitionId)
                    : ""} ${customerName(customer)}`}
                                 image="/images/handshake.jpg"/>
                <CardContent>
                    {
                        instance === null
                            ? <CircularProgress style={{color: teal[500]}}
                                                thickness={6}/>
                            : <div>

                                <MiniPages miniPages={[
                                    mainPage(user,
                                             customer,
                                             instance,
                                             instanceVariables,
                                             activities,
                                             load(processId, this.updateState.bind(this))),
                                    MiniPage.create("Overzicht", <InstanceDetails instance={instance}
                                                                                  instanceVariables={instanceVariables}
                                                                                  tasks={tasks}
                                                                                  activities={activities}
                                                                                  customer={customer}/>),
                                    MiniPage.create("Diagram", <BpmnDiagram xml={this.state.xml}
                                                                            activeIds={activeActivityIds(activities)}/>),
                                    MiniPage.create("Technisch", <Variables variables={instanceVariables}/>),
                                ].filter(page => page !== null)}/>

                                <Button variant="contained"
                                        color="primary"
                                        onClick={() => deleteProcess(user, customer, instance)}>Process verwijderen</Button>
                            </div>
                    }
                </CardContent>
            </Card>
        </LoggedOn> : <CircularProgress/>;

    }
}

const mapStateToProps = state => ({
    customer: currentCustomer(state),
    user:     currentUser(state),
});

const dispatchToProps = dispatch => ({
    deleteProcess: (user, customer, process) =>
                       dispatch(promiseAction(noteClient.addNote(user, customer, note(process))
                                                        .then(_ => CamundaProcessClient.deleteByProcessId(process.id))
                                                        .then(_ => push(paths.processTypes)),
                                              retrieving(ActionType.asynchronous.form.post.POSTING),
                                              errorAction)),

});

const decorate = compose(
    connect(mapStateToProps, dispatchToProps),
    securePage([roles.admin]),
    withStyles(styles),
);

export default decorate(Instance);
