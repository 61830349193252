import Point from "./Point";

class Dot extends Point {

    /**
     * @type {number}
     */
    weight;

    /**
     * @type {String}
     */
    color;

    /**
     * @param {Point} point
     * @param {number} weight
     * @param {String} color
     */
    constructor(point, weight = 1, color = null) {
        super(point.x, point.y);
        this.weight = weight
        this.color = color;
    }
}

export default Dot;

