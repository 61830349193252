import RelationClient from "../RelationClient";
import ProposalClient from "../../proposal/ProposalClient";
import {getJson} from "../../HTTP/get";
import {postJson, postJson2} from "../../HTTP/post";
import {putJson} from "../../HTTP/put";

/**
 * A Item is an object with two three fields: a name, adescription and a price
 *
 * @typedef {Object} Item
 * @property {string} name
 * @property {string} description
 * @property {Number} price
 */


const removeItemCruft = ({id, createdAt, active, modifiedAt, createdBy, customer, ...entity}) => entity;

const removeOrderCruft = ({createdAt, active, modifiedAt, createdBy, customer, ...order}) => {
    if (!Array.isArray(order.items)) {
        order.items = [];
    }
    order.items = order.items.map(removeItemCruft);
    return order;
};

/**
 * A Item is an object with two fields: description and a list of Items
 *
 * @typedef {Object} Order
 * @property {string} description
 * @property {List<Item>} price
 */
class OrderClient {

    /**
     * Returns a promise that will resolve once the note has been added to the customers.
     *
     * @param {string} description
     * @param {object} customer
     * @return {Promise<Object>}
     */
    addOrder(customer, order) {
        return putJson(RelationClient.getOrderEndpoint(customer))({data: order})
            .then(result => result.entity)
        //.then(removeOrderCruft);
    }

    /**
     * @param customer
     * @returns {Promise<Object>}
     */
    getOrders(customer) {
        return getJson()(RelationClient.getServiceUrl(RelationClient.orderEndpoint) + `?customerId=${customer.id}`)
            .then(response => response.entity)
            .then(orders => orders.map(removeOrderCruft));
    }

    confirm(orderId, signature) {
        return postJson(RelationClient.getServiceUrl(RelationClient.orderEndpoint) + `/confirm/${orderId}`)(signature)
            .then(response => response.entity)
            .then(removeOrderCruft);
    }

    createFactuur(orderId, factuur) {
        return putJson(`${RelationClient.getFactuurEndpoint()}/create/${orderId}`)({data: factuur})
            .then(result => result.entity)
            .then(removeOrderCruft);
    }

    getFacturen(orderId) {
        return getJson()(`${RelationClient.getFactuurEndpoint()}/find/${orderId}`)
            .then(response => response.entity);
    }

    getFactuur(factuur) {
        return postJson2(ProposalClient.pdfUrl(), {})(factuur);
    }

}

const orderClient = new OrderClient();

export default OrderClient;
export {
    orderClient,
}
