/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 19-12-17.
 */
import React from "react";
import {customerClient} from "../../../api/relation/customer/CustomerClient";
import Downshift from "downshift";
import PropTypes from "prop-types";
import searchFieldPresentation from "./SearchFieldPresentation";

/**
 * Given a function that asynchronously retrieves suggestion this method returns
 * an HOC that wraps a presentational component in a stateful container that takes care of handling suggestions
 *
 * @param {function(string): Promise.<array.<SuggestionType>>} getSuggestions
 * @return {function(*): *} a Higher Order Component
 */
const searchField =
    getSuggestions =>
        presentation =>
            class AutosuggestField extends React.Component {
                /*
                 * Downshift does not provide a way to retrieve remote suggestions,
                 * so we have to wrap the <Downshift> element in a container component
                 * that stores the list of suggestions
                 */

                static propTypes = {
                    currentSearchTerm: PropTypes.string,
                    onSubmitQuestion: PropTypes.func,
                    classes: PropTypes.object.isRequired
                };

                constructor(props) {
                    super(props);
                    this.onBlur = this.onBlur.bind(this);
                    this.onSelectSuggestion = this.onSelectSuggestion.bind(this);
                    this.onValueChange = this.onValueChange.bind(this);

                    this.state = {
                        value: "",
                        suggestions: [],
                    };
                }

                onValueChange(value) {
                    this.props.onNewSearchTerm(value);
                    getSuggestions(value, this.props.customerStatus).then(suggestions => this.setState({value, suggestions}));
                }

                onSelectSuggestion(value) {
                    this.props.onNewSearchTerm(value);
                    this.setState({value: value});
                }

                onBlur(event) {
                    this.props.onNewSearchTerm(event.targetRef.value);
                    this.setState({value: event.target.value});
                }

                render() {
                    return (
                        <Downshift onStateChange={({inputValue}, _) => inputValue ? this.onValueChange(inputValue) : null}
                                   onChange={this.onSelectSuggestion}
                                   value={this.state.value}>
                            {
                                presentation({suggestions: this.state.suggestions, onBlur: this.onBlur})
                            }
                        </Downshift>
                    );
                }
            };

export default searchField(customerClient.suggestions)(searchFieldPresentation);
export {
    searchField,
}
