/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 15-12-17.
 */
import React from "react";
import LoggedOn from "../../component/Main/Base/LoggedOn/LoggedOn";
import TopMenu from "./topmenu/TopMenu";
import securePage from "../SecurePage/SecurePage";

import Insurance from "./insurance/Insurance";
import Product from "./insurance/Product";
import {products} from "./insurance/products";
import {roles} from "../../../api/login/LoginClient";

/**
 * @return {React}
 */
const Comparison = () =>
    <LoggedOn pageHeader={<TopMenu/>}
              background="noBackGround">

        <Insurance name="Beroepsaansprakelijkheid">
            <Product product={products.nn}/>
            <Product product={products.dll}/>
            <Product product={products.asr}/>
            <Product product={products.nn}/>
        </Insurance>

        <Insurance name="Een">
            <Product product={products.nn}/>
        </Insurance>

        <Insurance name="Verzuim">
            <Product product={products.nn}/>
            <Product product={products.dll}/>
            <Product product={products.asr}/>
        </Insurance>

        <Insurance name="Twee">
            <Product product={products.nn}/>
            <Product product={products.dll}/>
        </Insurance>

        <Insurance name="Een">
            <Product product={products.nn}/>
        </Insurance>


    </LoggedOn>;

export default securePage([roles.admin])(Comparison);
