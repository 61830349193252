/**
 * 'Enum' for the different possible restrictions
 */
class TaskRestriction {
    static CurrentUser = new TaskRestriction("CurrentUser")
    static CurrentCustomer = new TaskRestriction("CurrentCustomer")
    static None = new TaskRestriction("None")

    constructor(restriction) {
        this.restriction = restriction;
    }
}

const currentUserQuery = user => ({processVariables: `emailAssignee_eq_${user.email}`});
const currentCustomerQuery = customer => ({processVariables: `customerId_eq_${customer.id}`});
const processInstanceQuery = ({processInstanceId}) => ({processInstanceId});
const activeProcessInstanceQuery = ({processInstanceId}, type) => ({processInstanceId, name: type});


export default TaskRestriction;
export {
    TaskRestriction,
    currentUserQuery,
    currentCustomerQuery,
    processInstanceQuery,
    activeProcessInstanceQuery
}
