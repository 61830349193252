import {purple, red, teal} from "@material-ui/core/colors";

const skin = {
    id: "default",
    images: {
        background1: "/images/building1.jpg",
        personaBackground: "/images/office.jpg",
        logo: null,
    },

    palette: {
        primary: teal,
        secondary: purple,
        error: red,
        primaryText: teal,
        secondaryText: purple,
        anchors: {
            regular: null,
            hover: null,
            visited: null,
        },
        personaBackground: null,
        copyrightForeground: null,
        copyrightBackground: null,
        footerForeground: null,
        footerBackground: null,

    },
    texts: {
        copyright: "Atlas",
        company: "Blue Horizon",
    },
    overrides: {
        logo: {},
        mainBackground: {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '70% 0%',
            backgroundImage: `url('/images/building2.jpg')`
        },
    }

};

export default skin;
