/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 29-1-18.
 */
import React from "react";
import {FormControl, Input, InputLabel} from "@material-ui/core";
import Message from "../Message";
import questionPropType from "../../../propType/question";
import PropTypes from 'prop-types';
import Tip from "../../Tip";

const id = question => `question-${question["id"]}`;

const TextAreaQuestion = ({question, value, onChange, onBlur}) =>
    <div style={{display: "flex"}}>
        <FormControl disabled={question.locked}
                     error={!question.valid}
                     fullWidth
        >
            <InputLabel required={question.required}>{question.description}</InputLabel>
            <Input id={id(question)}
                   multiline
                   value={value}
                   onChange={onChange}
                   onBlur={onBlur}/>
            <Message question={question}/>
        </FormControl>
        <Tip tip={question.tooltip}/>
    </div>;

TextAreaQuestion.propTypes = {
    question: questionPropType,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
};

export default TextAreaQuestion;
