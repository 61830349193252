/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 15-12-17.
 */
import React from "react";
import PropTypes from 'prop-types';
import Paginator from "./Paginator/Paginator";
import {Table, TableBody, TableCell, TableHead, TableRow, withStyles} from "@material-ui/core";
import styles from "./styles";
import SearchResult from "./SearchResult/SearchResult";

const customerClass =
    (classes, customerStatus) =>
        customer => {
            if (customerStatus === "ALL") {
                switch (customer.customerStatus) {
                    case "CUSTOMER":
                        return classes.customer;
                    case "PROSPECT":
                        return classes.prospect;
                    case "ARCHIVED":
                        return classes.archived;
                    default:
                        console.error("Unexpected customer status", customer.customerStatus);
                }
            }
            return classes.customer;
        }

/**
 * A result such as returned by e.g. CustomerClient.search(). We only assume it has an id.
 * @typedef {Object} Result
 * @property {number} id
 */

/**
 * @typedef {Object} SearchResults
 * @property {array.<Result>} results
 * @property {number} totalResults
 * @property {Search} search
 */

class SearchResults extends React.Component {

    /*
     *
     * @param {SearchResults} searchResults
     * @param mapper: Result -> SearchResult  Maps the result to the kind of SearchResult expected by the SearchResult Componenent
     * @param {function} onClick: object -> ()
     * @param {string} action
     * @param {function} onAction: () -> ()
     * @param {function} onPaging: number -> ()
     *
     * @return {React}
     */
    render() {
        const {
            searchResults, customerStatus, mapper, onClick, action, actionIcon, onAction, onPaging, onDelete = null, classes
        } = this.props;

        const customerClasses = customerClass(classes, customerStatus)

        return (
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>Naam</TableCell>
                            <TableCell>Adres</TableCell>
                            <TableCell>Geboortedatum</TableCell>
                            <TableCell>Bekijk</TableCell>
                            <TableCell>{typeof action == "string" ? action : "Actie"}</TableCell>
                            {
                                onDelete ? <TableCell>Verwijder</TableCell> : null
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            searchResults.totalResults > 0 ? searchResults.results
                                                                          .map(mapper(onClick, onAction))
                                                                          .map((result, index) => <SearchResult key={index}
                                                                                                                result={result}
                                                                                                                onDelete={onDelete}
                                                                                                                action={action}
                                                                                                                actionIcon={actionIcon}
                                                                                                                customerClasses={customerClasses}
                                                                                                                classes={classes}/>
                                                                          )
                                                           : <TableRow/>
                        }
                    </TableBody>
                </Table>

                <Paginator currentPage={searchResults.search.page}
                           itemsPerPage={searchResults.search.itemsPerPage}
                           totalResults={searchResults.totalResults}
                           onPaging={onPaging}/>
            </div>
        );
    }
}

SearchResults.propTypes = {
    searchResults: PropTypes.object.isRequired,
    mapper: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    action: PropTypes.oneOfType([PropTypes.string,
                                 PropTypes.func]).isRequired,
    //actionIcon: PropTypes.object.isRequired,
    onAction: PropTypes.func.isRequired,
    onPaging: PropTypes.func.isRequired
};

export default withStyles(styles)(SearchResults);

