/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 26-9-18.
 */
import React from "react";
import {Button, TableCell, TableRow} from "@material-ui/core";
import {formatPrice} from "../Contract/common";
import {orderClient} from "../../../api/relation/order/OrderClient";
import moment from "moment";
import {empty} from "../../../api/process/util";

const pdf2blob = pdf => {
    const base64Str = Buffer.from(pdf).toString('base64');
    const binaryString = window.atob(base64Str);
    const binaryLen = binaryString.length;

    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], {type: "application/pdf"});
}

const formatDate = date => empty(date) ? "" : moment(date).format("D MMMM YYYY");

const openPdf = factuur => orderClient.getFactuur(factuur)
                                      .then(data => data.arrayBuffer())
                                      .then(pdf2blob)
                                      .then(window.URL.createObjectURL)
                                      .then(objectURL => {

                                          const link = document.createElement('a');
                                          document.body.appendChild(link);
                                          link.href = objectURL;
                                          link.download = "Factuur.pdf";
                                          link.click();
                                          window.URL.revokeObjectURL(objectURL);
                                          link.remove();

                                          // OR (instead of creating and clicking a link)
                                          // window.location.assign(objectURL);
                                          // opens the pdf directly (buggy; the file cannot be downloaded)
                                      })

class FactuurRow extends React.Component {

    state = {
        hover: false,
    };

    render() {
        const {factuur} = this.props;

        return (
            <TableRow onMouseOver={_ => this.setState({hover: true})}
                      onMouseOut={_ => this.setState({hover: false})}>
                <TableCell>{formatDate(factuur.factuurDatum)}</TableCell>
                <TableCell>{formatPrice(factuur.amount)}</TableCell>
                <TableCell>{factuur.factuurnummer}</TableCell>
                <TableCell> <Button variant="contained"
                                    color='primary'
                                    onClick={() => openPdf(factuur)}>
                    Bekijk
                </Button></TableCell>
                {/*<TableCell>{factuur.betaalstatus}</TableCell>*/}
            </TableRow>
        )
    }
}

export default FactuurRow;

