/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 16-5-18.
 */
import React from "react"
import {Paper} from "@material-ui/core";
import Suggestion from "./Suggestion";
import PropTypes from "prop-types";

/**
 * @param {boolean} isOpen
 * @param {array.<SuggestionType>} suggestions
 * @param {number} highlightedIndex
 * @param {function} itemPropGetter - A function that takes an item and creates a bunch of props (such as onClick)
 *                                   to be spread to  the Suggestion component
 *                                   (ie a function that is typically injected by Downshift as a prop to its render props)
 * @return {Component}
 */
const Suggestions = ({suggestions, isOpen, highlightedIndex, itemPropGetter, classes}) =>
    isOpen? <Paper className={classes.dropdown} square>
               {
                   suggestions.map((suggestion, index) => <Suggestion key={index}
                                                                      suggestion={suggestion}
                                                                      isHighlighted={highlightedIndex === index}
                                                                      itemProps={itemPropGetter({item: suggestion})}/>)
               }
           </Paper>
           : null;

Suggestions.propTypes = {
    suggestions: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    highlightedIndex: PropTypes.number,
    itemPropGetter: PropTypes.func.isRequired,
};

export default Suggestions;

