/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 13-12-17.
 */
import {info} from "./util";

const dblack = (...messages) =>
    messages.forEach(message => console.log(`%c ${JSON.stringify(message, null, 2)}`, 'color: black; font-weight: bold;'));

const dred =
    (...messages) =>
        messages.forEach(message => console.log(`%c ${JSON.stringify(message, null, 6)}`, 'color: red; font-weight: bold;'));

const dgreen =
    (...messages) =>
        messages.forEach(message => console.log(`%c ${JSON.stringify(message, null, 6)}`, 'color: green; font-weight: bold;'));

const dblue =
    (...messages) =>
        messages.forEach(message => console.log(`%c ${JSON.stringify(message, null, 6)}`, 'color: blue; font-weight: bold;'));


const dyellow =
    (...messages) =>
        messages.forEach(message => console.log(`%c ${JSON.stringify(message, null, 6)}`, 'color:  #FFDC00; font-weight: bold;'));

const dcyan =
    (...messages) =>
        messages.forEach(message => console.log(`%c ${JSON.stringify(message, null, 6)}`, 'color:  cyan; font-weight: bold;'));


const debug = {
    black: dblack,
    red: dred,
    green: dgreen,
    blue: dblue,
    yellow: dyellow,
    cyan: dcyan,
    info: info
}

export {
    dblack,
    dred,
    dgreen,
    dblue,
    dyellow,
    dcyan,
    debug
};
