import React from "react";
import Mouse from "./device/Mouse";
import TouchPad from "./device/Touchpad";
import Browser from "./device/Browser";
import Nullable from "./Nullable";
import StrokeEventHandler from "./stroke/StrokeEventHandler";

/**
 * @class {JSX.Element} SignaturePad
 **/
class SignaturePad extends React.Component {

    canvasRef = React.createRef();

    /**
     * @type {Array<Device>}
     */
    devices = [];

    /**
     * @type StrokeEventHandler
     */
    strokeEventHandler;

    constructor(props) {
        super(props);

        this.penColor = props.penColor || "black";
        this.backgroundColor = props.backgroundColor || "rgba(0,0,0,0)";

        this.devices.push(new Mouse());
        this.devices.push(new TouchPad());
        this.devices.push(new Browser());
    }

    componentDidMount() {
        this.initCanvas(this.canvasRef.current);
        if (this.props.dataUrl) {
            this.fromDataURL(this.props.dataUrl);
        }
        this.strokeEventHandler = StrokeEventHandler.get(this.canvasRef.current, this.props);
        this.registerDevices(this.strokeEventHandler);
    }

    componentWillUnmount() {
        this.unRegisterDevices(this.strokeEventHandler);
    }

    /**
     * @param {StrokeEventHandler} strokeEventHandler
     */
    registerDevices(strokeEventHandler) {
        this.devices.forEach(device => device.register(strokeEventHandler));
    }

    unRegisterDevices() {
        this.devices.forEach(device => device.unRegister());
    }

    onClear(event) {
        if (event) {
            event.preventDefault();
        }
        this.initCanvas(this.canvasRef.current)
        this.strokeEventHandler.reset();
    }

    initCanvas(currentCanvas) {
        const context = currentCanvas.getContext("2d");

        context.fillStyle = this.backgroundColor;
        context.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
        context.fillRect(0, 0, currentCanvas.width, currentCanvas.height);
        context.fillStyle = this.penColor;

        resizeCanvas(currentCanvas);
    }

    toDataURL(imageType, quality) {
        const canvas = this.canvasRef.current;
        return canvas.toDataURL.apply(canvas, arguments);
    }

    fromDataURL(dataUrl) {
        const context = this.canvasRef.current.getContext("2d");

        const ratio = window.devicePixelRatio || 1
        const width = this.canvasRef.current.width / ratio;
        const height = this.canvasRef.current.height / ratio;

        this.initCanvas(this.canvasRef.current);

        let image = new Image()
        image.src = dataUrl;
        image.onload = function () {
            context.drawImage(image, 0, 0, width, height);
        };
    }

    render() {
        const {ClearButton, CancelButton, DoneButton, size = {width: 720, height: 150}} = this.props;
        return (
            <div>
                <div>
                    <canvas ref={this.canvasRef}
                            {...size}/>
                </div>

                <Nullable Component={ClearButton}
                          onClick={this.onClear.bind(this)}/>

                <Nullable Component={CancelButton}/>

                <Nullable Component={DoneButton}
                          getDataURI={this.toDataURL.bind(this)}/>
            </div>
        );
    }
}

/**
 * When zoomed out to less than 100%, for some very strange reason,
 * some browsers report devicePixelRatio as less than 1
 * and only part of the canvas is cleared then.
 * @param currentCanvas
 */
function resizeCanvas(currentCanvas) {
    const context = currentCanvas.getContext("2d");

    let ratio = Math.max(window.devicePixelRatio || 1, 1);
    currentCanvas.width = currentCanvas.offsetWidth * ratio;
    currentCanvas.height = currentCanvas.offsetHeight * ratio;
    context.scale(ratio, ratio);
}

export default SignaturePad;
export {
    resizeCanvas
};


