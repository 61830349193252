import DossierDate from "./DossierDate";

class OVDossierDate extends DossierDate {

    status;

    constructor(name, expiry, isCompleted, completion, status) {
        super(name, expiry, isCompleted, completion);
        this.status = status;

    }

    getIcon() {
        if (this.status === "Weigering") {
            return "clear"
        }
        if (this.status === "Finaal Akkoord") {
            return "check"
        }
        return super.getIcon();
    }

    getClass() {
        if (this.status === "Weigering") {
            return "problem"
        }

        if (this.status === "Finaal Akkoord") {
            return "done";
        }

        return super.getClass();
    }

    getDescription() {
        if (this.status === "Finaal Akkoord") {
            return "";
        }
        return super.getDescription();
    }

}

export default OVDossierDate;
