/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 14-9-18.
 */
import uploadTypes from "./UploadTypes";

/**
 * @typedef {Object} Upload
 * @property {file} file
 * @property {?string} type
 */

/**
 * @param type
 * @return {function} file -> Upload
 */
const createUpload = (type = uploadTypes.unknown) => file => ({file, type});

/**
 *
 * @param {file} upload
 * @param {Array<Upload>} uploads
 * @return {Array<Upload>}
 */
const removeUpload = (upload, uploads) => uploads.filter(({file}) => file.name !== upload.name &&
                                                                     file.type !== upload.type &&
                                                                     file.size !== upload.size);

export {
    createUpload,
    removeUpload,
}
