/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 15-12-17.
 */
import * as React from "react";
import {Card, CardContent} from "@material-ui/core";

import {connect} from "react-redux";
import SearchBar from "../../../../component/SearchBar/SearchBar";
import SearchResults from "../../../../component/SearchResults/SearchResults";
import {currentSearch, searchResults, searchTerm} from "../../../../../state/selector/customer";
import search from "../../../../../action/creator/asynchonous/search/search";
import {deleteCustomer, unArchive} from "../../../../../action/creator/asynchonous/customer/status";
import securePage from "../../../SecurePage/SecurePage";
import {routeCustomer} from "../../../../../action/creator/router";
import CardMediaHeader from "../../../../component/CardMediaHeader/CardMediaHeader";
import LoggedOn from "../../../../component/Main/Base/LoggedOn/LoggedOn";
import {customerToSearchResult} from "../../../../../api/relation/customer/util";
import {errorAction} from "../../../../../api/relation/RelationClient";
import {roles} from "../../../../../api/login/LoginClient";
import {compose} from "redux";
import {currentUser} from "../../../../../state/selector/user";
import {noteClient, noteTypes} from "../../../../../api/relation/note/NoteClient";

/**
 *
 * @param {string} currentSearchTerm
 * @param {SearchResults} searchResults
 * @param {function} onSearch: page -> string ->  ()
 * @param {function} onView: number -> ()
 * @param {function} onUnArchive: customerObject -> ()
 * @param {function} onDelete: customerObject -> ()
 * @return {React}
 */
const ArchivedCustomers = ({user, currentSearchTerm, searchResults, onSearch, onView, onUnArchive, onDelete}) => (
    <LoggedOn>
        <SearchBar currentSearchTerm={searchResults.search.searchTerm}
                   onSearch={onSearch(user)(1)}
                   customerStatus="ARCHIVED"/>

        <Card>
            <CardMediaHeader title="Klantenarchief"
                             image="/images/handshake.jpg"/>
            <CardContent>
                <SearchResults searchResults={searchResults}
                               mapper={customerToSearchResult}
                               onClick={onView}
                               actionIcon="unarchive"
                               action="Restaureer"
                               onAction={onUnArchive}
                               onDelete={onDelete}
                               onPaging={page => onSearch(user)(page)(currentSearchTerm)}/>
            </CardContent>
        </Card>
    </LoggedOn>
);

const mapStateToProps = state => ({
    user: currentUser(state),
    currentSearchTerm: searchTerm(state),
    searchResults: searchResults(state),
    search: currentSearch(state),
});

const mapDispatchToProps = dispatch => ({
    onView: customer => routeCustomer(customer.id).then(dispatch),
    dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onUnArchive: customer => noteClient.addNote(stateProps.user, customer, {
        description: noteTypes.UNARCHIVE_CUSTOMER,
        text: ""
    })
        .then(_ => unArchive(customer, stateProps.search).then(dispatchProps.search)),
    onDelete: customer => deleteCustomer(customer, stateProps.search).then(dispatchProps.search),
    onSearch:
        user =>
            page =>
                searchTerm =>
                    search(user)(stateProps.user)({
                        searchTerm: searchTerm,
                        page: page,
                        customerStatus: "ARCHIVED",
                    }).then(dispatchProps.dispatch)
                        .catch(error => dispatchProps.dispatch(errorAction(error))),
});

const decorate = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    securePage([roles.admin])
)

export default decorate(ArchivedCustomers);
