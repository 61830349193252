/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 15-12-17.
 */
import React from "react";
import {Route} from "react-router";
import {ConnectedRouter} from "react-router-redux";

import Login from "./page/Login/LoginPage";
import NewClient from "./page/customer/NewCustomer/NewCustomer";
import Dashboard from "./page/DashboardHome/DashboardHome";
import ForgotPassword from "./page/ForgotPassword/ForgotPassword";
import PartnerAccount from "./page/identity/PartnerAccount/PartnerAccount";
import ScrollToTop from "./page/ScrollToTop";
import Comparison from "./page/Comparison/Comparison";
import Organization from "./page/identity/Organization/Organizations/Organizations";
import NewOrganization from "./page/identity/Organization/NewOrganization/NewOrganization";
import ViewOrganization from "./page/identity/Organization/View/ViewOrganization";
import NewUser from "./page/identity/User/NewUser/NewUser";
import ViewUser from "./page/identity/User/View/ViewUser";
import paths from "./paths";
import CreateBusinessCustomer from "./page/anonymous/CreateBusinessCustomer";
import ThankYou from "./page/anonymous/ThankYou";
import FormTest from "./page/FormTest/FormTest";
import GroupTest from "./page/FormTest/GroupTest";
import QuestionTest from "./page/FormTest/QuestionTest";
import NewBusinessCustomer from "./page/customer/NewCustomer/NewBusinessCustomer/NewBusinessCustomer";
import Customers from "./page/customer/Customers/active/Customers";
import ArchivedCustomers from "./page/customer/Customers/archived/ArchivedCustomers";
import Prospects from "./page/customer/Customers/prospect/Prospects";
import SetPassword from "./setPassword/SetPassword";
import ThankYouBankgarantie from "./page/anonymous/ThankYouBankgarantie";
import Offers from "./page/customer/Customer/page/mini/Offers";
import Timeline from "./page/customer/Customer/page/mini/Timeline";
import Contract from "./page/customer/Customer/page/mini/Contract";
import Documents from "./page/customer/Customer/page/mini/Documents";
import BusinessCustomer from "./page/customer/Customer/page/BusinessCustomer";
import PrivateCustomer from "./page/customer/Customer/page/PrivateCustomer";
import TaskRestriction from "./page/process/task/TaskRestriction";
import NewPrivateCustomer from "./page/customer/NewCustomer/NewPrivateCustomer/NewPrivateCustomer";
import PortalPrivateCustomer from "./page/customer/Customer/page/PortalPrivateCustomer";
import Tasks from "./page/process/task/Tasks";
import LoggedOnTask from "./page/process/task/LoggedOnTask";
import ProcessTypes from "./page/process/types/ProcessTypes";
import ProcessType from "./page/process/types/ProcessType";
import Instance from "./page/process/instance/Instance";
import ExternalTask from "./page/process/task/ExternalTask";
import ThanksForSignature from "./page/process/task/documentedRequest/customerApprove/ThanksForSignature";
import ThanksForSignatureBankgarantie
    from "./page/process/task/bankgarantie/customerApprove/ThanksForSignatureBankgarantie";
import DossiersPage from "./page/dossier/DossiersPage";

const Router = ({history}) => (
    <ConnectedRouter history={history}>
        <div>
            <ScrollToTop>

                <Route exact
                       strict
                       path={"//"}
                       component={CreateBusinessCustomer}/>

                <Route exact
                       strict
                       path={paths.root}
                       component={CreateBusinessCustomer}/>

                {/*<Route exact*/}
                {/*       path={paths.klant}*/}
                {/*       component={ViewBusinessCustomer}/>*/}

                <Route exact
                       path={`${paths.setPassword}/:token`}
                       component={SetPassword}/>

                {/*<Route exact*/}
                {/*       path={`${paths.request}/:id`}*/}
                {/*       component={HandleProposalRequest}/>*/}

                <Route exact
                       path={paths.thankyou}
                       component={ThankYou}/>
                <Route exact
                       path={paths.thankyouBankgarantie}
                       component={ThankYouBankgarantie}/>

                <Route exact
                       path={paths.login}
                       component={Login}/>
                <Route exact
                       path={paths.dashboard}
                       component={Dashboard}/>
                <Route exact
                       path={paths.newClient}
                       component={NewClient}/>
                <Route exact
                       path={paths.newPrivateCustomer}
                       component={NewPrivateCustomer}/>
                <Route exact
                       path={paths.dossier}
                       component={DossiersPage}/>
                <Route exact
                       path={paths.newBusinessCustomer}
                       component={NewBusinessCustomer}/>
                <Route exact
                       path={paths.customers}
                       component={Customers}/>
                <Route exact
                       path={paths.prospects}
                       component={Prospects}/>
                <Route exact
                       path={paths.privateCustomer}
                       component={PrivateCustomer}/>
                <Route exact
                       path={`${paths.privateCustomer}/:id`}
                       component={PrivateCustomer}/>
                <Route exact
                       path={paths.user}
                       component={PortalPrivateCustomer}/>

                <Route exact
                       path={`${paths.viewUser}/:id`}
                       component={ViewUser}/>
                <Route exact
                       path={paths.businessCustomer}
                       component={BusinessCustomer}/>
                <Route exact
                       path={paths.passwordReminder}
                       component={ForgotPassword}/>
                <Route exact
                       path={paths.partnerAccount}
                       component={PartnerAccount}/>
                <Route exact
                       path={paths.archive}
                       component={ArchivedCustomers}/>
                <Route exact
                       path={paths.offers}
                       component={Offers}/>
                <Route exact
                       path={paths.timeline}
                       component={Timeline}/>
                <Route exact
                       path={paths.contracts}
                       component={Contract}/>
                <Route exact
                       path={paths.documents}
                       component={Documents}/>
                <Route exact
                       path="/organizations"
                       component={Organization}/>
                <Route exact
                       path="/new-organization"
                       component={NewOrganization}/>
                <Route exact
                       path="/organization"
                       component={ViewOrganization}/>
                <Route exact
                       path="/new-user"
                       component={NewUser}/>
                <Route exact
                       path="/user"
                       component={ViewUser}/>
                <Route exact
                       path="/forget-password"
                       component={ForgotPassword}/>
                <Route exact
                       path="/comparison"
                       component={Comparison}/>

                <Route exact
                       path={paths.task}
                       render={(props) => (
                           <Tasks {...props} restriction={TaskRestriction.None}/>
                       )}/>

                <Route exact
                       path={paths.mytasks}
                       render={(props) => (
                           <Tasks {...props} restriction={TaskRestriction.CurrentUser}/>
                       )}/>

                <Route exact
                       path={`${paths.task}/:id`}
                       component={LoggedOnTask}/>

                <Route exact
                       path={`${paths.signature}/:id`}
                       render={(props) => (<ExternalTask {...props} type="Ondertekening"/>)}/>

                <Route exact
                       path={`${paths.signature1}/:id`}
                       render={(props) => (<ExternalTask {...props} no={1} type="Ondertekening"/>)}/>

                <Route exact
                       path={`${paths.signature2}/:id`}
                       render={(props) => (<ExternalTask {...props} no={2} type="Ondertekening"/>)}/>

                <Route exact
                       path={paths.processTypes}
                       component={ProcessTypes}/>

                <Route exact
                       path={`${paths.processTypes}/:id`}
                       component={ProcessType}/>

                <Route exact
                       path={`${paths.processes}/:id`}
                       component={Instance}/>

                <Route exact
                       path={`${paths.formTesting}/`}
                       component={FormTest}/>

                <Route exact
                       path={`${paths.formTesting}/:id`}
                       component={FormTest}/>

                <Route exact
                       path={`${paths.groupTesting}/:id`}
                       component={GroupTest}/>

                <Route exact
                       path={`${paths.questionTesting}/:id`}
                       component={QuestionTest}/>

                <Route exact
                       path={paths.thanksForSignature}
                       component={ThanksForSignature}/>

                <Route exact
                       path={paths.thanksForSignatureBankgarantie}
                       component={ThanksForSignatureBankgarantie}/>


            </ScrollToTop>
        </div>

    </ConnectedRouter>
);

export default Router;
export {
    Router,
};

