/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 20-12-17.
 */
import ActionType from "../../action/ActionType";

const currentCustomer = (state = {}, {type, customer}) => {
    if (type === ActionType.CURRENT_CUSTOMER) {
        return customer;
    }
    return state;
};

export default currentCustomer;
