/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 14-2-18.
 */
const columnStyles = theme => ({
    leftColumn: {
      width: "40%",
      paddingRight: 15,
    },
    rightColumn: {
        width: "40%",
        paddingLeft: 15,
    },
    singleColumn: {
        width: "100%"
    },
});
export default columnStyles;
