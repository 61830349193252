import Promise from "bluebird";
import {setInstanceVariable} from "../../../../api/process/process/processes";
import {isPresent} from "../../../../util/util";

const setAssignee =
    processInstanceId =>
        ({name, email}) =>
            Promise.all([
                            setInstanceVariable(processInstanceId)("String", "nameAssignee", name),
                            setInstanceVariable(processInstanceId)("String", "emailAssignee", email)
                        ]);

const setAdvisor =
    processInstanceId =>
        ({name, email}) =>
            Promise.all([
                            setInstanceVariable(processInstanceId)("String", "nameAdvisor", name),
                            setInstanceVariable(processInstanceId)("String", "emailAdvisor", email)
                        ]);

const getAssignee = ({nameAssignee, emailAssignee}) => ({name: nameAssignee, email: emailAssignee});

const getAdvisor = ({nameAdvisor, emailAdvisor, nameAssignee, emailAssignee}) => {

    // Earlier we stored the advisor in the  assignee variable (because at that point tasks were only assigned to advisors)
    // Now we want to assign tasks to customers and overwriting the assignee would lose the chosen advisor, but  that value
    // is still important because we need to assign the process back to the advisor
    //
    // So if we're dealing with an instance where no advisor is set, we should fall back to  the assignee,
    // and make sure that from now on we set the advisor separately.

    return isPresent(nameAdvisor) ? ({name: nameAssignee, email: emailAssignee}) : ({name: nameAdvisor, email: emailAdvisor});
};

export {
    getAdvisor,
    setAdvisor,
    getAssignee,
    setAssignee,
}
