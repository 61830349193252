/**
 * 'Smart' component, that connects the real component to the store
 *
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 12-03-18.
 */
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import form, {formState} from "../../../../state/selector/form/form";
import QuestionForm from "../presentation/QuestionForm"
import onStep from "./onStep";
import onSubmitQuestion from "./onSubmitQuestion";

const mapStateToProps = state => ({
    form: form(state),
    formState: formState(state),
    currentStep: state.currentStep,
});

const mapDispatchToHandlers = dispatch => ({
    dispatch
});

const mergeProps = ({form, currentStep, formState},
                    {dispatch},
                    {endpoint, data, submitAction, customer, header = null, buttonText = null, stepPromise = x => Promise.resolve()}) =>
    ({
        form,
        customer,
        formState,
        currentStep,
        header,
        buttonText,
        onSubmitQuestion: onSubmitQuestion(dispatch)(endpoint),
        onStep:
            requestedStep =>
                event =>
                    stepPromise(currentStep, form)
                        .then(() => onStep(dispatch)(endpoint)(submitAction)(currentStep)(requestedStep)(event))

    });

const decorate = connect(mapStateToProps, mapDispatchToHandlers, mergeProps);
const connectedQuestionForm = decorate(QuestionForm);

connectedQuestionForm.propTypes = {
    endpoint: PropTypes.string.isRequired,
    documents: PropTypes.array,
    step: PropTypes.func,
    data: PropTypes.object,
    submitAction: PropTypes.func.isRequired,
};
export default connectedQuestionForm;

export {
    QuestionForm,
    mapStateToProps,
    mapDispatchToHandlers,
    mergeProps,
}
