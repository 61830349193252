import React from "react";
import RelationClient from "../../../../../../api/relation/RelationClient";
import Role from "./Role";


const getRole = (roles, name) => Array.isArray(roles) && roles.filter(role => role.name === name)[0];


export class Consultants extends React.Component {

    state = {
        roles:       [],
        consultants: [],
    }

    componentDidMount() {
        RelationClient.getData("/userRole/all")
                      .then(result => result.entity)
                      .then(roles => this.setState({roles}))
    }

    render() {
        const {customer, reloadCustomer} = this.props;
        const {roles} = this.state;

        return roles? (
            [
                <br key={10}/>,
                <br key={11}/>,
                <Role customer={customer}
                      reloadCustomer={reloadCustomer}
                      roleName={"role_apotheek_adviseur"}
                      role={getRole(roles, "role_apotheek_adviseur")}
                      key={1}/>,
                <br key={12}/>,
                <Role customer={customer}
                      reloadCustomer={reloadCustomer}
                      roleName={"role_notaris"}
                      role={getRole(roles, "role_notaris")}
                      key={2}/>,
                <br key={13}/>,
                <Role customer={customer}
                      reloadCustomer={reloadCustomer}
                      roleName={"role_makelaar"}
                      role={getRole(roles, "role_makelaar")}
                      key={3}/>,
                <br key={14}/>,
                <Role customer={customer} reloadCustomer={reloadCustomer}
                      roleName={"role_eerste_lijn"}
                      role={getRole(roles, "role_eerste_lijn")}
                      key={4}/>,
            ]
        ) : null;
    }
}
