/**
 * TODO: Add Documentation
 */
import React from "react";
import LoggedOn from "../../../../../component/Main/Base/LoggedOn/LoggedOn";
import CustomerTopMenu from "../../components/menu/CustomerTopMenu";
import securePage from "../../../../SecurePage/SecurePage";
import {roles} from "../../../../../../api/login/LoginClient";
import {currentCustomer} from "../../../../../../state/selector/customer";
import {compose} from "redux";
import {connect} from "react-redux";
import Notes from "../../../../../component/Note/Notes";

// TODO: Remove; this class will be obsolete as soon as the PortalPrivateCustomer page is updated to the new setup

class Timeline extends React.Component {

    render() {
        return (
            <LoggedOn pageHeader={<CustomerTopMenu/>}>
                <Notes customer={this.props.customer}/>
            </LoggedOn>
        );
    }
}

const mapStateToProps = state => ({
    customer: currentCustomer(state),
});

export default compose(
    connect(mapStateToProps),
    securePage([roles.admin]),
)(Timeline);



