/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 30-07-19.
 */

import React from "react";
import {Card, CardContent} from "@material-ui/core";
import BigCardHeader from "../../../component/Card/BigCardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const Variables = ({variables}) => {

    // The "Object" variables represent json that's probably to vast/technical to display to  endusers.
    // But at least show them in the console.
    Object.entries(variables)
          .filter(([_, variable]) => variable.type === "Object")
          .forEach(([field, variable]) => console.info(field, JSON.stringify(variable.value, null, 4)));



    return <Card>
        <CardContent>
            <BigCardHeader title="Technische gegevens"/>
            <List component="nav"
                  aria-label="Main mailbox folders">
                {
                    Object.entries(variables)
                          .filter(([_, variable]) => variable.type !== "Object")
                          .sort(([field1], [field2]) => field1 === field2 ? 0
                                                                          : field1 > field2 ? 1
                                                                                            : -1)
                          .map(([field, variable]) => {

                              let {value, type} = variable;
                              if (type === "Boolean") {
                                  value = value ? "true" : "false";
                              }

                              return <ListItem button
                                               key={field}>
                                  <ListItemText primary={field}
                                                key={1}/>
                                  <ListItemText primary={value}
                                                key={2}/>
                              </ListItem>;
                          })
                }
            </List>
        </CardContent>
    </Card>;
};

export default Variables
