import {Card, CardContent, Divider, Typography} from "@material-ui/core";
import React from "react";
import moment from "moment";
import classnames from "classnames";
import {withStyles} from "@material-ui/core/index";
import NoteIcon from "./NoteIcon";
import Partial from "../Partial/Partial";

const styles = theme => ({
    canvas: {height: "300px"},
    card: {
        marginTop: "0px",
        marginBottom: "30px",
        paddingTop: "30px",
        maxWidth: "70%"
    },
    fullWidthCard: {
        marginTop: "30px",
        paddingTop: "30px",
    },
    left: {
        alignSelf: "flex-start"
    },
    right: {
        alignSelf: "flex-end"
    },
    header: {
        fontSize: "25px"
    },
    extraHeader: {},
    footer: {
        float: "right",
        fontSize: "11px",
        marginTop: "-9px",
        marginBottom: "-15px",
    }
});

const date = note => moment(note.creationTime).format("D MMMM YYYY [om] HH:mm");

const Note = ({note, index, extraHeader = null, classes}) =>
    <Card key={index}
          className={classnames(extraHeader ? classes.fullWidthCard : classes.card,
                                index % 2 === 0 ? classes.left : classes.right)}>
        < CardContent>
            {
                extraHeader ? <Typography className={classes.extraHeader}
                                          variant="h3">
                    {extraHeader}
                </Typography> : null
            }

            <Typography className={classes.header}
                        variant="h2">
                <NoteIcon description={note.description}/>
                {note.description ? note.description : "Notitie toegevoegd"}
            </Typography>

            <Partial content={note.text}
                     showFadedContent={note.contributor !== "systeem" && note.contributor !== "Systeem"}/>

            <Divider/>
            <Typography className={classes.footer}
                        paragraph={true}
                        variant="body2">
                Op {date(note)} {note.contributor === "systeem" || note.contributor === "Systeem" ? "" : `door ${note.contributor}`}
            </Typography>

        </CardContent>
    </Card>

export default withStyles(styles)(Note);
