import {getJson, getReq} from "../../HTTP/get";
import {postJson} from "../../HTTP/post";

const useDummyData = () => `${process.env.REACT_APP_ENVIRONMENT}` === "create_dummy_data";

const endPoints = {
    REQUEST_PROPOSAL: "process/proposal",
    COMPLETE_PROPOSAL: "process/complete",
    DOCUMENTED_REQUEST: "process/documented-request",

};

const getServiceUrl = endpoint => `${process.env.REACT_APP_INSURANCE_PROCESSOR}/${endpoint}`;

const groupToData = group => {
    const fields = {};
    group.questions.forEach(function (question) {
        if (question.visible) {
            fields[question.field] = {
                type: question.type,
                value: question.value,
                description: question.description
            };
        }
    });

    return {
        header: group.header,
        fields: fields,
    }
};

const formToData = form => {
    let groups = [];
    form.groups.forEach(function (group) {
        groups.push(groupToData(group));
    });
    return {
        formType: form.formType,
        recipient: form.recipient,
        groups
    };
};

class InsuranceProcessClient {

    /**
     * Returns a promise that will resolve once the proprosal service has handled the request
     * (which may involve saving the proposal, calculating premiuns, sending notification-mails, etc.
     *
     * @param agent
     * @param customer
     * @param {object} requestForm
     * @return {Promise<object>}
     */
    static requestProposal(agent, customer, requestForm) {
        let data = {
            agent,
            businessCustomer: customer,
            form: formToData(requestForm),
        };

        return postJson(getServiceUrl(endPoints.REQUEST_PROPOSAL))(data)
    }

    /**
     * Returns a promise that will resolve once the InsuranceProcessor has started up a new DOCUMENTED_REQUEST process
     *
     * @param agent
     * @param customer
     * @param {object} requestForm
     * @return {Promise<object>}
     */
    static documentedRequest(agent, customer, requestForm) {
        let log = true;

        let data = {
            agent,
            privateCustomer: customer,
            form: formToData(requestForm),
        };
        return postJson(getServiceUrl(endPoints.DOCUMENTED_REQUEST), {}, log)(data)
    }



    /**
     * Returns a promise containing a list of the customers most recent requests
     * @param customer
     * @return {Promise<Array<object>>}
     */
    findProposals(customer) {
        const customerId = useDummyData() ? 14 : customer.id;
        return getJson()(`${getServiceUrl(endPoints.REQUEST_PROPOSAL)}completed/${customerId}`)
            .then(response => response.result);
    }

    /**
     * Returns a promise containing a list of the customers most recent requests
     * @param id
     * @return {Promise<Array<object>>}
     */
    findProposal(id) {
        const proposalId = useDummyData() ? 1 : id;
        return getJson()(`${getServiceUrl(endPoints.REQUEST_PROPOSAL)}/${proposalId}`)
            .then(response => response.result);
    }

    static completeProposal(caseInstanceId) {
        return getReq()(`${getServiceUrl(endPoints.COMPLETE_PROPOSAL)}/${caseInstanceId}`);
    }

}

const insuranceProcessClient = new InsuranceProcessClient();

export default InsuranceProcessClient;
export {
    insuranceProcessClient,
}
