import Sample from "../geometry/point/Sample";
import Curves from "./curve/Curves";
import Samples from "./Samples";

const createSample = (event, canvas) => new Sample(
    (event.clientX - canvas.getBoundingClientRect().left),
    (event.clientY - canvas.getBoundingClientRect().top)
)

/**
 * @class StrokeEventHandler
 * @property {Function} strokeBegin
 * @property {Function} strokeUpdate
 * @property {Function} strokeBegin
 * @property {Function} resizeCanvas
 **/
class StrokeEventHandler {

    /**
     * This class is supposed to be used as a Singleton: the constructor is private, instead the get()-method should be
     * used to get an instance (that method simply returns instance, creating it when necessary)
     *
     * @type {StrokeEventHandler}
     */
    static instance;

    /**
     * @type {Curves}
     */
    curves;

    /**
     * @type {Samples}
     */
    samples = new Samples();

    currentCanvas;

    /**
     *
     * @param currentCanvas
     * @param {Curves} curves
     * @private
     */
    constructor(currentCanvas, curves) {
        this.curves = curves;
        this.currentCanvas = currentCanvas;
    }

    /**
     * @param currentCanvas
     * @param {Object} props
     */
    static get(currentCanvas, props) {
        if (this.instance) {
            this.instance.currentCanvas = currentCanvas;
        }
        else {
            this.instance = new StrokeEventHandler(currentCanvas,
                                                   new Curves(props));
        }
        return this.instance;
    }

    reset() {
        this.samples = new Samples();
        this.curves.reset();
    }

    strokeBegin(event) {
        this.reset();
        this.strokeUpdate(event);
    }

    strokeUpdate(event) {
        const curves = this.curves;
        const samples = this.samples;
        const context = this.currentCanvas.getContext("2d");

        samples.add(createSample(event, this.currentCanvas));
        samples.getStroke(curves).draw(context);
    }

    strokeEnd(event) {
        const curves = this.curves;
        const samples = this.samples;
        const context = this.currentCanvas.getContext("2d");

        // It might seem reasonable to add the coordinates of the the strokeEndEvent as
        // a sample, but that results in a counterintuitive user experience:
        //     The user generally stops moving the mouse long before letting go of the
        //     mousebutton, and at that point doesn't expect the drawing to change anymore.
        // All that needs to be done is to finish the curve based on the samples collected so far.

        samples.getStroke(curves).draw(context);
    }

}

export default StrokeEventHandler;
