import {getJson} from "../HTTP/get";
import {postJson} from "../HTTP/post";

/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 14-8-18.
 */


const useDummyData = () => `${process.env.REACT_APP_ENVIRONMENT}` === "create_dummy_data";

const endPoints = {
    REQUEST_PROPOSAL: "requestProposal/",
    PDF: "test/bliss/pdf/factuur",
};

const getServiceUrl = endpoint => `${process.env.REACT_APP_PROPOSALSERVICE}/${endpoint}`;

const groupToData = group => {
    const fields = {};
    group.questions.forEach(function (question) {
        if (question.visible) {
            fields[question.field] = {
                value: question.value,
                description: question.description
            };
        }
    });

    return {
        header: group.header,
        fields: fields,
    }
};

const formToData = form => {
    let groups = [];
    form.groups.forEach(function (group) {
        groups.push(groupToData(group));
    });
    return {
        formType: form.formType,
        groups
    };
};

class ProposalClient {

    /**
     * Returns a promise that will resolve once the proprosal service has handled the request
     * (which may involve saving the proposal, calculating premiuns, sending notification-mails, etc.
     *
     * @param agent
     * @param customer
     * @param {object} requestForm
     * @return {Promise<object>}
     */
    static requestProposal(agent, customer, requestForm) {
        let data = {
            agent,
            businessCustomer: customer,
            form: formToData(requestForm),
        };
        
        return postJson(getServiceUrl(endPoints.REQUEST_PROPOSAL))(data)
    }

    /**
     * Returns a promise containing a list of the customers most recent requests
     * @param customer
     * @return {Promise<Array<object>>}
     */
    findProposals(customer) {
        const customerId = useDummyData() ? 14 : customer.id;
        return getJson()(`${getServiceUrl(endPoints.REQUEST_PROPOSAL)}completed/${customerId}`)
            .then(response => response.result);
    }

    /**
     * Returns a promise containing a list of the customers most recent requests
     * @param proposalId
     * @return {Promise<Array<object>>}
     */
    findProposal(id) {
        const proposalId = useDummyData() ? 1 : id;
        return getJson()(`${getServiceUrl(endPoints.REQUEST_PROPOSAL)}/${proposalId}`)
            .then(response => response.result);
    }


    static pdfUrl() {
        return `${getServiceUrl(endPoints.PDF)}`;
    }

}

const proposalClient = new ProposalClient();

export default ProposalClient;
export {
    proposalClient,
}
