/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 10-1-18.
 */

import React from "react"
import {CardMedia, Typography, withStyles} from "@material-ui/core";

const styles = theme => ({
    media: {
        height: 210,
    },
    header: {
        color: "white",
        fontSize: "24px",
        marginLeft: "24px",
        position: "relative",
        bottom: "48px",
    }
});

const CardMediaHeader = ({title, image, classes, ...other}) => (
    <div {...other} >
        <CardMedia className={classes.media}
                   image={image}
                   title={title}/>
        <Typography variant="h5"
                    className={classes.header}>
            {title}
        </Typography>
    </div>
);

export default withStyles(styles)(CardMediaHeader);
