/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 15-12-17.
 */
import React from "react";
import {range} from "../../../../util/util";
import {Button, Grid, Icon, IconButton, withStyles} from "@material-ui/core";

// NB: using withStyles() will insert the rules for Paginator above the rules for Button, so that
//     .button will not overrule the .MuiButton minWidth rule. Because of that then .button style is
//     including style, rather than className, below
const styles = theme => ({
    root: {
        marginTop: "17px",
    },
    button: {
        minWidth: "36px",
        padding: "0",
        margin: "0 2px",
    },
    iconButton: {
        height: "36px",
    }
});

/**
 * @param {number} currentPage
 * @param {number} itemsPerPage
 * @param {number} totalResults
 * @param {function} onPaging: number -> ()
 * @param classes
 */
const Paginator = ({currentPage, itemsPerPage, totalResults, onPaging, classes}) => {

    const firstPage = 1;
    const lastPage = Math.ceil(totalResults / itemsPerPage);

    const onFirstPage = currentPage === firstPage;
    const onLastPage = currentPage === lastPage;

    const pages = range(1, lastPage);

    return (
        <Grid container
              className={classes.root}
              justify="center">

            <Grid item>
                <IconButton className={classes.iconButton}
                            onClick={() => onPaging(1)}>
                    {onFirstPage ? null : <Icon>chevron_left</Icon>}
                </IconButton>
            </Grid>

            {
                pages.filter(page => Math.abs(currentPage - page) <= 2)
                     .map(page =>
                              <Grid key={page}
                                    item>
                                  {
                                      page === currentPage
                                          ? <Button variant="contained"
                                                    color="primary"
                                                    style={styles().button}>{page}</Button>
                                          : <Button onClick={() => onPaging(page)}
                                                    style={styles().button}>{page}</Button>
                                  }
                              </Grid>)
            }

            <Grid item>
                <IconButton className={classes.iconButton}
                            onClick={() => onPaging(lastPage)}>
                    {onLastPage ? null : <Icon>chevron_right</Icon>}
                </IconButton>
            </Grid>
        </Grid>);
};

export default withStyles(styles)(Paginator);
