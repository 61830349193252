import React from "react";
import LoggedOn from "../../component/Main/Base/LoggedOn/LoggedOn";
import securePage from "../SecurePage/SecurePage";
import {roles} from "../../../api/login/LoginClient";
import Dossiers from "../../component/Dossier/Dossiers";

const DossiersPage = () =>
    <LoggedOn>

        <Dossiers title="Dossiers"
                  mediaHeader={true}
                  showAllways={true}/>
    </LoggedOn>

export default securePage([roles.admin])(DossiersPage);
