/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 4-7-19.
 */
import React from "react";
import Promise from "bluebird";
import {instanceVariables, processInstance, setInstanceVariable} from "../../../../../../api/process/process/processes";
import {Forms} from "../../../../../../api/form/FormClient";
import QuestionForm from "../../../../../component/QuestionForm/smart/QuestionForm";
import {retrieveForm} from "../../../../../../action/creator/asynchonous/form/retrieveForm";
import {CircularProgress, Typography, withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMediaHeader from "../../../../../component/CardMediaHeader/CardMediaHeader";
import {push} from "react-router-redux";
import {paths} from "../../../../../paths";
import insuranceProcessClient from "../../../../../../api/process/insuranceProcess/InsuranceProcessClient";
import {cleanupVariables} from "../../../util";
import {compose} from "redux";
import {connect} from "react-redux";
import {noteClient} from "../../../../../../api/relation/note/NoteClient";
import {currentUser} from "../../../../../../state/selector/user";

import MiniPage from "../../../../../component/MiniPage/MiniPage";
import MiniPages from "../../../../../component/MiniPage/MiniPages";
import Request from "../../documentedRequest/component/Request";
import {setAssignee} from "../../assignee";
import {fullName} from "../../../../../../util/customer";
import {promiseAction} from "../../../../../../middleware/promiseDispatch";
import {closeTask} from "../../../../../../api/process/task/tasks";
import Notes from "../../../../../component/Note/Notes";
import DocumentUpload from "../../../../../component/Documents/DocumentUpload";
import {getQuestionValue} from "../../../../../../util/form";


const styles = theme => ({
    card: {paddingLeft: "24px"},
    documents: {
        padding: "24px 0 24px 0",
    },
});

const getNotes = customer => noteClient.getNotes(customer);


const pickCustomer = variables => variables.customer ? variables.customer : variables.privateCustomer;

class ApproveCustomer extends React.Component {

    state = {
        instance: null,
        agent: null,
        customer: null,
        request: null,
        notes: [],
    };

    componentDidMount() {
        const {task} = this.props;
        const {processInstanceId} = task;


        Promise.all([processInstance(processInstanceId),
            instanceVariables(processInstanceId)])
            .spread((instance, variables) => {
                this.setState({
                    instance,
                    agent: cleanupVariables(variables).agent,
                    customer: pickCustomer(cleanupVariables(variables)),
                    request: cleanupVariables(variables).form,
                });
            })
            .then(() => getNotes(this.state.customer).then(notes => this.setState({notes})));

        this.props.loadForm();
    }

    render() {
        const {customer, notes} = this.state;
        const {task, classes} = this.props;


        if (this.state.instance && this.state.request && this.state.agent) {
            const approve = form => {

                const setVariablesPromise = getQuestionValue(form)("geaccepteerd") === "Ja"
                    ? noteClient.addSystemNote(customer,
                        "Bankgarantie akkoord",
                        "Bankgarantie akkoord")
                        .then(_ => Promise.all([
                            setAssignee(task.processInstanceId)(
                                {name: fullName(customer), email: "info@hypotheekadviseurs.nl"}),

                            setInstanceVariable(task.processInstanceId)(
                                "String",
                                "bankgarantie_akkoord",
                                true),

                        ]))
                    : noteClient.addSystemNote(customer,
                        "Bankgarantie afgewezen",
                        "Bankgarantie afgewezen").then(_ => setInstanceVariable(task.processInstanceId)("String", "bankgarantie_akkoord", false));

                // return promiseAction(setVariablesPromise.then(_ => nop));
                return promiseAction(setVariablesPromise.then(_ => closeTask(task))
                    .then(_ => push(paths.mytasks)));
            };

            return notes ? [
                <Card key={1}>
                    <CardMediaHeader title={`Actie ${task ? task.name : ""}`}
                                     image="/images/handshake.jpg"/>

                    <div className={classes.card}>
                        <Typography key="0"
                                    variant="h5">
                            Opdracht bevestiging
                        </Typography>

                        <br/>

                        <QuestionForm endpoint={Forms.assesCustomer}
                                      submitAction={approve}
                                      stepPromise={Promise.resolve}/>
                    </div>
                </Card>
                ,
                <Card key={2}>
                    <Typography variant="h5">Aanvraag</Typography>

                    <MiniPages miniPages={[
                        MiniPage.create("Verzoek", <Request customer={customer}
                                                            request={this.state.request}/>),
                        MiniPage.create(
                            "Documenten",
                            <div className={classes.documents}>
                                <DocumentUpload
                                    typeFilter={([type]) => ['koopovereenkomst', 'renteaanbod', 'overige'].includes(type)}/>
                            </div>),
                        MiniPage.create("Tijdlijn", <Notes customer={customer}
                                                           notes={notes}/>),
                    ]}/>
                </Card>
            ] : <CircularProgress/>;
        }
        return <div/>;
    }
}

const mapStateToProps = state => ({
    user: currentUser(state),
});

const mapDispatchToProps = dispatch => ({
    back: task => () => dispatch(push(paths.task)),
    completeTask: task => () => insuranceProcessClient.completeTask(task.id),
    loadForm: () => dispatch(retrieveForm(Forms.assesCustomer)), dispatch,
});

const decorate = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
);

export default decorate(ApproveCustomer);
