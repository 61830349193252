import React from "react";
import PropTypes from "prop-types";
import HTMLReactParser from "html-react-parser";
import Anchor from "../Anchor/Anchor";
import {Typography, withStyles} from "@material-ui/core";
import classnames from "classnames";

/**
 * Very basic check to determine if we should treat the string as text (ie respect whitespace)
 * or as html (ie ignore whitespace)
 *
 * @param {string} string
 * @returns {boolean}
 */
const isHtml = string => string.trim().substr(0, 1) === "<";

const styles = theme => ({
    text: {
        whiteSpace: "pre-wrap"
    },
    html: {},
    open: {},
    closed: {
        maxHeight: "17px",
        overflow: "hidden",
    },
    fadedContent: {
        maxHeight: "60px",
        overflow: "hidden",

    },
    anchor: {
        display: "block",
        marginBottom: "5px",
    }
});

/**
 * Returns the number of newlines in the the string
 * @param {string} string
 */
const height = string => string.split(/\r\n|\r|\n/).length

class Partial extends React.Component {

    static propTypes = {
        showFadedContent: PropTypes.bool,
        content: PropTypes.string.isRequired,
        classes: PropTypes.object.isRequired,
    };

    state = {
        open: false,
    }

    render() {

        const {content, showFadedContent = true, variant = "body1", classes} = this.props
        const {open} = this.state;

        const hide = isHtml(content) || height(content) > 5

        return hide ? <Typography className={classnames(isHtml(content) ? classes.html
                                                                        : classes.text,
                                                        open ? classes.open
                                                             : showFadedContent ? classes.fadedContent
                                                                                : classes.closed)}
                                  component="div"
                                  variant={variant}>
                        <Anchor onClick={_ => this.setState({open: !open})}>
                            <span className={classes.anchor}>{open ? "Verberg details" : "Toon details"}</span>
                        </Anchor>
                        {isHtml(content) ? HTMLReactParser(content) : content}
                    </Typography>
                    : <Typography className={classnames(isHtml(content) ? classes.html
                                                                        : classes.text,
                                                        classes.open)}
                                  component="div"
                                  variant={variant}>
                   {isHtml(content) ? HTMLReactParser(content) : content}
               </Typography>
    }
}

export default withStyles(styles)(Partial);
