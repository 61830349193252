import {empty} from "../process/util";

const setDefaults = form => ({
    ...form,
    groups: form.groups.map(group => {
        return {
            ...group,
            questions: group.questions.map(setQuestionDefault),
        };
    }),
});

const setQuestionDefault = question => {

    return empty(question.value) && !empty(question.defaultValue) ? {
        ...question,
        value: question.defaultValue,
    } : question;
};

export default setDefaults;
