/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 25-9-18.
 */
import React from "react";
import {Button, CardContent, CircularProgress} from "@material-ui/core";
import teal from "@material-ui/core/colors/teal";
import Typography from "@material-ui/core/Typography/Typography";
import paths from "../../../paths";
import {connect} from "react-redux";
import {push} from 'react-router-redux';
import CardMediaHeader from "../../../component/CardMediaHeader/CardMediaHeader";
import Card from "@material-ui/core/Card";
import {closeTask} from "../../../../api/process/task/tasks";
import {promiseAction} from "../../../../middleware/promiseDispatch";
import {noteClient, noteTypes} from "../../../../api/relation/note/NoteClient";
import {currentCustomer} from "../../../../state/selector/customer";
import {currentUser} from "../../../../state/selector/user";
import retrieving from "../../../../action/creator/asynchonous/retrieving";
import ActionType from "../../../../action/ActionType";
import {errorAction} from "../../../../api/relation/RelationClient";

/**
 * @param task
 * @returns Note
 */
const note = task => ({
    description: noteTypes.TASK_CLOSED,
    text: `Algemene taak '${task.name}' afgesloten`,
})

class GenericTask extends React.Component {

    render() {
        const {user, customer, closeTask, task} = this.props;
        return (

            <Card>
                <CardMediaHeader title={`Taak ${task ? task.name : ""}`}
                                 image="/images/handshake.jpg"/>
                <CardContent>
                    {
                        task === null
                        ? <CircularProgress style={{color: teal[500]}}
                                            thickness={6}/>
                        : [
                                <Typography key="0"
                                            variant="h2">
                                    {task.name}
                                </Typography>,
                                <Typography key="1"
                                            paragraph={true}>
                                    Wanneer de bijbehorende werkzaamheden naar tevredenheid uitgevoerd zijn kan de taak hier afgesloten worden
                                </Typography>,
                                <Button key="2"
                                        variant="contained"
                                        color='primary'
                                        onClick={() => {
                                            closeTask(user, customer, task)
                                        }}>Taak sluiten</Button>
                            ]
                    }
                </CardContent>
            </Card>
        )
    }
}

const mapStateToProps = state => ({
    customer: currentCustomer(state),
    user: currentUser(state),
});

const dispatchToProps = dispatch => ({
    closeTask: (user, customer, task) => dispatch(promiseAction(noteClient.addNote(user, customer, note(task))
                                                                          .then(_ => closeTask(task))
                                                                          .then(_ => push(paths.task)),
                                                                retrieving(ActionType.asynchronous.form.post.POSTING),
                                                                errorAction))
});
export default connect(mapStateToProps, dispatchToProps)(GenericTask);
