const APP_INSTANCE = {
    BANKGARANTIE:        "BANKGARANTIE",
    BLISS:               "BLISS",
    BOUWKUNDIGE_KEURING: "BOUWKUNDIGE_KEURING",
    ERASMUS:             "ERASMUS",
    MOGELIJK:            "MOGELIJK",
    QUICK_SCAN:          "QUICK_SCAN",
    WONING_SCAN:         "WONING_SCAN",
}

const appInstance = () => {
    if (process.env.NODE_ENV === "production") {
        switch (window.location.hostname) {
            case "intermediair.bankgarantie.com":
            case "aanvraag.bankgarantie.com":
            case "portal.bankgarantie.com":
            case "notaris.bankgarantie.com":
            case "aanvragen.bankgarantie.com":
                return APP_INSTANCE.BANKGARANTIE;
            case "mijn.qhypotheekadviseurs.nl":
            case "voorons.qhypotheekadviseurs.nl":
            case "bliss.blisshypotheekadviseurs.nl":
                return APP_INSTANCE.BLISS;
            case "bouwkundigekeuring.blisshypotheekadviseurs.nl":
                return APP_INSTANCE.BOUWKUNDIGE_KEURING;
            case "erasmus.erasmusverzekeringen.nl":
                return APP_INSTANCE.ERASMUS
            case "bp6.up2serve.com":
                return APP_INSTANCE.MOGELIJK;
            case "quickscan.blisshypotheekadviseurs.nl":
                return APP_INSTANCE.QUICK_SCAN;
            case "woningscan.greenfactor.nl":
            case "woningscan.energiewijzer.nl" :
            case "zonnepanelen.energiewijzer.nl":
            case "woningscan.blisshypotheekadviseurs.nl":
                return APP_INSTANCE.WONING_SCAN;
            default:
                throw Error(`Could not determine AppInstance for host ${window.location.hostname}`);
        }
    } else {
        return APP_INSTANCE.BLISS;
    }
}

export {
    APP_INSTANCE,
    appInstance,
};
