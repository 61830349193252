import React from "react";
import {connect} from "react-redux";
import SearchBar from "../../../../component/SearchBar/SearchBar";
import SearchResults from "../../../../component/SearchResults/SearchResults";
import search from "../../../../../action/creator/asynchonous/search/search";
import {currentSearch, searchResults, searchTerm} from "../../../../../state/selector/customer";
import {accept, deleteCustomer} from "../../../../../action/creator/asynchonous/customer/status";
import {routeCustomer} from "../../../../../action/creator/router";
import {compose} from "redux";
import {Card, CardContent} from "@material-ui/core";
import CardMediaHeader from "../../../../component/CardMediaHeader/CardMediaHeader";
import securePage from "../../../SecurePage/SecurePage";
import LoggedOn from "../../../../component/Main/Base/LoggedOn/LoggedOn";
import {customerToSearchResult} from "../../../../../api/relation/customer/util";
import {errorAction} from "../../../../../api/relation/RelationClient";
import {roles} from "../../../../../api/login/LoginClient";
import {currentUser} from "../../../../../state/selector/user";
import {noteClient, noteTypes} from "../../../../../api/relation/note/NoteClient";

/**
 *
 * @param {string} currentSearchTerm
 * @param {SearchResults} searchResults
 * @param {function} onSearch: number -> string ->  ()
 * @param {function} onView: object -> ()
 * @param {function} onAccept: customerObject -> ()
 * @param classes
 * @return {JSX.Element}
 */
const Customers = ({user, currentSearchTerm, searchResults, onSearch, onView, onAccept, onDelete}) =>
    <LoggedOn>
        <SearchBar currentSearchTerm={currentSearchTerm}
                   onSearch={onSearch(user)(1)}
                   customerStatus="PROSPECT"/>

        <Card style={{borderRadius: 2}}>
            <CardMediaHeader title="Prospects"
                             image="/images/handshake.jpg"/>
            <CardContent>
                <SearchResults searchResults={searchResults}
                               mapper={customerToSearchResult}
                               onClick={onView}
                               action="Accepteer"
                               actionIcon="check"
                               onAction={onAccept}
                               onDelete={onDelete}
                               onPaging={page => onSearch(user)(page)(currentSearchTerm)}/>
            </CardContent>
        </Card>
    </LoggedOn>;

const mapStateToProps = state => ({
    user: currentUser(state),
    currentSearchTerm: searchTerm(state),
    searchResults: searchResults(state),
    search: currentSearch(state),
});

const mapDispatchToProps = dispatch => ({
    onView: customer => routeCustomer(customer.id).then(dispatch),
    dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>
    ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        onAccept: customer => noteClient.addNote(stateProps.user, customer, {
            description: noteTypes.ACCEPT_CUSTOMER,
            text: ""
        })
            .then(_ => accept(customer, stateProps.search).then(dispatchProps.search)),
        onDelete: customer => deleteCustomer(customer, stateProps.search).then(dispatchProps.search),
        onSearch:
            user =>
                page =>
                    searchTerm =>
                        search(user)({
                            searchTerm: searchTerm,
                            page: page,
                            customerStatus: "PROSPECT"
                        }).then(dispatchProps.dispatch)
                            .catch(error => dispatchProps.dispatch(errorAction(error))),
    });

const decorate = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    securePage([roles.admin]),
);
export default decorate(Customers);
