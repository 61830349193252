/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 5-9-17.
 */
import FormClient from "../../../../api/form/FormClient";
import ActionType from "../../../ActionType";
import retrieving from "../retrieving";
import error from "../../error";
import retrieved from "../retrieved";
import {promiseAction} from "../../../../middleware/promiseDispatch";
import form, {formType} from "../../../../state/selector/form/form";
import {compoundAction} from "../../../../middleware/compoundDispatch";
import stepAction from "./stepAction";
import nop from "../../nop";

const errorStep = form => form.groups.findIndex(group => group.valid === false);
const hasError = form => errorStep(form) >= 0;
const errorStepAction = (form, currentStep) => hasError(form) && errorStep(form) < currentStep ? stepAction(errorStep(form)) : nop;

/**
 * NB. these days the endpoint is stored in the state (eg form.type = "PROPOSAL_BCA")
 *     So we could probably use postFormFromState everywhere...
 *
 * @return {function(string): StateActionCreator}
 */
const postForm =
    endpoint =>
        state =>
            promiseAction(
                FormClient.postForm(endpoint, form(state))
                          .then(form => compoundAction(retrieved(ActionType.asynchronous.form.post.RESULT)(form),
                                                       errorStepAction(form, state.currentStep))),
                retrieving(ActionType.asynchronous.form.post.POSTING),
                error("Problem posting QuestionForm"),
            );

/**
 * @type {StateActionCreator}
 */
const postFormFromState = state => {
    return postForm(formType(state))(state);
};

export default postForm;
export {
    postForm,
    postFormFromState,
}







