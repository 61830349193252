/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 20-02-20.
 */

import {instanceVariables, processInstance} from "../process/processes";
import {tasks} from "./tasks";
import {fullName} from "../../../util/customer";
import {caseInstance} from "../case/cases";
import Promise from "bluebird";
import {friendlyNameFromDefinitionId} from "../util/process";
import {customerClient} from "../../relation/customer/CustomerClient";
import {isPresent} from "../../../util/util";
import {cleanupVariables} from "../../../app/page/process/util";

const customerNameProcess = processVariables => {

    if (processVariables.privateCustomer != null && processVariables.privateCustomer.value != null) {
        return fullName(processVariables.privateCustomer.value);
    }
    if (processVariables.businessCustomer != null && processVariables.businessCustomer.value != null) {
        return fullName(processVariables.businessCustomer.value);
    }
    if (processVariables.customer != null && processVariables.customer.value != null) {
        return fullName(processVariables.customer.value);
    }
    return "";
};

const fetchName = customerId => isPresent(customerId) ? customerClient.fetchCustomer(customerId)
        .then(customer => fullName(customer))
    : Promise.resolve("");

const customerName = processVariables => customerNameProcess(processVariables) === "" ? fetchName(processVariables.customerId)
    : Promise.resolve(customerNameProcess(processVariables));

/**
 * @param task
 * @return {Promise<string>}
 */
const parentName = task => {
    if (task.caseInstanceId) {
        return caseInstance(task.caseInstanceId)
            .then(instance => instance.caseDefinitionName);
    } else if (task.processInstanceId) {
        return (processInstance(task.processInstanceId))
            .then(instance => friendlyNameFromDefinitionId(instance.definitionId));
    } else {
        return Promise.resolve("Onbekend");
    }
};

/**
 * Stores the state in component.state.searchResults
 * @param component a React Component
 * @param {Object} query
 * @return Promise<void>
 */
const loadTasks =
    (component, query = undefined) =>
        tasks(query)
            .then(searchResults => {

                // Prefill state with intermediate results
                component.setState({searchResults: searchResults});

                // And task by task fill in the details (customername/processname)
                searchResults.results.forEach((searchResult, index) => {
                    Promise.all([parentName(searchResult),
                        instanceVariables(searchResult.processInstanceId)])
                        .spread((processName, variables) =>
                            customerName(cleanupVariables(variables)).then(customerName => {
                                searchResults.results[index] = {...searchResult, customerName, processName};
                                component.setState({searchResults})
                            }));
                })
            }).catch(error => console.error("Error loading tasks: ", error.message));

export default loadTasks;






