import React from "react";
import {withStyles} from "@material-ui/core/index";
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import FactuurRow from "./FactuurRow";

const styles = theme => ({
    orders: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: "30px"
    }
});

class Facturen extends React.Component {


    render() {

        const {facturen, useHeader = true, classes} = this.props;
        return facturen.length === 0
            ? <div/>
            : <div className={classes.orders}>

                <Typography className={classes.header}
                            variant="h2">
                    Facturering
                </Typography>
                <Table>
                    {
                        useHeader ? <TableHead>
                                <TableRow>
                                    <TableCell>Datum</TableCell>
                                    <TableCell>Bedrag</TableCell>
                                    <TableCell>Nummer</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            : null
                    }

                    <TableBody>
                        {
                            facturen.map(factuur => <FactuurRow factuur={factuur}
                                                                key={factuur.id}/>)
                        }
                    </TableBody>
                </Table>
            </div>;
    }
}

export default withStyles(styles)(Facturen);
