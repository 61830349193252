/**
 * Returns the value (or array of values for MultiSelect questions) of the first
 * question in the form whose field equals fieldName
 * @param form
 * @param fieldName
 * @returns {string|array}
 */
const getQuestionValue =
    form =>
        fieldName => {
            for (const group of form.groups) {
                for (const question of group.questions) {
                    if (question.field === fieldName) {
                        return question.value;
                    }
                }
            }
        };

/**
 * Returns the value (or array of values for MultiSelect questions) of the first
 * question in the first group of the form whose field equals fieldName
 *
 * I guess this implementation is a quicky/oneoff, done for forms consisting of only one group.
 * If so, at one point we should consider using `getQuestionValue` above everywhere
 *
 * @param form
 * @param fieldName
 * @returns {string|array}
 */
const getQuestionValueSingleGroup =
    form =>
        fieldName => {
            let value = "";
            form.groups[0].questions.forEach(question => {
                if (question.field === fieldName) {
                    value = question.value;
                }
            })
            return value;
        };

export {
    getQuestionValue,
    getQuestionValueSingleGroup,
}
