/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 5-9-17.
 */

import ActionType from "../../action/ActionType";

const handleRetrievedForm = (form, action) => {
    if (action.type === ActionType.asynchronous.form.get.RETRIEVED ||
        action.type === ActionType.asynchronous.form.post.RESULT) {
        return action.payload;
    }
    return form;
};
export default handleRetrievedForm;




