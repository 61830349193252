/**
 * @type Device
 */
import {resizeCanvas} from "../SignaturePad";

class Browser {

    /**
     * type {StrokeEventHandler}
     */
    strokeEventHandler;

    /**
     *
     * @param {StrokeEventHandler} strokeEventHandler
     */
    register(strokeEventHandler) {
        this.strokeEventHandler = strokeEventHandler;
        window.addEventListener("resize", this.resizeCanvas.bind(this));
        this.resizeCanvas();
    }

    unRegister() {
        window.removeEventListener("resize", this.resizeCanvas);
    }

    /**

     */
    resizeCanvas() {
        const currentCanvas = this.strokeEventHandler.currentCanvas;
        if (typeof currentCanvas !== "undefined") {
            resizeCanvas(currentCanvas);
        }
    }
}

export default Browser;
