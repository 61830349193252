/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 9-10-18.
 */

export default {
    input: {display: "none"},

    // Dropzone styles (copy of the styles in the dropzone package, adapted to the component)
    rejectedDropzone: {
        borderStyle: 'solid',
        borderColor: '#c66',
        backgroundColor: '#eee'
    },
    disabledDropzone: {
        opacity: 0.5
    },
    activeDropzone: {
        borderStyle: 'solid',
        borderColor: '#6c6',
        backgroundColor: '#eee'
    },
    defaultDropzone: {
        width: "100%",
        height: "auto",
        borderWidth: 1,
        borderColor: '#666',
        borderStyle: 'dashed',
        borderRadius: 5,
        marginTop: "40px",
        padding: "25px 25px 25px 25px"
    }
}
