/**
 * Created by M.J. van der Werf <vanderwerf@bluehorizon.nl> on 15-8-18.
 */
import React from "react";
import LoggedOff from "../../../../../component/Main/Base/LoggedOff/LoggedOff";
import {Card, CardContent, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import BigCardHeader from "../../../../../component/Card/BigCardHeader";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    typography: {
        marginBottom: "10px"
    },
});

const ThanksForSignature =
    ({classes}) =>
        <LoggedOff header="Bedankt">
            <Grid item
                  lg={12}
                  md={12}>
                <Card>
                    <CardContent>
                        <BigCardHeader title="Bedankt"/>
                        <Typography className={classes.typography}
                                    variant="body1">
                            Wij zullen uw ondertekening zo spoedig mogelijk verwerken </Typography>
                    </CardContent>
                </Card>

            </Grid>
        </LoggedOff>

export default withStyles(styles)(ThanksForSignature);
